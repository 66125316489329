export const delay = (delayInMs) => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(2);
    }, delayInMs);
  });
};


// export module
export default {
  delay,
};