<template>
  <div class="requisites-modal"
    @close-modal="onModalClose"
  >
    <div class="requisites-modal__inner">
      <div class="row">

        <div class="column">
          <div class="requisites-modal__dragAndDrop pointer"
               @dragover="dragover"
               @dragleave="dragleave"
               @drop="drop">
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.5 29.7002C1.89782 29.7002 2.27936 29.8582 2.56066 30.1395C2.84196 30.4208 3 30.8024 3 31.2002V38.7002C3 39.4958 3.31607 40.2589 3.87868 40.8215C4.44129 41.3841 5.20435 41.7002 6 41.7002H42C42.7957 41.7002 43.5587 41.3841 44.1213 40.8215C44.6839 40.2589 45 39.4958 45 38.7002V31.2002C45 30.8024 45.158 30.4208 45.4393 30.1395C45.7206 29.8582 46.1022 29.7002 46.5 29.7002C46.8978 29.7002 47.2794 29.8582 47.5607 30.1395C47.842 30.4208 48 30.8024 48 31.2002V38.7002C48 40.2915 47.3679 41.8176 46.2426 42.9428C45.1174 44.0681 43.5913 44.7002 42 44.7002H6C4.4087 44.7002 2.88258 44.0681 1.75736 42.9428C0.632141 41.8176 0 40.2915 0 38.7002V31.2002C0 30.8024 0.158035 30.4208 0.43934 30.1395C0.720644 29.8582 1.10218 29.7002 1.5 29.7002Z" fill="url(#paint0_linear)"/>
              <path d="M22.9379 35.562C23.0773 35.7017 23.2428 35.8125 23.425 35.8881C23.6073 35.9638 23.8026 36.0027 23.9999 36.0027C24.1972 36.0027 24.3926 35.9638 24.5748 35.8881C24.7571 35.8125 24.9226 35.7017 25.0619 35.562L34.0619 26.562C34.3436 26.2803 34.5018 25.8983 34.5018 25.5C34.5018 25.1017 34.3436 24.7197 34.0619 24.438C33.7803 24.1563 33.3983 23.9981 32.9999 23.9981C32.6016 23.9981 32.2196 24.1563 31.9379 24.438L25.4999 30.879V4.5C25.4999 4.10218 25.3419 3.72064 25.0606 3.43934C24.7793 3.15804 24.3978 3 23.9999 3C23.6021 3 23.2206 3.15804 22.9393 3.43934C22.658 3.72064 22.4999 4.10218 22.4999 4.5V30.879L16.0619 24.438C15.7803 24.1563 15.3983 23.9981 14.9999 23.9981C14.6016 23.9981 14.2196 24.1563 13.9379 24.438C13.6563 24.7197 13.498 25.1017 13.498 25.5C13.498 25.8983 13.6563 26.2803 13.9379 26.562L22.9379 35.562Z" fill="url(#paint1_linear)"/>
              <defs>
                <linearGradient id="paint0_linear" x1="24" y1="29.7002" x2="24" y2="44.7002" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#8F21C9"/>
                  <stop offset="0.0001" stop-color="#8F21C9"/>
                  <stop offset="1" stop-color="#4640B9"/>
                </linearGradient>
                <linearGradient id="paint1_linear" x1="23.9999" y1="3" x2="23.9999" y2="36.0027" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#8F21C9"/>
                  <stop offset="0.0001" stop-color="#8F21C9"/>
                  <stop offset="1" stop-color="#4640B9"/>
                </linearGradient>
              </defs>
            </svg>
            <p class="requisites-modal__dragAndDrop-text">
              {{ $t('TaxText.move_requisites_files') }}
            </p>
            <input id="assetsFieldHandle"
                   class="file_input pointer" multiple
                   type="file" name="fields[assetsFieldHandle][]"
                   @change="onChange" ref="file" accept="*.*" />
          </div>
        </div>

        <div class="column" style="padding: 20px 20px 20px 20px;">

                <!-- Upload Files List  -->
                <div v-if="fileList.length > 0">
                  <p class="new-account__item-text" style="margin-top: -15px;">
                    {{ $t('Photos.files_wait_upload') }}
                  </p>
                  <div v-if="reloadPreview">
                    <p
                        class="upload-file"
                        v-for="file in fileList"
                        @click="removeFile(file)"
                        :title="$t('Buttons.delete')"
                        :key="file">
                        {{ file.name }}
                    </p>
                  </div>

                  <!--div @click="uploadScans" class="new-account__logo-button">
                    {{ $t('Buttons.upload_file') }}
                  </div-->

                </div>

                <!-- Uploaded Files List  -->
                <div v-if="allFiles.length > 0">
                  <p class="new-account__item-text" style="margin-top: 10px;">
                    {{ $t('Photos.files_uploaded') }}
                  </p>
                  <div
                      class="images photo-preview"
                  >
                    <p
                        class="download-file"
                        v-for="file in allFiles"
                        @click="downloadFile(file)"
                        :title="$t('Buttons.download_file')"
                        :key="file">
                        {{ getFileName(file) }}
                    </p>
                  </div>

                </div>
        </div>

      </div>

      <div class="requisites-modal__buttons-block">
          <div
              v-if="editPermission"
              class="requisites-modal__download-button"
              @click="uploadFiles"
          >
              <p>{{ $t('Buttons.download') }}</p>
          </div>
          <div
              class="requisites-modal__back-button"
              @click="close"
          >
              <p>{{ $t('Buttons.back') }}</p>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

import {delay} from "@/tools/utilsTools";
import {hasContent, getFileName} from "@/tools/dataTools";

import DragDrop from "@/mixins/dragDropEvents";

import { baseURL } from '@/common/config.js'

export default {
  name: 'RequisitesUserDownloadModal',
  emits: ['refresh','close-modal'],
  components: {
  },
  props: {
    editPermission: Boolean,
    user: Object,
  },
  mixins: [DragDrop],
  delimiters: ['${', '}'], // Avoid Twig conflicts
  data() {
    return {
      isLoading: false,

      // drag and drop files
      reloadPreview: true,
      fileList: [],
      uploadedFiles: null,

      prizes: [],
      files: [],
    };
  },
  mounted() {
    this.prepareUserData()
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    user: function (newValue, oldValue) {
      this.prepareUserData()
    },
    isLoading() {
      if(this.isLoading)
        this.$progress.start();
      else
        this.$progress.finish();
    },
  },
  computed: {
    allFiles() {
      return this.uploadedFiles ? this.uploadedFiles :(this.files ? this.files : []);
    },
  },
  methods: {
    ...mapActions(["UPLOAD_PRIZES_REQUISITE"]),

    getFileName,

    prepareUserData() {
      let prizes = []
      let files = []

      // go through all participants
      if (hasContent(this.user) && hasContent(this.user.participants)) {
        this.user.participants.map((participant) => {

          // go through all prizes
          if(participant.prizes) {
            participant.prizes.map((prize) => {
              prizes.push(prize.id)

              // go through all prize files
              if(prize.files) {
                prize.files.map((file) => {
                  if(!files.includes(file))
                    files.push(file)
                })
              }
            })
          }
        })
      }

      this.prizes = prizes
      this.files = files
    },

    async uploadFiles() {
      if(this.fileList && this.fileList.length > 0) {
        this.$notify({
          type: "notification",
          title: this.$t('Photos.process_is_active'),
          text: this.$t('Photos.send_file'),
        });

        // try to send files
        for (let i = 0; i < this.fileList.length; i++) {
          await delay(1000)
          let result = await this.uploadFile(this.fileList[i])
          this.fileList[i].uploaded = result
        }

        // remove uploaded files
        this.fileList = this.fileList.filter(file => file.uploaded === false)
      }
      else {
        this.$notify({
          type: "warn",
          title: this.$t('Errors.error_not_ok'),
          text: this.$t('Photos.file_is_not_selected'),
        });
      }
    },

    async uploadFile(file) {
      return new Promise(resolve => {
        if(file == null)
          resolve(false);

        this.isLoading = true

        let formData = new FormData();

        formData.append("scan", file);
        formData.append("name", file.name);
        formData.append("ids", this.prizes);

        this.UPLOAD_PRIZES_REQUISITE( {
          ids: this.prizes,
          name: file.name,
          scan: formData,
        }).then((res) => {
          this.isLoading = false

          if (res.success) {

            this.uploadedFiles =  res.files
            this.$emit('refresh', this.user)

            this.$notify({
              type: "success",
              title: this.$t('Errors.success_operation'),
              text: this.$t('Photos.file_upload_success'),
            });
            resolve(true)
          } else {
            this.$notify({
              type: "error",
              title: this.$t('Errors.error_not_ok'),
              text: this.$t('Photos.file_upload_error'),
            });

            resolve(false)
          }
        });

      });
    },

    close() {
      this.fileList = [];

      this.$emit('close-modal');
    },

    // drag and drop files
    async onChange() {
      this.fileList = [...this.$refs.file.files];

      for (let i = 0; i < this.fileList.length; i++) {
        if(this.fileList[i].url == null) {
          this.reloadPreview = false
          //await getFileImage(this.fileList[i])
          this.reloadPreview = true
        }
      }
    },

    // eslint-disable-next-line no-unused-vars
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
    },

    removeFile(file) {
      if(this.fileList && file) {
        if (
            confirm(this.$t('Photos.remove_file'))
        ) {
          //
          this.fileList = this.fileList.filter(f => f !== file)
        }
      }
    },

    downloadFile(file) {
      if (hasContent(file)) {
        const filename = getFileName(file)
        const link = document.createElement('a')

        link.href = `${baseURL}${file}`
        link.download = filename
        link.target = '_blank'

        link.click()
      }
    },

  }
}
</script>

<style lang="scss" src="@/assets/scss/common.scss"></style>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixin.scss";

.requisites-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 45px 78px 25px 45px;
    background-color: $white;
    border-radius: 10px;
    box-shadow: 0px 0px 8px 2px #CED5F480;
    z-index: 1;

    &__dragAndDrop {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      //width: 600px;
      height: 270px;
      margin-bottom: 25px;
      padding: 67px 67px 67px;
      border: 1px dotted $purple;
      border-radius: 10px;
      background-color: $SideBlocksColor;
      cursor: pointer;
      svg {
          margin: 0 auto 35px;
      }
    }

    &__dragAndDrop-text {
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      cursor: pointer;
      color: $black;
    }

    &__buttons-block {
        display: flex;
        justify-content: center;
    }

    &__download-button {
        width: 190px;
        height: 35px;
        margin-right: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(180deg, #8F21C9 0%, #8F21C9 0.01%, #4640B9 100%);
        color: $white;
        font-size: 16px;
        border-radius: 5px;
        cursor: pointer;
    }

    &__back-button {
        width: 190px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $purple;
        font-size: 16px;
        border: 1px solid $purple;
        border-radius: 5px;
        cursor: pointer;
    }
}

.photo-preview {
  display: flex;
  flex-wrap: wrap;
  &__item {
    width: 70px;
    height: 70px;
    cursor: pointer;
    margin-bottom: 20px;
    &:not(:last-child) {
      margin-right: 30px;
    }
  }
  &__img {
    width: 70px;
    height: 70px;
  }
}

.upload-file {
  margin-bottom: 5px;
  cursor: no-drop;
}

.download-file {
  margin-bottom: 5px;
  cursor: context-menu;
}

.file_input {
  margin-bottom: -47px;
  width: inherit;
  height: inherit;
  opacity: 0;
  overflow: hidden;
  position: absolute;
}

/* Create two equal columns that floats next to each other */
.column {
  float: left;
  width: 50%;
  //padding: 10px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}


</style>
