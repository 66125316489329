module.exports = {

  isEmpty: function (data) {
    return data == undefined || data == null || data === 'null' || data === '';
  },

  hasContent: function (data) {
    return !module.exports.isEmpty(data);
  },

  getFileName: function (file) {
    if (module.exports.isEmpty(file))
      return ''
    else
      return file.split('\\').pop().split('/').pop();
  },

  checkEmailPattern(value) {
    if (value) {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

      let result = pattern.test(value)

      if(result) {
        // check special symbols
        let symbols =
            //value.includes('+') ||
            value.includes('!') ||
            value.includes('?') ||
            value.includes(',') ||
            value.includes('<') ||
            value.includes('>') ||
            value.includes('=') ||
            value.includes('#') ||
            value.includes('$') ||
            value.includes('^') ||
            value.includes('&') ||
            value.includes('*') ||
            value.includes('(') ||
            value.includes(')') ||
            value.includes('{') ||
            value.includes('}')

        if(symbols)
          result = false
      }

      return result
    }

    return false
  },
};
