import {hasContent} from "@/tools/dataTools";

export const ReportType = {
    DELIVERY: 'delivery',
    MONEY: 'money',
    PARTICIPANTS: 'participants',
    DRAWINGS: 'drawings',
    PRIZES: 'prizes',
};

export const ReportItem = {
    // UUID
    id: '',
    // JSON
    info: {
        // string
        name: '',
        // string
        from: null,
        // string
        to: null,
        // bool
        allPeriod: false,
    },
    // string
    type: ReportType.DELIVERY,
    // json
    history: [],
    // date
    createdAt: '',
    // date
    updatedAt: '',
};

export const NewReportItem = () => {
    let item = JSON.parse(JSON.stringify(ReportItem))

    return item
};

export const ReportItemFromBody = (data) => {
    let object = NewReportItem()

    object.id = data.id;
    object.info = JSON.parse(JSON.stringify(data.info));
    object.type = data.type;
    object.history = data.history;

    object.createdAt = data.createdAt;
    object.updatedAt = data.updatedAt;

    return object;
};


export const ReportDeliveryRequiredVerify = (item) => {
    if(hasContent(item)) {
        if(hasContent(item.id)) {
            return !!(hasContent(item.info.name) &&
              hasContent(item.info.companies) &&
              hasContent(item.info.promotions));
        }
        else {
            return !!(hasContent(item.info.name) &&
              hasContent(item.info.companies) &&
              hasContent(item.info.promotions));
        }
    }
    else {
        return false;
    }
};

export const ReportMoneyRequiredVerify = (item) => {
    if(hasContent(item)) {
        if(hasContent(item.id)) {
            return !!(hasContent(item.info.name) &&
              hasContent(item.info.from) &&
              hasContent(item.info.to) &&
              hasContent(item.info.company) &&
              hasContent(item.info.promotion));
        }
        else {
            return !!(hasContent(item.info.name) &&
              hasContent(item.info.from) &&
              hasContent(item.info.to) &&
              hasContent(item.info.company) &&
              hasContent(item.info.promotion));
        }
    }
    else {
        return false;
    }
};

export const ReportParticipantsRequiredVerify = (item) => {
    if(hasContent(item)) {
        if(hasContent(item.id)) {
            return !!(hasContent(item.info.name) &&
                hasContent(item.info.company) &&
                hasContent(item.info.promotion));
        }
        else {
            return !!(hasContent(item.info.name) &&
                hasContent(item.info.company) &&
                hasContent(item.info.promotion));
        }
    }
    else {
        return false;
    }
};

export const ReportPrizesRequiredVerify = (item) => {
    if(hasContent(item)) {
        if(hasContent(item.id)) {
            return !!(hasContent(item.info.name) &&
                hasContent(item.info.company) &&
                hasContent(item.info.promotion));
        }
        else {
            return !!(hasContent(item.info.name) &&
                hasContent(item.info.company) &&
                hasContent(item.info.promotion));
        }
    }
    else {
        return false;
    }
};

// export module
export default {
    ReportType,

    ReportItem,
    NewReportItem,
    ReportItemFromBody,

    ReportDeliveryRequiredVerify,
    ReportMoneyRequiredVerify,
    ReportParticipantsRequiredVerify,
    ReportPrizesRequiredVerify,

};
