<template>
    <div class="block-code">
        <div class="block-code__inner">
            <div class="block-code__header">
            {{ $t('CodesText.block_codes') }}
            </div>
            <div class="block-code__columns">
                <div class="block-code__column">
                    <div class="block-code__item select">
                        <p class="block-code__item-text">
                            {{ $t('CodesText.range_codes_from') }} <span>*</span>
                        </p>
                        <input class="block-code__item-input" type="text"
                               :placeholder="$t('Buttons.start')"
                               :disabled="!editPermission"
                               v-model="code1">
                    </div>
                </div>
                <div class="block-code__column">
                    <div class="block-code__item textarea">
                        <p class="block-code__item-text">
                            {{ $t('CodesText.range_codes_to') }} <span>*</span>
                        </p>
                        <input class="block-code__item-input" type="text"
                               :placeholder="$t('Buttons.end')"
                               :disabled="!editPermission"
                               v-model="code2"
                        >
                    </div>
                </div>
            </div>
        </div>
        <div class="buttons-block">
            <div
                class="buttons-block__back"
                @click="$emit('close-modal')"
            >
                <p class="buttons-block__back-text">
                  {{ $t('Buttons.cancel') }}
                </p>
            </div>
            <div class="buttons-block__save" @click="blockCodes">
                <p class="buttons-block__save-text">
                  {{ $t('Buttons.block') }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import {UsersType} from "@/models/users";
import {isEmpty} from "@/tools/dataTools";

export default {
  name: 'BlockCodeModal',
  components: {
  },

  data() {
    return {
      isLoading: false,

      // types
      code1: '',
      code2: '',
    }
  },
  watch: {
    isLoading() {
      if(this.isLoading)
        this.$progress.start();
      else
        this.$progress.finish();
    },
  },
  methods: {
    ...mapGetters(["GET_USER",]),
    ...mapActions(["DEACTIVATE_CODE",]),

    blockCodes() {
      if(isEmpty(this.code1) || isEmpty(this.code2)) {
        this.$notify({
          type: "error",
          title: this.$t('Errors.error_not_ok'),
          text: this.$t('Errors.error_wrong_data'),
        });
      }
      else {
        this.isLoading = true

        this.DEACTIVATE_CODE({
          code1: this.code1,
          code2: this.code2,
        }).then((res) => {
          this.isLoading = false

          if (res.success) {
            this.$notify({
              type: "success",
              title: this.$t('Errors.success_operation'),
              text: this.$t('CodesText.deactivate_code_success'),
            });

            this.close()
          } else {
            this.$notify({
              type: "error",
              title: this.$t('Errors.not_success_operation'),
              text: this.$t('CodesText.deactivate_code_error'),
            });
          }
        });
      }
    },

    close() {
      this.$emit('close-modal');
    }
  },
  computed: {
    user() {
      return this.GET_USER();
    },
    isAdmin() {
      return this.user ? (this.user.role === UsersType.ADMIN) : false;
    },
    companies() {
      return this.GET_COMPANIES();
    },
    editPermission() {
      if(this.isAdmin)
        return true
      else {
        // find type of access for current company
        let companies = this.user ? this.user.companies : [];

        if(this.companyData == null || companies == null || companies.length === 0)
          return false
        else {
          let company = companies.find(c => c.id === this.companyData.id);

          if(company == null || company.CompanyUsers == null)
            return false;
          else
            return company.CompanyUsers.write;
        }
      }
    },
  },
}
</script>

<style lang="scss" src="@/assets/scss/common.scss"></style>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixin.scss";

.block-code {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $white;
    border-radius: 10px;
    padding: 50px 45px 40px 70px;
    box-shadow: 0px 0px 8px 2px rgba(206, 213, 244, 0.5);
    z-index: 1;
    &__inner {
        margin-bottom: 30px;
    }

    &__validation-rule {
        margin-bottom: 30px;
        span {
            color: $red;
        }
        &-back {
            @include white-button;
            width: 190px;
        }
        &-forward {

        }
    }

    &__header {
        width: 100%;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 30px;
        text-transform: uppercase;
    }

    &__text {
        width: 100%;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 30px;
        text-transform: none;
    }

    .block-code__item {
        margin-bottom: 0;
        &-text {
            font-size: 12px;
            font-weight: 500;
            text-transform: uppercase;
        }
    }

    .buttons-block__save {
        margin-left: 30px;
        margin-right: 0;
    }
}

.block-code__columns {
    display: flex;
    flex-flow: row nowrap;
}

.block-code__column {
    &:first-child {
        margin-right: 30px;
    }
}

.block-code__item {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    width: 265px;
    margin-bottom: 20px;
    &-text {
        width: 100%;
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 5px;
        color: $black;
        span {
            color: $red;
        }
    }

    &-input {
        @include search-input;
    }

    &.textarea {
        grid-column: span 3;
        input {
            border-radius: 5px;
            width: 100%;
        }
    }

    &.select {
        .block-code__item-text {
            span {
                color: $red;
            }
    }

        .block-code__item-button {
            &::after {
                width: 9px;
                height: 7px;
                background: url('../assets/img/triangle.png');
            }
        }
    }

    &-button {
        @include search-input__button;
    }
}

.buttons-block {
    height: fit-content;
    margin: 0 auto;
    &__back {
        @include white-button;
        width: 190px;
    }
    &__save {
        @include purple-button;
        width: 190px;
        margin-left: 0;
        margin-right: 30px;
    }
}

.block-code__checboxes {
    padding-top: 40px;
    &-text {
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: 20px;
    }
}

.block-code__checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  &:not(:last-child) {
      margin-bottom: 10px;
  }
  &-checkbox {
    display: none;
  }
  &-fake {
    display: inline-block;
    width: 17px;
    height: 17px;
    margin-right: 12px;
    border-radius: 2px;
    box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.25);
    position: relative;
    &::before {
      content: '✔';
      position: absolute;
      color: $black;
      font-size: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
    }
  }
  &-text {
    font-size: 12px;
    font-weight: 500;
    color: $activeText;
  }
  .block-code__checkbox-checkbox:checked + .block-code__checkbox-fake::before {
    opacity: 1;
  }
  span {
    color: $red;
  }
}

</style>
