<template>
  <div class="listitems">
    <ModalEditPrize
        v-if="modalOpened"
        :editPermission="editPermission"
        :prize="currentPrize"
        @close-modal="closeModalPrize"
    />
    <ModalEditDetermining
        v-if="modalDeterminingOpened"
        :editPermission="editPermission"
        :determining="currentDetermining"
        @close-modal="closeModalDetermining"
    />
    <ModalEditGame
        v-if="modalGameOpened"
        :editPermission="editPermission"
        :game="currentGame"
        @close-modal="closeModalGame"
    />

    <div class="page-header">
      <h2 class="page-header__title">
        {{ $t('ListItemText.header') }}
      </h2>
      <div class="buttons-block">
        <div
             class="page-header__outload"
             @click="downloadList('xls')">
          <img
              width="20" height="16"
              src="@/assets/img/bi_cloud_download.svg"
              alt="notification avatar">
          <p class="page-header__outload-text">
            {{ $t('Buttons.download_xls') }}
          </p>
        </div>

        <div v-if="prizesScreenOpened"
             class="page-header__newcode"
             @click="openModalPrizeInformation(null)"
        >
          <p class="page-header__newcode-text">
            {{ $t('ListItemText.add_prize') }}
          </p>
        </div>

        <div v-if="determiningScreenOpened"
             class="page-header__newcode"
             @click="openModalDeterminingInformation(null)"
        >
          <p class="page-header__newcode-text">
            {{ $t('ListItemText.add_determining') }}
          </p>
        </div>

        <div v-if="gamesScreenOpened"
             class="page-header__newcode"
             @click="openModalGameInformation(null)"
        >
          <p class="page-header__newcode-text">
            {{ $t('ListItemText.add_game') }}
          </p>
        </div>

      </div>
    </div>

    <div class="buttons-panel">

      <div class="buttons-panel__prizes"
        @click="prizesScreenOpen()"
        :class="{ active: prizesScreenOpened}"
      >
        <p class="buttons-panel__prizes-text">
          {{ $t('ListItemText.prizes') }}
        </p>
        <div class="buttons-panel__prizes-counter">
          <p>
            {{ prizesCount }}
          </p>
        </div>
      </div>

      <div class="buttons-panel__prizes"
        @click="determiningScreenOpen()"
        :class="{ active: determiningScreenOpened}"
      >
        <p class="buttons-panel__prizes-text">
          {{ $t('ListItemText.winner_determining') }}
        </p>
        <div class="buttons-panel__prizes-counter">
          <p>
            {{ determiningCount }}
          </p>
        </div>
      </div>

      <div class="buttons-panel__prizes"
           @click="gamesScreenOpen()"
           :class="{ active: gamesScreenOpened}"
      >
        <p class="buttons-panel__prizes-text">
          {{ $t('ListItemText.games') }}
        </p>
        <div class="buttons-panel__prizes-counter">
          <p>
            {{ gamesCount }}
          </p>
        </div>
      </div>

      <div class="buttons-panel__input">
        <p class="buttons-panel__input-text">
          {{ $t('InterfaceText.search') }}
        </p>
        <input type="text" class="buttons-panel__input-input" :placeholder="$t('FieldsText.enter_request')"
               :value="filterData.search"
               @input="setListsSearch">
        <div class="buttons-panel__input-button"></div>
      </div>

    </div>

    <ListsPrizesModal
      v-if="prizesScreenOpened"
      :editPermission="editPermission"
      :search="filterData.search"
      @refresh="getPrizes"
      @edit="openModalPrizeInformation"/>

    <ListsDeterminingModal
      v-if="determiningScreenOpened"
      :editPermission="editPermission"
      :search="filterData.search"
      @refresh="getDetermining"
      @edit="openModalDeterminingInformation"
      @delete="deleteDetermining"/>

    <ListsGamesModal
        v-if="gamesScreenOpened"
        :editPermission="editPermission"
        :search="filterData.search"
        @refresh="getGames"
        @edit="openModalGameInformation"
        @delete="deleteGame"/>

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

import InputsMixin from "@/mixins/inputsEvent";

import ModalEditPrize from "@/components/ModalEditPrize";
import ModalEditDetermining from "@/components/ModalEditDetermining";
import ModalEditGame from "@/components/ModalEditGame";

import ListsPrizesModal from '../components/ListsPrizesModal'
import ListsDeterminingModal from '../components/ListsDeterminingModal'
import ListsGamesModal from '../components/ListsGamesModal'

import {hasContent} from "@/tools/dataTools";
import {UsersType} from "@/models/users";

export default {
  name: 'ListItems',
  components: {
    ModalEditPrize,
    ModalEditDetermining,
    ModalEditGame,

    ListsPrizesModal,
    ListsDeterminingModal,
    ListsGamesModal,
  },
  mixins: [InputsMixin],
  data() {
    return {
      isLoading: false,
      modalOpened: false,
      modalDeterminingOpened: false,
      modalGameOpened: false,

      currentPrize: null,
      currentDetermining: null,
      currentGame: null,

      prizesScreenOpened: true,
      determiningScreenOpened: false,
      gamesScreenOpened: false,

      // data
      filterData: {
        search: '',
      },
    }
  },
  beforeMount() {
  },
  mounted() {
    this.filterData = {
      search: this.list_items_filter.search,
    }

    this.getPrizes()
    this.getDetermining()
    this.getGames()

    this.prizesScreenOpen()
  },
  computed: {
    user() {
      return this.GET_USER();
    },
    isAdmin() {
      return this.user ? (this.user.role === UsersType.ADMIN) : false;
    },
    editPermission() {
      return this.isAdmin;
    },
    prizesCount() {
      return this.GET_LIST_ITEMS().prizes ? this.GET_LIST_ITEMS().prizes.length : 0;
    },
    determiningCount() {
      return this.GET_LIST_ITEMS().determining ? this.GET_LIST_ITEMS().determining.length : 0;
    },
    gamesCount() {
      return this.GET_LIST_ITEMS().games ? this.GET_LIST_ITEMS().games.length : 0;
    },
    list_items_filter() {
      return this.GET_LIST_ITEMS().filter;
    },
    employees_filter() {
      return this.GET_EMPLOYEES().filter;
    },
  },

  watch: {
    // eslint-disable-next-line no-unused-vars
    "$store.state.employees.filter": function (newValue, oldValue) {
      if(this.user) {
        if(newValue != null) {
          this.filterData.company = newValue.company
        }

        this.filterItems();
      }
    },
    // eslint-disable-next-line no-unused-vars
    "$store.state.list_items.filter": function (newValue, oldValue) {
      if(newValue != null) {
        this.filterData.search = newValue.search
      }

      if(newValue.search !== oldValue.search)
        this.filterItems();
    },
    // eslint-disable-next-line no-unused-vars
    "$store.state.list_items.prizes": function (newValue, oldValue) {
      this.filterItems();
    },
    isLoading() {
      if(this.isLoading)
        this.$progress.start();
      else
        this.$progress.finish();
    },
  },
  methods: {
    ...mapGetters(["GET_USER","GET_LIST_ITEMS","GET_EMPLOYEES",]),
    ...mapActions([
      "SET_LIST_ITEMS_FILTER",
      "GET_PRIZES",
      "GET_PRIZES_DOWNLOAD",
      "GET_DETERMINING",
      "DELETE_DETERMINING",
      "GET_DETERMINING_DOWNLOAD",
      "GET_GAMES",
      "DELETE_GAME",
      "GET_GAMES_DOWNLOAD",
    ]),

    prizesScreenOpen() {
      this.filterData.search = '';
      this.prizesScreenOpened = true;
      this.determiningScreenOpened = false;
      this.gamesScreenOpened = false;
    },
    determiningScreenOpen() {
      this.filterData.search = '';
      this.prizesScreenOpened = false;
      this.determiningScreenOpened = true;
      this.gamesScreenOpened = false;
    },
    gamesScreenOpen() {
      this.filterData.search = '';
      this.prizesScreenOpened = false;
      this.determiningScreenOpened = false;
      this.gamesScreenOpened = true;
    },

    setListsSearch(e) {
      if(hasContent(e.target.value)) {
        this.filterData.search = e.target.value
        this.filterItems()
      }

      this.SET_LIST_ITEMS_FILTER({
        ...this.list_items_filter,
        search: e.target.value,
      });
    },

    filterItems() {

    },


    openModalPrizeInformation(item) {
      this.currentPrize = item;
      this.modalOpened = true;
    },

    closeModalPrize() {
      this.currentPrize = null;
      this.modalOpened = false;

      this.getPrizes()
    },


    openModalDeterminingInformation(item) {
      this.currentDetermining = item;
      this.modalDeterminingOpened = true;
    },

    closeModalDetermining() {
      this.currentDetermining = null;
      this.modalDeterminingOpened = false;

      this.getDetermining()
    },


    openModalGameInformation(item) {
      this.currentGame = item;
      this.modalGameOpened = true;
    },

    closeModalGame() {
      this.currentGame = null;
      this.modalGameOpened = false;

      this.getGames()
    },


    getPrizes() {
      if(this.user) {
        this.isLoading = true

        this.GET_PRIZES({
          search: this.filterData.search,
        }).then(() => {
          this.isLoading = false
        });
      }
    },

    getDetermining() {
      if(this.user) {
        this.isLoading = true

        this.GET_DETERMINING({
          search: this.filterData.search,
        }).then(() => {
          this.isLoading = false
        });
      }
    },

    getGames() {
      if(this.user) {
        this.isLoading = true

        this.GET_GAMES({
          search: this.filterData.search,
        }).then(() => {
          this.isLoading = false
        });
      }
    },

    deleteDetermining(item) {
      if(item) {
        if (
            confirm(this.$t('DeterminingText.delete'))
        ) {
          this.isLoading = true

          this.DELETE_DETERMINING({
            id: item.id,
          }).then((res) => {
            this.isLoading = false

            if (res.success) {
              this.$notify({
                type: "success",
                title: this.$t('Errors.success_operation'),
                text: this.$t('DeterminingText.delete_success'),
              });
            } else {
              this.$notify({
                type: "error",
                title: this.$t('Errors.not_success_operation'),
                text: this.$t('DeterminingText.delete_code_error'),
              });
            }
          });
        }
      }
    },

    deleteGame(item) {
      if(item) {
        if (
            confirm(this.$t('GameText.delete'))
        ) {
          this.isLoading = true

          this.DELETE_GAME({
            id: item.id,
          }).then((res) => {
            this.isLoading = false

            if (res.success) {
              this.$notify({
                type: "success",
                title: this.$t('Errors.success_operation'),
                text: this.$t('GameText.delete_success'),
              });
            } else {
              this.$notify({
                type: "error",
                title: this.$t('Errors.not_success_operation'),
                text: this.$t('GameText.delete_game_error'),
              });
            }
          });
        }
      }
    },

    downloadList(format) {
      if(this.prizesScreenOpened)
        this.downloadPrizesList(format)
      else if(this.determiningScreenOpened)
        this.downloadDeterminingList(format)
      else if(this.gamesScreenOpened)
        this.downloadGameList(format)
    },

    // requests
    downloadPrizesList(format) {
      if (format) {
        this.isLoading = true

        // download specific format
        this.GET_PRIZES_DOWNLOAD({
          format,
        }).then(() => {
          this.isLoading = false

          this.$notify({
            type: "success",
            title: this.$t('Errors.success_operation'),
            text: this.$t('Errors.file_is_generated'),
          });
        }).catch(() => {
          this.isLoading = false

          this.$notify({
            type: "error",
            title: this.$t('Errors.not_success_operation'),
            text: this.$t('Errors.file_is_not_generated'),
          });
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t('Errors.not_success_operation'),
          text: this.$t('Errors.error_format'),
        });
      }
    },

    // requests
    downloadDeterminingList(format) {
      if (format) {
        this.isLoading = true

        // download specific format
        this.GET_DETERMINING_DOWNLOAD({
          format,
        }).then(() => {
          this.isLoading = false

          this.$notify({
            type: "success",
            title: this.$t('Errors.success_operation'),
            text: this.$t('Errors.file_is_generated'),
          });
        }).catch(() => {
          this.isLoading = false

          this.$notify({
            type: "error",
            title: this.$t('Errors.not_success_operation'),
            text: this.$t('Errors.file_is_not_generated'),
          });
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t('Errors.not_success_operation'),
          text: this.$t('Errors.error_format'),
        });
      }
    },

    // requests
    downloadGameList(format) {
      if (format) {
        this.isLoading = true

        // download specific format
        this.GET_GAMES_DOWNLOAD({
          format,
        }).then(() => {
          this.isLoading = false

          this.$notify({
            type: "success",
            title: this.$t('Errors.success_operation'),
            text: this.$t('Errors.file_is_generated'),
          });
        }).catch(() => {
          this.isLoading = false

          this.$notify({
            type: "error",
            title: this.$t('Errors.not_success_operation'),
            text: this.$t('Errors.file_is_not_generated'),
          });
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t('Errors.not_success_operation'),
          text: this.$t('Errors.error_format'),
        });
      }
    },

  }
}
</script>

<style lang="scss" src="@/assets/scss/common.scss"></style>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixin.scss";

.listitems {
  flex: 1;
  width: 100%;
  min-width: 1150px;

  .buttons-panel {
    margin-bottom: 30px;
  }

  .buttons-panel__checkbox {
    margin-right: 30px;
  }

  .table {
    //@include grid-table-row(60px, 30px, 11% 88px 11% 6px 11% 31px 7.3% 43px 13.5% 18px 10% 23px 10% 69px 3.4%);
    @include grid-table-row(145px, 60px, 11.8% 136px 13.75% 136px 14% 153px 8.2% 155px 6%);

    &__cell {
      &:nth-child(2) {
        grid-column-start: 3;
        grid-column-end: 4;
      }
      &:nth-child(3) {
        grid-column-start: 5;
        grid-column-end: 6;
      }
      &:nth-child(4) {
        grid-column-start: 7;
        grid-column-end: 8;
      }
      &:nth-child(5) {
        grid-column-start: 9;
        grid-column-end: 10;
      }
      &:nth-child(6) {
        grid-column-start: 11;
        grid-column-end: 12;
      }
      &:nth-child(7) {
        grid-column-start: 13;
        grid-column-end: 14;
      }
      &:nth-child(8) {
        grid-column-start: 15;
        grid-column-end: 16;
      }
    }
  }

  .table2 {
    @include grid-table-row(60px, 40px, 27% 207px 25.2% 210px 6%);

    &__cell {
      &:first-child {
        grid-column-start: 1;
        grid-column-end: 2;
      }
      &:nth-child(2) {
        grid-column-start: 3;
        grid-column-end: 4;
      }
      &:nth-child(3) {
        grid-column-start: 5;
        grid-column-end: 6;
      }
    }
  }

}

.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  &__title {
    @include page-header;
  }
}

.buttons-block {
  display: flex;
}

.page-header__outload {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  width: 180px;
  padding: 7px 0;
  background-color: $white;
  border-radius: 5px;
  border: 1px solid $purple;
  cursor: pointer;
  // border-image: linear-gradient(#8F21C9, #4640B9) 30 / 1 / 0 stretch;
  svg {
    margin-right: 5px;
  }
  img {
   margin-right: 5px;
  }
  &-text {
    font-size: 14px;
    font-weight: 16px;
    color: $purple;
  }
}

.buttons-panel {
  display: flex;
  align-items: center;
  padding: 25px 110px 25px 30px;
  background-color: $white;
  border-radius: 10px;
  box-shadow: 0px 0px 8px 2px #CED5F480;
}

.buttons-panel__prizes {
  display: flex;
  align-items: center;
  margin-right: 25px;
  padding-right: 26px;
  position: relative;
  cursor: pointer;
  &::after {
    content: "";
    position: absolute;
    top: -4.5px;
    right: 0;
    width: 2px;
    height: 37px;
    background-color: $bodyBackground;
  }

  &-text {
    font-size: 16px;
    font-weight: 400;
    margin-right: 7px;
  }

  &-counter {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    min-width: 28px;
    height: 28px;
    min-height: 28px;
    background: $inputCaption;
    border-radius: 50px;
    p {
      color: $white;
    }
  }

  &.active {
    .buttons-panel__prizes-text {
      font-weight: 600;
    }

    .buttons-panel__prizes-counter {
      background: linear-gradient(180deg, #8F21C9 0%, #8F21C9 0.01%, #4640B9 100%);
    }
  }
}

.buttons-panel__questions {
  display: flex;
  align-items: center;
  margin: 0 auto 0 0;
  cursor: pointer;
  &-text {
    font-size: 16px;
    font-weight: 400;
    margin-right: 7px;
  }

  &-counter {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    min-width: 28px;
    height: 28px;
    min-height: 28px;
    background: $inputCaption;
    border-radius: 50px;
    p {
      color: $white;
    }
  }

  &.active {
    .buttons-panel__questions-text {
      font-weight: 600;
    }

    .buttons-panel__questions-counter {
      background: linear-gradient(180deg, #8F21C9 0%, #8F21C9 0.01%, #4640B9 100%);
    }
  }
}

.buttons-panel__input {
  display: flex;
  align-items: center;
  margin-right: 30px;
  &-text {
    font-size: 12px;
    font-weight: 500;
    margin-right: 30px;
  }

  &-input {
    @include search-input;
  }

  &-button {
    @include search-input__button;
  }

  &.select {
    .buttons-panel__input-text {
      position: relative;
      &::after {
        content: "*";
        position: absolute;
        top: -2px;
        right: -6px;
        color: $red;
      }
    }

    .buttons-panel__input-button {
      &::after {
        width: 9px;
        height: 7px;
        background: url('../assets/img/triangle.png');
      }
    }
  }
}

.buttons-panel__checkbox {
  display: flex;
  align-items: center;
  margin-right: 30px;
  cursor: pointer;
  &-checkbox {
    display: none;
  }
  &-fake {
    display: inline-block;
    width: 17px;
    height: 17px;
    margin-right: 12px;
    border-radius: 2px;
    box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.25);
    position: relative;
    &::before {
      content: '✔';
      position: absolute;
      color: $black;
      font-size: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
    }
  }
  &-text {
    font-size: 12px;
    font-weight: 500;
    color: $activeText;
  }
  .buttons-panel__checkbox-checkbox:checked + .buttons-panel__checkbox-fake::before {
    opacity: 1;
  }
  span {
    color: $red;
  }
}

</style>
