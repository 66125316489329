import store from '@/store'
import {hasContent} from "@/tools/dataTools";

export const StatisticsType = {
    SENT: 'sent',
    RECEIVED: 'received',
    BALANCE: 'balance',
};

export const Operators = {
    // UUID
    id: '',
    // string
    name: '',
    // string
    host: '',
    // string
    user: '',
    // string
    pass: '',
    // string
    sib_num: '',
    // string
    sender: '',
    // boolean
    active: true,
    // json
    history: [],
};

export const NewOperator = () => {
    let item = JSON.parse(JSON.stringify(Operators))

    //item.id = generateUid()

    return item
};

export const OperatorsFromBody = (data) => {
    let object = NewOperator()

    object.id = data.id;
    object.name = data.name;
    object.host = data.host;
    object.user = data.user;
    object.pass = data.pass;
    object.sib_num = data.sib_num;
    object.sender = data.sender;
    object.active = data.active;

    object.history = data.history;

    return object;
};

export const OperatorRequiredVerify = (operator) => {
    if(hasContent(operator)) {
        if(hasContent(operator.id)) {
            return !!(hasContent(operator.name) &&
              hasContent(operator.host) &&
              hasContent(operator.user) &&
              hasContent(operator.pass) &&
              hasContent(operator.sib_num) &&
              hasContent(operator.sender))
        }
        else {
            return !!(hasContent(operator.name) &&
              hasContent(operator.host) &&
              hasContent(operator.user) &&
              hasContent(operator.pass) &&
              hasContent(operator.sib_num) &&
              hasContent(operator.sender))
        }
    }
    else {
        return false;
    }
};


export const StatisticsTypes = () => {
    return [
        {
            type: StatisticsType.SENT,
            label: store.state.$t('OperatorStatistics.sent'),
        },
        {
            type: StatisticsType.RECEIVED,
            label: store.state.$t('OperatorStatistics.received'),
        },
        // {
        //     type: StatisticsType.BALANCE,
        //     label: store.state.$t('OperatorStatistics.balance'),
        // },
    ]
};


// export module
export default {
    Operators,
    NewOperator,
    OperatorsFromBody,

    OperatorRequiredVerify,

    StatisticsType,
    StatisticsTypes,
};
