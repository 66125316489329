<template>

  <RequisitesUserDownloadModal
      v-if="requisitesDownloadModalOpened"
      :editPermission="editPermission"
      :user="selectedItem"
      @refresh="getDocumentAndPrize"
      @close-modal="requisitesDownloadModalClose"
  />

  <UserPrizesList
      :opened="modalPrizesOpened"
      :participants="currentPrizes"
      :year="year"
      @close-modal="closeModalPrizeInformation"
  />

  <div class="table">
    <div class="table__row title">
      <p class="table__cell">{{ $t('FieldsText.fio_label') }}</p>
      <p class="table__cell">{{ $t('TableHeaders.phone') }}</p>
      <p class="table__cell">{{ $t('TableHeaders.email') }}</p>
      <p class="table__cell">{{ $t('TaxText.promotionsCount') }}</p>
      <p class="table__cell">{{ $t('TaxText.prizesCount') }}</p>
      <p class="table__cell">{{ $t('TaxText.prizesPrice') }}</p>
      <p class="table__cell">{{ $t('TaxText.requisites') }}</p>
    </div>
    <div class="table__body">
      <div
          v-for="n in documents" :key="n.id"
          class="table__row"
      >
        <div class="table__cell multiline_left">
          <p>{{ `${n.name} ${n.surname} ${n.lastname}` }}</p>
        </div>
        <div class="table__cell">
          <p>{{ n.phone ? n.phone : '-' }}</p>
        </div>
        <div class="table__cell">
          <p>{{ n.email ? n.email : '-' }}</p>
        </div>
        <div class="table__cell text-left">
          <p>{{ promotionsCountInformation(n.participants) }}</p>
        </div>
        <div class="table__cell">
          <p>{{ prizesCountInformation(n.participants) }}</p>
        </div>
        <div class="table__cell">
          <p>{{ `${n.totalPrizesSum ? n.totalPrizesSum : 0}` }}</p>
        </div>
        <div class="table__cell button">

          <div
              class="table__cell-button"
              :title="$t('ListText.prizes_information')"
              @click="showPrizesInformation(n.participants)"
          >
            <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.0127 5.68201H16.4809C17.3121 5.68201 18 6.35565 18 7.23431V12.5649H9.74522V6.35565C9.4586 6.26778 9.20064 6.15063 9.02866 5.9749C9 5.9749 9 5.94561 8.97134 5.94561C8.97134 5.94561 8.97134 5.9749 8.94268 5.9749C8.74204 6.15063 8.51274 6.26778 8.25478 6.35565V12.5649H0V7.23431C0 6.35565 0.659236 5.68201 1.49045 5.68201H4.98726C4.58599 5.47699 4.21338 5.1841 3.89809 4.9205C2.52229 3.57322 2.26433 1.69874 3.3535 0.644351C3.8121 0.23431 4.41401 0 5.07325 0C6.01911 0 6.99363 0.380753 7.82484 1.17155C8.28344 1.61088 8.71338 2.22594 8.97134 2.81172C9.25796 2.22594 9.6879 1.61088 10.1465 1.17155C10.949 0.380753 11.9809 0 12.8981 0C13.586 0 14.1879 0.23431 14.6178 0.644351C15.707 1.69874 15.449 3.57322 14.0446 4.9205C13.758 5.1841 13.3854 5.47699 13.0127 5.68201ZM7.91083 4.83264C8.02548 4.56904 7.73885 3.22176 6.79299 2.31381C6.30573 1.84519 5.6465 1.58159 5.07325 1.58159C4.90127 1.58159 4.61465 1.61088 4.41401 1.78661C4.35669 1.84519 4.18471 1.96234 4.18471 2.28452C4.18471 2.66527 4.41401 3.25105 4.9586 3.74895C5.6465 4.42259 6.73567 4.89121 7.53822 4.89121C7.79618 4.89121 7.91083 4.83264 7.91083 4.83264ZM10.0892 4.83264C10.0892 4.83264 10.2325 4.89121 10.4618 4.89121C11.2643 4.89121 12.3535 4.42259 13.0414 3.74895C13.586 3.25105 13.758 2.66527 13.758 2.28452C13.758 1.96234 13.6146 1.84519 13.586 1.78661C13.3854 1.61088 13.0701 1.58159 12.8981 1.58159C12.3535 1.58159 11.6656 1.84519 11.1783 2.31381C10.2611 3.22176 9.94586 4.53975 10.0892 4.86192V4.83264ZM8.25478 21H1.49045C0.659236 21 0 20.3264 0 19.477V14.1172H8.25478V21ZM16.4809 21H9.74522V14.1172H18V19.477C18 20.3264 17.3121 21 16.4809 21Z" fill="#BDBDBD"/>
            </svg>
          </div>

          <div
              v-if="editPermission"
              class="table__cell-button"
              style="padding-left: 5px;"
              @click="requisitesDownloadModalOpen(n)">
            <img
                v-if="userPrizesFiles(n).length > 0"
                :title="$t('TaxText.show_requisites')"
                src="@/assets/img/icons/show_requisites.svg">
            <img
                v-else
                :title="$t('TaxText.upload_requisites')"
                src="@/assets/img/icons/upload_requisites.svg">
          </div>

          <div v-if="editPermission && !n.tax_payed"
               class="table__cell-button"
               style="padding-left: 10px;"
               @click="openModalPayTax(n)">
            <img
                :title="$t('TaxText.pay_tax')"
                width="20" height="20"
                src="@/assets/img/icon_confirm.svg" alt="">
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

import {PrizeStatusText} from "@/models/participants"
import RequisitesUserDownloadModal from "@/components/RequisitesUserDownloadModal.vue";
import UserPrizesList from "@/components/UserPrizesList.vue";
import {userPrizesFiles, userPrizesIds} from "@/models/users";

export default {
  name: 'ListUsersTaxesAll',
  emits: ["get", "getAll"],
  components: {
    UserPrizesList,
    RequisitesUserDownloadModal,
  },
  props: {
    editPermission: Boolean,
    search: String,
    items: Array,
    year: Number,
  },
  data() {
    return {
      modalPrizesOpened: false,
      currentPrizes: null,

      selectedItem: null,
      requisitesDownloadModalOpened: false,
    }
  },
  computed: {
    documents() {
      return this.items ?
          this.items.filter(
              (v) =>
                  `${v.name ? v.name : ''} ${v.surname ? v.surname : ''} ${v.lastname ? v.lastname : ''} ${v.phone ? v.phone : ''} ${v.email ? v.email : ''} `
                      .toLowerCase()
                      .indexOf(this.search.toLowerCase()) !== -1
          )
          : [];
    },
  },
  watch: {
  },
  methods: {
    userPrizesFiles,
    ...mapActions(["UPDATE_PRIZE_CONFIRM_TAX",]),

    PrizeStatusText,

    getDocumentAndPrize(item) {
      this.$emit('get', item)
    },

    showPrizesInformation(participants) {
      if(participants) {
        this.currentPrizes = participants;
        this.modalPrizesOpened = true;
      }
    },

    closeModalPrizeInformation() {
      this.currentPrizes = null;
      this.modalPrizesOpened = false;
    },

    requisitesDownloadModalOpen(item) {
      if(item) {
        this.selectedItem = item;
        this.requisitesDownloadModalOpened = true;
      }
      else {
        this.$notify({
          type: "error",
          title: this.$t('Errors.not_success_operation'),
          text: this.$t('TaxText.missing_prize_data'),
        });
      }
    },

    requisitesDownloadModalClose() {
      this.requisitesDownloadModalOpened = false;
      this.selectedItem = null;
    },

    openModalPayTax(item) {
      if(item) {
        //
        if(userPrizesFiles(item).length > 0) {
          // try to update prize pay taxes
          if (
              confirm(this.$t('TaxText.confirm_pay_tax'))
          ) {
            this.isLoading = true

            this.UPDATE_PRIZE_CONFIRM_TAX({
              user: item.id,
              prizes: userPrizesIds(item),
            }).then((res) => {
              this.isLoading = false

              if (res.success) {
                this.$emit('getAll')

                this.$notify({
                  type: "success",
                  title: this.$t('Errors.success_operation'),
                  text: this.$t('TaxText.pay_tax_success'),
                });
              } else {
                this.$notify({
                  type: "error",
                  title: this.$t('Errors.not_success_operation'),
                  text: this.$t('TaxText.pay_tax_error'),
                });
              }
            });
          }
        }
        else {
          this.$notify({
            type: "error",
            title: this.$t('Errors.not_success_operation'),
            text: this.$t('TaxText.missing_requisites_data'),
          });
        }
      }
      else {
        this.$notify({
          type: "error",
          title: this.$t('Errors.not_success_operation'),
          text: this.$t('TaxText.missing_prize_data'),
        });
      }
    },

    promotionsCountInformation(participants) {
      let response = 0

      if(participants) {
        participants.map(() => {
          response++
        })
      }

      return response
    },

    prizesCountInformation(participants) {
      let response = 0

      if(participants) {
        participants.map((participant) => {
          if(participant.prizes) {
            participant.prizes.map(() => {
              response++
            })
          }
        })
      }

      return response
    },

  },
}
</script>

<style lang="scss" src="@/assets/scss/common.scss"></style>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixin.scss";

</style>
