import store from '@/store'
import { generateUid } from '@/tools/cryptoTools'
import {Action} from '@/models/prizes'

export const PromoActionType = {
    INTERACTIVE: 'interactive',
    INVITATION: 'invitation',
};

export const ActionForGetCode = {
    NO: 'no',
};

export const PromoAction = {
    // UUID
    id: '',
    // string
    name: '',
    // string
    type: PromoActionType.INTERACTIVE,

    // integer
    quantityNewPersons: 0,
    // string
    requiredNewPersonsRegisterBuy: Action.NO,
    // integer
    buysQuantity: 0,
    // integer
    codesQuantity: 0,
    // integer
    codesQuantityPerPersonPerPromo: 0,
    // integer
    codesQuantityPerPersonPerDay: 0,
    // integer
    codesQuantityPerPersonPerWeek: 0,
    // integer
    codesQuantityPerPersonPerMonth: 0,

    // string
    actionForCode: ActionForGetCode.NO,
    // integer
    scoreForAction: 0,

    // string
    requiredComunity: Action.NO,
    // string
    requiredRepost: Action.NO,

    // string
    socialMedia: Action.NO,
    // string
    socialMediaAddress: null,
    // string
    linkToPostForRepost: null,

    // string
    requredAction: null,
    // integer
    requredActionScore: 0,
};

export const NewPromoAction = () => {
    let item = JSON.parse(JSON.stringify(PromoAction))

    item.id = generateUid()

    return item
};

export const PromoActionFromBody = (data) => {
    let object = NewPromoAction()

    object.id = data.id;
    object.full_name = data.full_name;
    object.name = data.name;
    object.category = data.category;
    object.type = data.type;
    object.price = data.price;
    object.pointsQuantity = data.pointsQuantity;
    object.count = data.count;

    object.prizesQuantityPerPersonPerPromo = data.prizesQuantityPerPersonPerPromo;
    object.prizesQuantityPerPersonPerDay = data.prizesQuantityPerPersonPerDay;
    object.prizesQuantityPerPersonPerWeek = data.prizesQuantityPerPersonPerWeek;
    object.prizesQuantityPerPersonPerMonth = data.prizesQuantityPerPersonPerMonth;
    object.awardingPrizeTimeInDays = data.awardingPrizeTimeInDays;
    object.applicationsQuantityPerPromo = data.applicationsQuantityPerPromo;
    object.applicationsQuantityPerDay = data.applicationsQuantityPerDay;
    object.applicationsQuantityPerWeek = data.applicationsQuantityPerWeek;
    object.applicationsQuantityPerMonth = data.applicationsQuantityPerMonth;
    object.automaticalDelivery = data.automaticalDelivery;
    object.automaticalApplicatonForPromo = data.automaticalApplicatonForPromo;

    object.applicatonForPromoStartedAt = data.applicatonForPromoStartedAt;
    object.applicatonForPromoEndedAt = data.applicatonForPromoEndedAt;

    object.drawingPromoStartedAt = data.drawingPromoStartedAt;
    object.drawingPromoEndedAt = data.drawingPromoEndedAt;

    object.deletePointsAfterDelivery = data.deletePointsAfterDelivery;

    object.actionForPrize = data.actionForPrize;
    object.winnerDetermining = data.winnerDetermining;
    object.advancedPrize = data.advancedPrize;
    object.actionsForAdvancePrize = data.actionsForAdvancePrize;
    object.paymentDirection = data.paymentDirection;

    object.comission = data.comission;

    object.goodsGroup = data.goodsGroup;
    object.goodsGroupScore = data.goodsGroupScore;

    object.send_type = data.send_type;
    object.need_confirm_by_manager = data.need_confirm_by_manager;

    object.history = data.history;

    return object;
};


export const PromoActionTypes = () => {
    return [
        {
            type: PromoActionType.INTERACTIVE,
            label: store.state.$t('PromoActionType.interactive'),
        },
        {
            type: PromoActionType.INVITATION,
            label: store.state.$t('PromoActionType.invitation'),
        },
    ]
};

export const PromoActionTypeText = (type) => {
    let promoActionType = PromoActionTypes().find(item => item.type === type)
    if(type == null || promoActionType == null)
        return store.state.$t('PromoActionType.unknown');
    else
        return promoActionType.label;
};


export const ActionsForGetCode = () => {
    return [
        {
            action: ActionForGetCode.NO,
            label: store.state.$t('ActionForGetCode.no'),
        },
    ]
};

export const ActionsForGetCodeText = (action) => {
    let actionLabel = ActionsForGetCode().find(item => item.action === action)
    if(action == null || actionLabel == null)
        return store.state.$t('ActionForGetCode.unknown');
    else
        return actionLabel.label;
};



// export module
export default {
    PromoAction,

    NewPromoAction,
    PromoActionFromBody,

    PromoActionType,
    PromoActionTypes,
    PromoActionTypeText,

    ActionForGetCode,
    ActionsForGetCode,
    ActionsForGetCodeText,
};