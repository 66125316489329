import store from '@/store'

export const ParticipantsStatus = {
    ACTIVE: 'active',
    BLOCKED: 'blocked',
};

export const PrizeStatus = {
    NONE: 'none',
    WAIT: 'wait',
    SENT: 'sent',
};

export const DrawingStatus = {
    WAIT: 'wait',
    ERROR: 'error',
    FINISHED: 'finished',
};

export const Participants = {
    // UUID
    id: '',
    // UUID
    promotion: '',
    // UUID
    user: '',
    // json
    codes: [],
    // string
    status: ParticipantsStatus.ACTIVE,
    // UUID
    prize_user: '',
    // json
    prize: {},
    // string
    prize_status: PrizeStatus.NONE,
    // UUID
    send_prize_user: '',
    // json
    history: [],
};

export const NewParticipants = () => {
    return JSON.parse(JSON.stringify(Participants));
};

export const ParticipantsFromBody = (data) => {
    let object = NewParticipants()

    object.id = data.id;
    object.promotion = data.promotion;
    object.user = data.user;
    object.code = data.codes;
    object.status = data.status;
    object.prize_user = data.prize_user;
    object.prize = data.prize;
    object.prize_status = data.prize_status;
    object.send_prize_user = data.send_prize_user;
    object.history = data.history;

    return object;
};

export const ParticipantsStatusText = (type) => {
    switch (type) {
        case ParticipantsStatus.ACTIVE:
            return store.state.$t('ParticipantsStatus.active');
        case ParticipantsStatus.BLOCKED:
            return store.state.$t('ParticipantsStatus.blocked');
        default:
            return store.state.$t('ParticipantsStatus.unknown');
    }
};

export const PrizeStatusText = (status) => {
    switch (status) {
        case PrizeStatus.NONE:
            return store.state.$t('PrizeStatus.none');
        case PrizeStatus.WAIT:
            return store.state.$t('PrizeStatus.wait');
        case PrizeStatus.SENT:
            return store.state.$t('PrizeStatus.sent');
        default:
            return store.state.$t('PrizeStatus.unknown');
    }
};

export const DrawingStatusText = (status) => {
    switch (status) {
        case DrawingStatus.WAIT:
            return store.state.$t('DrawingStatus.wait');
        case DrawingStatus.ERROR:
            return store.state.$t('DrawingStatus.error');
        case DrawingStatus.FINISHED:
            return store.state.$t('DrawingStatus.finished');
        default:
            return store.state.$t('DrawingStatus.unknown');
    }
};

export const BillStatusText = (status) => {
    switch (status) {
        case 0:
            return store.state.$t('BillStatus.status_0');
        case 1:
            return store.state.$t('BillStatus.status_1');
        case 2:
            return store.state.$t('BillStatus.status_2');
        case 3:
            return store.state.$t('BillStatus.status_3');
        case 4:
            return store.state.$t('BillStatus.status_4');
        case 5:
            return store.state.$t('BillStatus.status_5');
        case 10:
            return store.state.$t('BillStatus.status_10');
        case 11:
            return store.state.$t('BillStatus.status_11');
        case 12:
            return store.state.$t('BillStatus.status_12');
        default:
            return store.state.$t('BillStatus.unknown');
    }
};


// export module
export default {
    ParticipantsStatus,
    ParticipantsStatusText,

    PrizeStatus,
    PrizeStatusText,

    Participants,

    NewParticipants,
    ParticipantsFromBody,

    DrawingStatus,
    DrawingStatusText,

    BillStatusText
};
