let mixin = {
    methods: {
        dragover(event) {
          event.preventDefault();
          // Add some visual fluff to show the user can drop its files
          if (!event.currentTarget.classList.contains('bg-green-300')) {
            event.currentTarget.classList.remove('bg-gray-100');
            event.currentTarget.classList.add('bg-green-300');
          }
        },
        dragleave(event) {
          // Clean up
          event.currentTarget.classList.add('bg-gray-100');
          event.currentTarget.classList.remove('bg-green-300');
        },
    }
}

export default mixin