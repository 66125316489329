import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VCalendar from 'v-calendar';
import { createI18n } from 'vue-i18n'
import ru from './locale/ru.json'
import Notifications from '@kyvg/vue3-notification'
import Datepicker from 'vue3-date-time-picker';
import vSelect from 'vue-select'
import TableLite from 'vue3-table-lite';
import Vue3Progress from "vue3-progress";

import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'

import { install } from './plugin';

import 'vue3-date-time-picker/dist/main.css'

// Create VueI18n instance with options
const i18n = createI18n({
  locale: 'ru',
  fallbackLocale: 'ru',
  messages: { ru },
})

const progressOptions = {
  //position: "fixed",
  height: "4px",
  //color: "#650ED3",
};


export const app = createApp(App)
  .use(router)
  .use(VCalendar, {})
  .use(i18n)
  .use(store)
  .use(Notifications)
  .use(Vue3Progress, progressOptions)
  .use(VueViewer, {
    defaultOptions: {
      zIndex: 9999
    }
  })
  .use(install);

app.component('Datepicker', Datepicker);
app.component('v-select', vSelect)
app.component('vue-table-lite', TableLite)

app.mount('#app')

