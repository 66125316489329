<template>
  <!-- General Information -->
    <NewPromotionStepFirst
        ref="NewPromotionStepFirst"
        v-if="stepCounter === 1"
        :editPermission="editPermission"
        :promotion="item"
        :companies="companies"
        @updatePromotion="updatePromotion"
        @step="changeStep"
    />
  <!-- Participants Information -->
    <NewPromotionStepSecond
        ref="NewPromotionStepSecond"
        v-if="stepCounter === 2"
        :editPermission="editPermission"
        :promotion="item"
        @updatePromotion="updatePromotion"
        @step="changeStep"
    />
  <!-- Communications Information -->
    <NewPromotionStepThird
        ref="NewPromotionStepThird"
        v-if="stepCounter === 3"
        :editPermission="editPermission"
        :promotion="item"
        @updatePromotion="updatePromotion"
        @step="changeStep"
    />
  <!-- Stores and products Information -->
  <NewPromotionStepStores
      ref="NewPromotionStepStores"
      v-if="stepCounter === 4"
      :editPermission="editPermission"
      :promotion="item"
      @updatePromotion="updatePromotion"
      @addNewStore="addNewStore"
      @addNewProduct="addNewProduct"
      @step="changeStep"
  />
  <!-- Prizes Information -->
    <NewPromotionStepFourth
        ref="NewPromotionStepFourth"
        v-if="stepCounter === 5"
        :editPermission="editPermission"
        :promotion="item"
        @updatePromotion="updatePromotion"
        @addNewPrize="addNewPrize"
        @step="changeStep"
        @getDeterminings="getDeterminings"
    />
  <!-- Actions Information -->
    <NewPromotionStepFifth
        ref="NewPromotionStepFifth"
        v-if="stepCounter === 6"
        :editPermission="editPermission"
        :promotion="item"
        @updatePromotion="updatePromotion"
        @savePromotion="savePromotion"
        @addNewAction="addNewAction"
        @step="changeStep"
    />
</template>

<script>
import NewPromotionStepFirst from '@/components/NewPromotionStepFirst.vue'
import NewPromotionStepSecond from '@/components/NewPromotionStepSecond.vue'
import NewPromotionStepThird from '@/components/NewPromotionStepThird.vue'
import NewPromotionStepStores from '@/components/NewPromotionStepStores.vue'
import NewPromotionStepFourth from '@/components/NewPromotionStepFourth.vue'
import NewPromotionStepFifth from '@/components/NewPromotionStepFifth.vue'

import {hasContent} from "@/tools/dataTools";

export default {
  name: 'NewPromotionsStepsScreen',
  emits: [
    "step",
    "updatePromotion",
    "savePromotion",
    "addNewStore",
    "addNewProduct",
    "addNewPrize",
    "addNewAction",
    "getDeterminings",
  ],
  components: {
    NewPromotionStepFirst,
    NewPromotionStepSecond,
    NewPromotionStepThird,
    NewPromotionStepStores,
    NewPromotionStepFourth,
    NewPromotionStepFifth
  },
  props: {
    editPermission: Boolean,
    promotion: Object,
    companies: Array,
    step: Number,
  },
  data() {
    return {
      stepCounter: 1,

      item: {},
    }
  },
  watch: {
    stepCounter: function() {
      this.$emit("step", this.stepCounter)
    },

    promotion: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler () {
        this.item = this.promotion
      }
    },
  },
  mounted() {
    this.$emit("step", this.stepCounter)
  },
  methods: {
    updatePromotion(item) {
      if (hasContent(item)) {
        this.item = item
        this.$emit("updatePromotion", this.item)
      }
    },

    savePromotion(item) {
      if (hasContent(item)) {
        this.item = item
        this.$emit("savePromotion", this.item)
      }
    },

    changeStep(counter) {
      this.stepCounter = counter;
    },

    checkStepCompleted(step) {
      switch (step) {
        // Шаг 1. Общие данные акции - done
        case 1:
          return this.$refs.NewPromotionStepFirst.checkStepCompleted()
        // Шаг 2. Данные участников - done
        case 2:
          return this.$refs.NewPromotionStepSecond.checkStepCompleted()
        // Шаг 3. Коммуникации - done
        case 3:
          return this.$refs.NewPromotionStepThird.checkStepCompleted()
        // Шаг 4. Магазины и акционные товары - done
        case 4:
          return this.$refs.NewPromotionStepStores.checkStepCompleted()
        // Шаг 5. Условия для получения приза - done
        case 5:
          return this.$refs.NewPromotionStepFourth.checkStepCompleted()
        // Шаг 6. Пригласительные коды или иные интерактивные  действия - working
        case 6:
          return this.$refs.NewPromotionStepFifth.checkStepCompleted()
        default:
          return false
      }
    },

    addNewStore() {
      this.$emit("addNewStore", null)
    },

    addNewProduct() {
      this.$emit("addNewProduct", null)
    },

    addNewPrize() {
      this.$emit("addNewPrize", null)
    },

    addNewAction() {
      this.$emit("addNewAction", null)
    },

    getDeterminings() {
      this.$emit("getDeterminings", null)
    },

  },
}
</script>

<style lang="scss" src="@/assets/scss/common.scss"></style>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixin.scss";

.required.not-validate {
    position: relative;
    &::after {
        content: 'Поле заполнено некорректно!';
        position: absolute;
        bottom: -15px;
        left: 0;
        color: $red;
        font-size: 12px;
    }
}

.step {
    margin-bottom: 35px;
    &__content {
        padding: 25px 60px 35px 35px;
        border-radius: 10px;
        background-color: $white;

        &.registry {
            margin-top: 5px;
        }

        &.vertical-space {
            margin-top: 5px;
        }
    }

    &__inner-header {
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 10px;
    }

    
    .step__table.four-columns {
        .table {
            @include grid-table-row(150px, 60px, 40% 125px 12% 150px 12% 180px 3.7%);
            margin-bottom: 5px;

            &__body .table__row {
                position: relative;
                &::before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 20px;
                    width: 20px;
                    height: 20px;
                    background: url('../assets/img/arrow-row.png');
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                }
            }

            &__cell {
                &:first-child {
                    grid-column-start: 1;
                    grid-column-end: 2;
                }
                &:nth-child(2) {
                    grid-column-start: 3;
                    grid-column-end: 4;
                }
                &:nth-child(3) {
                    grid-column-start: 5;
                    grid-column-end: 6;
                }
                &:nth-child(4) {
                    grid-column-start: 7;
                    grid-column-end: 8;
                }
            }
        }
    }

    .step__table.three-columns {
        .table {
            @include grid-table-row(160px, 235px, 45% 250px 10% 200px 2%);
            margin-bottom: 5px;

            &__body .table__row {
                position: relative;
                &::before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 20px;
                    width: 20px;
                    height: 20px;
                    background: url('../assets/img/arrow-row.png');
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                }
            }

            &__body .table__row__selected {
                position: relative;
                background-color: #EDEFF8;
                &::before {
                  content: "";
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                  left: 20px;
                  width: 20px;
                  height: 20px;
                  background: url('../assets/img/arrow-row-white.png');
                  background-repeat: no-repeat;
                  background-size: contain;
                  background-position: center;
                }
            }

            &__cell {
                &:first-child {
                    grid-column-start: 1;
                    grid-column-end: 2;
                }
                &:nth-child(2) {
                    grid-column-start: 3;
                    grid-column-end: 4;
                }
                &:nth-child(3) {
                    grid-column-start: 5;
                    grid-column-end: 6;
                }
            }
        }
    }

    .step__table.two-columns {
        .table {
            @include grid-table-row(145px, 245px, 45% 595px 4.5%);
            margin-bottom: 5px;

            &__body .table__row {
                position: relative;
                &::before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 20px;
                    width: 20px;
                    height: 20px;
                    background: url('../assets/img/arrow-row.png');
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                }
            }

            &__cell {
                &:first-child {
                    grid-column-start: 1;
                    grid-column-end: 2;
                }
                &:nth-child(2) {
                    grid-column-start: 3;
                    grid-column-end: 4;
                }
            }
        }
    }
    // .table {
    //     @include grid-table-row(150px, 60px, 40% 125px 12% 150px 12% 180px 3.7%);
    //     margin-bottom: 5px;

    //     &__body .table__row {
    //         position: relative;
    //         &::before {
    //             content: "";
    //             position: absolute;
    //             top: 50%;
    //             transform: translateY(-50%);
    //             left: 20px;
    //             width: 20px;
    //             height: 20px;
    //             background: url('../assets/img/arrow-row.png');
    //             background-repeat: no-repeat;
    //             background-size: contain;
    //             background-position: center;
    //         }
    //     }

    //     &__cell {
    //         &:first-child {
    //             grid-column-start: 1;
    //             grid-column-end: 2;
    //         }
    //         &:nth-child(2) {
    //             grid-column-start: 3;
    //             grid-column-end: 4;
    //         }
    //         &:nth-child(3) {
    //             grid-column-start: 5;
    //             grid-column-end: 6;
    //         }
    //         &:nth-child(4) {
    //             grid-column-start: 7;
    //             grid-column-end: 8;
    //         }
    //     }
    // }
}

.step + .buttons-block {
    margin-bottom: 100px;
}

.step__header {
    font-size: 16px;
    font-weight: 500;
    color: $activeText;
    margin-bottom: 10px;
    text-transform: uppercase;
}

.step__items {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 20px;
    &.two-columns {
        grid-template-columns: 37% 59%;
        grid-column-gap: 65px;
        .steps__items-column {
            display: flex;
            justify-content: space-between;
            flex-flow: row wrap;
        }
        &.steps__items-column:first-child {
            flex-flow: column nowrap;
        }
    }
    .steps__items-column {
        display: flex;
    }
    .steps__items-row {
        display: grid;
        grid-column-gap: 15px;
        grid-template-columns: repeat(3, 1fr) 175px;
        width: 100%;
        &:first-child {
        }
        &:last-child {
            .buttons-panel__checkbox:first-child {
                grid-column-start: 1;
                grid-column-end: 3;
            }
        }
    }
}

.step__item {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-end;
    width: 265px;
    position: relative;
    &-text {
        width: 100%;
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 5px;
        color: $black;
        span {
            color: $red;
        }
    }

    &-popup {
        width: 100%;
        display: none;
        position: absolute;
        top: 65px;
        left: 50%;
        transform: translateX(-50%);
        padding: 6px;
        background: $bodyBackground;
        font-size: 12px;
        color: $activeText;
        border-radius: 5px;
        z-index: 10;
    }

    &-input {
        @include search-input;
    }

    &-input:hover + .step__item-popup.not-empty {
        display: block;
    }

    &-button {
        @include search-input__button;
    }

    &-period {
        width: 122px;
        height: fit-content;
        padding: 10px;
        font-size: 12px;
        font-weight: 500;
        border: 1px solid $inputCaption;
        border-radius: 5px;
        box-shadow: 0px 0px 2px 0px #00000040;
        outline: none;
        &::placeholder {
            color: $inputCaption;
        }
    }

    &.period {
        @include period-input;
        &::after {
            top: unset;
            bottom: 10px;
            transform: none;
        }
    }

    &.short {
      width: 100%;
      grid-column: span 1;
      margin-top: 10px;
    }

    &.medium {
      width: 100%;
      grid-column: span 3;
      align-items: flex-end;
      margin-top: 10px;
    }

    &.large {
        width: 100%;
        grid-column: span 3;
        align-items: flex-end;
    }

    &.textarea {
        input {
            border-radius: 5px;
            width: 100%;
        }
    }

    &.textarea-prize {
        input {
            border-radius: 5px;
            width: 100%;
            min-width: 815px;
        }
    }

    &.select {
        .step__item-button {
            &::after {
                width: 9px;
                height: 7px;
                background: url('../assets/img/triangle.png');
            }
        }
    }

    &.button {
        @include white-button;
        width: 130px;
        align-self: flex-end;
        text-align: center;
        grid-column-start: 6;
        grid-column-end: 7;
        p {
            font-size: 16px;
            color: $purple;
            margin-bottom: 0;
        }
    }

    &.search {
      display: flex;
      align-items: center;
      margin-right: 30px;

      &-text {
        font-size: 12px;
        font-weight: 500;
        margin-right: 30px;
      }

      &-input {
        @include search-input;
      }

      &-button {
        @include search-input__button;
      }

      &.select {
        .buttons-panel__input-text {
          position: relative;

          &::after {
            content: "*";
            position: absolute;
            top: -2px;
            right: -6px;
            color: $red;
          }
        }

        .buttons-panel__input-button {
          &::after {
            width: 9px;
            height: 7px;
            background: url('../assets/img/triangle.png');
          }
        }
      }
    }
}

.buttons-panel__checkbox {
  margin-right: 0;
  margin-bottom: 4px;
}

.prizes-empty,
.codes-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column nowrap;
    &__image {
        margin-bottom: 20px;
    }
    &__text {
        max-width: 380px;
        font-size: 20px;
        text-align: center;
    }
}

</style>