export const Tips = {
    // UUID
    id: '',
    // string
    name: '',
    // string
    description: '',
    // json
    links: [],
    // json
    photos: [],
    // json
    videos: [],
    // json
    history: [],
};

export const NewTips = () => {
    return JSON.parse(JSON.stringify(Tips));
};

export const TipsFromBody = (data) => {
    let object = NewTips()

    object.id = data.id;
    object.name = data.name;
    object.description = data.description;

    object.links = data.links;
    object.photos = data.photos;
    object.videos = data.videos;
    object.history = data.history;

    return object;
};


// export module
export default {
    Tips,

    NewTips,
    TipsFromBody,
};
