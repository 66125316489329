import { generateUid } from '@/tools/cryptoTools'
import {hasContent} from "@/tools/dataTools";

export const Stores = {
    // UUID
    id: '',
    // string
    name: '',
    // string
    inn: '',
    // string
    status: '',
    // json
    history: [],
};

export const NewStore = () => {
    let item = JSON.parse(JSON.stringify(Stores))

    item.id = generateUid()

    return item
};

export const StoreFromBody = (data) => {
    let object = NewStore()

    object.id = data.id;
    object.name = data.name;
    object.inn = data.inn;
    object.status = data.status;
    object.history = data.history;

    return object;
};

export const StoreRequiredVerify = (prize) => {
    if(hasContent(prize)) {
        if(hasContent(prize.id)) {
            return !!(hasContent(prize.name) &&
                hasContent(prize.inn));
        }
        else {
            return !!(hasContent(prize.name) &&
                hasContent(prize.inn));
        }
    }
    else {
        return false;
    }
};

export const Products = {
    // UUID
    id: '',
    // string
    name: '',
    // string
    code: '',
    // string
    status: '',
    // integer
    score: 1,
    // json
    history: [],
};

export const NewProduct = () => {
    let item = JSON.parse(JSON.stringify(Products))

    item.id = generateUid()

    return item
};

export const ProductFromBody = (data) => {
    let object = NewProduct()

    object.id = data.id;
    object.name = data.name;
    object.code = data.code;
    object.status = data.status;
    object.score = data.score;
    object.history = data.history;

    return object;
};

export const ProductRequiredVerify = (prize) => {
    if(hasContent(prize)) {
        if(hasContent(prize.id)) {
            return !!(hasContent(prize.name) &&
              hasContent(prize.score));
        }
        else {
            return !!(hasContent(prize.name) &&
              hasContent(prize.score));
        }
    }
    else {
        return false;
    }
};


// export module
export default {
    Stores,
    NewStore,
    StoreFromBody,
    StoreRequiredVerify,

    Products,
    NewProduct,
    ProductFromBody,
    ProductRequiredVerify,
};
