import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Accounts from '../views/Accounts.vue'
import Bookkeeping from '../views/Bookkeeping.vue'
import Codes from '../views/Codes.vue'
import Companies from '../views/Companies.vue'
import Handbooks from '../views/Handbooks.vue'
import Lists from '../views/Lists.vue'
import ListItems from '../views/ListItems.vue'
import Promotions from '../views/Promotions.vue'
import ReportsOld from '../views/ReportsOld.vue'
import Reports from '../views/Reports.vue'
import SuspiciousParticipants from '../views/SuspiciousParticipants.vue'
import Login from '../views/Login.vue'
import NewPromotion from '../views/NewPromotion.vue'
import NewAccount from '../views/NewAccount.vue'
import NewReport from '../views/NewReport.vue'
import PhonesItems from '../views/PhonesItems.vue'
import Bill from '../views/Bill.vue'

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: 'companies'
  },
  {
    path: '/accounts',
    name: 'Accounts',
    component: Accounts
  },
  {
    path: '/bookkeeping',
    name: 'Bookkeeping',
    component: Bookkeeping
  },
  {
    path: '/codes',
    name: 'Codes',
    component: Codes
  },
  {
    path: '/companies',
    name: 'Companies',
    component: Companies
  },
  {
    path: '/handbooks',
    name: 'Handbooks',
    component: Handbooks
  },
  {
    path: '/lists',
    name: 'Lists',
    component: Lists
  },
  {
    path: '/listitems',
    name: 'ListItems',
    component: ListItems
  },
  {
    path: '/promotions',
    name: 'Promotions',
    component: Promotions
  },
  {
    path: '/reports_old',
    name: 'ReportsOld',
    component: ReportsOld
  },
  {
    path: '/reports',
    name: 'Reports',
    component: Reports
  },
  {
    path: '/suspicious-participants',
    name: 'SuspiciousParticipants',
    component: SuspiciousParticipants
  },
  {
    path: '/promotions/new-promotions',
    name: 'NewPromotion',
    component: NewPromotion
  },
  {
    path: '/promotions/new-account',
    name: 'NewAccount',
    component: NewAccount
  },
  {
    path: '/reports/new-report',
    name: 'NewReport',
    component: NewReport
  },
  {
    path: '/phones',
    name: 'Phones',
    component: PhonesItems
  },
  {
    path: '/bill',
    name: 'Bill',
    component: Bill
  },
]

const router = createRouter({
  //history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),
  routes
})

export default router
