<template>

  <ModalPromotionPrize
      v-if="modalPrizeOpened"
      :editPermission="false"
      :prize="currentPrize"
      @close-modal="closeModalPrizeInformation"
  />

  <div
    class="modal"
    v-if="opened"
  >
    <div class="wrapper">

      <!-- Header -->
      <div class="header">
        <div class="left-content">
          <div class="text">{{ `${$t('TaxText.userPrizes')} ${year} ${$t('TaxText.yearLower')}` }}</div>
        </div>
        <div
          class="close"
          @click="close"
        >
          <img src="@/assets/img/icons/close.svg">
        </div>

      </div>

      <!-- Content -->
      <div class="content">

        <div class="chat-history">

          <!-- Tabs Titles -->
          <div class="tabs-wrapper">

            <!-- History Tab -->
            <div v-if="historyTabVisibility"
                class="tab-history"
                @click="activeTab = 0"
            >
              <p>{{ $t('TabHeaders.history') }}</p>
              <div
                  class="shadow"
                  v-if="!(activeTab === 0)"
              ></div>
            </div>
          </div>


          <!-- History Tab Content -->
          <div
              class="history-wrapper"
              v-if="activeTab === 0"
          >
            <div class="inner">
              <div class="table-wrapper">
                <div class="column-names">
                  <div class="date">{{ $t('TableHeaders.date') }}</div>
                  <div class="time">{{ $t('FieldsText.company_label') }}</div>
                  <div class="author">{{ $t('FieldsText.promotion_label') }}</div>
                  <div class="changes">{{ $t('FieldsText.prize_label') }}</div>
                  <div class="action"></div>
                </div>
                <div class="table">
                  <div
                      class="table-string"
                      v-for="item in prizesData"
                      :key="item.id"
                  >
                    <div class="table-cell">{{ parseFullTime(item.createdAt) }}</div>
                    <div class="table-cell">{{ item.fk_promotion.fk_company.name }}</div>
                    <div class="table-cell">{{ item.fk_promotion.name }}</div>
                    <div class="table-cell">{{ item.prize.prize.full_name }}</div>

                    <div class="table__cell button">
                      <div
                          class="table__cell-button"
                          :title="$t('ListText.prize_information')"
                          @click="showPrizeInformation(item.prize)"
                      >
                        <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M13.0127 5.68201H16.4809C17.3121 5.68201 18 6.35565 18 7.23431V12.5649H9.74522V6.35565C9.4586 6.26778 9.20064 6.15063 9.02866 5.9749C9 5.9749 9 5.94561 8.97134 5.94561C8.97134 5.94561 8.97134 5.9749 8.94268 5.9749C8.74204 6.15063 8.51274 6.26778 8.25478 6.35565V12.5649H0V7.23431C0 6.35565 0.659236 5.68201 1.49045 5.68201H4.98726C4.58599 5.47699 4.21338 5.1841 3.89809 4.9205C2.52229 3.57322 2.26433 1.69874 3.3535 0.644351C3.8121 0.23431 4.41401 0 5.07325 0C6.01911 0 6.99363 0.380753 7.82484 1.17155C8.28344 1.61088 8.71338 2.22594 8.97134 2.81172C9.25796 2.22594 9.6879 1.61088 10.1465 1.17155C10.949 0.380753 11.9809 0 12.8981 0C13.586 0 14.1879 0.23431 14.6178 0.644351C15.707 1.69874 15.449 3.57322 14.0446 4.9205C13.758 5.1841 13.3854 5.47699 13.0127 5.68201ZM7.91083 4.83264C8.02548 4.56904 7.73885 3.22176 6.79299 2.31381C6.30573 1.84519 5.6465 1.58159 5.07325 1.58159C4.90127 1.58159 4.61465 1.61088 4.41401 1.78661C4.35669 1.84519 4.18471 1.96234 4.18471 2.28452C4.18471 2.66527 4.41401 3.25105 4.9586 3.74895C5.6465 4.42259 6.73567 4.89121 7.53822 4.89121C7.79618 4.89121 7.91083 4.83264 7.91083 4.83264ZM10.0892 4.83264C10.0892 4.83264 10.2325 4.89121 10.4618 4.89121C11.2643 4.89121 12.3535 4.42259 13.0414 3.74895C13.586 3.25105 13.758 2.66527 13.758 2.28452C13.758 1.96234 13.6146 1.84519 13.586 1.78661C13.3854 1.61088 13.0701 1.58159 12.8981 1.58159C12.3535 1.58159 11.6656 1.84519 11.1783 2.31381C10.2611 3.22176 9.94586 4.53975 10.0892 4.86192V4.83264ZM8.25478 21H1.49045C0.659236 21 0 20.3264 0 19.477V14.1172H8.25478V21ZM16.4809 21H9.74522V14.1172H18V19.477C18 20.3264 17.3121 21 16.4809 21Z" fill="#BDBDBD"/>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import {
  UsersType,
} from "@/models/users";

import {
  hasContent,
} from "@/tools/dataTools";

import {
  parseDate,
  parseTime,
  parseFullTime,
} from "@/tools/dateTools";

import ModalPromotionPrize from "@/components/ModalPromotionPrize.vue";

export default {
  name: "UserPrizesList",
  emits: ['close-modal'],
  components: {
    ModalPromotionPrize,
  },
  props: {
    opened: Boolean,
    participants: Array,
    year: Number,
  },
  delimiters: ['${', '}'], // Avoid Twig conflicts
  data() {
    return {

      // data
      activeTab: 0,

      prizesData: [],

      modalPrizeOpened: false,
      currentPrize: null,
    };
  },
  mounted() {
    this.preparePrizesData()
  },
  created() {
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    participants: function (newValue, oldValue) {
      this.preparePrizesData()
    },

  },
  methods: {
    ...mapGetters(["GET_USER",]),

    parseDate,
    parseTime,
    parseFullTime,

    preparePrizesData() {
      let items = []

      if (hasContent(this.participants)) {
        this.participants.map((participant) => {
          if(participant.prizes) {
            participant.prizes.map((prize) => {
              let item = JSON.parse(JSON.stringify(participant))
              delete item.prizes
              item.prize = JSON.parse(JSON.stringify(prize))

              items.push(item)
            })
          }
        })
      }

      this.prizesData = items
    },

    showPrizeInformation(prize) {
      if(prize && prize.prize) {
        this.currentPrize = prize.prize;
        this.modalPrizeOpened = true;
      }
    },

    closeModalPrizeInformation() {
      this.currentPrize = null;
      this.modalPrizeOpened = false;
    },

    close() {
      this.activeTab = 0;

      this.$emit('close-modal');
    }

  },
  computed: {
    user() {
      return this.GET_USER();
    },
    isAdmin() {
      return this.user ? (this.user.role === UsersType.ADMIN) : false;
    },

    historyTabVisibility() {
      //return true;
      return hasContent(this.prizesData) && this.editPermission;
    },
    editPermission() {
      // if(this.user.id === this.prizesData.id)
      //   return true;
      // else
      return this.isAdmin;
    },
  },
};
</script>

<style scoped lang="scss">
  @import "@/assets/scss/_variables.scss";
  @import "@/assets/scss/_mixin.scss";

  .v-cloak {
  display: none;
  }

  .modal {
    @include modal-base;
    .wrapper {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 100;
      overflow: hidden;
      border-radius: 10px;
      background: #fff;
      width: 1032px;
      padding-bottom: 1px;
      @include modal-base-header;
      @include modal-base-content;
      .header {
        background: $mainBgColor;
      }
      .vs__dropdown-toggle {
        height: 24px !important;
      }
      .content {
        .user-info {
          @include d-flex-row-n;
          .edit-buttons {
            @include d-flex-col-n;
            .avatar {
              border-radius: 5px;
              overflow: hidden;
              display: flex;
              flex-flow: row nowrap;
              position: relative;
              width: 158px;
              height: 132px;
              align-items: center;
              justify-content: center;
              img {
                width: 100%;
                height: 100%;
              }
              .edit-overlay {
                background: rgba(0, 0, 0, 0.3);
                @include d-flex-row-n;
                justify-content: center;
                align-items: center;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                cursor: pointer;
                .image-input {
                  left: 0;
                  top: 0;
                  opacity: 0;
                  display: block;
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  cursor: pointer;
                }
              }
            }
            .edit-button {
              margin-top: 9px;
              @include ui-default-button(#fff, $blue);
            }
            .save-button {
              margin-top: 11px;
              @include ui-default-button($blue, #fff);
            }
          }
          .edit-inputs {
            @include d-flex-col-n;
            width: 241px;
            margin-left: 33px;
            & > *:first-child ~ * {
              margin-top: 5px;
            }
          }
        }
        .chat-history {
          .tabs-wrapper {
            display: flex;
            .tab-chat,
            .tab-history {
              background-color: $white;
              border-radius: 10px;
              width: 123px;
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              box-shadow: 0px -3px 4px 0px #0000000d;
              cursor: pointer;
              position: relative;
              p {
                color: $blue;
              }
              .shadow {
                position: absolute;
                bottom: -10px;
                left: -6px;
                width: 144px;
                height: 10px;
                box-shadow: 0px -3px 4px 0px #0000000d;
                z-index: 1;
              }
            }
            .tab-chat {
              margin-right: 10px;
              position: relative;
              &::before {
                content: "";
                position: absolute;
                width: 10px;
                height: 20px;
                bottom: -10px;
                left: 0;
                background-color: $white;
              }
              &::after {
                content: "";
                width: 20px;
                height: 10px;
                position: absolute;
                bottom: -1px;
                right: -10px;
                background-color: $white;
                clip-path: polygon(
                        52% 23%,
                        51% 10%,
                        50% 0%,
                        0% 0%,
                        0% 25%,
                        0% 100%,
                        100% 100%,
                        92% 96%,
                        84% 90%,
                        73% 80%,
                        67% 70%,
                        62% 59%,
                        58% 47%,
                        55% 36%
                );
              }
              .shadow {
                left: 0;
                width: 128px;
              }
            }
            .tab-history {
              position: relative;
              &::before {
                content: "";
                position: absolute;
                width: 20px;
                height: 10px;
                bottom: -1px;
                left: -10px;
                background-color: $white;
                transform: scale(-1, 1);
                clip-path: polygon(
                        52% 23%,
                        51% 10%,
                        50% 0%,
                        0% 0%,
                        0% 25%,
                        0% 100%,
                        100% 100%,
                        92% 96%,
                        84% 90%,
                        73% 80%,
                        67% 70%,
                        62% 59%,
                        58% 47%,
                        55% 36%
                );
              }

              &::after {
                content: "";
                width: 20px;
                height: 10px;
                position: absolute;
                bottom: -1px;
                right: -10px;
                background-color: $white;
                clip-path: polygon(
                        52% 23%,
                        51% 10%,
                        50% 0%,
                        0% 0%,
                        0% 25%,
                        0% 100%,
                        100% 100%,
                        92% 96%,
                        84% 90%,
                        73% 80%,
                        67% 70%,
                        62% 59%,
                        58% 47%,
                        55% 36%
                );
              }
            }
          }
          .main-wrapper {
            border-radius: 10px;
            background-color: $white;
            //padding: 30px 30px 40px;
            box-shadow: 0px -3px 4px 0px #0000000d;
          }
          .documents-wrapper {
            border-radius: 10px;
            background-color: $white;
            //padding: 30px 30px 40px;
            box-shadow: 0px -3px 4px 0px #0000000d;
          }
          .chat-wrapper {
            border-radius: 10px;
            background-color: $white;
            padding: 12px 25px 25px 25px;
            box-shadow: 0px -3px 4px 0px #0000000d;
            .msgs-container {
              margin-bottom: 27px;
              height: 454px;
              overflow-y: scroll;
              .msg-container {
                max-width: 77%;
                display: flex;
                margin-bottom: 13px;
                img {
                  width: 40px;
                  height: 40px;
                  margin-right: 20px;
                }
                .msg-wrapper {
                  display: flex;
                  flex-direction: column;
                  .msg {
                    margin-bottom: 5px;
                    padding: 7px 33px 6px 13px;
                    background-color: #f2f2f2;
                    border-radius: 100px;
                    p {
                      color: $activeText;
                      margin: 0;
                    }
                    .msg-info {
                      display: flex;
                      margin-bottom: 5px;
                      .name {
                        font-size: 12px;
                        font-weight: 500;
                        margin-right: 15px;
                      }
                      .date-time {
                        color: $chatNotActiveText;
                        font-size: 10px;
                      }
                    }
                    .text {
                      font-size: 12px;
                      font-weight: 300;
                      flex-grow: 1;
                    }
                  }
                  .answer-button {
                    color: $textGray;
                    font-size: 10px;
                  }
                }
                &.self-message {
                  margin: 0 0 13px auto;
                  justify-content: flex-end;
                  img {
                    order: 2;
                    margin-right: 10px;
                    margin-left: 20px;
                  }
                  .msg-wrapper {
                    .answer-button {
                      display: inline-block;
                      cursor: pointer;
                      text-align: right;
                    }
                  }
                }
                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
            .chat-input {
              display: flex;
              .avatar {
                width: 36px;
                height: 36px;
                border-radius: 50%;
                margin-right: 18px;
              }
              .chat-form {
                display: flex;
                width: 100%;
                .chat-message {
                  flex-grow: 1;
                  margin-right: 10px;
                  padding: 12px;
                  border: 1px solid $inputCaption;
                  border-radius: 5px;
                  font-size: 12px;
                  font-weight: 500;
                  &::placeholder {
                    color: $textGray;
                    font-size: 12px;
                  }
                }
                .send-message {
                  width: 158px;
                  border: 1px solid $purple;
                  border-radius: 5px;
                  background-color: $white;
                  box-shadow: 0px 0px 4px 0px #00000040;
                  color: $purple;
                  &:hover {
                    cursor: pointer;
                  }
                }
              }
            }
          }
          .history-wrapper {
            background-color: $white;
            padding: 12px 25px 25px 25px;
            box-shadow: 0px -3px 4px 0px #0000000d;
            border-radius: 10px;
            .inner {
              .table-wrapper {
                height: 520px;
                overflow-y: scroll;
                .column-names {
                  padding: 7px;
                  margin-bottom: 5px;
                  display: flex;
                  justify-content: space-between;
                  background-color: $photoBg;
                  border-radius: 5px;
                  div {
                    color: $textGray;
                    text-align: center;
                  }
                  .date {
                    width: 114px;
                  }
                  .time {
                    width: 93px;
                  }
                  .author {
                    width: 133px;
                  }
                  .changes {
                    width: 362px;
                  }
                  .action {
                    width: 14px;
                  }
                }
                .table {
                  .table-string {
                    display: flex;
                    justify-content: space-between;
                    .table-cell {
                      padding: 7px 0 13px;
                      color: $textGray;
                      text-align: center;
                      position: relative;
                      &:nth-child(1) {
                        width: 114px;
                      }
                      &:nth-child(2) {
                        width: 93px;
                      }
                      &:nth-child(3) {
                        width: 133px;
                      }
                      &:nth-child(4) {
                        width: 362px;
                      }
                      &::after {
                        position: absolute;
                        content: "";
                        bottom: 0;
                        left: 0;
                        right: 0;
                        border-radius: 5px;
                        height: 1px;
                        background-color: $photoBg;
                      }
                    }
                  }
                }
              }
            }
          }
          .active {
            display: block;
          }
        }

        .dd {
          position: relative;
          width: 250px;
          &.object {
            width: 100% !important;
            margin-top: 0px !important;
            font-size: 12px;
            font-weight: 500;
            // .dd-button {
            //   height: 27px !important;
            // }
          }
          .dd-button {
            @include ui-dropdown-button();
            border-radius: 0px 5px 5px 0px;
          }
          & ::v-deep .vs__dropdown-toggle {
            border: 1px solid $inputCaption;
            box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.25);
            border-radius: 5px;
          }
          &:not(:first-child) {
            margin-top: 10px;
          }
        }

        .avatar-preview {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          &__item {
            width: 180px;
            height: 180px;
            border-radius: 50%;
            margin: auto;
            cursor: pointer;
            &:not(:last-child) {
              margin-right: 30px;
            }
          }
          &__img {
            width: 70px;
            height: 70px;
          }
        }

        .photo-preview {
          display: flex;
          flex-wrap: wrap;
          &__item {
            width: 70px;
            height: 70px;
            cursor: pointer;
            margin-bottom: 20px;
            &:not(:last-child) {
              margin-right: 30px;
            }
          }
          &__img {
            width: 70px;
            height: 70px;
          }
        }

        .input-wrapper {
          margin-bottom: 10px;
          .caption {
            font-size: 12px;
            color: $inputCaption;
          }
          input {
            border: none;
            border-bottom: 1px solid #d9d9d9;
            color: rgba(#1e4fdf, 0.17);
            outline: none;
            font-size: 14px;
            color: #000000;
            width: 100%;
          }
          textarea {
            border: none;
            border-bottom: 1px solid #d9d9d9;
            color: rgba(30, 79, 223, 0.17);
            outline: none;
            font-size: 14px;
            color: #4f4f4f;
            width: 100%;
            resize: none;
          }
        }

      }
    }
    .date-picker {
      align-items: center;
      .d-comp {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        opacity: 0;
        background: black;
        // z-index: 99999;
      }
      .date-start {
        position: relative;
        input {
          @include ui-input($pl: 11px, $w: 265px);
        }
      }
      .date-pick {
        position: relative;
      }
    }
  }

  .new-account {
    display: flex;
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    width: 1032px;
    height: 558px;
    background-color: $white;
    border-radius: 10px;
    padding: 50px 145px 40px 30px;
    // &__inner {
    //     display: flex;
    //     flex-flow: row nowrap;
    // }

    &__validation-rule {
      margin-bottom: 30px;
      span {
        color: $red;
      }
      &-back {
        @include white-button;
        width: 190px;
      }
      &-forward {

      }
    }
  }

  .new-account__columns {
    display: flex;
    flex-flow: row nowrap;
    margin-right: 30px;
  }

  .new-account__column {
    &:first-child {
      margin-right: 30px;
    }
    &:nth-child(2) {
      margin-right: 30px;
    }
    &:nth-child(3) {
      margin-right: -30px;
    }
  }

  .new-account__inner-header {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 500;
    margin-top: -20px;
    margin-bottom: 20px;
  }

  .new-account__item {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    width: 265px;
    margin-bottom: 20px;
    &-text {
      width: 100%;
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 5px;
      color: $black;
      span {
        color: $red;
      }
    }

    &-input {
      @include search-input;
    }

    &.textarea {
      grid-column: span 3;
      input {
        border-radius: 5px;
        width: 100%;
      }
    }
  }

  .new-account__checkbox {
    margin-top: 15px;

    label {
      margin-left: 10px;
    }
  }

</style>