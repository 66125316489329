// Nodejs encryption with CTR
const crypto = require("crypto");
const {cryptData, cryptoKey} = require("@/common/config");
const CryptoJS = require("crypto-js");

let digits        = '0123456789';
let enUppercase   = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
let enLowercase   = 'abcdefghijklmnopqrstuvwxyz';
let ruUppercase   = 'АБВГДЕЁЖЗИЙКЛМНОПРСТУФХЦЧШЩЪЫЬЭЮЯ';
let ruLowercase   = 'абвгдеёжзийклмнопрстуфхцчшщъыьэюя';

module.exports = {
  /* Generate random 36 length value */
  generateUid: function (length = 16) {
    return crypto.randomBytes(length).toString('hex')
  },

  getStringByConfiguration: function (useDigits, enUp, enLow, ruUp, ruLow, exclude) {
    let characters = '';

    // format characters using config
    if(useDigits)
      characters += digits;
    if(enUp)
      characters += enUppercase;
    if(enLow)
      characters += enLowercase;
    if(ruUp)
      characters += ruUppercase;
    if(ruLow)
      characters += ruLowercase;

    // exclude characters from string
    for (const char of exclude ? exclude : '') {
      characters = characters.replace(char, '')
    }

    return characters;
  },

  generateRandomStringFromString: function (length, characters) {
    let result = '';
    let charactersLength = characters.length;

    for ( let i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  },

  generateRandomString: function (length, useDigits, enUp, enLow, ruUp, ruLow, exclude) {
    let result = '';
    let characters = this.getStringByConfiguration(useDigits, enUp, enLow, ruUp, ruLow, exclude);

    let charactersLength = characters.length;

    for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  },

  randomFixedInteger: function (length) {
    return Math.floor(Math.pow(10, length-1) + Math.random() * (Math.pow(10, length) - Math.pow(10, length-1) - 1));
  },

  tryToEncryptObject: function (data) {
    return cryptData ? {
      data: CryptoJS.AES.encrypt(JSON.stringify(data), cryptoKey).toString(),
    } : data
  }

};