import {hasContent} from "@/tools/dataTools";

export const GameItem = {
    // UUID
    id: '',
    // string
    name: '',
    // JSON
    info: {
    },
    // json
    history: [],
};

export const NewGameItem = () => {
    let item = JSON.parse(JSON.stringify(GameItem))

    return item
};

export const GameItemFromBody = (data) => {
    let object = NewGameItem()

    object.id = data.id;
    object.name = data.name;
    object.info = JSON.parse(JSON.stringify(data.info));
    object.history = data.history;

    return object;
};


export const GameItemRequiredVerify = (game) => {
    if(hasContent(game)) {
        if(hasContent(game.id)) {
            return !!(hasContent(game.name));
        }
        else {
            return !!(hasContent(game.name));
        }
    }
    else {
        return false;
    }
};

// export module
export default {
    GameItem,
    NewGameItem,
    GameItemFromBody,

    GameItemRequiredVerify,
};
