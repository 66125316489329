<template>

    <!-- Stores List -->
    <div class="step__table three-columns">

      <div v-if="item && item.stores && item.stores.length > 0">
        <div class="title-header">
          <p>{{ `${$t('StoreText.header')} (${item.stores.length})` }}</p>
        </div>

        <div class="table three-columns">
          <div class="table__row title">
            <p class="table__cell">{{ $t('FieldsText.title_label') }}</p>
            <p class="table__cell">{{ $t('StoreText.inn_label') }}</p>
            <p class="table__cell">{{ $t('TableHeaders.action') }}</p>
          </div>
          <div class="table__body">
            <div
                v-for="storeItem in item.stores" :key="storeItem.id"
                class="table__row"
                :class="(store && store.id === storeItem.id) ? 'table__row__selected' : 'table__row'"
                style="cursor: pointer;"
                @click="changeStoreID(storeItem)"
            >
              <div style="text-align: left; margin-left: -150px;">
                <p>
                  {{ storeItem.name }}
                </p>
              </div>
              <div class="table__cell">
                <p>
                  {{ storeItem.inn }}
                </p>
              </div>
              <div class="table__cell button">
                <div class="table__cell-button" :title="$t('Buttons.delete')" @click="deleteStore(storeItem)">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.0001 3.12868e-08C10.8855 -0.000110722 11.7389 0.293826 12.3928 0.824097C13.0467 1.35437 13.4539 2.08266 13.5344 2.86599H19.0325C19.2777 2.86605 19.5138 2.94881 19.6929 3.09753C19.8721 3.24625 19.981 3.44984 19.9977 3.66718C20.0145 3.88451 19.9377 4.09938 19.783 4.26837C19.6282 4.43736 19.4071 4.54786 19.1642 4.57755L19.0325 4.58558H18.2803L16.6454 17.173C16.5726 17.7317 16.2711 18.2472 15.7984 18.6214C15.3257 18.9955 14.7146 19.2022 14.0815 19.2021H5.9188C5.28569 19.2022 4.67464 18.9955 4.20189 18.6214C3.72915 18.2472 3.4277 17.7317 3.35489 17.173L1.71874 4.58558H0.967756C0.733898 4.58557 0.507953 4.51033 0.331707 4.37376C0.155461 4.2372 0.0408371 4.04855 0.00903235 3.84272L0 3.72578C9.86195e-06 3.51801 0.0847014 3.31727 0.238413 3.16069C0.392124 3.00411 0.604456 2.90227 0.836141 2.87401L0.967756 2.86599H6.4659C6.54639 2.08266 6.95356 1.35437 7.60747 0.824097C8.26139 0.293826 9.1148 -0.000110722 10.0001 3.12868e-08ZM8.06464 7.16497C7.7485 7.16497 7.48398 7.34266 7.42979 7.57652L7.41946 7.66709V14.6888L7.42979 14.7782C7.48398 15.012 7.7485 15.1897 8.06464 15.1897C8.38077 15.1897 8.64529 15.012 8.69948 14.7782L8.70981 14.6876V7.66823L8.69948 7.57652C8.64529 7.3438 8.38077 7.16497 8.06464 7.16497ZM11.9357 7.16497C11.6195 7.16497 11.355 7.34266 11.3008 7.57652L11.2905 7.66709V14.6888L11.3008 14.7782C11.355 15.012 11.6195 15.1897 11.9357 15.1897C12.2518 15.1897 12.5163 15.012 12.5705 14.7782L12.5808 14.6876V7.66823L12.5705 7.57652C12.5163 7.3438 12.2518 7.16611 11.9357 7.16611V7.16497ZM10.0001 1.71959C9.21949 1.71959 8.56787 2.21254 8.41948 2.86599H11.5808C11.4311 2.21254 10.7808 1.71959 10.0001 1.71959Z" fill="#BDBDBD"/>
                  </svg>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

  <!-- Store Information -->
  <div
      class="step__content registry"
      v-if="store"
  >
    <div class="step__inner">
      <div class="step__inner-header">
        <p>{{ $t('StoreText.title') }}</p>
      </div>

      <div>

        <div class="grid-container">

          <div class="step__item textarea required">
            <p class="step__item-text">
              {{ $t('FieldsText.title_req_label') }}
            </p>
            <input class="step__item-input" type="text"
                   required oninvalid="this.setCustomValidity($t('FieldsText.title_label_input'))" oninput="this.setCustomValidity('')"
                   v-model="store.name" :disabled="!(editPermission)">
          </div>

          <div class="step__item textarea">
            <p class="step__item-text">
              {{ $t('StoreText.inn_req_label') }}
            </p>
            <input class="step__item-input" type="text" name="inn" maxlength="12"
                   required oninput="this.value = this.value.replace(/[^0-9]/g, '')"
                   v-model="store.inn" :disabled="!(editPermission)">
          </div>

          <div class="step__item select">
          </div>

          <div class="step__item select">
          </div>

        </div>

      </div>
    </div>
  </div>

    <!-- Products List -->
  <div class="step__table three-columns" style="margin-top: 50px;">

    <div v-if="item && item.products && item.products.length > 0">
      <div class="title-header">
        <p>{{ `${$t('ProductText.header')} (${item.products.length})` }}</p>
      </div>

      <div class="table three-columns">
        <div class="table__row title">
          <p class="table__cell">{{ $t('FieldsText.title_label') }}</p>
          <p class="table__cell">{{ $t('ProductText.score') }}</p>
          <p class="table__cell">{{ $t('TableHeaders.action') }}</p>
        </div>
        <div class="table__body">
          <div
              v-for="productItem in item.products" :key="productItem.id"
              class="table__row"
              :class="(product && product.id === productItem.id) ? 'table__row__selected' : 'table__row'"
              style="cursor: pointer;"
              @click="changeProductID(productItem)"
          >
            <div style="text-align: left; margin-left: -150px;">
              <p>
                {{ productItem.name }}
              </p>
            </div>
            <div class="table__cell">
              <p>
                {{ productItem.score }}
              </p>
            </div>
            <div class="table__cell button">
              <div class="table__cell-button" :title="$t('Buttons.delete')" @click="deleteProduct(productItem)">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.0001 3.12868e-08C10.8855 -0.000110722 11.7389 0.293826 12.3928 0.824097C13.0467 1.35437 13.4539 2.08266 13.5344 2.86599H19.0325C19.2777 2.86605 19.5138 2.94881 19.6929 3.09753C19.8721 3.24625 19.981 3.44984 19.9977 3.66718C20.0145 3.88451 19.9377 4.09938 19.783 4.26837C19.6282 4.43736 19.4071 4.54786 19.1642 4.57755L19.0325 4.58558H18.2803L16.6454 17.173C16.5726 17.7317 16.2711 18.2472 15.7984 18.6214C15.3257 18.9955 14.7146 19.2022 14.0815 19.2021H5.9188C5.28569 19.2022 4.67464 18.9955 4.20189 18.6214C3.72915 18.2472 3.4277 17.7317 3.35489 17.173L1.71874 4.58558H0.967756C0.733898 4.58557 0.507953 4.51033 0.331707 4.37376C0.155461 4.2372 0.0408371 4.04855 0.00903235 3.84272L0 3.72578C9.86195e-06 3.51801 0.0847014 3.31727 0.238413 3.16069C0.392124 3.00411 0.604456 2.90227 0.836141 2.87401L0.967756 2.86599H6.4659C6.54639 2.08266 6.95356 1.35437 7.60747 0.824097C8.26139 0.293826 9.1148 -0.000110722 10.0001 3.12868e-08ZM8.06464 7.16497C7.7485 7.16497 7.48398 7.34266 7.42979 7.57652L7.41946 7.66709V14.6888L7.42979 14.7782C7.48398 15.012 7.7485 15.1897 8.06464 15.1897C8.38077 15.1897 8.64529 15.012 8.69948 14.7782L8.70981 14.6876V7.66823L8.69948 7.57652C8.64529 7.3438 8.38077 7.16497 8.06464 7.16497ZM11.9357 7.16497C11.6195 7.16497 11.355 7.34266 11.3008 7.57652L11.2905 7.66709V14.6888L11.3008 14.7782C11.355 15.012 11.6195 15.1897 11.9357 15.1897C12.2518 15.1897 12.5163 15.012 12.5705 14.7782L12.5808 14.6876V7.66823L12.5705 7.57652C12.5163 7.3438 12.2518 7.16611 11.9357 7.16611V7.16497ZM10.0001 1.71959C9.21949 1.71959 8.56787 2.21254 8.41948 2.86599H11.5808C11.4311 2.21254 10.7808 1.71959 10.0001 1.71959Z" fill="#BDBDBD"/>
                </svg>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Store Information -->
  <div
      class="step__content registry"
      v-if="product"
  >
    <div class="step__inner">
      <div class="step__inner-header">
        <p>{{ $t('ProductText.title') }}</p>
      </div>

      <div>

        <div class="grid-container">

          <div class="step__item textarea required large">
            <p class="step__item-text">
              {{ $t('FieldsText.title_req_label') }}
            </p>
            <input class="step__item-input" type="text"
                   required oninvalid="this.setCustomValidity($t('FieldsText.title_label_input'))" oninput="this.setCustomValidity('')"
                   v-model="product.name" :disabled="!(editPermission)">
          </div>

          <div class="step__item textarea"  style="margin-left: 30px">
            <p class="step__item-text">
              {{ $t('ProductText.score_req') }}
            </p>
            <input class="step__item-input" type="number" min="1"
                   v-model="product.score" :disabled="!(editPermission)">
          </div>

          <div class="step__item select">
          </div>

          <div class="step__item select">
          </div>

        </div>

      </div>
    </div>
  </div>

</template>

<script>
import InputsMixin from "@/mixins/inputsEvent";

export default {
  name: 'NewPromotionStepStoresList',
  emits: ["step","updatePromotion"],
  components: {
  },
  mixins: [InputsMixin],
  props: {
    editPermission: Boolean,
    promotion: Object,
  },
  data() {
      return {
        item: {},
        store: null,
        product: null,
      }
  },
  watch: {
    promotion: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler () {
        this.item = this.promotion
      }
    },
  },
  methods: {
      textShow(event) {
          if (event.target.value.length > 33) {
              //console.dir(event.target.parentElement.querySelector(".step__item-popup"))
              event.target.parentElement.querySelector(".step__item-popup").classList.add("not-empty");
              event.target.parentElement.querySelector(".step__item-popup p").innerText = event.target.value;
          }
      },

      changeStoreID(item) {
        if(item) {
          if(!this.store || this.store.id !== item.id)
            this.store = this.item.stores.find(p => p.id === item.id)
          else
            this.store = null
        }
        else
          this.store = null
      },
      deleteStore(store) {
        if(store) {
          if (
              confirm(this.$t('StoreText.delete'))
          ) {
            this.item.stores = this.item.stores.filter(p => p.id !== store.id)

            if(this.store && this.store.id === store.id) {
              process.nextTick(() => {
                this.store = null
              });
            }

            this.$emit("updatePromotion", this.item)

            this.$notify({
              type: "success",
              title: this.$t('Errors.success_operation'),
              text: this.$t('StoreText.delete_success'),
            });
          }
        }
      },

    changeProductID(item) {
      if(item) {
        if(!this.product || this.product.id !== item.id)
          this.product = this.item.products.find(p => p.id === item.id)
        else
          this.product = null
      }
      else
        this.product = null
    },
    deleteProduct(product) {
      if(product) {
        if (
            confirm(this.$t('ProductText.delete'))
        ) {
          this.item.products = this.item.products.filter(p => p.id !== product.id)

          if(this.product && this.product.id === product.id) {
            process.nextTick(() => {
              this.product = null
            });
          }

          this.$emit("updatePromotion", this.item)

          this.$notify({
            type: "success",
            title: this.$t('Errors.success_operation'),
            text: this.$t('ProductText.delete_success'),
          });
        }
      }
    },

  },
  computed: {
  },
}
</script>

<style lang="scss" src="@/assets/scss/common.scss"></style>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixin.scss";

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto;
  padding: 10px;
}

.title-header {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 500;
  margin-top: -20px;
  margin-bottom: 20px;
}

</style>
