<template>
    <div class="table">
        <div class="table__row title">
          <p class="table__cell">{{ $t('TableHeaders.title') }}</p>
          <p class="table__cell">{{ $t('TableHeaders.prize_category') }}</p>
          <p class="table__cell">{{ $t('FieldsText.prize_type_label') }}</p>
          <p class="table__cell">{{ $t('TableHeaders.price') }}</p>
          <p class="table__cell">{{ $t('TableHeaders.action') }}</p>
        </div>
        <div class="table__body">
            <div
                v-for="n in prizes" :key="n.id"
                class="table__row"
            >
                <div class="table__cell">
                    <p>{{ n.info.name }}</p>
                </div>
                <div class="table__cell">
                    <p>{{ n.info.category }}</p>
                </div>
                <div class="table__cell">
                    <p>{{ PrizesTypeText(n.info.type) }}</p>
                </div>
                <div class="table__cell">
                  <p>{{ n.info.price }}</p>
                </div>

                <div class="table__cell button">
                    <div
                        v-if="editPermission"
                        class="table__cell-button"
                        @click="editPrizeInfo(n)">
                      <img
                          :title="$t('TabHeaders.information')"
                          src="@/assets/img/view_info.svg">
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {mapGetters} from "vuex";
import {hasContent} from "@/tools/dataTools";

import {PassportIsFull} from "@/models/users";
import {PrizesType, PrizesTypeText} from "@/models/prizes";
import {PrizeStatus} from "@/models/participants";

export default {
  name: 'ListsPrizesModal',
  components: {
  },
  props: {
    editPermission: Boolean,
    search: String,
  },
  data() {
    return {
      PrizesType,
      PrizeStatus,
    }
  },
  computed: {
    prizes() {
      return this.GET_LIST_ITEMS().prizes ?
          this.GET_LIST_ITEMS().prizes.filter(
              (v) =>
                  `${v.info.name ? v.info.name : ''} ${v.info.category ? v.info.category : ''} ${v.info.price ? v.info.price : ''} ${v.info.comission ? v.info.comission : ''}`
                      .toLowerCase()
                      .indexOf(this.search.toLowerCase()) !== -1
          )
          : [];
    },
  },
  watch: {
  },
  methods: {
    ...mapGetters(["GET_LIST_ITEMS"]),

    PassportIsFull,

    PrizesTypeText,

    editPrizeInfo(prize) {
      if(hasContent(prize))
        this.$emit('edit', prize)
    },

  },
}
</script>

<style lang="scss" src="@/assets/scss/common.scss"></style>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixin.scss";

</style>
