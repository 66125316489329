<template>
    <section class="step first">

        <!-- General Information -->
        <div class="step__content">
            <div class="step__inner">
                <p class="step__header">
                  {{ $t('PromotionText.general_information') }}
                </p>

                <div>
                  <div class="grid-container">
                    <div class="step__item textarea required">
                        <p class="step__item-text">
                          {{ $t('FieldsText.title_req_label') }}
                        </p>
                        <input v-model="item.name" class="step__item-input" type="text"
                               required
                               :placeholder="$t('FieldsText.title_label_input')">
                    </div>
                    <div class="step__item select required">
                        <p class="step__item-text">
                          {{ $t('FieldsText.client_req_label') }}
                        </p>
                        <div class="dd object dd-company-filter">
                          <v-select
                              :placeholder="$t('FieldsText.select_from_list')"
                              :options="companies"
                              :reduce="(option) => option.id"
                              label="name"
                              :clearable="false"
                              v-model="item.company"
                              :rules="[requiredSelectRules]"
                              :class="{ 'error--text--select': (isEmpty(item.company)) }"
                          >
                            <!-- eslint-disable-next-line vue/no-unused-vars  -->
                            <template #no-options="{ search, searching, loading }">
                              {{ $t('Errors.missing_options') }}
                            </template>
                          </v-select>
                          <div
                              class="dd-button"
                              @click="openSelectOptions('.dd-company-filter .vs__dropdown-toggle')"
                          ></div>
                        </div>
                    </div>
                    <div class="step__item select required">
                        <p class="step__item-text">
                          {{ $t('FieldsText.status_req_label') }}
                        </p>
                        <div class="dd object dd-status-filter">
                          <v-select
                              :placeholder="$t('FieldsText.select_from_list')"
                              :options="PromotionsStatuses()"
                              :reduce="(option) => option.status"
                              label="label"
                              :clearable="false"
                              v-model="item.status"
                              :rules="[requiredSelectRules]"
                              :class="{ 'error--text--select': (isEmpty(item.status)) }"
                          >
                            <!-- eslint-disable-next-line vue/no-unused-vars  -->
                            <template #no-options="{ search, searching, loading }">
                              {{ $t('Errors.missing_options') }}
                            </template>
                          </v-select>
                          <div
                              class="dd-button"
                              @click="openSelectOptions('.dd-status-filter .vs__dropdown-toggle')"
                          ></div>
                        </div>
                    </div>
                  </div>

                  <div class="grid-container">
                    <div class="step__item period required">
                      <p class="step__item-text">
                        {{ $t('PromotionText.period_req') }}
                      </p>
                      <DateTimePicker
                          :start="item.startedAt"
                          :end="item.endedAt"
                          :required="true"
                          @updateDates="updateDatesStartEnd"
                      />
                    </div>

                    <div class="step__item select required">
                        <p class="step__item-text">
                          {{ $t('PromotionText.common_promotion') }}
                        </p>
                        <div class="dd object dd-promotion-filter">
                          <v-select
                              :placeholder="$t('FieldsText.select_from_list')"
                              :options="promotions"
                              :reduce="(option) => option.id"
                              label="name"
                              :clearable="true"
                              v-model="item.commonPromotion"
                          >
                            <!-- eslint-disable-next-line vue/no-unused-vars  -->
                            <template #no-options="{ search, searching, loading }">
                              {{ $t('Errors.missing_options') }}
                            </template>
                          </v-select>
                          <div
                              class="dd-button"
                              @click="openSelectOptions('.dd-promotion-filter .vs__dropdown-toggle')"
                          ></div>
                        </div>
                    </div>

                    <div class="step__item select">
                      <p class="step__item-text">
                        {{ $t('PhonesText.operator') }}
                      </p>
                      <div class="dd object dd-operator-filter">
                        <v-select
                            :placeholder="$t('FieldsText.select_from_list')"
                            :options="operators"
                            :reduce="(option) => option.id"
                            label="name"
                            :clearable="true"
                            v-model="item.operator"
                        >
                          <!-- eslint-disable-next-line vue/no-unused-vars  -->
                          <template #no-options="{ search, searching, loading }">
                            {{ $t('Errors.missing_options') }}
                          </template>
                        </v-select>
                        <div
                            class="dd-button"
                            @click="openSelectOptions('.dd-operator-filter .vs__dropdown-toggle')"
                        ></div>
                      </div>
                    </div>

                  </div>


                  <!--div class="title-part">
                    <p class="step__item-text" style="margin-top=10px">
                        {{ $t('PromotionText.other_settings') }}
                    </p>
                  </div>
                  <div class="grid-part-container">

                    <div class="step__item select">
                      <div class="new-promotion__checkbox">
                        <input
                            :disabled="!editPermission"
                            type="checkbox" id="tax"
                            v-model="item.settings.tax" />
                        <label for="tax">{{ $t('PromotionText.calculateTax') }}</label>
                      </div>
                    </div>

                    <div class="step__item select">
                      <div class="new-promotion__checkbox">
                        <input
                            :disabled="!editPermission"
                            type="checkbox" id="scoring"
                            v-model="item.settings.scoring" />
                        <label for="scoring">{{ $t('PromotionText.scoring') }}</label>
                      </div>
                    </div>

                    <div class="step__item select">
                    </div>

                  </div-->

                </div>

            </div>
        </div>

        <!-- Texts Information -->
        <div class="step__content" style="margin-top: 20px">
          <div class="step__inner">

          <div class="page-header">
            <p class="step__header">
              {{ $t('PromotionText.texts_information') }}
            </p>
            <div class="buttons-block">
            </div>
          </div>

          <!-- Promotion Title Text -->
          <div class="grid-container" style="margin-top: -25px;">
            <div class="step__item textarea large required">
              <p class="step__item-text">
                {{ $t('PromotionText.texts_information_title') }}
              </p>
              <input class="step__item-input" type="text"
                     required
                     :placeholder="$t('PromotionText.texts_information_title_input')"
                     v-model="item.texts.title">
            </div>
          </div>

          <!-- Promotion Description Text -->
          <div class="grid-container">
            <div class="step__item textarea large required">
              <p class="step__item-text">
                {{ $t('PromotionText.texts_information_description') }}
              </p>
              <input class="step__item-input" type="text"
                     required
                     :placeholder="$t('PromotionText.texts_information_description_input')"
                     v-model="item.texts.description" :disabled="!editPermission">
            </div>
          </div>

        </div>
        </div>
    </section>

    <div class="buttons-block">
        <p class="buttons-block__validation-rule">
          {{ $t('Rules.required_fields') }}
        </p>
        <div 
            class="buttons-block__forward"
            @click="plusStep"
        >
            <p class="buttons-block__forward-text">
              {{ $t('Buttons.forward') }}
            </p>
        </div>
    </div>

</template>

<script>
import {mapGetters} from "vuex";

import {isEmpty} from "@/tools/dataTools";

import DateTimePicker from '@/components/DateTimePicker.vue'
import InputsMixin from "@/mixins/inputsEvent";

import {
  PromotionsStatuses,
} from "@/models/promotions";

import {
  parseDate,
  getMilliseconds,
} from "@/tools/dateTools";

export default {
  name: 'NewPromotionStepFirst',
  emits: ["step","updatePromotion"],
  components: {
      DateTimePicker,
  },
  mixins: [InputsMixin],
  props: {
    editPermission: Boolean,
    promotion: Object,
    companies: Array,
  },
  data() {
      return {
        item: {},

        requiredSelectRules: [(v) => !!v || 'Выберите значение'],
      }
  },
  watch: {
    promotion: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler () {
        this.item = this.promotion
      }
    },
  },
  computed: {
    promotions() {
      return this.GET_PROMOTIONS() ? this.GET_PROMOTIONS().all.filter((p) => p.id !== this.promotion.id) : []
    },
    operators() {
      return this.GET_LIST_PHONES() ? this.GET_LIST_PHONES().operators : []
    }
  },
  methods: {
    ...mapGetters(["GET_PROMOTIONS","GET_LIST_PHONES",]),

    PromotionsStatuses,

    isEmpty,
    parseDate,

    updateDatesStartEnd(from, to) {
      this.item.startedAt = from
      this.item.endedAt = to
    },

    checkStepCompleted() {
      let completed = true

      if(isEmpty(this.item.name))
        completed = false
      else if(isEmpty(this.item.company))
        completed = false
      else if(isEmpty(this.item.status))
        completed = false
      else if(isEmpty(this.item.startedAt))
        completed = false
      else if(isEmpty(this.item.endedAt))
        completed = false
      else if(isEmpty(this.item.texts.title))
        completed = false
      else if(isEmpty(this.item.texts.description))
        completed = false

      if (completed) {
        // compare dates
        if(getMilliseconds(this.item.endedAt) >= getMilliseconds(this.item.startedAt)) {
          return true
        }
        else {
          this.$notify({
            type: "error",
            title: this.$t('Errors.error_not_ok'),
            text: this.$t('Errors.end_date_less_start_date'),
          });
        }
      }
      else {
        this.$notify({
          type: "error",
          title: this.$t('Errors.error_not_ok'),
          text: this.$t('Errors.missing_required_data'),
        });
      }

      return false
    },

    plusStep() {
      // check if all fields are completed
      if(this.checkStepCompleted()) {
        this.$emit("updatePromotion", this.item)
        this.$emit("step", 2)
      }
    }
  }
}
</script>

<style lang="scss" src="@/assets/scss/common.scss"></style>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixin.scss";

  .grid-container {
    display: grid;
    grid-template-columns: auto auto auto;
    padding: 10px;
  }

  .title-part {
    display: grid;
    padding-top: 10px;
    padding-left: 10px;
    padding-end: 10px;
  }

  .grid-part-container {
    display: grid;
    grid-template-columns: auto auto auto;
    padding-left: 10px;
    padding-end: 10px;
    padding-bottom: 10px;
  }

  .dd {
    position: relative;
    width: 100%;
    &.object {
      width: 265px !important;
      margin-top: 0px !important;
      font-size: 12px;
      font-weight: 500;
      // .dd-button {
      //   height: 27px !important;
      // }
    }
    .dd-button {
      @include ui-dropdown-button();
      border-radius: 0px 5px 5px 0px;
    }
    & ::v-deep .vs__dropdown-toggle {
      border: 1px solid $inputCaption;
      box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
    }
    &:not(:first-child) {
      margin-top: 10px;
    }
  }

</style>