<template>
    <div class="step__content no-codes">
        <div class="step__inner">
            <div class="codes-empty">
                <img class="codes-empty__image" src="@/assets/img/empty-codes.png" alt="empty prisez image">
                <p class="codes-empty__text">
                    Необходимо добавить пригласительные коды или иные интерактивные действия
                </p>
            </div>
        </div>
    </div>
</template>

<script>


export default {
  name: 'NewPromotionStepFifthSubStepSecond',
  components: {
  },
}
</script>

<style lang="scss" src="@/assets/scss/common.scss"></style>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixin.scss";

</style>