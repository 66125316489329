<template>
  <div class="determining-modal"
    @close-modal="onModalClose"
  >

    <div class="determining-modal__inner">

      <div class="determining-modal__header">
        <p>{{ $t('DeterminingText.header') }}</p>
      </div>

      <!-- Content -->
      <div class="grid-container">

        <div class="step__item textarea required">
          <p class="step__item-text">
          {{ $t('FieldsText.title_req_label') }}
          </p>
          <input class="step__item-input" type="text"
                 oninput="this.value = this.value.replace(/[^a-zA-Zа-яА-Я0-9\s]/g, '')"
                 v-model="determiningData.info.name" :disabled="!(editPermission)">
        </div>

        <div class="step__item select required" style="margin-left: 20px;">
          <p class="step__item-text">
            {{ $t('DeterminingText.type') }}
          </p>
          <div class="dd object dd-type-filter">
            <v-select
                :disabled="!(editPermission)"
                :placeholder="$t('FieldsText.select_from_list')"
                :options="DeterminingTypes()"
                :reduce="(option) => option.type"
                label="label"
                :clearable="false"
                v-model="determiningData.info.type"
            >
              <!-- eslint-disable-next-line vue/no-unused-vars  -->
              <template #no-options="{ search, searching, loading }">
                {{ $t('Errors.missing_options') }}
              </template>
            </v-select>
            <div
                class="dd-button"
                @click="openSelectOptions('.dd-type-filter .vs__dropdown-toggle')"
            ></div>
          </div>
        </div>

        <div class="step__item textarea long required" style="margin-left: 20px;">
            <p class="step__item-text">
              {{ (determiningData.info.type === DeterminingType.FORMULA) ? $t('TableHeaders.formula_desc') : $t('TableHeaders.link') }}
            </p>
            <input class="step__item-input" type="text"
                   v-model="determiningData.info.link" :disabled="!(editPermission)">
        </div>

      </div>

      <div
          v-if="determiningData && determiningData.info.type === DeterminingType.FORMULA"
          class="new-account__validation-rule multiline_left" style="padding-top:10px;">
        <span>* </span>{{ $t('DeterminingText.formula_example') }}
        <br>
        <span>* </span>{{ $t('DeterminingText.formulaConstants') }}
      </div>


      <!-- Buttons -->
      <div class="determining-modal__buttons-block">
          <div v-if="editPermission" class="determining-modal__download-button" @click="addDetermining">
              <p>{{ $t('Buttons.save') }}</p>
          </div>
          <div
              class="determining-modal__back-button"
              @click="close"
          >
              <p>{{ $t('Buttons.close') }}</p>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

import {isEmpty} from "@/tools/dataTools";

import DragDrop from "@/mixins/dragDropEvents";
import InputsMixin from "@/mixins/inputsEvent";

import {NewDeterminingItem, DeterminingItemFromBody, DeterminingItemRequiredVerify, DeterminingType, DeterminingTypes } from "@/models/determining";


export default {
  name: 'ModalEditDetermining',
  components: {
  },
  props: {
    editPermission: Boolean,
    determining: Object,
  },
  mixins: [InputsMixin, DragDrop],
  delimiters: ['${', '}'], // Avoid Twig conflicts
  data() {
    return {
      DeterminingType,

      isLoading: false,

      determiningData: NewDeterminingItem(),
    };
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    determining: function (newValue, oldValue) {
      if (!newValue) {
        this.determiningData = NewDeterminingItem()
      } else {
        this.determiningData = DeterminingItemFromBody(this.determining)
      }
    },
    isLoading() {
      if(this.isLoading)
        this.$progress.start();
      else
        this.$progress.finish();
    },
  },
  mounted() {
    if (this.determining) {
      this.determiningData = DeterminingItemFromBody(this.determining)
    }
  },
  computed: {
  },
  methods: {
    ...mapActions(["CREATE_DETERMINING","UPDATE_DETERMINING"]),

    DeterminingTypes,

    textShow(event) {
      if (event.target.value.length > 33) {
        //console.dir(event.target.parentElement.querySelector(".step__item-popup"))
        event.target.parentElement.querySelector(".step__item-popup").classList.add("not-empty");
        event.target.parentElement.querySelector(".step__item-popup p").innerText = event.target.value;
      }
    },

    addDetermining() {
      if(this.determiningData == null) {
        this.$notify({
          type: "error",
          title: this.$t('Errors.error_not_ok'),
          text: this.$t('Errors.error_wrong_data'),
        });
      }
      else if(!DeterminingItemRequiredVerify(this.determiningData)) {
        this.$notify({
          type: "error",
          title: this.$t('Errors.error_not_ok'),
          text: this.$t('Errors.missing_required_data'),
        });
      }
      else if(isEmpty(this.determiningData.id)) {
        this.createDetermining()
      }
      else {
        this.updateDetermining()
      }
    },

    createDetermining() {
      this.isLoading = true

      this.CREATE_DETERMINING({
        ...this.determiningData,
      }).then((res) => {
        if (res.success) {
          this.isLoading = false

          this.determiningData.id = res.id

          this.$notify({
            type: "success",
            title: this.$t('Errors.success_operation'),
            text: this.$t('DeterminingText.determining_created'),
          });
        } else {
          this.$notify({
            type: "error",
            title: this.$t('Errors.error_not_ok'),
            text: this.$t('Errors.error_try_again'),
          });
        }
      });
    },

    updateDetermining() {
      this.isLoading = true

      this.UPDATE_DETERMINING({
        ...this.determiningData,
      }).then((res) => {
        this.isLoading = false

        if (res.success) {
          this.$notify({
            type: "success",
            title: this.$t('Errors.success_operation'),
            text: this.$t('DeterminingText.determining_updated'),
          });
        } else {
          this.$notify({
            type: "error",
            title: this.$t('Errors.error_not_ok'),
            text: this.$t('Errors.error_try_again'),
          });
        }
      });
    },

    close() {
      this.determiningData = NewDeterminingItem();

      this.$emit('close-modal');
    },

  }
}
</script>

<style lang="scss" src="@/assets/scss/common.scss"></style>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixin.scss";

.determining-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 45px 78px 25px 45px;
    background-color: $white;
    border-radius: 10px;
    box-shadow: 0px 0px 8px 2px #CED5F480;
    z-index: 1;

    .determining-modal__header {
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 500;
      margin-top: -20px;
      margin-bottom: 20px;
    }

    &__dragAndDrop {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      //width: 600px;
      height: 270px;
      margin-bottom: 25px;
      padding: 67px 67px 67px;
      border: 1px dotted $purple;
      border-radius: 10px;
      background-color: $SideBlocksColor;
      cursor: pointer;
      svg {
          margin: 0 auto 35px;
      }
    }

    &__dragAndDrop-text {
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      cursor: pointer;
      color: $black;
    }

    &__buttons-block {
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }

    &__download-button {
        width: 190px;
        height: 35px;
        margin-right: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(180deg, #8F21C9 0%, #8F21C9 0.01%, #4640B9 100%);
        color: $white;
        font-size: 16px;
        border-radius: 5px;
        cursor: pointer;
    }

    &__back-button {
        width: 190px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $purple;
        font-size: 16px;
        border: 1px solid $purple;
        border-radius: 5px;
        cursor: pointer;
    }
}

.photo-preview {
  display: flex;
  flex-wrap: wrap;
  &__item {
    width: 70px;
    height: 70px;
    cursor: pointer;
    margin-bottom: 20px;
    &:not(:last-child) {
      margin-right: 30px;
    }
  }
  &__img {
    width: 70px;
    height: 70px;
  }
}

.upload-file {
  margin-bottom: 5px;
  cursor: no-drop;
}

.download-file {
  margin-bottom: 5px;
  cursor: context-menu;
}

.file_input {
  margin-bottom: -47px;
  width: inherit;
  height: inherit;
  opacity: 0;
  overflow: hidden;
  position: absolute;
}

/* Create two equal columns that floats next to each other */
.column {
  float: left;
  width: 50%;
  //padding: 10px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto;
  padding: 10px;
}

.dd {
  position: relative;
  width: 100%;
  &.object {
    width: 265px !important;
    margin-top: 0px !important;
    font-size: 12px;
    font-weight: 500;
    // .dd-button {
    //   height: 27px !important;
    // }
  }
  .dd-button {
    @include ui-dropdown-button();
    border-radius: 0px 5px 5px 0px;
  }
  & ::v-deep .vs__dropdown-toggle {
    border: 1px solid $inputCaption;
    box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
  &:not(:first-child) {
    margin-top: 10px;
  }
}

.multiline_left {
  white-space: pre-wrap;
  text-align: start;
}


</style>
