<template>
    <section class="step fourth">

      <ModalEditDetermining
          v-if="modalDeterminingOpened"
          :editPermission="editPermission"
          :determining="currentDetermining"
          @close-modal="closeModalDetermining"
      />

      <div class="page-header" style="margin-top: -20px;">

        <h2 class="page-header__title"/>

        <div class="buttons-block">
          <div
              class="page-header__outload"
              @click="addNewPrize"
          >
            <p class="page-header__outload-text">
              {{ $t('PrizeText.add') }}
            </p>
          </div>
        </div>

      </div>

        <NewPromotionStepFourthSubStepFirst
            v-if="subStepCounter === 1"
        />
        <NewPromotionStepFourthSubStepSecond
            v-if="subStepCounter === 2"
        />
        <NewPromotionStepFourthSubStepThird
            v-if="subStepCounter === 3"
        />
        <NewPromotionStepFourthSubStepFourth
            v-if="subStepCounter === 4"
        />
        <NewPromotionStepFourthSubStepFifth
            v-if="subStepCounter === 5"
            :editPermission="editPermission"
            :promotion="item"
            @updatePromotion="updatePromotion"
            @openModalDeterminingInformation="openModalDeterminingInformation"
        />
    </section>
    <div class="buttons-block">
        <p class="buttons-block__validation-rule">
          {{ $t('Rules.required_fields') }}
        </p>
        <div 
            class="buttons-block__back"
            @click="minusStep"
        >
            <p class="buttons-block__back-text">
              {{ $t('Buttons.back') }}
            </p>
        </div>
        <div 
            class="buttons-block__forward"
            @click="plusStep"
        >
            <p class="buttons-block__forward-text">
              {{ $t('Buttons.forward') }}
            </p>
        </div>
    </div>
</template>

<script>
import ModalEditDetermining from "@/components/ModalEditDetermining";

import NewPromotionStepFourthSubStepFirst from '../components/NewPromotionStepFourthSubStepFirst.vue'
import NewPromotionStepFourthSubStepSecond from '../components/NewPromotionStepFourthSubStepSecond.vue'
import NewPromotionStepFourthSubStepThird from '../components/NewPromotionStepFourthSubStepThird.vue'
import NewPromotionStepFourthSubStepFourth from '../components/NewPromotionStepFourthSubStepFourth.vue'
import NewPromotionStepFourthSubStepFifth from '../components/NewPromotionStepFourthSubStepFifth.vue'

import {
  hasContent,
  isEmpty,
} from "@/tools/dataTools";
import {getMilliseconds} from "@/tools/dateTools";
import {DrawingType} from "@/models/prizes";

export default {
  name: 'NewPromotionStepFourth',
  emits: [
    "step",
    "updatePromotion",
    "addNewPrize",
    "getDeterminings",
  ],
  components: {
    ModalEditDetermining,

    NewPromotionStepFourthSubStepFirst,
    NewPromotionStepFourthSubStepSecond,
    NewPromotionStepFourthSubStepThird,
    NewPromotionStepFourthSubStepFourth,
    NewPromotionStepFourthSubStepFifth,
  },
  props: {
    editPermission: Boolean,
    promotion: Object,
  },
  data() {
    return {
      subStepCounter: 1,

      item: {},

      currentDetermining: null,
      modalDeterminingOpened: false,
    }
  },
  watch: {
    promotion: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler () {
        this.item = this.promotion

        this.calculateCurrentView()
      }
    },
  },
  methods: {
    addNewPrize() {
      this.$emit("addNewPrize")
    },

    calculateCurrentView() {
      if (hasContent(this.item) && hasContent(this.item.prizes) && this.item.prizes.length > 0)
        this.subStepCounter = 5
      else
        this.subStepCounter = 2
    },

    updatePromotion(item) {
      if (hasContent(item)) {
        this.item = item
        this.$emit("updatePromotion", this.item)

        this.calculateCurrentView()
      }
    },
    minusStep() {
      this.$emit("updatePromotion", this.item)
      this.$emit("step", 4)
    },
    checkStepCompleted() {
      let success = true
      let datesSuccess = true
      let certificatesSuccess = true

      for(let i = 0; i < this.item.prizes.length; i++) {
        let prize = this.item.prizes[i]

        console.log(prize)

        if(isEmpty(prize.full_name))
          success = false
        else if(isEmpty(prize.name))
          success = false
        else if(isEmpty(prize.categoryNumber))
          success = false
        else if(isEmpty(prize.category))
          success = false
        else if(isEmpty(prize.type))
          success = false
        else if(isEmpty(prize.price))
          success = false
        else if(isEmpty(prize.count))
          success = false
        else if(prize.useOnlineCertificates && (
            !prize.certificates || prize.certificates.length < prize.count
        )) {
          certificatesSuccess = false
          success = false
        }
        else if(isEmpty(prize.awardingPrizeTimeInDays))
          success = false
        else if(isEmpty(prize.automaticalDelivery))
          success = false
            // was deleted
            // else if(isEmpty(prize.automaticalApplicatonForPromo))
        //   success = false
        else if(isEmpty(prize.deletePointsAfterDelivery))
          success = false
        else if(isEmpty(prize.actionForPrize))
          success = false
        else if(isEmpty(prize.actionsForAdvancePrize))
          success = false
        else if(isEmpty(prize.paymentDirection))
          success = false
            // else if(isEmpty(prize.goodsGroup))
        //   success = false
        else if(isEmpty(prize.winnerDetermining))
          success = false
        else if(isEmpty(prize.drawingType))
          success = false
        else if(prize.drawingType === DrawingType.EVERYDAY) {
          if (
              isEmpty(prize.drawingEveryday.applicatonForPromoStartedAt) ||
              isEmpty(prize.drawingEveryday.applicatonForPromoEndedAt) ||
              isEmpty(prize.drawingEveryday.drawingPromoStartedAt) ||
              isEmpty(prize.drawingEveryday.drawingPromoEndedAt) ||
              isEmpty(prize.drawingEveryday.numberOfPrizes) ||
              isEmpty(prize.drawingEveryday.moveRemainingPrizesToNextDrawing)
          ) {
            success = false
          }
          // compare dates
          else if(getMilliseconds(prize.drawingEveryday.applicatonForPromoEndedAt) < getMilliseconds(prize.drawingEveryday.applicatonForPromoStartedAt)) {
            if(getMilliseconds(prize.drawingEveryday.drawingPromoEndedAt) < getMilliseconds(prize.drawingEveryday.drawingPromoStartedAt)) {
              datesSuccess = false
            }
          }
        }
        else if(prize.drawingType === DrawingType.SPECIFIC_DATES) {
          if(!prize.drawingSpecificDates || prize.drawingSpecificDates.length === 0)
            success = false
          else {
            for (let i = 0; i < prize.drawingSpecificDates.length; i++) {
              let item = prize.drawingSpecificDates[i]

              // check data competed
              if (
                  isEmpty(item.applicatonForPromoStartedAt) ||
                  isEmpty(item.applicatonForPromoEndedAt) ||
                  isEmpty(item.drawingPromoAt) ||
                  isEmpty(item.numberOfPrizes) ||
                  isEmpty(item.moveRemainingPrizesToNextDrawing)
              ) {
                success = false
              }
              // compare dates
              else if(getMilliseconds(prize.applicatonForPromoEndedAt) < getMilliseconds(prize.applicatonForPromoStartedAt)) {
                datesSuccess = false
              }
            }
          }
        }
      }

      if(success) {
        if(!datesSuccess) {
          this.$notify({
            type: "error",
            title: this.$t('Errors.error_not_ok'),
            text: this.$t('Errors.end_date_less_start_date'),
          });
        }
        else if(!certificatesSuccess) {
          this.$notify({
            type: "error",
            title: this.$t('Errors.error_not_ok'),
            text: this.$t('PrizeText.miss_certificates_or_count_is_not_enough'),
          });
        }
        else {
          return true
        }
      }
      else {
        this.$notify({
          type: "error",
          title: this.$t('Errors.error_not_ok'),
          text: this.$t('Errors.missing_required_data'),
        });
      }

      return false
    },
    plusStep() {
      // check if all fields are completed
      if(this.checkStepCompleted()) {
        this.$emit("updatePromotion", this.item)
        this.$emit("step", 6)
      }
    },

    openModalDeterminingInformation(determining) {
      this.currentDetermining = determining;
      this.modalDeterminingOpened = true;
    },

    closeModalDetermining() {
      this.currentDetermining = null;
      this.modalDeterminingOpened = false;

      this.$emit("getDeterminings")
    },

  }
}
</script>

<style lang="scss" src="@/assets/scss/common.scss"></style>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixin.scss";

</style>