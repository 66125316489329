<template>
    <div class="new-company">
        <div class="new-company__inner">
            <div class="new-company__columns">
                <div class="new-company__column">
                    <div class="new-company__item textarea">
                        <p class="new-company__item-text">
                            Название компании
                        </p>
                        <input class="new-company__item-input" type="text" placeholder="Введите фамилию">
                    </div>
                    <div class="new-company__item textarea">
                        <p class="new-company__item-text">
                            ИНН
                        </p>
                        <input class="new-company__item-input" type="text" placeholder="Введите отчество">
                    </div>
                    <div class="new-company__item textarea">
                        <p class="new-company__item-text">
                            БИК
                        </p>
                        <input class="new-company__item-input" type="text" placeholder="Введите email">
                    </div>
                </div>
                <div class="new-company__column">
                    <div class="new-company__item textarea">
                        <p class="new-company__item-text">
                            Ф.И.О. генерального директора
                        </p>
                        <input class="new-company__item-input" type="text" placeholder="Введите отчество">
                    </div>
                    <div class="new-company__item textarea">
                        <p class="new-company__item-text">
                            Кор. счёт
                        </p>
                        <input class="new-company__item-input" type="text" placeholder="Введите email">
                    </div>
                </div>
            </div>
            <div class="new-company__validation-rule">
                <span>* </span>Все поля обязательны к заполнению
            </div>
            <div class="buttons-block">
                <div class="buttons-block__save">
                    <p class="buttons-block__save-text">
                        Сохранить
                    </p>
                </div>
                <div
                    class="buttons-block__back"
                    @click="$emit('close-modal')"    
                >
                    <p class="buttons-block__back-text">
                      {{ $t('Buttons.back') }}
                    </p>
                </div>
            </div>
        </div>
        <div class="new-company__logo">
            <p class="new-company__logo-text">
                ЛОГОТИП КОМПАНИИ
            </p>
            <div class="new-company__logo-drop">
                <img src="@/assets/img/photo-drop.png" alt="">
            </div>
            <p class="new-company__logo-requirements">
                Требования к изображению: требования к изображению, требования к изображению, требования к изображению, требования к изображению
            </p>
        </div>
    </div>
</template>

<script>

export default {
  name: 'AddNewCompany',
  components: {
  },
}
</script>

<style lang="scss" src="@/assets/scss/common.scss"></style>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixin.scss";

.new-company {
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $white;
    border-radius: 10px;
    padding: 50px 145px 40px 70px;
    box-shadow: 0px 0px 8px 2px $shadow;
    // &__inner {
    //     display: flex;
    //     flex-flow: row nowrap;
    // }

    &__validation-rule {
        margin-bottom: 30px;
        span {
            color: $red;
        }
        &-back {
            @include white-button;
            width: 190px;
        }
        &-forward {

        }
    }
}

.new-company__columns {
    display: flex;
    flex-flow: row nowrap;
    margin-right: 55px;
}

.new-company__column {
    &:first-child {
        margin-right: 30px;
    }
}

.new-company__item {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    width: 265px;
    margin-bottom: 20px;
    &-text {
        width: 100%;
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 5px;
        color: $black;
        span {
            color: $red;
        }
    }

    &-input {
        @include search-input;
    }

    &.textarea {
        grid-column: span 3;
        input {
            border-radius: 5px;
            width: 100%;
        }
    }
}

.buttons-block {
    &__back {
        @include white-button;
        width: 190px;
    }
    &__save {
        @include purple-button;
        width: 190px;
        margin-left: 0;
        margin-right: 30px;
    }
}

.new-company__logo {
    &-text {
        text-align: center;
        margin-bottom: 5px;
        text-transform: uppercase;
    }
    &-drop {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 200px;
        height: 200px;
        margin-bottom: 15px;
        background: $bodyBackground;
        border-radius: 5px;
    }
    &-requirements {
        font-size: 9px;
        font-weight: 500;
    }
}

</style>