<template>
    <div class="table">
        <div class="table__row title">
            <p class="table__cell">{{ $t('FieldsText.promotion_label') }}</p>
            <p class="table__cell">{{ $t('FieldsText.fio_label') }}</p>
            <p class="table__cell">{{ $t('TableHeaders.phone') }}</p>
            <p class="table__cell">{{ $t('FieldsText.prize_type_label') }}</p>
            <p class="table__cell">{{ $t('FieldsText.prize_label') }}</p>
            <p class="table__cell">{{ $t('FieldsText.prize_status_label') }}</p>
            <p class="table__cell">{{ $t('FieldsText.personal_data_label') }}</p>
            <p class="table__cell">{{ $t('TableHeaders.action') }}</p>
        </div>
        <div class="table__body">
            <div
                v-for="n in documents" :key="n.id"
                class="table__row"
            >
                <div class="table__cell">
                    <p>{{ n.fk_promotion.name }}</p>
                </div>
                <div class="table__cell">
                    <p>{{ `${n.fk_user.name} ${n.fk_user.surname} ${n.fk_user.lastname}` }}</p>
                </div>
                <div class="table__cell">
                  <p>{{ n.fk_user.phone ? n.fk_user.phone : '-' }}</p>
                </div>
                <div class="table__cell">
                  <p>{{ PrizesTypeText(n.prize.type) }}</p>
                </div>
                <div class="table__cell">
                  <p>{{ n.prize.full_name ? n.prize.full_name : '-' }}</p>
                </div>
                <div class="table__cell">
                  <p>{{ PrizeStatusText(n.status) }}</p>
                </div>
                <div class="table__cell">
                  <p>{{ PassportIsFull(n.fk_user.passport) ? $t('PersonalDataExists.exists') : $t('PersonalDataExists.miss') }}</p>
                </div>

                <div class="table__cell button">

                  <div
                    class="table__cell-button"
                    :title="$t('ListText.prize_information')"
                    @click="showPrizeInformation(n.prize)"
                  >
                    <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M13.0127 5.68201H16.4809C17.3121 5.68201 18 6.35565 18 7.23431V12.5649H9.74522V6.35565C9.4586 6.26778 9.20064 6.15063 9.02866 5.9749C9 5.9749 9 5.94561 8.97134 5.94561C8.97134 5.94561 8.97134 5.9749 8.94268 5.9749C8.74204 6.15063 8.51274 6.26778 8.25478 6.35565V12.5649H0V7.23431C0 6.35565 0.659236 5.68201 1.49045 5.68201H4.98726C4.58599 5.47699 4.21338 5.1841 3.89809 4.9205C2.52229 3.57322 2.26433 1.69874 3.3535 0.644351C3.8121 0.23431 4.41401 0 5.07325 0C6.01911 0 6.99363 0.380753 7.82484 1.17155C8.28344 1.61088 8.71338 2.22594 8.97134 2.81172C9.25796 2.22594 9.6879 1.61088 10.1465 1.17155C10.949 0.380753 11.9809 0 12.8981 0C13.586 0 14.1879 0.23431 14.6178 0.644351C15.707 1.69874 15.449 3.57322 14.0446 4.9205C13.758 5.1841 13.3854 5.47699 13.0127 5.68201ZM7.91083 4.83264C8.02548 4.56904 7.73885 3.22176 6.79299 2.31381C6.30573 1.84519 5.6465 1.58159 5.07325 1.58159C4.90127 1.58159 4.61465 1.61088 4.41401 1.78661C4.35669 1.84519 4.18471 1.96234 4.18471 2.28452C4.18471 2.66527 4.41401 3.25105 4.9586 3.74895C5.6465 4.42259 6.73567 4.89121 7.53822 4.89121C7.79618 4.89121 7.91083 4.83264 7.91083 4.83264ZM10.0892 4.83264C10.0892 4.83264 10.2325 4.89121 10.4618 4.89121C11.2643 4.89121 12.3535 4.42259 13.0414 3.74895C13.586 3.25105 13.758 2.66527 13.758 2.28452C13.758 1.96234 13.6146 1.84519 13.586 1.78661C13.3854 1.61088 13.0701 1.58159 12.8981 1.58159C12.3535 1.58159 11.6656 1.84519 11.1783 2.31381C10.2611 3.22176 9.94586 4.53975 10.0892 4.86192V4.83264ZM8.25478 21H1.49045C0.659236 21 0 20.3264 0 19.477V14.1172H8.25478V21ZM16.4809 21H9.74522V14.1172H18V19.477C18 20.3264 17.3121 21 16.4809 21Z" fill="#BDBDBD"/>
                    </svg>
                  </div>

                    <div
                      v-if="editPermission"
                      class="table__cell-button"
                      style="padding-left: 5px;"
                      @click="editPrizeUserInfo(n)"
                    >
                      <img
                          :title="$t('ListText.user_information')"
                          src="@/assets/img/icons/info.svg">
                    </div>

                    <div
                      v-if="editPermission && (n.prize.type !== PrizesType.MONEY) && n.status === PrizeStatus.WAIT"
                      class="table__cell-button"
                      style="padding-left: 5px;"
                      :title="$t('ListText.confirm_sent_prize')"
                      @click="sendPrizeConfirm(n)">
                      <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11 0V6C11 6.53043 11.2107 7.03914 11.5858 7.41421C11.9609 7.78929 12.4696 8 13 8H19V18C19 18.5304 18.7893 19.0391 18.4142 19.4142C18.0391 19.7893 17.5304 20 17 20H10.19C11.225 18.9226 11.8573 17.5215 11.9804 16.0326C12.1035 14.5436 11.71 13.0578 10.866 11.825C10.022 10.5922 8.77904 9.68779 7.3464 9.26397C5.91376 8.84016 4.37885 8.9228 3 9.498V2C3 1.46957 3.21071 0.960859 3.58579 0.585786C3.96086 0.210714 4.46957 0 5 0H11ZM12.5 0.5V6C12.5 6.13261 12.5527 6.25979 12.6464 6.35355C12.7402 6.44732 12.8674 6.5 13 6.5H18.5L12.5 0.5ZM11 15.5C11 16.2223 10.8577 16.9375 10.5813 17.6048C10.3049 18.2721 9.89981 18.8784 9.38909 19.3891C8.87837 19.8998 8.27205 20.3049 7.60476 20.5813C6.93747 20.8577 6.22227 21 5.5 21C4.77773 21 4.06253 20.8577 3.39524 20.5813C2.72795 20.3049 2.12163 19.8998 1.61091 19.3891C1.10019 18.8784 0.695063 18.2721 0.418663 17.6048C0.142262 16.9375 -1.07627e-08 16.2223 0 15.5C2.17362e-08 14.0413 0.579463 12.6424 1.61091 11.6109C2.64236 10.5795 4.04131 10 5.5 10C6.95869 10 8.35764 10.5795 9.38909 11.6109C10.4205 12.6424 11 14.0413 11 15.5ZM2.5 15C2.36739 15 2.24021 15.0527 2.14645 15.1464C2.05268 15.2402 2 15.3674 2 15.5C2 15.6326 2.05268 15.7598 2.14645 15.8536C2.24021 15.9473 2.36739 16 2.5 16H7.293L5.646 17.646C5.55211 17.7399 5.49937 17.8672 5.49937 18C5.49937 18.1328 5.55211 18.2601 5.646 18.354C5.73989 18.4479 5.86722 18.5006 6 18.5006C6.13278 18.5006 6.26011 18.4479 6.354 18.354L8.854 15.854C8.90056 15.8076 8.93751 15.7524 8.96271 15.6916C8.98792 15.6309 9.00089 15.5658 9.00089 15.5C9.00089 15.4342 8.98792 15.3691 8.96271 15.3084C8.93751 15.2476 8.90056 15.1924 8.854 15.146L6.354 12.646C6.26011 12.5521 6.13278 12.4994 6 12.4994C5.86722 12.4994 5.73989 12.5521 5.646 12.646C5.55211 12.7399 5.49937 12.8672 5.49937 13C5.49937 13.1328 5.55211 13.2601 5.646 13.354L7.293 15H2.5Z" fill="#BDBDBD"/>
                      </svg>
                    </div>

                    <div
                        v-if="editPermission && (n.prize.type === PrizesType.MONEY) && n.status === PrizeStatus.WAIT"
                        class="table__cell-button"
                        style="padding-left: 5px;"
                        :title="$t('ListText.sent_money')"
                        @click="sendMoneyConfirm(n)">
                        <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11 0V6C11 6.53043 11.2107 7.03914 11.5858 7.41421C11.9609 7.78929 12.4696 8 13 8H19V18C19 18.5304 18.7893 19.0391 18.4142 19.4142C18.0391 19.7893 17.5304 20 17 20H10.19C11.225 18.9226 11.8573 17.5215 11.9804 16.0326C12.1035 14.5436 11.71 13.0578 10.866 11.825C10.022 10.5922 8.77904 9.68779 7.3464 9.26397C5.91376 8.84016 4.37885 8.9228 3 9.498V2C3 1.46957 3.21071 0.960859 3.58579 0.585786C3.96086 0.210714 4.46957 0 5 0H11ZM12.5 0.5V6C12.5 6.13261 12.5527 6.25979 12.6464 6.35355C12.7402 6.44732 12.8674 6.5 13 6.5H18.5L12.5 0.5ZM11 15.5C11 16.2223 10.8577 16.9375 10.5813 17.6048C10.3049 18.2721 9.89981 18.8784 9.38909 19.3891C8.87837 19.8998 8.27205 20.3049 7.60476 20.5813C6.93747 20.8577 6.22227 21 5.5 21C4.77773 21 4.06253 20.8577 3.39524 20.5813C2.72795 20.3049 2.12163 19.8998 1.61091 19.3891C1.10019 18.8784 0.695063 18.2721 0.418663 17.6048C0.142262 16.9375 -1.07627e-08 16.2223 0 15.5C2.17362e-08 14.0413 0.579463 12.6424 1.61091 11.6109C2.64236 10.5795 4.04131 10 5.5 10C6.95869 10 8.35764 10.5795 9.38909 11.6109C10.4205 12.6424 11 14.0413 11 15.5ZM2.5 15C2.36739 15 2.24021 15.0527 2.14645 15.1464C2.05268 15.2402 2 15.3674 2 15.5C2 15.6326 2.05268 15.7598 2.14645 15.8536C2.24021 15.9473 2.36739 16 2.5 16H7.293L5.646 17.646C5.55211 17.7399 5.49937 17.8672 5.49937 18C5.49937 18.1328 5.55211 18.2601 5.646 18.354C5.73989 18.4479 5.86722 18.5006 6 18.5006C6.13278 18.5006 6.26011 18.4479 6.354 18.354L8.854 15.854C8.90056 15.8076 8.93751 15.7524 8.96271 15.6916C8.98792 15.6309 9.00089 15.5658 9.00089 15.5C9.00089 15.4342 8.98792 15.3691 8.96271 15.3084C8.93751 15.2476 8.90056 15.1924 8.854 15.146L6.354 12.646C6.26011 12.5521 6.13278 12.4994 6 12.4994C5.86722 12.4994 5.73989 12.5521 5.646 12.646C5.55211 12.7399 5.49937 12.8672 5.49937 13C5.49937 13.1328 5.55211 13.2601 5.646 13.354L7.293 15H2.5Z" fill="#BDBDBD"/>
                        </svg>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {hasContent} from "@/tools/dataTools";

import {PassportIsFull} from "@/models/users";
import {PrizesType, PrizesTypeText} from "@/models/prizes";
import {PrizeStatus, PrizeStatusText} from "@/models/participants";

export default {
  name: 'ListsPrizesAndDocumentsModal',
  components: {
  },
  props: {
    editPermission: Boolean,
    search: String,
    prizesAndDocuments: Array,
  },
  data() {
    return {
      PrizesType,
      PrizeStatus,
    }
  },
  computed: {
    documents() {
      const items = this.prizesAndDocuments ?
          this.prizesAndDocuments.filter(
              (v) =>
                  `${v.fk_promotion.name} ${v.fk_user.surname ? v.fk_user.surname : ''} ${v.fk_user.name ? v.fk_user.name : ''} ${v.fk_user.phone ? v.fk_user.phone : ''} ${v.prize.name ? v.prize.name : ''}`
                      .toLowerCase()
                      .indexOf(this.search.toLowerCase()) !== -1
          )
          : [];

      return items
    },
  },
  watch: {
  },
  methods: {
    PrizeStatusText,
    PassportIsFull,
    PrizesTypeText,

    editPrizeUserInfo(prize) {
      if(hasContent(prize))
        this.$emit('edit', prize)
    },

    showPrizeInformation(prize) {
      if(hasContent(prize)) {
        this.$emit('showPrizeInformation', prize)
      }
    },

    sendPrizeConfirm(participant) {
      if(hasContent(participant)) {
        if(PassportIsFull(participant.fk_user.passport))
          this.$emit('sendPrizeConfirm', participant)
        else {
          this.$notify({
            type: "error",
            title: this.$t('Errors.not_success_operation'),
            text: this.$t('Errors.missing_personal_data'),
          });
        }
      }
    },

    sendMoneyConfirm(participant) {
      if(hasContent(participant)) {
        if(PassportIsFull(participant.fk_user.passport))
          this.$emit('sendMoneyConfirm', participant)
        else {
          this.$notify({
            type: "error",
            title: this.$t('Errors.not_success_operation'),
            text: this.$t('Errors.missing_personal_data'),
          });
        }
      }
    }

  },
}
</script>

<style lang="scss" src="@/assets/scss/common.scss"></style>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixin.scss";

</style>
