<template>
    <section class="step fourth">

      <div class="page-header" style="margin-top: -20px;">

        <h2 class="page-header__title"/>

        <div class="buttons-block">
          <div
              class="page-header__outload"
              @click="addNewStore"
          >
            <p class="page-header__outload-text">
              {{ $t('StoreText.add') }}
            </p>
          </div>

          <div
              class="page-header__outload"
              style="margin-left: 10px;"
              @click="addNewProduct"
          >
            <p class="page-header__outload-text">
              {{ $t('ProductText.add') }}
            </p>
          </div>
        </div>

      </div>

      <NewPromotionStepStoresEmpty
          v-if="subStepCounter === 1"
      />
      <NewPromotionStepStoresList
          v-if="subStepCounter === 2"
          :editPermission="editPermission"
          :promotion="item"
          @updatePromotion="updatePromotion"
      />
    </section>

    <div class="buttons-block">
        <p class="buttons-block__validation-rule">
          {{ $t('Rules.required_fields') }}
        </p>
        <div 
            class="buttons-block__back"
            @click="minusStep"
        >
            <p class="buttons-block__back-text">
              {{ $t('Buttons.back') }}
            </p>
        </div>
        <div 
            class="buttons-block__forward"
            @click="plusStep"
        >
            <p class="buttons-block__forward-text">
              {{ $t('Buttons.forward') }}
            </p>
        </div>
    </div>
</template>

<script>
import NewPromotionStepStoresEmpty from '../components/NewPromotionStepStoresEmpty.vue'
import NewPromotionStepStoresList from '../components/NewPromotionStepStoresList.vue'

import {
  hasContent,
} from "@/tools/dataTools";

import {StoreRequiredVerify, ProductRequiredVerify} from "@/models/stores";

export default {
  name: 'NewPromotionStepStores',
  emits: [
    "step",
    "updatePromotion",
    "addNewStore",
    "addNewProduct",
  ],
  components: {
    NewPromotionStepStoresEmpty,
    NewPromotionStepStoresList,

  },
  props: {
    editPermission: Boolean,
    promotion: Object,
  },
  data() {
    return {
      subStepCounter: 1,

      item: {}
    }
  },
  watch: {
    promotion: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler () {
        this.item = this.promotion

        this.calculateCurrentView()
      }
    },
  },
  methods: {
    StoreRequiredVerify,
    ProductRequiredVerify,

    addNewStore() {
      this.$emit("addNewStore")
    },

    addNewProduct() {
      this.$emit("addNewProduct")
    },

    calculateCurrentView() {
      if (
          hasContent(this.item) && (
              (hasContent(this.item.stores) && this.item.stores.length > 0) ||
              (hasContent(this.item.products) && this.item.products.length > 0)
          )
      )
        this.subStepCounter = 2
      else
        this.subStepCounter = 1
    },

    updatePromotion(item) {
      if (hasContent(item)) {
        this.item = item
        this.$emit("updatePromotion", this.item)

        this.calculateCurrentView()
      }
    },
    minusStep() {
      this.$emit("updatePromotion", this.item)
      this.$emit("step", 3)
    },
    checkStepCompleted() {
      let completed = true

      const hasStoresAndProducts = (
          //this.item.stores && this.item.stores.length > 0 &&
          this.item && this.item.products && this.item.products.length > 0
      )

      // check stores
      for(let item of this.item.stores) {
        if(!StoreRequiredVerify(item))
          completed = false
      }

      // check products
      for(let item of this.item.products) {
        if(!ProductRequiredVerify(item))
          completed = false
      }

      if(completed) {
        if(hasStoresAndProducts) {
          return true
        }
        else {
          this.$notify({
            type: "error",
            title: this.$t('Errors.error_not_ok'),
            text: this.$t('Errors.miss_configured_stores_and_products'),
          });
        }
      }
      else {
        this.$notify({
          type: "error",
          title: this.$t('Errors.error_not_ok'),
          text: this.$t('Errors.missing_required_data'),
        });
      }

      return false
    },
    plusStep() {
      // check if all fields are completed
      if(this.checkStepCompleted()) {
        this.$emit("updatePromotion", this.item)
        this.$emit("step", 5)
      }
    }
  }
}
</script>

<style lang="scss" src="@/assets/scss/common.scss"></style>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixin.scss";

</style>