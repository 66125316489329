<template>
    <div class="step__content">
        <div class="step__inner">
            <div class="step__inner-header">
                <p>
                    Интерактивный конкурс
                </p>
            </div>
            <div class="step__items">
                <div class="step__item textarea required">
                    <p class="step__item-text">
                        Количество регистраций новых (приглашённых) участников<span>*</span>
                    </p>
                    <input class="step__item-input" type="text" placeholder="Введите название сайта">
                </div>
                <div class="step__item textarea required">
                    <p class="step__item-text">
                        Необходимость регистрации покупки новым (приглашённым) участником<span>*</span>
                    </p>
                    <input @input="textShow" class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-popup">
                        <p></p>
                    </div>
                </div>
                <div class="step__item textarea required">
                    <p class="step__item-text">
                        Сколько покупок должен зарегистрировать каждый новый (приглашённый) участник<span>*</span>
                    </p>
                    <input @input="textShow" class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-popup">
                        <p></p>
                    </div>
                </div>
                <div class="step__item textarea required">
                    <p class="step__item-text">
                        Количество кодов  для приглашения выдаваемых за одно целевое действие<span>*</span>
                    </p>
                    <input @input="textShow" class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-popup">
                        <p></p>
                    </div>
                </div>
                <div class="step__item textarea">
                    <p class="step__item-text">
                        Сколько раз один участник может получить код за период акции
                    </p>
                    <input @input="textShow" class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-popup">
                        <p></p>
                    </div>
                </div>
                <div class="step__item  textarea">
                    <p class="step__item-text">
                        Сколько раз один участник может получить код за день
                    </p>
                    <input @input="textShow" class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-popup">
                        <p></p>
                    </div>
                </div>
                <div class="step__item textarea">
                    <p class="step__item-text">
                        Сколько раз один участник может получить код за неделю
                    </p>
                    <input @input="textShow" class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-popup">
                        <p></p>
                    </div>
                </div>
                <div class="step__item textarea">
                    <p class="step__item-text">
                        Сколько раз один участник может получить код за месяц
                    </p>
                    <input @input="textShow" class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-popup">
                        <p></p>
                    </div>
                </div>
                <div class="step__item select">
                    <p class="step__item-text">
                        Действие для получения кода
                    </p>
                    <input class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-button"></div>
                </div>
                <div class="step__item textarea">
                    <p class="step__item-text">
                        Сколько баллов получает за целевые действия приглашённого
                    </p>
                    <input @input="textShow" class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-popup">
                        <p></p>
                    </div>
                </div>
                <div class="step__item select required">
                    <p class="step__item-text">
                        Необходимость состоять в сообществе<span>*</span>
                    </p>
                    <input class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-button"></div>
                </div>
                <div class="step__item select">
                    <p class="step__item-text">
                        Социальные сети
                    </p>
                    <input class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-button"></div>
                </div>
                <div class="step__item textarea">
                    <p class="step__item-text">
                        Адрес сообщества (по количеству выбранных на предыдущем шаге)
                    </p>
                    <input @input="textShow" class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-popup">
                        <p></p>
                    </div>
                </div>
                <div class="step__item select required">
                    <p class="step__item-text">
                        Необходимость сделать репост<span>*</span>
                    </p>
                    <input class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-button"></div>
                </div>
                <div class="step__item textarea">
                    <p class="step__item-text">
                        Ссылка на запись для репоста (по количеству выбранных на предыдущем шаге)
                    </p>
                    <input @input="textShow" class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-popup">
                        <p></p>
                    </div>
                </div>
                <div class="step__item textarea">
                    <p class="step__item-text">
                        Что требуется сделать
                    </p>
                    <input @input="textShow" class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-popup">
                        <p></p>
                    </div>
                </div>
                <div class="step__item textarea">
                    <p class="step__item-text">
                        Сколько баллов получает участник за совершение интерактивного действия
                    </p>
                    <input @input="textShow" class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-popup">
                        <p></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>


export default {
    name: 'NewPromotionStepFifthSubStepThird',
    components: {
    },
    methods: {
        textShow(event) {
            if (event.target.value.length > 33) {
                event.target.parentElement.querySelector(".step__item-popup").classList.add("not-empty");
                event.target.parentElement.querySelector(".step__item-popup p").innerText = event.target.value;
            }
        }
    },
}
</script>

<style lang="scss" src="@/assets/scss/common.scss"></style>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixin.scss";

</style>