import {hasContent} from "@/tools/dataTools";

export const Companies = {
    // UUID
    id: '',
    // string
    name: '',
    // string
    inn: '',
    // string
    bik: '',
    // string
    director: '',
    // string
    cor_count: '',
    // string
    address: '',
    // string
    avatar: '',
    // json
    messages: [],
    // json
    history: [],

    // data from other tables
    users: [],
};

export const CompaniesRequiredVerify = (company) => {
    if(hasContent(company)) {
        return !!(hasContent(company.name));
    }
    else {
        return false;
    }
};

export const NewCompanies = () => {
    return JSON.parse(JSON.stringify(Companies));
};

export const CompaniesFromBody = (data) => {
    let object = NewCompanies()

    object.id = data.id;
    object.name = data.name;
    object.inn = data.inn;
    object.bik = data.bik;
    object.director = data.director;
    object.cor_count = data.cor_count;
    object.address = data.address;
    object.avatar = data.avatar;
    object.messages = data.messages;
    object.history = data.history;

    // check if user is attached to any company
    if(data.users != null)
        object.users = JSON.parse(JSON.stringify(data.users));


    return object;
};


// export module
export default {
    Companies,

    CompaniesRequiredVerify,
    NewCompanies,
    CompaniesFromBody,
};
