<template>
    <div class="step__table four-columns">
        <div class="table">
            <div class="table__row title">
                <p class="table__cell">Название приза</p>
                <p class="table__cell">дата розыгрыша</p>
                <p class="table__cell">категория приза</p>
                <p class="table__cell">Действия</p>
            </div>
            <div class="table__body">
                <div
                    v-for="n in 2" :key="n"
                    class="table__row"
                >
                    <div class="table__cell">
                        <p>
                            Приз за первое место в акции “Счастливчик” телефон Iphone 12
                        </p>
                    </div>
                    <div class="table__cell">
                        <p>
                            25.06.2021
                        </p>
                    </div>
                    <div class="table__cell">
                        <p>
                            Денежный
                        </p>
                    </div>
                    <div class="table__cell button">
                        <div class="table__cell-button">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.0001 3.12868e-08C10.8855 -0.000110722 11.7389 0.293826 12.3928 0.824097C13.0467 1.35437 13.4539 2.08266 13.5344 2.86599H19.0325C19.2777 2.86605 19.5138 2.94881 19.6929 3.09753C19.8721 3.24625 19.981 3.44984 19.9977 3.66718C20.0145 3.88451 19.9377 4.09938 19.783 4.26837C19.6282 4.43736 19.4071 4.54786 19.1642 4.57755L19.0325 4.58558H18.2803L16.6454 17.173C16.5726 17.7317 16.2711 18.2472 15.7984 18.6214C15.3257 18.9955 14.7146 19.2022 14.0815 19.2021H5.9188C5.28569 19.2022 4.67464 18.9955 4.20189 18.6214C3.72915 18.2472 3.4277 17.7317 3.35489 17.173L1.71874 4.58558H0.967756C0.733898 4.58557 0.507953 4.51033 0.331707 4.37376C0.155461 4.2372 0.0408371 4.04855 0.00903235 3.84272L0 3.72578C9.86195e-06 3.51801 0.0847014 3.31727 0.238413 3.16069C0.392124 3.00411 0.604456 2.90227 0.836141 2.87401L0.967756 2.86599H6.4659C6.54639 2.08266 6.95356 1.35437 7.60747 0.824097C8.26139 0.293826 9.1148 -0.000110722 10.0001 3.12868e-08ZM8.06464 7.16497C7.7485 7.16497 7.48398 7.34266 7.42979 7.57652L7.41946 7.66709V14.6888L7.42979 14.7782C7.48398 15.012 7.7485 15.1897 8.06464 15.1897C8.38077 15.1897 8.64529 15.012 8.69948 14.7782L8.70981 14.6876V7.66823L8.69948 7.57652C8.64529 7.3438 8.38077 7.16497 8.06464 7.16497ZM11.9357 7.16497C11.6195 7.16497 11.355 7.34266 11.3008 7.57652L11.2905 7.66709V14.6888L11.3008 14.7782C11.355 15.012 11.6195 15.1897 11.9357 15.1897C12.2518 15.1897 12.5163 15.012 12.5705 14.7782L12.5808 14.6876V7.66823L12.5705 7.57652C12.5163 7.3438 12.2518 7.16611 11.9357 7.16611V7.16497ZM10.0001 1.71959C9.21949 1.71959 8.56787 2.21254 8.41948 2.86599H11.5808C11.4311 2.21254 10.7808 1.71959 10.0001 1.71959Z" fill="#BDBDBD"/>
                            </svg>
                        </div>
                        <div class="table__cell-button">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18 0H8C7.46957 0 6.96086 0.210714 6.58579 0.585786C6.21071 0.960859 6 1.46957 6 2V4H14C14.5304 4 15.0391 4.21071 15.4142 4.58579C15.7893 4.96086 16 5.46957 16 6V14H18C18.5304 14 19.0391 13.7893 19.4142 13.4142C19.7893 13.0391 20 12.5304 20 12V2C20 1.46957 19.7893 0.960859 19.4142 0.585786C19.0391 0.210714 18.5304 0 18 0Z" fill="#BDBDBD"/>
                                <path d="M2 20H12C13.103 20 14 19.103 14 18V8C14 6.897 13.103 6 12 6H2C0.897 6 0 6.897 0 8V18C0 19.103 0.897 20 2 20ZM4 10H10V12H4V10ZM4 14H10V16H4V14Z" fill="#BDBDBD"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="step__content">
        <div class="step__inner">
            <div class="step__inner-header">
                <p>
                    Условия для получения приза
                </p>
            </div>
            <div class="step__items">
                <div class="step__item textarea required">
                    <p class="step__item-text">
                        Категория приза<span>*</span>
                    </p>
                    <input @input="textShow" class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-popup">
                        <p></p>
                    </div>
                </div>
                <div class="step__item textarea required">
                    <p class="step__item-text">
                        Наименование приза<span>*</span>
                    </p>
                    <input @input="textShow" class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-popup">
                        <p></p>
                    </div>
                </div>
                <div class="step__item select required">
                    <p class="step__item-text">
                        Тип приза<span>*</span>
                    </p>
                    <input class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-button"></div>
                </div>
                <div class="step__item textarea required">
                    <p class="step__item-text">
                        Стоимость приза<span>*</span>
                    </p>
                    <input @input="textShow" class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-popup">
                        <p></p>
                    </div>
                </div>
                <div class="step__item textarea">
                    <p class="step__item-text">
                        Количество баллов для получения приза / участия в розыгрыше
                    </p>
                    <input @input="textShow" class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-popup">
                        <p></p>
                    </div>
                </div>
                <div class="step__item  textarea required">
                    <p class="step__item-text">
                        Количество призов<span>*</span>
                    </p>
                    <input @input="textShow" class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-popup">
                        <p></p>
                    </div>
                </div>
                <div class="step__item textarea">
                    <p class="step__item-text">
                        Сколько раз один участник может получить приз за период акции
                    </p>
                    <input @input="textShow" class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-popup">
                        <p></p>
                    </div>
                </div>
                <div class="step__item textarea">
                    <p class="step__item-text">
                        Сколько раз один участник может получить приз за день
                    </p>
                    <input @input="textShow" class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-popup">
                        <p></p>
                    </div>
                </div>
                <div class="step__item textarea">
                    <p class="step__item-text">
                        Сколько раз один участник может получить приз за неделю
                    </p>
                    <input @input="textShow" class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-popup">
                        <p></p>
                    </div>
                </div>
                <div class="step__item textarea">
                    <p class="step__item-text">
                        Сколько раз один участник может получить приз за месяц
                    </p>
                    <input @input="textShow" class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-popup">
                        <p></p>
                    </div>
                </div>
                <div class="step__item textarea required">
                    <p class="step__item-text">
                        Срок вручения приза в рабочих днях<span>*</span>
                    </p>
                    <input @input="textShow" class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-popup">
                        <p></p>
                    </div>
                </div>
                <div class="step__item textarea">
                    <p class="step__item-text">
                        Сколько один участник может подать заявок за период акции
                    </p>
                    <input @input="textShow" class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-popup">
                        <p></p>
                    </div>
                </div>
                <div class="step__item textarea">
                    <p class="step__item-text">
                        Сколько один участник может подать заявок за день
                    </p>
                    <input @input="textShow" class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-popup">
                        <p></p>
                    </div>
                </div>
                <div class="step__item textarea">
                    <p class="step__item-text">
                        Сколько один участник может подать заявок за неделю
                    </p>
                    <input @input="textShow" class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-popup">
                        <p></p>
                    </div>
                </div>
                <div class="step__item textarea">
                    <p class="step__item-text">
                        Сколько один участник может подать заявок за месяц
                    </p>
                    <input @input="textShow" class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-popup">
                        <p></p>
                    </div>
                </div>
                <div class="step__item select required">
                    <p class="step__item-text">
                        Автоматическое вручение<span>*</span>
                    </p>
                    <input class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-button"></div>
                </div>
                <div class="step__item select required">
                    <p class="step__item-text">
                        Автоматическая заявка на участие в розыгрыше<span>*</span>
                    </p>
                    <input class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-button"></div>
                </div>
                <div class="step__item period required">
                    <p class="step__item-text">
                        Даты и время сбора заявок для участия в розыгрыше<span>*</span>
                    </p>
                    <DateTimePicker/>
                    <!-- <input class="step__item-period" type="text" placeholder="Дата начала">
                    <input class="step__item-period" type="text" placeholder="Дата завершения"> -->
                </div>
                <div class="step__item period required">
                    <p class="step__item-text">
                        Даты розыгрыша<span>*</span>
                    </p>
                    <DateTimePicker/>
                    <!-- <input class="step__item-period" type="text" placeholder="Дата начала">
                    <input class="step__item-period" type="text" placeholder="Дата завершения"> -->
                </div>
                <div class="step__item select required">
                    <p class="step__item-text">
                        Сгорание баллов при регистрации заявки/ получении приза<span>*</span>
                    </p>
                    <input class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-button"></div>
                </div>
                <div class="step__item select required">
                    <p class="step__item-text">
                        Действие для получения приза / участия в розыгрыше<span>*</span>
                    </p>
                    <input class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-button"></div>
                </div>
                <div class="step__item select required">
                    <p class="step__item-text">
                        Способ определения победителя<span>*</span>
                    </p>
                    <input class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-button"></div>
                </div>
                <div class="step__item select">
                    <p class="step__item-text">
                        Дополнительный приз
                    </p>
                    <input class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-button"></div>
                </div>
                <div class="step__item select required">
                    <p class="step__item-text">
                        Действие для получения дополнительного  приза<span>*</span>
                    </p>
                    <input class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-button"></div>
                </div>
                <div class="step__item select required">
                    <p class="step__item-text">
                        Направление выплат<span>*</span>
                    </p>
                    <input class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-button"></div>
                </div>
                <div class="step__item select">
                    <p class="step__item-text">
                        Способ вручения приза
                    </p>
                    <input class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-button"></div>
                </div>
                <div class="step__item select">
                    <p class="step__item-text">
                        Комиссия
                    </p>
                    <input class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-button"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="step__content registry">
        <div class="step__inner">
            <div class="step__inner-header">
                <p>
                    Регистрация нескольких чеков
                </p>
            </div>
            <div class="step__items">
                <div class="step__item textarea required">
                    <p class="step__item-text">
                        Количество кодов с  товаров из группы 1<span>*</span>
                    </p>
                    <input @input="textShow" class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-popup">
                        <p></p>
                    </div>
                </div>
                <div class="step__item select required">
                    <p class="step__item-text">
                        Группа товаров 1<span>*</span>
                    </p>
                    <input class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-button"></div>
                </div>
                <div class="step__item textarea">
                    <p class="step__item-text">
                        Количество баллов за регистрацию товара из группы 1
                    </p>
                    <input @input="textShow" class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-popup">
                        <p></p>
                    </div>
                </div>
                <div class="step__item button">
                    <p class="step__item-text">
                        + Добавить
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DateTimePicker from '@/components/DateTimePicker'

export default {
    name: 'NewPromotionStepFourthSubStepFirst',
    components: {
    DateTimePicker
    },
    methods: {
        textShow(event) {
            if (event.target.value.length > 33) {
                console.dir(event.target.parentElement.querySelector(".step__item-popup"))
                event.target.parentElement.querySelector(".step__item-popup").classList.add("not-empty");
                event.target.parentElement.querySelector(".step__item-popup p").innerText = event.target.value;
            }
        }
    }
}
</script>

<style lang="scss" src="@/assets/scss/common.scss"></style>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixin.scss";

</style>