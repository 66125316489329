import {hasContent} from "@/tools/dataTools";

export const SMS = {
    // UUID
    operator: null,
    // string
    target: null,
    // string
    message: '',
};

export const NewSMS = () => {
    let item = JSON.parse(JSON.stringify(SMS))

    //item.id = generateUid()

    return item
};

export const SMSFromBody = (data) => {
    let object = NewSMS()

    object.operator = data.operator;
    object.target = data.target;
    object.message = data.message;

    return object;
};

export const SMSRequiredVerify = (item) => {
    return !!(hasContent(item.operator) &&
        hasContent(item.target) &&
        hasContent(item.message))
};


// export module
export default {
    SMS,
    NewSMS,
    SMSFromBody,

    SMSRequiredVerify,
};
