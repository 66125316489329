<template>
  <div class="promotionprize-modal"
    @close-modal="onModalClose"
  >

    <div class="promotionprize-modal__inner">

      <!-- Content -->
      <div>

        <div class="grid-container-1">

          <div class="step__item textarea large py-2" style="padding-right: 17px;">
            <p class="step__item-text">
              {{ $t('PrizeText.full_name') }}
            </p>
            <input class="step__item-input" type="text"
                   v-model="prizeData.full_name" :disabled="!(editPermission)">
          </div>

        </div>


        <div class="grid-container">

          <div class="step__item textarea required">
              <p class="step__item-text">
              {{ $t('PrizeText.name_req') }}
              </p>
              <input class="step__item-input" type="text"
                   v-model="prizeData.name" :disabled="!(editPermission)">
          </div>

          <div class="step__item textarea required">
              <p class="step__item-text">
                {{ $t('PrizeText.category') }}
              </p>
              <input class="step__item-input" type="text"
                      v-model="prizeData.category" :disabled="!(editPermission)">
          </div>

          <div class="step__item textarea required">
            <p class="step__item-text">
              {{ $t('PrizeText.type') }}
            </p>
            <input class="step__item-input" type="text"
                   :value="PrizesTypeText(prizeData.type)" :disabled="!(editPermission)">
          </div>


          <div class="step__item textarea"/>

        </div>


        <div class="grid-container">

          <div class="step__item textarea">
              <p class="step__item-text">
                {{ $t('PrizeText.requiredScore') }}
              </p>
              <input class="step__item-input" type="number" min="0"
                      v-model="prizeData.pointsQuantity" :disabled="!(editPermission)">
          </div>

          <div class="step__item  textarea required">
              <p class="step__item-text">
                {{ $t('PrizeText.count') }}
              </p>
              <input class="step__item-input" type="number" min="0"
                      v-model="prizeData.count" :disabled="!(editPermission)">
          </div>

          <div class="step__item textarea required">
            <p class="step__item-text">
              {{ $t('PrizeText.price') }}
            </p>
            <input class="step__item-input" type="text" name="price"
                   oninput="this.value = this.value.replace(/[^0-9]/g, '')"
                   v-model="prizeData.price" :disabled="!(editPermission)">
          </div>


          <div class="step__item textarea"/>

        </div>


        <div class="grid-container">

          <div class="step__item textarea">
            <p class="step__item-text">
              {{ $t('PrizeText.prizesQuantityPerPersonPerPromo') }}
            </p>
            <input class="step__item-input" type="number" min="0"
                   v-model="prizeData.prizesQuantityPerPersonPerPromo" :disabled="!(editPermission)">
          </div>

          <div class="step__item textarea">
            <p class="step__item-text">
              {{ $t('PrizeText.prizesQuantityPerPersonPerDay') }}
            </p>
            <input class="step__item-input" type="number" min="0"
                   v-model="prizeData.prizesQuantityPerPersonPerDay" :disabled="!(editPermission)">
          </div>

          <div class="step__item textarea">
              <p class="step__item-text">
                {{ $t('PrizeText.prizesQuantityPerPersonPerWeek') }}
              </p>
              <input class="step__item-input" type="number" min="0"
                      v-model="prizeData.prizesQuantityPerPersonPerWeek" :disabled="!(editPermission)">
          </div>

          <div class="step__item textarea">
              <p class="step__item-text">
                {{ $t('PrizeText.prizesQuantityPerPersonPerMonth') }}
              </p>
              <input class="step__item-input" type="number" min="0"
                      v-model="prizeData.prizesQuantityPerPersonPerMonth" :disabled="!(editPermission)">
          </div>

        </div>


        <div class="grid-container">

          <div class="step__item textarea">
            <p class="step__item-text">
              {{ $t('PrizeText.applicationsQuantityPerPromo') }}
            </p>
            <input class="step__item-input" type="number" min="0"
                   v-model="prizeData.applicationsQuantityPerPromo" :disabled="!(editPermission)">
          </div>

          <div class="step__item textarea">
              <p class="step__item-text">
                {{ $t('PrizeText.applicationsQuantityPerDay') }}
              </p>
              <input class="step__item-input" type="number" min="0"
                      v-model="prizeData.applicationsQuantityPerDay" :disabled="!(editPermission)">
          </div>

          <div class="step__item textarea">
              <p class="step__item-text">
                {{ $t('PrizeText.applicationsQuantityPerWeek') }}
              </p>
              <input class="step__item-input" type="number" min="0"
                      v-model="prizeData.applicationsQuantityPerWeek" :disabled="!(editPermission)">
          </div>

          <div class="step__item textarea">
              <p class="step__item-text">
                {{ $t('PrizeText.applicationsQuantityPerMonth') }}
              </p>
              <input class="step__item-input" type="number" min="0"
                      v-model="prizeData.applicationsQuantityPerMonth" :disabled="!(editPermission)">
          </div>

          <div class="step__item select">
          </div>

        </div>

        <div class="grid-container"/>

        <div class="grid-container">

          <div class="step__item textarea required">
            <p class="step__item-text">
              {{ $t('PrizeText.awardingPrizeTimeInDays') }}
            </p>
            <input class="step__item-input" type="number" min="0"
                   v-model="prizeData.awardingPrizeTimeInDays" :disabled="!(editPermission)">
          </div>

          <div class="step__item select required">
            <p class="step__item-text">
              {{ $t('PrizeText.automaticalDelivery') }}
            </p>
            <div class="dd object dd-automaticalDelivery-filter">
              <v-select
                  :disabled="!(editPermission)"
                  :placeholder="$t('FieldsText.select_from_list')"
                  :options="Actions()"
                  :reduce="(option) => option.action"
                  label="label"
                  :clearable="true"
                  v-model="prizeData.automaticalDelivery"
              >
                <!-- eslint-disable-next-line vue/no-unused-vars  -->
                <template #no-options="{ search, searching, loading }">
                  {{ $t('Errors.missing_options') }}
                </template>
              </v-select>
              <div
                  class="dd-button"
                  @click="openSelectOptions('.dd-automaticalDelivery-filter .vs__dropdown-toggle')"
              ></div>
            </div>
          </div>

          <!--div class="step__item select required">
              <p class="step__item-text">
                {{ $t('PrizeText.winnerDetermining') }}
              </p>
              <div class="dd object dd-determining-filter">
                <v-select
                    :disabled="!(editPermission)"
                    :placeholder="$t('FieldsText.select_from_list')"
                    :options="allDetermining.map(v=>v.info.link)"
                    v-model="prizeData.winnerDetermining"
                    :clearable="true"
                    taggable
                >
                  <template #no-options="{ search, searching, loading }">
                    {{ $t('Errors.missing_options') }}
                  </template>
                </v-select>
                <div
                    class="dd-button"
                    @click="openSelectOptions('.dd-determining-filter .vs__dropdown-toggle')"
                ></div>
              </div>
          </div-->

          <div class="step__item select required">
            <p class="step__item-text">
              {{ $t('PrizeText.paymentDirection') }}
            </p>
            <div class="dd object dd-paymentDirection-filter">
              <v-select
                  :disabled="!(editPermission)"
                  :placeholder="$t('FieldsText.select_from_list')"
                  :options="PaymentDirections()"
                  :reduce="(option) => option.direction"
                  label="label"
                  :clearable="true"
                  v-model="prizeData.paymentDirection"
              >
                <!-- eslint-disable-next-line vue/no-unused-vars  -->
                <template #no-options="{ search, searching, loading }">
                  {{ $t('Errors.missing_options') }}
                </template>
              </v-select>
              <div
                  class="dd-button"
                  @click="openSelectOptions('.dd-paymentDirection-filter .vs__dropdown-toggle')"
              ></div>
            </div>
          </div>

          <div class="step__item textarea">
            <p class="step__item-text">
              {{ $t('PrizeText.comission') }}
            </p>
            <input class="step__item-input" type="number" min="0" max="100"
                   v-model="prizeData.comission" :disabled="!(editPermission)">
          </div>

          <div class="step__item select">
          </div>

        </div>

    </div>

      <!-- Buttons -->
      <div class="promotionprize-modal__buttons-block">
          <div
              class="promotionprize-modal__back-button"
              @click="close"
          >
              <p>{{ $t('Buttons.close') }}</p>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

import DragDrop from "@/mixins/dragDropEvents";
import InputsMixin from "@/mixins/inputsEvent";

import {
  NewPrize,
  PrizesFromBody,
  PrizesTypes,
  Actions,
  ActionsForPrize,
  PaymentDirections,
  PrizesTypeText
} from "@/models/prizes";


export default {
  name: 'ModalPromotionPrize',
  components: {
  },
  props: {
    editPermission: Boolean,
    prize: Object,
  },
  mixins: [InputsMixin, DragDrop],
  delimiters: ['${', '}'], // Avoid Twig conflicts
  data() {
    return {
      prizeData: NewPrize(),
    };
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    prize: function (newValue, oldValue) {
      if (!newValue) {
        this.prizeData = NewPrize()
      } else {
        this.prizeData = PrizesFromBody(this.prize)
      }
    },
  },
  mounted() {
    if (this.prize) {
      this.prizeData = PrizesFromBody(this.prize)
    }
  },
  computed: {
    allDetermining() {
      return this.GET_LIST_ITEMS().determining ? this.GET_LIST_ITEMS().determining : [];
    },
    determiningExamples() {
      let items = this.allDetermining;

      let response = []
      items.map(v => response.push(v.link));

      return response
    }
  },
  methods: {
    PrizesTypeText,
    ...mapGetters(["GET_LIST_ITEMS"]),

    PrizesTypes,
    Actions,
    ActionsForPrize,
    PaymentDirections,

    textShow(event) {
      if (event.target.value.length > 33) {
        console.dir(event.target.parentElement.querySelector(".step__item-popup"))
        event.target.parentElement.querySelector(".step__item-popup").classList.add("not-empty");
        event.target.parentElement.querySelector(".step__item-popup p").innerText = event.target.value;
      }
    },

    close() {
      this.prizeData = NewPrize();

      this.$emit('close-modal');
    },

  }
}
</script>

<style lang="scss" src="@/assets/scss/common.scss"></style>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixin.scss";

.promotionprize-modal {
    width: 1300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 45px 78px 25px 45px;
    background-color: $white;
    border-radius: 10px;
    box-shadow: 0px 0px 8px 2px #CED5F480;
    z-index: 3001;

    &__dragAndDrop {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      //width: 600px;
      height: 270px;
      margin-bottom: 25px;
      padding: 67px 67px 67px;
      border: 1px dotted $purple;
      border-radius: 10px;
      background-color: $SideBlocksColor;
      cursor: pointer;
      svg {
          margin: 0 auto 35px;
      }
    }

    &__dragAndDrop-text {
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      cursor: pointer;
      color: $black;
    }

    &__buttons-block {
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }

    &__download-button {
        width: 190px;
        height: 35px;
        margin-right: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(180deg, #8F21C9 0%, #8F21C9 0.01%, #4640B9 100%);
        color: $white;
        font-size: 16px;
        border-radius: 5px;
        cursor: pointer;
    }

    &__back-button {
        width: 190px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $purple;
        font-size: 16px;
        border: 1px solid $purple;
        border-radius: 5px;
        cursor: pointer;
    }
}

.photo-preview {
  display: flex;
  flex-wrap: wrap;
  &__item {
    width: 70px;
    height: 70px;
    cursor: pointer;
    margin-bottom: 20px;
    &:not(:last-child) {
      margin-right: 30px;
    }
  }
  &__img {
    width: 70px;
    height: 70px;
  }
}

.upload-file {
  margin-bottom: 5px;
  cursor: no-drop;
}

.download-file {
  margin-bottom: 5px;
  cursor: context-menu;
}

.file_input {
  margin-bottom: -47px;
  width: inherit;
  height: inherit;
  opacity: 0;
  overflow: hidden;
  position: absolute;
}

/* Create two equal columns that floats next to each other */
.column {
  float: left;
  width: 50%;
  //padding: 10px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto;
  padding: 10px;
}

.dd {
  position: relative;
  width: 100%;
  &.object {
    width: 265px !important;
    margin-top: 0px !important;
    font-size: 12px;
    font-weight: 500;
    // .dd-button {
    //   height: 27px !important;
    // }
  }
  .dd-button {
    @include ui-dropdown-button();
    border-radius: 0px 5px 5px 0px;
  }
  & ::v-deep .vs__dropdown-toggle {
    border: 1px solid $inputCaption;
    box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
  &:not(:first-child) {
    margin-top: 10px;
  }
}


</style>
