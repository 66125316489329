let mixin = {
    methods: {
        openSelectOptions(selector) {
            this.triggerMouseEvent(document.querySelector(selector), 'mousedown')
        },
        triggerMouseEvent (node, eventType) {
            var clickEvent = document.createEvent ('MouseEvents');
            clickEvent.initEvent (eventType, true, true);
            node.dispatchEvent (clickEvent);
        },
        textShow(event) {
            try {
                if (event.target.value.length > 33) {
                    //console.dir(event.target.parentElement.querySelector(".step__item-popup"))
                    //event.target.parentElement.querySelector(".step__item-popup").classList.add("not-empty");
                    event.target.parentElement.classList.add("not-empty");
                    //event.target.parentElement.querySelector(".step__item-popup p").innerText = event.target.value;
                    event.target.parentElement.innerText = event.target.value;
                }
            }
            catch (error) {
                console.log({
                    name: 'textShow',
                    error
                })
            }
        },
    }
}

export default mixin