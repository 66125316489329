<template>
  <div class="accounts">
    <ModalEditEmployee
        :opened="modalOpened"
        :employee="currentEmployee"
        :tab="modalOpenedTab"
        @close-modal="closeModalEmployee"
    />

    <div class="page-header">
      <h2 class="page-header__title">
        {{ $t('NavigationDrawerText.nav_employees_normal') }}
      </h2>
      <div class="buttons-block">
        <div v-if="isAdmin && currentEmployees && currentEmployees.length > 0"
          class="page-header__outload"
        >
          <img
              width="20" height="16"
              src="@/assets/img/bi_cloud_download.svg"
              alt="notification avatar">
          <p class="page-header__outload-text" @click="downloadUsersList('xls')">
            {{ $t('Buttons.download_xls') }}
          </p>
        </div>
<!--        <router-link to="/promotions/new-account">-->
        <div v-if="createPermission"
             class="page-header__newacc"
             @click="createNewEmployee">
          <p class="page-header__newacc-text">
            {{ $t('EmployeeText.new_user_button') }}
          </p>
        </div>
<!--        </router-link>-->
      </div>
    </div>
    <div class="buttons-panel">

      <div class="buttons-panel__input">
        <p class="buttons-panel__input-text">
          {{ $t('InterfaceText.search') }}
        </p>
        <input type="text" class="buttons-panel__input-input" :placeholder="$t('FieldsText.enter_request')"
               :value="filterData.fio"
               @input="setEmployeesFIO">
        <div class="buttons-panel__input-button"></div>
      </div>

      <div class="buttons-panel__input select">
        <p class="buttons-panel__input-text">
          {{ $t('FieldsText.role_label') }}
        </p>
        <div class="dd object dd-role-filter">
          <v-select
              :placeholder="$t('FieldsText.select_from_list')"
              :options="userTypes"
              :reduce="(option) => option.role"
              :clearable="false"
              v-model="filterData.role"
              @option:selected="setEmployeesRole"
          >
            <!-- eslint-disable-next-line vue/no-unused-vars  -->
            <template #no-options="{ search, searching, loading }">
              {{ $t('Errors.missing_options') }}
            </template>
          </v-select>
          <div
              class="dd-button"
              @click="openSelectOptions('.dd-role-filter .vs__dropdown-toggle')"
          ></div>
        </div>
      </div>

      <div class="buttons-panel__input select">
        <p class="buttons-panel__input-text">
          {{ $t('FieldsText.company_label') }}
        </p>
        <div class="dd object dd-company-filter">
          <v-select
              :placeholder="$t('FieldsText.select_from_list')"
              :options="companies"
              :reduce="(option) => option.id"
              label="name"
              :clearable="false"
              v-model="filterData.company"
              @option:selected="setEmployeesCompany"
          >
            <!-- eslint-disable-next-line vue/no-unused-vars  -->
            <template #no-options="{ search, searching, loading }">
              {{ $t('Errors.missing_options') }}
            </template>
          </v-select>
          <div
              class="dd-button"
              @click="openSelectOptions('.dd-company-filter .vs__dropdown-toggle')"
          ></div>
        </div>
      </div>

    </div>
    <div class="table">
      <div class="table__row title">
        <p class="table__cell">{{ $t('FieldsText.fio_label') }}</p>
        <p class="table__cell">{{ $t('FieldsText.email_label') }}</p>
        <p class="table__cell">{{ $t('FieldsText.phone_short_label') }}</p>
        <p class="table__cell">{{ $t('FieldsText.role_label') }}</p>
        <p class="table__cell">{{ $t('TableHeaders.action') }}</p>
      </div>
      <div class="table__body">
        <div
          v-for="employee in currentEmployees.slice((page - 1) * itemsPerPage, (page * itemsPerPage))" :key="employee.id"
          class="table__row"
        >
          <div class="table__cell">
            <p>
              {{ `${employee.name} ${employee.surname} ${employee.lastname}` }}
            </p>
          </div>
          <div class="table__cell">
            <p>
              {{employee.email}}
            </p>
          </div>
          <div class="table__cell">
            <p>
              {{employee.phone}}
            </p>
          </div>
          <div class="table__cell">
            <p>
              {{ UserTypeText(employee.role) }}
            </p>
          </div>
          <div class="table__cell button">
            <div class="table__cell-button"  @click="openModalEmp(employee, 2)">
              <img
                  :title="$t('TabHeaders.chat')"
                  src="@/assets/img/notification/message-badge.svg"
                  alt="notification avatar">
            </div>
            <div v-if="editPermission" class="table__cell-button" style="padding-left: 5px;" @click="openModalEmp(employee, 0)">
              <img
                  :title="$t('TabHeaders.information')"
                  src="@/assets/img/view_info.svg"
                  alt="notification avatar">
            </div>
            <div v-if="editPermission" class="table__cell-button" style="padding-left: 5px;" @click="openModalBlockEmp(employee)">
              <img v-if="employee.active"
                   :title="$t('TabHeaders.deactivate')"
                   width="20" height="20"
                   src="@/assets/img/user_block.svg" alt="">
              <img v-else
                   :title="$t('TabHeaders.activate')"
                   width="20" height="20"
                   src="@/assets/img/user_blocked.svg" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="pagination-buttons">
      <div class="pagination-buttons__arrow left">
        <img src="@/assets/img/left-arrow.png" alt="arrow icon">
      </div>
      <div v-bind:class="(n===page)?'pagination-buttons__number active':'pagination-buttons__number'"
           @click="changePage(n)"
           v-for="n in pageCount" :key="n"
      >{{ n }}</div>
      <div class="pagination-buttons__arrow right">
        <img src="@/assets/img/left-arrow.png" alt="arrow icon">
      </div>
    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import InputsMixin from "@/mixins/inputsEvent";

import ModalEditEmployee from "@/components/ModalEditEmployee";

import {
  UsersType,
  UsersTypes,
  NewUsers,
  UserTypeText,
  UsersFromBody,
} from "@/models/users";

import {
  hasContent,
} from "@/tools/dataTools";
import {SAVE_PAGES_FILTERS} from "@/common/config";

export default {
  name: 'Accounts',
  components: {
    ModalEditEmployee,
  },
  mixins: [InputsMixin],
  data() {
    return {
      isLoading: false,
      modalOpened: false,

      // pagination
      itemsPerPage: 6,
      page: 1,

      filterData: {
        fio: '',
        role: null,
        company: null,
      },
      currentEmployee: null,
      modalOpenedTab: 0,
      currentEmployees: [],
    };
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    "$store.state.employees.filter": function (newValue, oldValue) {
      this.filter(newValue);
    },
    // eslint-disable-next-line no-unused-vars
    "$store.state.employees.all": function (newValue, oldValue) {
      // apply filters
      this.filter(this.GET_EMPLOYEES().filter);
      // check changes in current employee
      if (this.currentEmployee) {
        let employee = this.GET_EMPLOYEES().all.find(
            (v) => v.id.toString() === this.currentEmployee.id.toString()
        );

        if(employee)
          this.currentEmployee = UsersFromBody(employee)
      }
    },
    pageCount() {
      if(this.pageCount < this.itemsPerPage)
        this.page = 1;
    },
    isLoading() {
      if(this.isLoading)
        this.$progress.start();
      else
        this.$progress.finish();
    },
  },
  mounted() {
    this.GET_USERS_FROM();

    if (SAVE_PAGES_FILTERS) {
      this.filter(this.GET_EMPLOYEES().filter);
    }
  },
  computed: {
    user() {
      return this.GET_USER();
    },
    isAdmin() {
      return this.user ? (this.user.role === UsersType.ADMIN) : false;
    },
    pageCount() {
      //return ~~(this.GET_EMPLOYEES().all.length / this.itemsPerPage) + 1 ;
      //return this.currentEmployees ? ~~(this.currentEmployees.length / this.itemsPerPage) + 1 : 1;
      return this.currentEmployees ? Math.ceil(this.currentEmployees.length / this.itemsPerPage) : 1;
    },
    userTypes() {
      return [
        {role: null, label: this.$t('InterfaceText.all')},
        ...UsersTypes()
      ]
    },
    companies() {
      if(this.isAdmin) {
        return [
          {id: null, name: this.$t('CompanyText.all_companies')},
          ...this.GET_COMPANIES()
        ]
      }
      else {
        return this.user ? this.user.companies : []
      }
    },
    employees_filter() {
      return this.GET_EMPLOYEES().filter;
    },
    createPermission() {
      return this.isAdmin;
    },
    editPermission() {
      return this.isAdmin;
    },
  },
  methods: {
    ...mapGetters(["GET_USER","GET_EMPLOYEES","GET_COMPANIES",]),
    ...mapActions(["GET_USERS_FROM","SET_EMPLOYEES_FILTER","DEACTIVATE_USER", "ACTIVATE_USER", "VIEW_USER_MESSAGE","GET_USERS_DOWNLOAD"]),

    UsersTypes,
    UserTypeText,

    // eslint-disable-next-line no-unused-vars
    filter(filter) {
      this.filterData = {...filter}

      let currentEmployees = this.GET_EMPLOYEES().all;

      // filter by FIO
      if (hasContent(filter.fio))
        currentEmployees = currentEmployees.filter(
            (v) =>
                `${v.surname ? v.surname : ''} ${v.name ? v.name : ''} ${v.lastname ? v.lastname : ''}`
                    .toLowerCase()
                    .indexOf(filter.fio.toLowerCase()) !== -1
        );

      // filter by role
      if (hasContent(filter.role))
        currentEmployees = currentEmployees.filter(
            (v) => v.role === filter.role
        );

      // filter by company
      if (hasContent(filter.company)) {
        currentEmployees = currentEmployees.filter(
            (v) => {
              return (v.companies != null && v.companies.filter(c => c.id === filter.company).length > 0) ||
                  (v.role === UsersType.ADMIN || v.isAdmin)
            }
        );
      }
      else {
        if(!this.isAdmin) {
          // show just admins
          currentEmployees = currentEmployees.filter(
              (v) => (v.role === UsersType.ADMIN || v.isAdmin)
          );
        }
      }

      // filter by current user company - if user is from specific company - show just current company users
      // if (hasContent(this.user.company))
      //   currentEmployees = currentEmployees.filter(
      //       (v) => v.company === this.user.company
      //   );

      this.currentEmployees = currentEmployees;
    },

    activateUser(employee) {
      if(employee != null) {
        if (
            confirm(this.$t('EmployeeText.activate_employee'))
        ) {
          this.isLoading = true

          this.ACTIVATE_USER({
            id: employee.id,
          }).then((res) => {
            this.isLoading = false

            if (res.success) {
              this.$notify({
                type: "success",
                title: this.$t('Errors.success_operation'),
                text: this.$t('EmployeeText.activate_employee_success'),
              });
            } else {
              this.$notify({
                type: "error",
                title: this.$t('Errors.not_success_operation'),
                text: this.$t('EmployeeText.activate_employee_error'),
              });
            }
          });
        }
      }
    },

    deactivateUser(employee) {
      if(employee != null) {
        if (
            confirm(this.$t('EmployeeText.deactivate_employee'))
        ) {
          this.isLoading = true

          this.DEACTIVATE_USER({
            id: employee.id,
          }).then((res) => {
            this.isLoading = false

            if (res.success) {
              this.$notify({
                type: "success",
                title: this.$t('Errors.success_operation'),
                text: this.$t('EmployeeText.deactivate_employee_success'),
              });
            } else {
              this.$notify({
                type: "error",
                title: this.$t('Errors.not_success_operation'),
                text: this.$t('EmployeeText.deactivate_employee_error'),
              });
            }
          });
        }
      }
    },

    setEmployeesFIO(e) {
      if (SAVE_PAGES_FILTERS) {
        this.SET_EMPLOYEES_FILTER({
          ...this.employees_filter,
          fio: e.target.value,
        });
      }
      else {
        this.filterData.fio = e.target.value
        this.filter(this.filterData)
      }
    },

    setEmployeesRole(data) {
      if (SAVE_PAGES_FILTERS) {
        this.SET_EMPLOYEES_FILTER({
          ...this.employees_filter,
          role: !data ? null : data.role,
        });
      }
      else {
        this.filter(this.filterData)
      }
    },

    setEmployeesCompany(data) {
      if (SAVE_PAGES_FILTERS) {
        this.SET_EMPLOYEES_FILTER({
          ...this.employees_filter,
          company: !data ? null : data.id,
        });
      }
      else {
        this.filterData.company = !data ? null : data.id
        this.filter(this.filterData)
      }
    },

    downloadUsersList(format) {
      if (format) {
        this.isLoading = true

        this.GET_USERS_DOWNLOAD({
          format,
          company: this.employees_filter ? this.employees_filter.company : null,
        }).then((res) => {
          this.isLoading = false

          if (res.success) {
            this.$notify({
              type: "success",
              title: this.$t('Errors.success_operation'),
              text: this.$t('InterfaceText.file_downloaded'),
            });
          }
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t('Errors.not_success_operation'),
          text: this.$t('Errors.error_format'),
        });
      }
    },

    changePage(page) {
      this.page = page;
    },

    createNewEmployee() {
      this.currentEmployee = NewUsers();
      this.modalOpenedTab = 0
      this.modalOpened = true;
    },
    openModalEmp(employee, tab) {
      if (employee.newMessages) {
        this.VIEW_USER_MESSAGE(employee);
      }

      this.modalOpenedTab = tab ? tab : 0;

      this.currentEmployee = employee;

      this.modalOpened = true;
    },
    closeModalEmployee() {
      this.currentEmployee = null;
      this.modalOpened = false;
    },
    // eslint-disable-next-line no-unused-vars
    openModalBlockEmp(employee) {
      if(employee.active) {
        this.deactivateUser(employee)
      }
      else {
        this.activateUser(employee)
      }
    },
  },
}
</script>

<style lang="scss" src="@/assets/scss/common.scss"></style>
<style lang="scss">
  @import "@/assets/scss/_variables.scss";
  @import "@/assets/scss/_mixin.scss";

  .accounts {
    flex: 1;
    width: 100%;
    min-width: 1150px;

    .table {
      //@include grid-table-row(60px, 40px, 9% 50px 9% 50px 9% 107px 9% 80px 12.6% 70px 12.6% 60px 3.2%);
      @include grid-table-row(145px, 60px, 11.8% 136px 13.75% 136px 14% 153px 8.2% 125px 6%);

      &__cell {
        &:first-child {
          grid-column-start: 1;
          grid-column-end: 2;
        }
        &:nth-child(2) {
          grid-column-start: 3;
          grid-column-end: 4;
        }
        &:nth-child(3) {
          grid-column-start: 5;
          grid-column-end: 6;
        }
        &:nth-child(4) {
          grid-column-start: 7;
          grid-column-end: 8;
        }
        &:nth-child(5) {
          grid-column-start: 9;
          grid-column-end: 10;
        }
        &:nth-child(6) {
          grid-column-start: 11;
          grid-column-end: 12;
        }
        &:nth-child(7) {
          grid-column-start: 13;
          grid-column-end: 14;
        }
      }
    }

    .dd {
      position: relative;
      width: 250px;
      &.object {
        width: 250px !important;
        margin-top: 0px !important;
        font-size: 12px;
        font-weight: 500;
        // .dd-button {
        //   height: 27px !important;
        // }
      }
      .dd-button {
        @include ui-dropdown-button();
        border-radius: 0px 5px 5px 0px;
      }
      & ::v-deep .vs__dropdown-toggle {
        border: 1px solid $inputCaption;
        box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
      }
      &:not(:first-child) {
        margin-top: 10px;
      }
    }

  }

  .page-header {
    &__newacc {
      @include purple-button;
      width: 210px;
    }
  }

</style>
