<template>

    <!-- Prizes List -->
    <div class="step__table three-columns">
        <div class="table three-columns">
            <div class="table__row title">
                <p class="table__cell">{{ $t('PrizeText.name') }}</p>
                <p class="table__cell">{{ $t('PrizeText.category') }}</p>
                <p class="table__cell">{{ $t('TableHeaders.action') }}</p>
            </div>
            <div class="table__body">
                <div
                    v-for="prizeItem in item.prizes" :key="prizeItem.id"
                    class="table__row"
                    :class="(prize && prize.id === prizeItem.id) ? 'table__row__selected' : 'table__row'"
                    style="cursor: pointer;"
                    @click="changeCurrentID(prizeItem)"
                >
                    <div style="text-align: left; margin-left: -150px;">
                        <p>
                          {{ prizeItem.full_name }}
                        </p>
                    </div>
                    <div class="table__cell">
                        <p>
                          {{ PrizesTypeText(prizeItem.type) }}
                        </p>
                    </div>
                    <div class="table__cell button">
                        <div class="table__cell-button" :title="$t('Buttons.delete')" @click="deletePrize(prizeItem)">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.0001 3.12868e-08C10.8855 -0.000110722 11.7389 0.293826 12.3928 0.824097C13.0467 1.35437 13.4539 2.08266 13.5344 2.86599H19.0325C19.2777 2.86605 19.5138 2.94881 19.6929 3.09753C19.8721 3.24625 19.981 3.44984 19.9977 3.66718C20.0145 3.88451 19.9377 4.09938 19.783 4.26837C19.6282 4.43736 19.4071 4.54786 19.1642 4.57755L19.0325 4.58558H18.2803L16.6454 17.173C16.5726 17.7317 16.2711 18.2472 15.7984 18.6214C15.3257 18.9955 14.7146 19.2022 14.0815 19.2021H5.9188C5.28569 19.2022 4.67464 18.9955 4.20189 18.6214C3.72915 18.2472 3.4277 17.7317 3.35489 17.173L1.71874 4.58558H0.967756C0.733898 4.58557 0.507953 4.51033 0.331707 4.37376C0.155461 4.2372 0.0408371 4.04855 0.00903235 3.84272L0 3.72578C9.86195e-06 3.51801 0.0847014 3.31727 0.238413 3.16069C0.392124 3.00411 0.604456 2.90227 0.836141 2.87401L0.967756 2.86599H6.4659C6.54639 2.08266 6.95356 1.35437 7.60747 0.824097C8.26139 0.293826 9.1148 -0.000110722 10.0001 3.12868e-08ZM8.06464 7.16497C7.7485 7.16497 7.48398 7.34266 7.42979 7.57652L7.41946 7.66709V14.6888L7.42979 14.7782C7.48398 15.012 7.7485 15.1897 8.06464 15.1897C8.38077 15.1897 8.64529 15.012 8.69948 14.7782L8.70981 14.6876V7.66823L8.69948 7.57652C8.64529 7.3438 8.38077 7.16497 8.06464 7.16497ZM11.9357 7.16497C11.6195 7.16497 11.355 7.34266 11.3008 7.57652L11.2905 7.66709V14.6888L11.3008 14.7782C11.355 15.012 11.6195 15.1897 11.9357 15.1897C12.2518 15.1897 12.5163 15.012 12.5705 14.7782L12.5808 14.6876V7.66823L12.5705 7.57652C12.5163 7.3438 12.2518 7.16611 11.9357 7.16611V7.16497ZM10.0001 1.71959C9.21949 1.71959 8.56787 2.21254 8.41948 2.86599H11.5808C11.4311 2.21254 10.7808 1.71959 10.0001 1.71959Z" fill="#BDBDBD"/>
                            </svg>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>

    <!-- Prize General Information -->
    <div
        class="step__content"
        v-if="prize"
    >
        <div class="step__inner">
            <div class="step__inner-header">
                <p>{{ $t('PrizeText.generalInformation') }}</p>
            </div>

            <div>

              <div class="grid-container">

                <div class="step__item textarea-prize required large py-2">
                  <p class="step__item-text">
                    {{ $t('PrizeText.full_name') }}
                  </p>
                  <input class="step__item-input" type="text"
                         required oninvalid="this.setCustomValidity('Введите значение')" oninput="this.setCustomValidity('')"
                         v-model="prize.full_name" :disabled="!(editPermission)">
                </div>

                <div class="step__item select required">
                    <p class="step__item-text">
                      {{ $t('PrizeText.prize_example') }}
                    </p>
                    <div class="dd object dd-example-filter">
                      <v-select
                          :placeholder="$t('FieldsText.select_from_list')"
                          :options="prizeExamples"
                          :reduce="(option) => option.id"
                          label="name"
                          :clearable="true"
                          v-model="examplePrize"
                          @option:selected="setPrizeSelectedData"
                      >
                        <!-- eslint-disable-next-line vue/no-unused-vars  -->
                        <template #no-options="{ search, searching, loading }">
                          {{ $t('Errors.missing_options') }}
                        </template>
                      </v-select>
                      <div
                          class="dd-button"
                          @click="openSelectOptions('.dd-example-filter .vs__dropdown-toggle')"
                      ></div>
                    </div>
                </div>

              </div>


              <div class="grid-container">

                <div class="step__item textarea-prize required large py-2">
                    <p class="step__item-text">
                    {{ $t('PrizeText.name_req') }}
                    </p>
                    <input class="step__item-input" type="text"
                           required oninvalid="this.setCustomValidity('Введите значение')" oninput="this.setCustomValidity('')"
                         v-model="prize.name" :disabled="!(editPermission)">
                </div>

                <div class="step__item select required">
                    <p class="step__item-text">
                      {{ $t('PrizeText.type') }}
                    </p>
                    <div class="dd object dd-type-filter">
                      <v-select
                          :placeholder="$t('FieldsText.select_from_list')"
                          :options="PrizesTypes()"
                          :reduce="(option) => option.type"
                          label="label"
                          :clearable="false"
                          v-model="prize.type"
                      >
                        <!-- eslint-disable-next-line vue/no-unused-vars  -->
                        <template #no-options="{ search, searching, loading }">
                          {{ $t('Errors.missing_options') }}
                        </template>
                      </v-select>
                      <div
                          class="dd-button"
                          @click="openSelectOptions('.dd-type-filter .vs__dropdown-toggle')"
                      ></div>
                    </div>
                </div>

              </div>


              <div class="grid-container">

                <div class="step__item  textarea required">
                  <p class="step__item-text">
                    {{ $t('PrizeText.prize_category_number') }}
                  </p>
                  <input class="step__item-input" type="number" min="0"  required
                         v-model="prize.categoryNumber" :disabled="!(editPermission)">
                </div>

                <!--div class="step__item textarea required">
                  <p class="step__item-text">
                    {{ $t('PrizeText.category') }}
                  </p>
                  <input class="step__item-input" type="text"
                         required oninvalid="this.setCustomValidity('Введите значение')" oninput="this.setCustomValidity('')"
                         v-model="prize.category" :disabled="!(editPermission)">
                </div-->
                <div class="step__item select required">
                  <p class="step__item-text">
                    {{ $t('PrizeText.category') }}
                  </p>
                  <div class="dd object dd-category-filter required">
                    <v-select
                        :placeholder="$t('FieldsText.select_from_list')"
                        :options="allCategories"
                        v-model="prize.category"
                        :clearable="false"
                        :rules="[requiredSelectRules]"
                        :class="{ 'error--text--select': (prize.category == null || prize.category === '') }"
                    >
                      <!-- eslint-disable-next-line vue/no-unused-vars  -->
                      <template #no-options="{ search, searching, loading }">
                        {{ $t('Errors.missing_options') }}
                      </template>
                    </v-select>
                    <div
                        class="dd-button required"
                        @click="openSelectOptions('.dd-category-filter .vs__dropdown-toggle')"
                    ></div>
                  </div>
                </div>

                <div class="step__item  textarea required">
                    <p class="step__item-text">
                      {{ $t('PrizeText.count') }}
                    </p>
                    <input class="step__item-input" id="count" type="text" name="prize_count" required
                           oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.setCustomValidity('')"
                           oninvalid="this.setCustomValidity('Введите значение')"
                            v-model="prize.count" :disabled="!(editPermission)">
                </div>

                <div class="step__item textarea required">
                  <p class="step__item-text">
                    {{ `${$t('PrizeText.price')} (${($t('PrizeText.price_total_simple'))}: ${totalPrizeValue})` }}
                  </p>
                  <input class="step__item-input" type="text" name="prize_price" required
                         oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.setCustomValidity('')"
                         oninvalid="this.setCustomValidity('Введите значение')"
                         v-model="prize.price" :disabled="!(editPermission)">
                </div>

                <!--div class="step__item textarea required">
                  <p class="step__item-text">
                    {{ $t('PrizeText.price_total') }}
                  </p>
                  <input class="step__item-input" type="text"
                         :value="totalPrizeValue" :disabled="true">
                </div-->

              </div>


              <div class="grid-container">

                <div class="step__item textarea">
                  <p class="step__item-text">
                    {{ $t('PrizeText.prizesQuantityPerPersonPerPromo') }}
                  </p>
                  <input class="step__item-input" type="text" name="prizesQuantityPerPersonPerPromo" required
                         oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.setCustomValidity('')"
                         oninvalid="this.setCustomValidity('Введите значение')"
                         v-model="prize.prizesQuantityPerPersonPerPromo" :disabled="!(editPermission)">
                </div>

                <div class="step__item textarea">
                  <p class="step__item-text">
                    {{ $t('PrizeText.prizesQuantityPerPersonPerDay') }}
                  </p>
                  <input class="step__item-input" type="text" name="prizesQuantityPerPersonPerDay" required
                         oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.setCustomValidity('')"
                         oninvalid="this.setCustomValidity('Введите значение')"
                         v-model="prize.prizesQuantityPerPersonPerDay" :disabled="!(editPermission)">
                </div>

                <div class="step__item textarea">
                    <p class="step__item-text">
                      {{ $t('PrizeText.prizesQuantityPerPersonPerWeek') }}
                    </p>
                    <input class="step__item-input" type="text" name="prizesQuantityPerPersonPerWeek" required
                           oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.setCustomValidity('')"
                           oninvalid="this.setCustomValidity('Введите значение')"
                            v-model="prize.prizesQuantityPerPersonPerWeek" :disabled="!(editPermission)">
                </div>

                <div class="step__item textarea">
                    <p class="step__item-text">
                      {{ $t('PrizeText.prizesQuantityPerPersonPerMonth') }}
                    </p>
                    <input class="step__item-input" type="text" name="prizesQuantityPerPersonPerMonth" required
                           oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.setCustomValidity('')"
                           oninvalid="this.setCustomValidity('Введите значение')"
                            v-model="prize.prizesQuantityPerPersonPerMonth" :disabled="!(editPermission)">
                </div>

              </div>


              <div class="grid-container">

                <div class="step__item textarea">
                  <p class="step__item-text">
                    {{ $t('PrizeText.applicationsQuantityPerPromo') }}
                  </p>
                  <input class="step__item-input" type="text" name="applicationsQuantityPerPromo" required
                         oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.setCustomValidity('')"
                         oninvalid="this.setCustomValidity('Введите значение')"
                         v-model="prize.applicationsQuantityPerPromo" :disabled="!(editPermission)">
                </div>

                <div class="step__item textarea">
                    <p class="step__item-text">
                      {{ $t('PrizeText.applicationsQuantityPerDay') }}
                    </p>
                    <input class="step__item-input" type="text" name="applicationsQuantityPerDay" required
                           oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.setCustomValidity('')"
                           oninvalid="this.setCustomValidity('Введите значение')"
                           v-model="prize.applicationsQuantityPerDay" :disabled="!(editPermission)">
                </div>

                <div class="step__item textarea">
                    <p class="step__item-text">
                      {{ $t('PrizeText.applicationsQuantityPerWeek') }}
                    </p>
                    <input class="step__item-input" type="text" name="applicationsQuantityPerWeek" required
                           oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.setCustomValidity('')"
                           oninvalid="this.setCustomValidity('Введите значение')"
                            v-model="prize.applicationsQuantityPerWeek" :disabled="!(editPermission)">
                </div>

                <div class="step__item textarea">
                    <p class="step__item-text">
                      {{ $t('PrizeText.applicationsQuantityPerMonth') }}
                    </p>
                    <input class="step__item-input" type="text" name="applicationsQuantityPerMonth" required
                           oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.setCustomValidity('')"
                           oninvalid="this.setCustomValidity('Введите значение')"
                            v-model="prize.applicationsQuantityPerMonth" :disabled="!(editPermission)">
                </div>

              </div>

            </div>

        </div>
    </div>

  <!-- Prize Certificates Information -->
  <div
      class="step__content vertical-space"
      v-if="prize && prize.type === PrizesType.CERTIFICATE"
  >
    <div class="step__inner">
      <div class="step__inner-header">
        <p>{{ $t('PrizeText.prizeCertificatesData') }}</p>
      </div>

      <div>

        <div class="grid-container">

          <div class="step__item select">
            <div class="new-promotion__checkbox">
              <input
                  :disabled="!editPermission"
                  type="checkbox" id="surname"
                  v-model="prize.useOnlineCertificates" />
              <label for="surname">{{ $t('PrizeText.useOnlineCertificates') }}</label>
            </div>
          </div>

          <div v-if="prize.useOnlineCertificates" class="step__item textarea">
            <p class="step__item-text">
              {{ $t('PrizeText.loadedCertificatesCount') }}
            </p>
            <input class="step__item-input" type="number" min="0" name="pointsQuantity" required
                   :value="prize.certificates ? prize.certificates : 0" disabled>
          </div>
          <div v-else class="step__item select"/>


          <div v-if="prize.useOnlineCertificates" class="buttons-block">
            <div
                class="buttons-block__test"
                @click="getNewCertificates"
            >
              <p class="buttons-block__test-text">
                {{ $t('PrizeText.getNewCertificates') }}
              </p>
            </div>
          </div>
          <div v-else class="step__item select"/>

          <div v-if="prize.useOnlineCertificates" class="buttons-block">
            <div
                class="buttons-block__test"
                @click="addNewCertificates"
            >
              <p class="buttons-block__test-text">
                {{ $t('PrizeText.addNewCertificates') }}
              </p>
            </div>
          </div>
          <div v-else class="step__item select"/>

        </div>

      </div>

    </div>
  </div>

    <!-- Prize Conditions Information -->
    <div
        class="step__content vertical-space"
        v-if="prize"
    >
        <div class="step__inner">
            <div class="step__inner-header">
                <p>{{ $t('PrizeText.prizeConditions') }}</p>
            </div>

            <div>


              <div class="grid-container">

                <div class="step__item textarea">
                  <p class="step__item-text">
                    {{ $t('PrizeText.requiredScore') }}
                  </p>
                  <input class="step__item-input" type="text" name="pointsQuantity" required
                         oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.setCustomValidity('')"
                         oninvalid="this.setCustomValidity('Введите значение')"
                         v-model="prize.pointsQuantity" :disabled="!(editPermission)">
                </div>

                <div class="step__item select required">
                  <p class="step__item-text">
                    {{ $t('PrizeText.actionForPrize') }}
                  </p>
                  <div class="dd object dd-actionForPrize-filter">
                    <v-select
                        :placeholder="$t('FieldsText.select_from_list')"
                        :options="ActionsForPrize()"
                        :reduce="(option) => option.action"
                        label="label"
                        :clearable="false"
                        v-model="prize.actionForPrize"
                    >
                      <!-- eslint-disable-next-line vue/no-unused-vars  -->
                      <template #no-options="{ search, searching, loading }">
                        {{ $t('Errors.missing_options') }}
                      </template>
                    </v-select>
                    <div
                        class="dd-button"
                        @click="openSelectOptions('.dd-actionForPrize-filter .vs__dropdown-toggle')"
                    ></div>
                  </div>
                </div>

                <div class="step__item select">
                </div>

                <div class="step__item select">
                </div>

                <!--div class="step__item select">
                  <p class="step__item-text">
                    {{ $t('PrizeText.advancedPrize') }}
                  </p>
                  <div class="dd object dd-advancedPrize-filter">
                    <v-select
                        :placeholder="$t('FieldsText.select_from_list')"
                        :options="Actions()"
                        :reduce="(option) => option.action"
                        label="label"
                        :clearable="false"
                        v-model="prize.advancedPrize"
                    >
                      <template #no-options="{ search, searching, loading }">
                        {{ $t('Errors.missing_options') }}
                      </template>
                    </v-select>
                    <div
                        class="dd-button"
                        @click="openSelectOptions('.dd-advancedPrize-filter .vs__dropdown-toggle')"
                    ></div>
                  </div>
                </div-->

                <!--div class="step__item select required">
                  <p class="step__item-text">
                    {{ $t('PrizeText.actionsForAdvancePrize') }}
                  </p>
                  <div class="dd object dd-actionsForAdvancePrize-filter">
                    <v-select
                        :placeholder="$t('FieldsText.select_from_list')"
                        :options="ActionsForPrize()"
                        :reduce="(option) => option.action"
                        label="label"
                        :clearable="false"
                        v-model="prize.actionsForAdvancePrize"
                    >
                      <template #no-options="{ search, searching, loading }">
                        {{ $t('Errors.missing_options') }}
                      </template>
                    </v-select>
                    <div
                        class="dd-button"
                        @click="openSelectOptions('.dd-actionsForAdvancePrize-filter .vs__dropdown-toggle')"
                    ></div>
                  </div>
                </div-->

              </div>

            </div>

        </div>
    </div>

    <!-- Prize Drawing Information -->
    <div
        class="step__content vertical-space"
        v-if="prize"
    >
      <div class="step__inner">

        <div class="page-header">
          <p class="step__inner-header">{{ $t('PrizeText.drawingConfigurations') }}</p>

          <div class="buttons-block">
            <div
                class="page-header__newcode"
                @click="openModalDeterminingInformation(null)"
                style="width: 310px;"
            >
              <p class="page-header__newcode-text">
                {{ $t('ListItemText.add_determining_full') }}
              </p>
            </div>
          </div>
        </div>

        <div>

          <div class="grid-container">

            <!--div class="step__item select required">
              <p class="step__item-text">
                {{ $t('PrizeText.automaticalApplicatonForPromo') }}
              </p>
              <div class="dd object dd-automaticalApplicatonForPromo-filter">
                <v-select
                    :placeholder="$t('FieldsText.select_from_list')"
                    :options="Actions()"
                    :reduce="(option) => option.action"
                    label="label"
                    :clearable="false"
                    v-model="prize.automaticalApplicatonForPromo"
                >
                  <template #no-options="{ search, searching, loading }">
                    {{ $t('Errors.missing_options') }}
                  </template>
                </v-select>
                <div
                    class="dd-button"
                    @click="openSelectOptions('.dd-automaticalApplicatonForPromo-filter .vs__dropdown-toggle')"
                ></div>
              </div>
            </div-->

            <div class="step__item select required">
              <p class="step__item-text">
                {{ $t('PrizeText.automaticalDelivery') }}
              </p>
              <div class="dd object dd-automaticalDelivery-filter">
                <v-select
                    :placeholder="$t('FieldsText.select_from_list')"
                    :options="Actions()"
                    :reduce="(option) => option.action"
                    label="label"
                    :clearable="false"
                    v-model="prize.automaticalDelivery"
                >
                  <!-- eslint-disable-next-line vue/no-unused-vars  -->
                  <template #no-options="{ search, searching, loading }">
                    {{ $t('Errors.missing_options') }}
                  </template>
                </v-select>
                <div
                    class="dd-button"
                    @click="openSelectOptions('.dd-automaticalDelivery-filter .vs__dropdown-toggle')"
                ></div>
              </div>
            </div>

            <div class="step__item textarea required">
              <p class="step__item-text">
                {{ $t('PrizeText.awardingPrizeTimeInDays') }}
              </p>
              <input class="step__item-input" type="text" name="awardingPrizeTimeInDays" required
                     oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.setCustomValidity('')"
                     oninvalid="this.setCustomValidity('Введите значение')"
                     v-model="prize.awardingPrizeTimeInDays" :disabled="!(editPermission)">
            </div>

            <div class="step__item textarea">
              <p class="step__item-text">
                {{ $t('PrizeText.comission') }}
              </p>
              <input class="step__item-input" type="number" min="0" max="100"
                     v-model="prize.comission" :disabled="!(editPermission)">
            </div>

            <div class="step__item select required">
            </div>

          </div>

          <div class="grid-container">

            <div class="step__item select required">
              <p class="step__item-text">
                {{ $t('PrizeText.winnerDetermining') }}
              </p>
              <div class="dd object dd-determining-filter required">
                <v-select
                    :placeholder="$t('FieldsText.select_from_list')"
                    :options="allDetermining"
                    :reduce="(option) => option.id"
                    label="name"
                    v-model="prize.winnerDetermining"
                    :clearable="true"
                    :rules="[requiredSelectRules]"
                    :class="{ 'error--text--select': (prize.winnerDetermining == null || prize.winnerDetermining === '') }"
                >
                  <!-- eslint-disable-next-line vue/no-unused-vars  -->
                  <template #no-options="{ search, searching, loading }">
                    {{ $t('Errors.missing_options') }}
                  </template>
                </v-select>
                <div
                    class="dd-button required"
                    @click="openSelectOptions('.dd-determining-filter .vs__dropdown-toggle')"
                ></div>
              </div>
            </div>

            <div class="step__item select required">
              <p class="step__item-text">
                {{ $t('PrizeText.deletePointsAfterDelivery') }}
              </p>
              <div class="dd object dd-deletePointsAfterDelivery-filter">
                <v-select
                    :placeholder="$t('FieldsText.select_from_list')"
                    :options="Actions()"
                    :reduce="(option) => option.action"
                    label="label"
                    :clearable="false"
                    v-model="prize.deletePointsAfterDelivery"
                >
                  <!-- eslint-disable-next-line vue/no-unused-vars  -->
                  <template #no-options="{ search, searching, loading }">
                    {{ $t('Errors.missing_options') }}
                  </template>
                </v-select>
                <div
                    class="dd-button"
                    @click="openSelectOptions('.dd-deletePointsAfterDelivery-filter .vs__dropdown-toggle')"
                ></div>
              </div>
            </div>

            <div class="step__item select required">
              <p class="step__item-text">
                {{ $t('PrizeText.paymentDirection') }}
              </p>
              <div class="dd object dd-paymentDirection-filter">
                <v-select
                    :placeholder="$t('FieldsText.select_from_list')"
                    :options="PaymentDirections()"
                    :reduce="(option) => option.direction"
                    label="label"
                    :clearable="false"
                    v-model="prize.paymentDirection"
                >
                  <!-- eslint-disable-next-line vue/no-unused-vars  -->
                  <template #no-options="{ search, searching, loading }">
                    {{ $t('Errors.missing_options') }}
                  </template>
                </v-select>
                <div
                    class="dd-button"
                    @click="openSelectOptions('.dd-paymentDirection-filter .vs__dropdown-toggle')"
                ></div>
              </div>
            </div>

            <div class="step__item select required">
              <p class="step__item-text">
                {{ $t('PrizeText.drawingType') }}
              </p>
              <div class="dd object dd-drawingType-filter">
                <v-select
                    :placeholder="$t('FieldsText.select_from_list')"
                    :options="DrawingTypes()"
                    :reduce="(option) => option.type"
                    label="label"
                    :clearable="false"
                    v-model="prize.drawingType"
                    :rules="[requiredSelectRules]"
                    :class="{ 'error--text--select': (isEmpty(prize.drawingType)) }"
                >
                  <!-- eslint-disable-next-line vue/no-unused-vars  -->
                  <template #no-options="{ search, searching, loading }">
                    {{ $t('Errors.missing_options') }}
                  </template>
                </v-select>
                <div
                    class="dd-button"
                    @click="openSelectOptions('.dd-drawingType-filter .vs__dropdown-toggle')"
                ></div>
              </div>
            </div>

          </div>

          <!-- Everyday Drawing Configuration -->
          <div v-if="prize.drawingType === DrawingType.EVERYDAY">

            <div class="title-part">
              <p class="step__inner-header" style="margin-top:10px;">
                {{ $t('PromotionText.everyday_drawing_settings') }}
              </p>
            </div>

            <div class="grid-container">

              <div class="step__item period">
                <p class="step__item-text">
                  {{ $t('PrizeText.applicatonForPromoDates') }}
                </p>
                <!-- <input class="step__item-period" type="text" placeholder="Дата начала">
                <input class="step__item-period" type="text" placeholder="Дата завершения"> -->
                <DateTimePicker
                    :start="prize.drawingEveryday.applicatonForPromoStartedAt"
                    :end="prize.drawingEveryday.applicatonForPromoEndedAt"
                    :required="true"
                    mode="dateTime"
                    @updateDates="updateDatesApplicatonForPromo"
                />
              </div>

              <!-- Drawing Dates -->
              <div class="step__item period">
                <p class="step__item-text">
                  {{ $t('PrizeText.drawingPromoDates') }}
                </p>
                <!-- <input class="step__item-period" type="text" placeholder="Дата начала">
                <input class="step__item-period" type="text" placeholder="Дата завершения"> -->
                <DateTimePicker
                    :start="prize.drawingEveryday.drawingPromoStartedAt"
                    :end="prize.drawingEveryday.drawingPromoEndedAt"
                    :required="true"
                    mode="dateTime"
                    @updateDates="updateDatesDrawingForPromo"
                />
              </div>

              <!-- Drawing Date -->
              <!--div class="step__item textarea required">
                <p class="step__item-text">
                  {{ $t('DrawingText.drawingPromoDate') }}
                </p>
                <div class="date-picker">
                  <div class="date-start" style="width: 265px;">
                    <input
                        :disabled="!editPermission"
                        :value="parseFullTime(prize.drawingEveryday.drawingPromoAt)"
                        class="step__item-input"
                        :placeholder="$t('DrawingText.drawingPromoDateRules')"
                    />
                    <Datepicker
                        :disabled="!editPermission"
                        v-model="prize.drawingEveryday.drawingPromoAt"
                        class="d-comp"
                        valueType="format"
                        locale="ru"
                        autoApply
                        hideInputIcon
                        closable
                        time-picker
                        :clearable="false"
                        :selectText="$t('Buttons.ok')"
                        :cancelText="$t('Buttons.cancel')"
                    ></Datepicker>
                  </div>
                </div>
              </div-->

              <div class="step__item textarea">
                <p class="step__item-text">
                  {{ $t('PrizeText.numberOfPrizes') }}
                </p>
                <input class="step__item-input" type="number" min="1" :max="prize ? prize.count ? prize.count : 0 : 0"
                       v-model="prize.drawingEveryday.numberOfPrizes" :disabled="!(editPermission)">
              </div>

              <div class="step__item select">
                <div class="new-promotion__checkbox">
                  <input
                      :disabled="!editPermission"
                      type="checkbox" id="moveRemainingPrizesToNextDrawing"
                      v-model="prize.drawingEveryday.moveRemainingPrizesToNextDrawing" />
                  <label for="moveRemainingPrizesToNextDrawing">{{ $t('PrizeText.moveRemainingPrizesToNextDrawing') }}</label>
                </div>
              </div>

            </div>

          </div>

          <!-- Specific Dates Drawing Configuration -->
          <div v-else-if="prize.drawingType === DrawingType.SPECIFIC_DATES">

            <div class="page-header" style="margin-top:18px; margin-left: 10px; margin-bottom: -2px;">
              <p class="step__inner-header">
                {{ $t('PromotionText.specific_dates_drawing_settings') }}
              </p>
              <div class="buttons-block">
                <div class="page-header__outload">
                  <p class="page-header__outload-text" @click="addNewDrawing">
                    {{ $t('PromotionText.new_specific_dates_drawing') }}
                  </p>
                </div>
              </div>
            </div>

            <div class="grid-container-5" v-for="data in prize.drawingSpecificDates" :key="data">

              <div class="step__item period">
                <p class="step__item-text">
                  {{ $t('PrizeText.applicatonForPromoDates') }}
                </p>
                <!-- <input class="step__item-period" type="text" placeholder="Дата начала">
                <input class="step__item-period" type="text" placeholder="Дата завершения"> -->
                <DateTimePicker
                    :start="data.applicatonForPromoStartedAt"
                    :end="data.applicatonForPromoEndedAt"
                    :object="data"
                    :required="true"
                    mode="dateTime"
                    @updateDates="updateDatesForDrawing"
                />
              </div>

              <!-- Drawing Date -->
              <div class="step__item textarea required">
                <p class="step__item-text">
                  {{ $t('DrawingText.drawingPromoDate') }}
                </p>
                <div class="date-picker">
                  <div class="date-start" style="width: 265px;">
                    <input
                        :disabled="!editPermission"
                        :value="parseFullTime(data.drawingPromoAt)"
                        class="step__item-input"
                        :placeholder="$t('DrawingText.drawingPromoDateRules')"
                    />
                    <Datepicker
                        :disabled="!editPermission"
                        v-model="data.drawingPromoAt"
                        class="d-comp"
                        valueType="format"
                        locale="ru"
                        autoApply
                        hideInputIcon
                        closable
                        :clearable="false"
                        :selectText="$t('Buttons.ok')"
                        :cancelText="$t('Buttons.cancel')"
                    ></Datepicker>
                  </div>
                </div>
              </div>

              <div class="step__item textarea">
                <p class="step__item-text">
                  {{ $t('PrizeText.numberOfPrizes') }}
                </p>
                <input class="step__item-input" type="number" min="1" :max="prize ? prize.count ? prize.count : 0 : 0"
                       v-model="data.numberOfPrizes" :disabled="!(editPermission)">
              </div>

              <div class="step__item select">
                <div class="new-promotion__checkbox">
                  <input
                      :disabled="!editPermission"
                      type="checkbox" id="moveRemainingPrizesToNextDrawing"
                      v-model="data.moveRemainingPrizesToNextDrawing" />
                  <label for="moveRemainingPrizesToNextDrawing">{{ $t('PrizeText.moveRemainingPrizesToNextDrawing') }}</label>
                </div>
              </div>

              <div class="step__item" style="width: 30px;">
                <div class="table__cell button">
                  <div v-if="editPermission" class="clickable-button" style="padding-bottom: 5px;" @click="deleteDrawingData(data)">
                    <img
                        :title="$t('Buttons.delete')"
                        src="@/assets/img/icon_delete.svg"
                        alt="notification avatar">
                  </div>
                </div>
              </div>

            </div>

          </div>

        </div>

      </div>
    </div>

    <!-- Prize Games Information -->
    <div
        class="step__content vertical-space"
        v-if="prize"
    >
      <div class="step__inner">

        <div class="page-header">
          <p class="step__inner-header">{{ $t('PrizeText.gamesConfigurations') }}</p>

          <div class="buttons-block">
            <div class="page-header__outload">
              <p class="page-header__outload-text" @click="addNewGame">
                {{ $t('ListItemText.add_game') }}
              </p>
            </div>
          </div>
        </div>

        <div>

          <!-- Specific Games Configuration -->
          <div>

            <div class="grid-container-2" v-for="data in prize.games" :key="data">

              <div class="step__item select required width-full">
                <p class="step__item-text">
                  {{ $t('GameText.header') }}
                </p>
                <div :class="`dd object dd-game_${data.id}-filter required`">
                  <v-select
                      :placeholder="$t('FieldsText.select_from_list')"
                      :options="allGames"
                      :reduce="(option) => option.id"
                      label="name"
                      v-model="data.id"
                      :clearable="true"
                      :rules="[requiredSelectRules]"
                      :class="{ 'error--text--select': isEmpty(data.id) }"
                  >
                    <!-- eslint-disable-next-line vue/no-unused-vars  -->
                    <template #no-options="{ search, searching, loading }">
                      {{ $t('Errors.missing_options') }}
                    </template>
                  </v-select>
                  <div
                      class="dd-button required"
                      @click="openSelectOptions(`.dd-game_${data.id}-filter .vs__dropdown-toggle`)"
                  ></div>
                </div>
              </div>


              <div class="step__item" style="width: 30px;">
                <div class="table__cell button">
                  <div v-if="editPermission" class="clickable-button" style="padding-bottom: 5px;" @click="deleteGameData(data)">
                    <img
                        :title="$t('Buttons.delete')"
                        src="@/assets/img/icon_delete.svg"
                        alt="notification avatar">
                  </div>
                </div>
              </div>

            </div>

          </div>

        </div>

      </div>
    </div>

    <!--div
        class="step__content vertical-space"
        v-if="prize"
    >
        <div class="step__inner">
            <div class="step__inner-header">
                <p>{{ $t('PrizeText.registerOneCheck') }}</p>
            </div>

            <div>

              <div class="grid-container">

                <div class="step__item textarea required">
                    <p class="step__item-text">
                      {{ $t('PrizeText.goodsGroup') }}
                    </p>
                    <input class="step__item-input" type="text"
                           required oninvalid="this.setCustomValidity('Введите значение')" oninput="this.setCustomValidity('')"
                            v-model="prize.goodsGroup" :disabled="!(editPermission)">
                </div>

                <div class="step__item textarea">
                    <p class="step__item-text">
                      {{ $t('PrizeText.goodsGroupScore') }}
                    </p>
                    <input class="step__item-input" type="text" name="goodsGroupScore" required
                           oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.setCustomValidity('')"
                           oninvalid="this.setCustomValidity('Введите значение')"
                            v-model="prize.goodsGroupScore" :disabled="!(editPermission)">
                </div>

                <div class="step__item select">
                </div>

                <div class="step__item select">
                </div>

              </div>

            </div>
        </div>
    </div-->

    <!--div
        class="step__content registry"
        v-if="prize"
    >
        <div class="step__inner">
            <div class="step__inner-header">
                <p>
                    Регистрация нескольких чеков
                </p>
            </div>
            <div class="step__items">
                <div class="step__item textarea required">
                    <p class="step__item-text">
                        Количество кодов с  товаров из группы 1<span>*</span>
                    </p>
                    <input class="step__item-input" type="text" placeholder="Введите название сайта" v-bind:value="prize.codesQuantityFromPerGroupOne">
                </div>
                <div class="step__item select required">
                    <p class="step__item-text">
                        Группа товаров 1<span>*</span>
                    </p>
                    <input class="step__item-input" type="text" placeholder="Введите название сайта" v-bind:value="prize.groupOne">
                    <div class="step__item-button"></div>
                </div>
                <div class="step__item textarea">
                    <p class="step__item-text">
                        Количество баллов за регистрацию товара из группы 1
                    </p>
                    <input class="step__item-input" type="text" placeholder="Введите название сайта">
                </div>
                <div class="step__item button">
                    <p class="step__item-text">
                        + Добавить
                    </p>
                </div>
            </div>
        </div>
    </div-->

  <input id="getNewCertificates"
         class="file_input pointer"
         type="file" name="fields[assetFieldHandle][]"
         hidden
         @change="onGetNewCertificates" ref="get_certificates_file" accept="text/csv" />

  <input id="addNewCertificates"
         class="file_input pointer"
         type="file" name="fields[assetFieldHandle][]"
         hidden
         @change="onAddNewCertificates" ref="add_certificates_file" accept="text/csv" />

</template>

<script>
import {mapActions, mapGetters} from "vuex";

import DateTimePicker from './DateTimePicker.vue'
import InputsMixin from "@/mixins/inputsEvent";

import {
  PrizesTypes,
  PrizesTypeText,

  Actions,
  ActionsForPrize,
  PaymentDirections,

  DrawingType,
  DrawingTypes,
  PrizesType,
  CertificateStatus,
} from "@/models/prizes";

import {hasContent, isEmpty} from "@/tools/dataTools";
import {parseFullTime} from "@/tools/dateTools";

export default {
  name: 'NewPromotionStepFourthSubStepFifth',
  emits: [
    "step",
    "updatePromotion",
    "openModalDeterminingInformation",
  ],
  components: {
      DateTimePicker,
  },
  mixins: [InputsMixin],
  props: {
    editPermission: Boolean,
    promotion: Object,
  },
  data() {
      return {
        CertificateStatus,
        DrawingType,

        item: {},
        prize: null,
        examplePrize: null,

        requiredSelectRules: [(v) => !!v || 'Выберите значение'],
      }
  },
  watch: {
    promotion: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler () {
        this.item = this.promotion

        // add stup prizes to view UI
        // this.item.prizes = this.item.prizes.concat(this.prizes)
        // console.log(this.item.prizes)
      }
    },
  },
  methods: {
    ...mapGetters(["GET_LIST_ITEMS", 'GET_LIST_GAMES']),
    ...mapActions(["UPLOAD_PRIZE_FILES"]),

    parseFullTime,
    isEmpty,

    PrizesTypes,
    PrizesTypeText,

    Actions,
    ActionsForPrize,
    PaymentDirections,
    DrawingTypes,

    textShow(event) {
        if (event.target.value.length > 33) {
            //console.dir(event.target.parentElement.querySelector(".step__item-popup"))
            event.target.parentElement.querySelector(".step__item-popup").classList.add("not-empty");
            event.target.parentElement.querySelector(".step__item-popup p").innerText = event.target.value;
        }
    },

    changeCurrentID(item) {
      if(item) {
        if(!this.prize || this.prize.id !== item.id)
          this.prize = this.item.prizes.find(p => p.id === item.id)
        else
          this.prize = null
      }
      else
        this.prize = null

      if(this.prize && !this.prize.drawingEveryday) {
        this.prize.drawingEveryday = {
          // date
          applicatonForPromoStartedAt: null,
          // date
          applicatonForPromoEndedAt: null,
          // date
          drawingPromoAt: null,
          // integer
          numberOfPrizes: 1,
          // bool
          moveRemainingPrizesToNextDrawing: false,
        }
      }

      this.examplePrize = null
    },

    deletePrize(prize) {
      if(prize) {
        if (
            confirm(this.$t('PrizeText.delete'))
        ) {
          this.item.prizes = this.item.prizes.filter(p => p.id !== prize.id)

          if(this.prize && this.prize.id === prize.id) {
            process.nextTick(() => {
              this.prize = null
            });
          }

          this.$emit("updatePromotion", this.item)

          this.$notify({
            type: "success",
            title: this.$t('Errors.success_operation'),
            text: this.$t('PrizeText.delete_success'),
          });
        }
      }
    },

    addNewDrawing() {
      if (this.item) {
        if(!this.checkDrawingData()) {
          this.$notify({
            type: "error",
            title: this.$t('Errors.error_not_ok'),
            text: this.$t('Errors.missing_required_data'),
          });

          return
        }

        if(!this.prize.drawingSpecificDates)
          this.prize.drawingSpecificDates = []

        this.prize.drawingSpecificDates.push({
          // date
          applicatonForPromoStartedAt: null,
          // date
          applicatonForPromoEndedAt: null,
          // date
          drawingPromoAt: null,

          // integer
          numberOfPrizes: 1,

          // bool
          moveRemainingPrizesToNextDrawing: false,
        })
      }
    },

    checkDrawingData() {
      let success = true

      if(!this.prize.drawingSpecificDates)
        this.prize.drawingSpecificDates = []

      for (let i = 0; i < this.prize.drawingSpecificDates.length; i++) {
        let item = this.prize.drawingSpecificDates[i]
        if (
            isEmpty(item.applicatonForPromoStartedAt) ||
            isEmpty(item.applicatonForPromoEndedAt) ||
            isEmpty(item.drawingPromoAt) ||
            isEmpty(item.numberOfPrizes) ||
            isEmpty(item.moveRemainingPrizesToNextDrawing)
        )
          success = false
      }


      return success
    },

    deleteGameData(data) {
      if (this.prize && data) {
        if(!this.prize.games)
          this.prize.games = []

        this.prize.games = this.prize.games.filter((item) => item !== data)
      }
    },

    addNewGame() {
      if (this.item) {
        if(!this.checkGameData()) {
          this.$notify({
            type: "error",
            title: this.$t('Errors.error_not_ok'),
            text: this.$t('Errors.missing_required_data'),
          });

          return
        }

        if(!this.prize.games)
          this.prize.games = []

        this.prize.games.push({
          // string
          id: null
        })
      }
    },

    checkGameData() {
      let success = true

      if(!this.prize.games)
        this.prize.games = []

      for (let i = 0; i < this.prize.games.length; i++) {
        let item = this.prize.games[i]
        if (
            isEmpty(item.id)
        )
          success = false
      }


      return success
    },

    updateDatesApplicatonForPromo(from, to) {
      if(this.prize) {
        this.prize.drawingEveryday.applicatonForPromoStartedAt = from
        this.prize.drawingEveryday.applicatonForPromoEndedAt = to
      }
    },

    updateDatesDrawingForPromo(from, to) {
      if(this.prize) {
        this.prize.drawingEveryday.drawingPromoStartedAt = from
        this.prize.drawingEveryday.drawingPromoEndedAt = to
      }
    },

    updateDatesForDrawing(from, to, object) {
      if(this.prize && this.prize.drawingSpecificDates) {
        let item = this.prize.drawingSpecificDates.find((item) => item === object)

        if(item) {
          item.applicatonForPromoStartedAt = from
          item.applicatonForPromoEndedAt = to
        }
      }
    },

    setPrizeSelectedData() {
      if(hasContent(this.examplePrize)) {
        let selectedPrize = this.allPrizes.find(p => p.id === this.examplePrize)

        if(hasContent(selectedPrize)) {
          this.prize.name = selectedPrize.info.name;
          this.prize.category = selectedPrize.info.category;
          this.prize.type = selectedPrize.info.type;
          this.prize.price = selectedPrize.info.price;
          this.prize.comission = selectedPrize.info.comission;
        }
      }
    },

    openModalDeterminingInformation(determining) {
      this.$emit("openModalDeterminingInformation", determining)
    },

    deleteDrawingData(data) {
      if (this.prize && data) {
        if(!this.prize.drawingSpecificDates)
          this.prize.drawingSpecificDates = []

        this.prize.drawingSpecificDates = this.prize.drawingSpecificDates.filter((item) => item !== data)
      }
    },

    getNewCertificates() {

      // no any used certificate - just ask to replace existing data
      if (confirm(this.$t('PrizeText.replaceCertificateFilesConfirmation'))) {
        document.getElementById('getNewCertificates').click()
      }
    },

    async onGetNewCertificates() {
      let file = this.$refs.get_certificates_file.files[0];

      // prepare csv file
      let response = await this.uploadPrizeFile(file, true)

      if(response && response.success) {
        // update number of prizes certificates
        this.prize.certificates = response.count
      }
    },

    addNewCertificates() {
      document.getElementById('addNewCertificates').click()
    },

    async onAddNewCertificates() {
      let file = this.$refs.add_certificates_file.files[0];

      // prepare csv file
      let response = await this.uploadPrizeFile(file, false)

      if(response && response.success) {
        // update number of prizes certificates
        this.prize.certificates = response.count
      }
    },



    async uploadPrizeFile(file, replace) {
      return new Promise(resolve => {
        if(file == null) {
          this.$notify({
            type: "error",
            title: this.$t('Errors.error_not_ok'),
            text: this.$t('Errors.error_read_file'),
          });

          resolve({success: false});
        }
        else {
          this.$notify({
            id: 'uploadPrizesCertificates',
            duration: 9999999999,
            type: "notification",
            title: this.$t('Errors.wait_finish'),
            text: this.$t('PrizeText.prepare_doc_started'),
          });
        }

        let formData = new FormData();

        formData.append("file", file);
        formData.append("promotion", this.promotion ? this.promotion.id : null);
        formData.append("prize", this.prize ? this.prize.id : null);
        formData.append("replace", replace ? replace : false);

        this.isLoading = true

        this.UPLOAD_PRIZE_FILES( {
          promotion: this.promotion ? this.promotion.id : null,
          prize: this.prize ? this.prize.id : null,
          replace: replace ? replace : false,
          file: formData,
        }).then((res) => {
          this.isLoading = false
          this.$notify.close('uploadPrizesCertificates');

          if (res.success) {
            this.$notify({
              type: "success",
              title: this.$t('Errors.success_operation'),
              text: this.$t('Errors.file_is_prepared'),
            });

            resolve({success: true, count: res.count});
          } else {
            this.$notify({
              type: "error",
              title: this.$t('Errors.error_not_ok'),
              text: this.$t('Errors.error_try_again'),
            });

            resolve({success: false});
          }
        });

      });
    },

  },
  computed: {
    PrizesType() {
      return PrizesType
    },
    allPrizes() {
      return this.GET_LIST_ITEMS().prizes ? this.GET_LIST_ITEMS().prizes : [];
    },
    prizeExamples() {
      let items = this.allPrizes;

      let response = []
      items.map(v => response.push({id: v.id, name: v.info.name}));

      return response
    },
    allDetermining() {
      let items = this.GET_LIST_ITEMS().determining ? this.GET_LIST_ITEMS().determining : [];
      items = JSON.parse(JSON.stringify(items))

      items.map((item) => {
        item.name = item.info.name
      })

      return items
    },
    allCategories() {
      let items = [
          this.$t('PrizeCategories.guaranteed'),
          this.$t('PrizeCategories.daily'),
          this.$t('PrizeCategories.weekly'),
          this.$t('PrizeCategories.monthly'),
          this.$t('PrizeCategories.main')
      ]

      return items
    },
    allGames() {
      let items = this.GET_LIST_GAMES();
      items = JSON.parse(JSON.stringify(items))

      return items
    },
    determiningExamples() {
      let items = this.allDetermining;

      let response = []
      items.map(v => response.push(v.link));

      return response
    },
    totalPrizeValue() {
      return this.prize ? parseInt(this.prize.price ? this.prize.price : 0) * parseInt(this.prize.count ? this.prize.count : 0) : 0
    },
  },
}
</script>

<style lang="scss" src="@/assets/scss/common.scss"></style>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixin.scss";

.dd {
  position: relative;
  width: 100%;
  &.object {
    width: 265px !important;
    margin-top: 0px !important;
    font-size: 12px;
    font-weight: 500;
    // .dd-button {
    //   height: 27px !important;
    // }
  }
  .dd-button {
    @include ui-dropdown-button();
    border-radius: 0px 5px 5px 0px;
  }
  & ::v-deep .vs__dropdown-toggle {
    border: 1px solid $inputCaption;
    box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
  &:not(:first-child) {
    margin-top: 10px;
  }
}

.date-picker {
  align-items: center;
  .d-comp {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    opacity: 0;
    background: black;
    // z-index: 99999;
  }
  .date-start {
    position: relative;
    input {
      @include ui-input($pl: 11px, $w: 265px);
    }
  }
  .date-pick {
    position: relative;
  }
}

.buttons-block {
   padding-bottom: 0;
   &__test {
     @include white-button;
     width: 100%;
     padding-left: 10px;
     padding-right: 10px;
     /* set the bottom to 0*/
     margin-top: 35px;
   }
 }

</style>
