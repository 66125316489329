import store from "@/store";
import {hasContent} from "@/tools/dataTools";


export const DeterminingType = {
    FORMULA: 'formula',
    LINK: 'link',
};

export const DeterminingItem = {
    // UUID
    id: '',
    // JSON
    info: {
        // string
        name: '',
        // string
        link: '',
        // string
        type: DeterminingType.FORMULA,
    },
    // json
    history: [],
};

export const NewDeterminingItem = () => {
    let item = JSON.parse(JSON.stringify(DeterminingItem))

    return item
};

export const DeterminingItemFromBody = (data) => {
    let object = NewDeterminingItem()

    object.id = data.id;
    object.info = JSON.parse(JSON.stringify(data.info));
    object.history = data.history;

    return object;
};


export const DeterminingItemRequiredVerify = (determining) => {
    if(hasContent(determining)) {
        if(hasContent(determining.id)) {
            return !!(hasContent(determining.info.name) &&
              hasContent(determining.info.type) &&
              hasContent(determining.info.link));
        }
        else {
            return !!(hasContent(determining.info.name) &&
              hasContent(determining.info.type) &&
              hasContent(determining.info.link));
        }
    }
    else {
        return false;
    }
};


export const DeterminingTypes = () => {
    return [
        {
            type: DeterminingType.FORMULA,
            label: store.state.$t('DeterminingText.formula'),
        },
        {
            type: DeterminingType.LINK,
            label: store.state.$t('DeterminingText.link'),
        },
    ]
};

export const DeterminingTypeText = (type) => {
    switch (type) {
        case DeterminingType.FORMULA:
            return store.state.$t('DeterminingText.formula');
        case DeterminingType.LINK:
            return store.state.$t('DeterminingText.link');
        default:
            return store.state.$t('DeterminingText.unknown');
    }
};

// export module
export default {
    DeterminingItem,
    NewDeterminingItem,
    DeterminingItemFromBody,

    DeterminingItemRequiredVerify,

    DeterminingType,
    DeterminingTypes,
    DeterminingTypeText,
};
