<template>
  <div>

    <div class="grid-container" style="background: #ffffff; border-radius: 10px">

      <div class="step__item select">
        <p class="step__item-text">
          {{ $t('PhonesText.operator') }}
        </p>
        <div class="dd object dd-operator-filter">
          <v-select
              :placeholder="$t('FieldsText.select_from_list')"
              :options="operators"
              :reduce="(option) => option.id"
              label="name"
              :clearable="true"
              v-model="filterData.operator"
          >
            <!-- eslint-disable-next-line vue/no-unused-vars  -->
            <template #no-options="{ search, searching, loading }">
              {{ $t('Errors.missing_options') }}
            </template>
          </v-select>
          <div
              class="dd-button"
              @click="openSelectOptions('.dd-operator-filter .vs__dropdown-toggle')"
          ></div>
        </div>
      </div>

      <div class="step__item select required">
        <p class="step__item-text">
          {{ $t('PhonesText.select_operation') }}
        </p>
        <div class="dd object dd-promotion-filter">
          <v-select
              :placeholder="$t('ReportText.statistic')"
              :options="StatisticsTypes()"
              :reduce="(option) => option.type"
              label="label"
              :clearable="false"
              v-model="filterData.type"
          >
            <!-- eslint-disable-next-line vue/no-unused-vars  -->
            <template #no-options="{ search, searching, loading }">
              {{ $t('Errors.missing_options') }}
            </template>
          </v-select>
          <div
              class="dd-button"
              @click="openSelectOptions('.dd-promotion-filter .vs__dropdown-toggle')"
          ></div>
        </div>
      </div>

      <div v-if="filterData.type !== StatisticsType.BALANCE" class="step__item period required">
        <p class="step__item-text">
          {{ $t('ReportText.period_req') }}
        </p>
        <DateTimePicker
            :start="filterData.startedAt"
            :end="filterData.endedAt"
            @updateDates="updateDatesStartEnd"
        />
      </div>

      <div class="page-header__newcode"
           style="margin-top: 19px"
           @click="getOperatorStatistics"
      >
        <p class="page-header__newcode-text">
          {{ $t('PhonesText.get_statistics') }}
        </p>
      </div>

    </div>

    <div class="table" style="margin-top: 30px">
        <div class="table__row title">
          <p class="table__cell">{{ $t('TableHeaders.date') }}</p>
          <p class="table__cell">{{ $t('TableHeaders.sms_message') }}</p>
          <p class="table__cell">{{ $t('TableHeaders.status') }}</p>
          <p class="table__cell">{{ $t('TableHeaders.sms_target') }}</p>
          <p class="table__cell">{{ $t('TableHeaders.sms_sender') }}</p>
        </div>
        <div class="table__body">
            <div
                v-for="n in statistics" :key="n.id"
                class="table__row"
            >
              <div class="table__cell">
                  <p>{{ n.CREATED }}</p>
              </div>
              <div class="table__cell">
                <p>{{ n.SMS_TEXT }}</p>
              </div>
              <div class="table__cell">
                <p>{{ n.SMS_STATUS }}</p>
              </div>
              <div class="table__cell">
                  <p>{{ n.SMS_TARGET }}</p>
              </div>
              <div class="table__cell">
                <p>{{ n.SMS_SENDER }}</p>
              </div>

            </div>
        </div>
    </div>

  </div>

</template>

<script>

import {mapActions, mapGetters} from "vuex";
import {hasContent} from "@/tools/dataTools";

import {PassportIsFull} from "@/models/users";
import {StatisticsType, StatisticsTypes} from "@/models/operators";

import DateTimePicker from "@/components/DateTimePicker.vue";
import {filter} from "core-js/internals/array-iteration";
import InputsMixin from "@/mixins/inputsEvent";
import {parseDateWithDates} from "@/tools/dateTools";

export default {
  name: 'StatisticsOperatorModal',
  components: {
    DateTimePicker
  },
  mixins: [InputsMixin],
  props: {
    editPermission: Boolean,
    search: String,
  },
  data() {
    return {
      StatisticsType,

      isLoading: false,

      filterData: {
        type: StatisticsType.SENT,
        operator: null,
        startedAt: null,
        endedAt: null,
      },

    }
  },
  mounted() {
    this.filterData = {
      type: this.operator_filter.type,
      operator: this.operator_filter.operator,
      startedAt: this.operator_filter.startedAt,
      endedAt: this.operator_filter.endedAt,
    }

    this.getOperatorStatistics()
  },
  computed: {
    statistics() {
      let items = this.GET_LIST_PHONES() ?
          this.GET_LIST_PHONES().statistics.filter(
              (v) =>
                  `${v.name ? v.name : ''} ${v.link ? v.link : ''} `
                      .toLowerCase()
                      .indexOf(this.search.toLowerCase()) !== -1
          )
          : [];

      // sort all items by date - new items on top
      items.sort(function (a, b) {
        return b.CREATED.localeCompare(a.CREATED)
      });

      return items;
    },
    operators() {
      return this.GET_LIST_PHONES() ? this.GET_LIST_PHONES().operators : []
    },
    operator_filter() {
      return this.GET_LIST_PHONES() ? this.GET_LIST_PHONES().filter : {}
    },
  },
  watch: {
    isLoading() {
      if(this.isLoading)
        this.$progress.start();
      else
        this.$progress.finish();
    },
  },
  methods: {
    filter,
    ...mapGetters(["GET_LIST_PHONES"]),
    ...mapActions(["SET_LIST_PHONES_FILTER","SET_LIST_PHONES_STATISTICS","GET_OPERATOR_STATISTICS", "GET_OPERATOR_BALANCE"]),

    PassportIsFull,

    StatisticsTypes,

    editStatisticsInfo(prize) {
      if(hasContent(prize))
        this.$emit('edit', prize)
    },

    updateDatesStartEnd(from, to) {
      this.filterData.startedAt = from
      this.filterData.endedAt = to
    },

    getOperatorStatistics() {
      // save current statistics filters
      this.SET_LIST_PHONES_FILTER({
        ...this.operator_filter,
        operator: this.filterData.operator,
        type: this.filterData.type,
        startedAt: this.filterData.startedAt,
        endedAt: this.filterData.endedAt,
      });

      if (this.filterData.operator) {
        if (this.filterData.type === StatisticsType.BALANCE) {
          this.getOperatorBalance()
        }
        else if (this.filterData.startedAt && this.filterData.endedAt) {
          // get operator statistics
          this.isLoading = true

          this.GET_OPERATOR_STATISTICS({
            type: this.filterData.type,
            operator: this.filterData.operator,
            startedAt: this.filterData.startedAt ? parseDateWithDates(this.filterData.startedAt) : this.filterData.startedAt,
            endedAt: this.filterData.endedAt ? parseDateWithDates(this.filterData.endedAt) : this.filterData.endedAt,
          }).then((res) => {
            this.isLoading = false

            if (res.success && res.statistics) {

              if(res.statistics.success) {
                const data = res.statistics.data.output

                // prepare inbox statistics
                //if(this.filterData.type === StatisticsType.RECEIVED) {

                  // check errors
                  if (data.errors) {
                    this.SET_LIST_PHONES_STATISTICS([]);

                    this.$notify({
                      type: "error",
                      title: this.$t('Errors.error_not_ok'),
                      text: data.errors.error ? data.errors.error.$t : this.$t('Errors.error_get_data'),
                    });
                  }
                  // prepare output
                  else {
                    let messages = []

                    if (data.MESSAGES && data.MESSAGES.MESSAGE) {

                      // check if we have an array
                      if(data.MESSAGES.MESSAGE.length) {
                        for(let i = 0; i < data.MESSAGES.MESSAGE.length; i++) {
                          messages.push(data.MESSAGES.MESSAGE[i])
                        }
                      }
                      else {
                        messages.push(data.MESSAGES.MESSAGE)
                      }
                    }

                    // save current statistics items
                    this.SET_LIST_PHONES_STATISTICS(messages);
                  }
              }
              else {
                this.SET_LIST_PHONES_STATISTICS([]);

                this.$notify({
                  type: "error",
                  title: this.$t('Errors.error_not_ok'),
                  text: res.statistics.message ? res.statistics.message : this.$t('Errors.error_get_data'),
                });
              }

            } else {
              this.$notify({
                type: "error",
                title: this.$t('Errors.error_not_ok'),
                text: res.message ? res.message : this.$t('Errors.error_try_again'),
              });
            }
          });

        }
        else {
          this.$notify({
            type: "error",
            title: this.$t('Errors.not_success_operation'),
            text: this.$t('PhonesText.select_period'),
          });
        }
      }
      else {
        this.$notify({
          type: "error",
          title: this.$t('Errors.not_success_operation'),
          text: this.$t('PhonesText.select_operator'),
        });
      }
    },

    getOperatorBalance() {
      if (this.filterData.operator) {
        // get operator balance
        this.isLoading = true

        this.GET_OPERATOR_BALANCE({
          operator: this.filterData.operator,
        }).then((res) => {
          this.isLoading = false

          if (res.success && res.statistics) {

            if(res.statistics.success) {
              const data = res.statistics.data.output

              // check errors
              if (data.errors) {

                this.$notify({
                  type: "error",
                  title: this.$t('Errors.error_not_ok'),
                  text: data.errors.error ? data.errors.error.$t : this.$t('Errors.error_get_data'),
                });
              }
              // prepare output
              else {

                this.$notify({
                  type: "success",
                  title: this.$t('Errors.success_operation'),
                  text: `${this.$t('PhonesText.operator_balance')}: ${data.balance ? data.balance.AGT_BALANCE : '0'}`,
                });
              }
            }
            else {
              this.$notify({
                type: "error",
                title: this.$t('Errors.error_not_ok'),
                text: res.statistics.message ? res.statistics.message : this.$t('Errors.error_get_data'),
              });
            }

          } else {
            this.$notify({
              type: "error",
              title: this.$t('Errors.error_not_ok'),
              text: res.message ? res.message : this.$t('Errors.error_try_again'),
            });
          }
        });
      }
      else {
        this.$notify({
          type: "error",
          title: this.$t('Errors.not_success_operation'),
          text: this.$t('PhonesText.select_operator'),
        });
      }
    }

  },
}
</script>

<style lang="scss" src="@/assets/scss/common.scss"></style>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixin.scss";

</style>
