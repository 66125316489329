<template>
    <div class="new-handbook">
        <div class="new-handbook__inner">
            <div class="new-handbook__header">
              {{ $t('TipsText.new_tip') }}
            </div>
            <div class="new-handbook__columns">
                <div class="new-handbook__column">
                    <div class="new-handbook__item textarea long">
                        <p class="new-handbook__item-text">
                          {{ $t('TipsText.tip_name') }}
                        </p>
                        <input
                            class="new-handbook__item-input"
                            type="text"
                            v-model="name"
                            :placeholder="$t('TipsText.enter_tip_name')">
                    </div>
                </div>
            </div>
        </div>
        <div class="buttons-block">
            <div
                class="buttons-block__back"
                @click="$emit('close-modal')"
            >
                <p class="buttons-block__back-text">
                    {{ $t('Buttons.cancel') }}
                </p>
            </div>
            <div
                class="buttons-block__save"
                @click="addNewItem"
            >
                <p class="buttons-block__save-text">
                  {{ $t('Buttons.add') }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { hasContent } from "@/tools/dataTools";

export default {
  name: 'AddNewHandbookModal',
  components: {
  },
  data() {
    return {
      name: '',
    }
  },
  methods: {
    addNewItem() {
      if(hasContent(this.name)) {

        this.$emit('add', this.name)
        this.$emit('close-modal')
      }
    }
  }
}
</script>

<style lang="scss" src="@/assets/scss/common.scss"></style>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixin.scss";

.new-handbook {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $white;
    border-radius: 10px;
    padding: 50px 45px 40px 70px;
    box-shadow: 0px 0px 8px 2px rgba(206, 213, 244, 0.5);
    z-index: 1;
    &__inner {
        margin-bottom: 30px;
    }

    &__validation-rule {
        margin-bottom: 30px;
        span {
            color: $red;
        }
        &-back {
            @include white-button;
            width: 190px;
        }
        &-forward {

        }
    }

    &__header {
        width: 100%;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 30px;
        text-transform: uppercase;
    }

    .new-handbook__item {
        margin-bottom: 0;
        &-text {
            font-size: 12px;
            font-weight: 500;
            text-transform: uppercase;
        }
    }

    .buttons-block__save {
        margin-left: 30px;
        margin-right: 0;
    }
}

.new-handbook__columns {
    display: flex;
    flex-flow: row nowrap;
}

.new-handbook__column {
    &:first-child {
        margin-right: 30px;
    }
}

.new-handbook__item {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    width: 265px;
    margin-bottom: 20px;
    &-text {
        width: 100%;
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 5px;
        color: $black;
        span {
            color: $red;
        }
    }

    &-input {
        @include search-input;
    }

    &.textarea {
        grid-column: span 3;
        input {
            border-radius: 5px;
            width: 100%;
        }
    }

    &.select {
        .new-handbook__item-text {
            span {
                color: $red;
            }
    }

        .new-handbook__item-button {
            &::after {
                width: 9px;
                height: 7px;
                background: url('../assets/img/triangle.png');
            }
        }
    }

    &.long {
        width: 470px;
    }

    &-button {
        @include search-input__button;
    }
}

.buttons-block {
    height: fit-content;
    margin: 0 auto;
    &__back {
        @include white-button;
        width: 190px;
    }
    &__save {
        @include purple-button;
        width: 190px;
        margin-left: 0;
        margin-right: 30px;
    }
}

.new-handbook__checboxes {
    padding-top: 40px;
    &-text {
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: 20px;
    }
}

.new-handbook__checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  &:not(:last-child) {
      margin-bottom: 10px;
  }
  &-checkbox {
    display: none;
  }
  &-fake {
    display: inline-block;
    width: 17px;
    height: 17px;
    margin-right: 12px;
    border-radius: 2px;
    box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.25);
    position: relative;
    &::before {
      content: '✔';
      position: absolute;
      color: $black;
      font-size: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
    }
  }
  &-text {
    font-size: 12px;
    font-weight: 500;
    color: $activeText;
  }
  .new-handbook__checkbox-checkbox:checked + .new-handbook__checkbox-fake::before {
    opacity: 1;
  }
  span {
    color: $red;
  }
}

</style>
