<template>

  <ConfirmBillModal
      v-if="confirmBillModalOpened"
      @close-modal="confirmBillModalClose"
      @ok="confirmBillModalOk"
  />

    <div class="table">
        <div class="table__row title">
          <p class="table__cell">{{ $t('FieldsText.date_label') }}</p>
          <p class="table__cell">{{ $t('FieldsText.company_label') }}</p>
          <p class="table__cell">{{ $t('FieldsText.promotion_label') }}</p>
          <p class="table__cell">{{ $t('FieldsText.fio_label') }}</p>
          <p class="table__cell">{{ $t('TableHeaders.phone') }}</p>
          <p class="table__cell">{{ $t('TableHeaders.email') }}</p>
          <p class="table__cell">{{ $t('TableHeaders.status') }}</p>
          <p class="table__cell">{{ $t('TableHeaders.action') }}</p>
        </div>
        <div class="table__body">
            <div
                v-for="n in bills" :key="n.id"
                class="table__row"
            >
              <div class="table__cell">
                <p>{{ parseFullTime(n.createdAt) }}</p>
              </div>
                <div class="table__cell">
                    <p>{{ n.fk_promotion.fk_company.name }}</p>
                </div>
                <div class="table__cell">
                  <p>{{ n.fk_promotion.name }}</p>
                </div>
                <div class="table__cell">
                    <p>{{ `${n.fk_participant.fk_user.name} ${n.fk_participant.fk_user.surname} ${n.fk_participant.fk_user.lastname}` }}</p>
                </div>
                <div class="table__cell">
                  <p>{{ n.fk_participant.fk_user.phone ? n.fk_participant.fk_user.phone : '-' }}</p>
                </div>
                <div class="table__cell">
                  <p>{{ n.fk_participant.fk_user.email ? n.fk_participant.fk_user.email : '-' }}</p>
                </div>
                <div class="table__cell">
                  {{ BillStatusText(n.qrStatusCode) }}
                </div>

                <div class="table__cell button">

                  <div
                      class="table__cell-button"
                      @click="openBillFile(n)"
                  >
                    <img
                        :title="$t('BillText.showPhoto')"
                        src="@/assets/img/icons/info.svg">
                  </div>

                  <div
                      v-if="n.qrStatusCode === 11"
                      class="table__cell-button"
                      style="padding-left: 5px;"
                      @click="billIsNotOk(n)">
                    <img
                         :title="$t('BillText.deactivate')"
                         src="@/assets/img/icon_block.svg"
                         alt="notification avatar">
                  </div>

                  <div
                      v-if="n.qrStatusCode === 11"
                      class="table__cell-button"
                      style="padding-left: 5px;"
                      @click="billIsOk(n)">
                    <img
                        :title="$t('BillText.confirm')"
                        src="@/assets/img/icons/check-circle-outline.svg"
                        alt="notification avatar">
                  </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {hasContent} from "@/tools/dataTools";
import {PassportIsFull} from "@/models/users";
import {PrizesType, PrizesTypeText} from "@/models/prizes";
import {BillStatusText, PrizeStatus, PrizeStatusText} from "@/models/participants";
import {parseFullTime} from "@/tools/dateTools";
import {baseURL} from "@/common/config";
import ConfirmBillModal from "@/components/ConfirmBillModal.vue";

export default {
  name: 'ListsCheckBillsManual',
  emits: [
    "updateBillStatus",
  ],
  components: {
    ConfirmBillModal
  },
  props: {
    editPermission: Boolean,
    search: String,
    promotionBills: Array,
  },
  data() {
    return {
      PrizesType,
      PrizeStatus,

      confirmBillModalOpened: false,
      selectedBill: null,
    }
  },
  computed: {
    bills() {
      const items = this.promotionBills ?
          this.promotionBills.filter(
              (v) =>
                  `${v.fk_promotion.name} ${v.fk_participant.fk_user.surname ? v.fk_participant.fk_user.surname : ''} ${v.fk_participant.fk_user.name ? v.fk_participant.fk_user.name : ''} ${v.fk_participant.fk_user.phone ? v.fk_participant.fk_user.phone : ''}`
                      .toLowerCase()
                      .indexOf(this.search.toLowerCase()) !== -1
          )
          : [];

      return items
    },
  },
  watch: {
  },
  methods: {
    BillStatusText,
    parseFullTime,
    PrizeStatusText,
    PassportIsFull,
    PrizesTypeText,

    openBillFile(bill) {
      if(hasContent(bill) && hasContent(bill.billPhoto)) {
        this.$viewerApi({
          images: [`${baseURL}${bill.billPhoto}`],
        });
      }
      else {
        this.$notify({
          type: "error",
          title: this.$t('Errors.error_get_data'),
          text: this.$t('Errors.image_data_missing'),
        });
      }
    },

    billIsNotOk(bill) {
      if(hasContent(bill)) {
        if (
            confirm(this.$t('BillText.confirmDeactivateBill'))
        ) {
          const newBill = JSON.parse(JSON.stringify(bill))
          newBill.qrStatusCode = 12
          newBill.billScore = 0

          this.$emit('updateBillStatus', newBill)
        }
      }
      else {
        this.$notify({
          type: "error",
          title: this.$t('Errors.error_get_data'),
          text: this.$t('Errors.bill_data_missing'),
        });
      }
    },

    billIsOk(bill) {
      this.confirmBillModalOpened = true
      this.selectedBill = bill
    },

    confirmBillModalClose() {
      this.confirmBillModalOpened = false
      this.selectedBill = null
    },

    confirmBillModalOk(score) {
      if(this.selectedBill && score) {
        const newBill = JSON.parse(JSON.stringify(this.selectedBill))
        newBill.qrStatusCode = 1
        newBill.billScore = score

        this.$emit('updateBillStatus', newBill)
      }
      else {
        this.$notify({
          type: "error",
          title: this.$t('Errors.error_get_data'),
          text: this.$t('Errors.bill_data_missing'),
        });
      }

      // clear data
      this.confirmBillModalOpened = false
      this.selectedBill = null
    },

  },
}
</script>

<style lang="scss" src="@/assets/scss/common.scss"></style>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixin.scss";


</style>
