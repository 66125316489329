<template>
    <div class="step__content no-prizes">
        <div class="step__inner">
            <div class="prizes-empty">
                <img class="prizes-empty__image" src="@/assets/img/shopping_cart.svg" alt="empty prisez image">
                <p class="prizes-empty__text">
                    Необходимо добавить хотя бы один магазин и товар.
                </p>
            </div>
        </div>
    </div>
</template>

<script>


export default {
  name: 'NewPromotionStepStoresEmpty',
  components: {
  },
}
</script>

<style lang="scss" src="@/assets/scss/common.scss"></style>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixin.scss";

</style>