<template>
  <div class="container">
    <notifications position="bottom right"/>
    <vue3-progress />
    <Header
      v-if="this.$route.name !== 'Login'"
    />
    <main>
      <LeftSideBar
        v-if="this.$route.name !== 'Login'"
      />
      <router-view/>
    </main>
  </div>
</template>

<script>
import Header from './components/Header.vue'
import LeftSideBar from './components/LeftSideBar.vue'

import { mapActions, mapGetters } from "vuex";
import { subscribeForUpdates } from "./common/utils/pool.js";

export default {
  name: 'App',
  components: {
    Header,
    LeftSideBar
  },
  data() {
    return {
      currentPage: this.getCurrentPage(this.$router.options.history.state.current),
    };
  },
  watch: {
    $route(to) {
      this.currentPage = this.getCurrentPage(to.name);
    },
    "$store.state.user": function (newValue, oldValue) {
      if (
          !this.$store.state.user &&
          this.$router.currentRoute.name !== "Login"
      ) {
        this.$router.push({ name: "Login" }).catch(() => {});
      } else if (!oldValue && newValue && this.user?.active) {

        this.subscribeToPoolEvents()
        this.getAllDbData()
      }
    },
    // eslint-disable-next-line no-unused-vars
    "$store.state.employees.filter": function (newValue, oldValue) {
      if(this.user) {
        this.getSpecificCompaniesData()
      }
    },
  },
  methods: {
    ...mapGetters(["GET_USER", "GET_EMPLOYEES", "GET_PROMOTIONS", "GET_STATISTICS"]),
    ...mapActions([
      "SET_NOTIFICATION_FUNCTION",
      "SET_TRANSLATE_FUNCTION",
      "GET_USER_CURRENT_INFO",
      "EXIT_FROM_ACCOUNT",
      "GET_MAIN_STATISTICS",
      "GET_LAST_NOTIFICATIONS",
      "GET_USERS_FROM",
      "GET_PROMOTIONS_FROM",
      "GET_TIPS_FROM",
      "GET_SUSPICIOUS_FROM",
      "GET_COMPANIES_FROM",
      "ADD_PROMOTION_MESSAGE",
      "CHANGE_USER",
      "ADD_USER_MESSAGE",
      "CHANGE_COMPANY",
      "ADD_COMPANY_MESSAGE",
      "CHANGE_COMPANY_USER",
      "SET_PROMOTIONS_FILTER",
      "CHANGE_PROMOTION",
      "CHANGE_TIP",
      "CHANGE_SUSPICIOUS",
      "CHANGE_CODE",
      "GENERATE_CODE",
      "GENERATE_CODE_QR",
      "GET_PRIZES",
      "GET_REPORTS_FROM",
      "GET_OPERATORS",
      "CHANGE_DRAWING",
    ]),
    getCurrentPage(routeName) {
      const pages = {
        '/': 'Home',
        '/accounts': 'Accounts',
        '/bookkeeping': 'Bookkeeping',
        '/codes': 'Codes',
        '/companies': 'Companies',
        '/handbooks': 'Handbooks',
        '/lists': 'Lists',
        '/promotions': 'Promotions',
        '/reports': 'Reports',
        '/suspicious-participants': 'SuspiciousParticipants',
        '/login': 'Login',
        '/phones': 'Phones',
      };

      return pages[routeName];
    },
    translateMe(val){
      return this.$t(val)
    },
    showNotification({type, title, text}){
      return this.$notify({
        type: type,
        title: title,
        text: text,
      });
    },

    subscribeToPoolEvents() {
      subscribeForUpdates(this.user.token, {
        ADD_PROMOTION_MESSAGE: this.ADD_PROMOTION_MESSAGE,
        CHANGE_USER: this.CHANGE_USER,
        ADD_USER_MESSAGE: this.ADD_USER_MESSAGE,
        CHANGE_COMPANY: this.CHANGE_COMPANY,
        ADD_COMPANY_MESSAGE: this.ADD_COMPANY_MESSAGE,
        CHANGE_COMPANY_USER: this.CHANGE_COMPANY_USER,
        CHANGE_PROMOTION: this.CHANGE_PROMOTION,
        CHANGE_TIP: this.CHANGE_TIP,
        CHANGE_SUSPICIOUS: this.CHANGE_SUSPICIOUS,
        CHANGE_CODE: this.CHANGE_CODE,
        GENERATE_CODE: this.GENERATE_CODE,
        GENERATE_CODE_QR: this.GENERATE_CODE_QR,
        CHANGE_DRAWING: this.CHANGE_DRAWING,
      });
    },

    getAllDbData() {
      this.GET_MAIN_STATISTICS();
      //this.GET_LAST_NOTIFICATIONS(this.user.token);
      this.GET_TIPS_FROM();
      this.GET_COMPANIES_FROM();
      this.GET_USERS_FROM();
      this.GET_PRIZES({ });
      this.GET_REPORTS_FROM({ });
      this.GET_OPERATORS({ });
    },

    getSpecificCompaniesData() {
      this.GET_PROMOTIONS_FROM({
        company: this.employees_filter ? this.employees_filter.company : null
      });
      this.GET_SUSPICIOUS_FROM({
        company: this.employees_filter ? this.employees_filter.company : null
      });
    }
  },
  mounted() {
    // set translate function global
    this.SET_NOTIFICATION_FUNCTION(this.showNotification)
    this.SET_TRANSLATE_FUNCTION(this.translateMe)

    // other function
    if (this.user?.token) {
      this.GET_USER_CURRENT_INFO(this.user.token).then((res) => {
        if (!res.success) {
          this.EXIT_FROM_ACCOUNT();
        } else if(this.user?.active) {

          this.subscribeToPoolEvents()
          this.getAllDbData()
        }
      });
    } else if (this.$router.currentRoute.name !== "Login") {
      this.$router.push({ name: "Login" }).catch(() => {});
    }
  },
  computed: {
    user() {
      return this.GET_USER();
    },
    employees_filter() {
      return this.GET_EMPLOYEES().filter;
    },
  },
}
</script>

<style lang="scss" src="@/assets/scss/common.scss"></style>
<style lang="scss">
@import "@/assets/scss/_variables.scss";

main {
  display: flex;
  flex-flow: row nowrap;
  padding-right: 58px;
}

.container {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
}

</style>
