<template>
  <section class="suspicious-participants">
    <div class="page-header">
      <h2 class="page-header__title">
        {{ $t('SuspiciousText.suspicious_users') }}
      </h2>
      <div class="buttons-block">
        <div v-if="suspiciousParticipants && suspiciousParticipants.length > 0"
          class="page-header__outload"
        >
          <img
              width="20" height="16"
              src="@/assets/img/bi_cloud_download.svg"
              alt="notification avatar">
          <p class="page-header__outload-text" @click="downloadSuspiciousList('xls')">
            {{ $t('Buttons.download_xls') }}
          </p>
        </div>
      </div>
    </div>

    <div class="buttons-panel">

      <div class="grid-container-3 width-full">

        <div class="step__item search">
          <p class="step__item-text">
            {{ $t('InterfaceText.search') }}
          </p>
          <input type="text" class="buttons-panel__input-input" :placeholder="$t('FieldsText.enter_request')"
                 :value="filterData.search"
                 @input="setListsSearch">
          <div class="buttons-panel__input-button"></div>
        </div>

        <div
            class="step__item select"
            v-if="false"
        >
          <p class="step__item-text">
            {{ $t('FieldsText.promotion_label') }}
          </p>
          <div class="dd object dd-role-filter">
            <v-select
                :placeholder="$t('FieldsText.select_from_list')"
                :options="promotions"
                :reduce="(option) => option.id"
                label="name"
                :clearable="false"
                v-model="filterData.promotion"
                @option:selected="setListsPromotion"
            >
              <!-- eslint-disable-next-line vue/no-unused-vars  -->
              <template #no-options="{ search, searching, loading }">
                {{ $t('Errors.missing_options') }}
              </template>
            </v-select>
            <div
                class="dd-button"
                @click="openSelectOptions('.dd-role-filter .vs__dropdown-toggle')"
            ></div>
          </div>
        </div>

        <div
            class="step__item select"
        >
          <p class="step__item-text">
            {{ $t('TableHeaders.status') }}
          </p>
          <div class="dd object dd-tax-filter">
            <v-select
                :placeholder="$t('FieldsText.select_from_list')"
                :options="userStatuses"
                :reduce="(option) => option.status"
                label="label"
                :clearable="false"
                v-model="filterData.userStatus"
                @option:selected="setListsUserStatus"
            >
              <!-- eslint-disable-next-line vue/no-unused-vars  -->
              <template #no-options="{ search, searching, loading }">
                {{ $t('Errors.missing_options') }}
              </template>
            </v-select>
            <div
                class="dd-button"
                @click="openSelectOptions('.dd-tax-filter .vs__dropdown-toggle')"
            ></div>
          </div>
        </div>

      </div>

    </div>

    <div class="table">
      <div class="table__row title">
        <!--p class="table__cell">{{ $t('FieldsText.profile_avatar') }}</p-->
        <!--p class="table__cell">{{ $t('FieldsText.surname_label') }}</p>
        <p class="table__cell">{{ $t('FieldsText.name_label') }}</p>
        <p class="table__cell">{{ $t('FieldsText.lastname_label') }}</p-->
        <p class="table__cell">{{ $t('FieldsText.fio_label') }}</p>
        <p class="table__cell">{{ $t('SuspiciousText.block') }}</p>
        <p class="table__cell">{{ $t('FieldsText.promotion_label') }}</p>
        <p class="table__cell">{{ $t('SuspiciousText.block_cause') }}</p>
        <p class="table__cell">{{ $t('TableHeaders.action') }}</p>
      </div>
      <div class="table__body">
        <div
          v-for="item in showParticipants.slice((page - 1) * itemsPerPage, (page * itemsPerPage))" :key="item.id"
          class="table__row"
        >
          <!--div class="table__cell">
            <img class="table__cell-image" src="@/assets/img/table-img.png" alt="table image">
          </div>
          <div class="table__cell">
            <p>
              {{ item.fk_user.surname }}
            </p>
          </div>
          <div class="table__cell">
            <p>
              {{ item.fk_user.name }}
            </p>
          </div>
          <div class="table__cell">
            <p>
              {{ item.fk_user.lastname }}
            </p>
          </div-->
          <div class="table__cell">
            <p>{{ `${item.fk_user.name} ${item.fk_user.surname} ${item.fk_user.lastname}` }}</p>
          </div>
          <div class="table__cell">
            <p>
              {{ parseFullTime(item.createdAt) }}
            </p>
          </div>
          <div class="table__cell">
            <p>
              {{ item.fk_promotion ? item.fk_promotion.name : '-' }}
            </p>
          </div>
          <div class="table__cell">
            <p>
              {{ ScamsStatusText(item.status) }}
            </p>
          </div>
          <div class="table__cell button">

            <div v-if="editPermission" class="table__cell-button" style="padding-left: 5px;" @click="openModalAcceptEmp(item)">
              <img
                 :title="$t('SuspiciousText.remove')"
                 width="20" height="20"
                 src="@/assets/img/user_activate.svg" alt="">
            </div>

            <div v-if="editPermission && item.fk_participant" class="table__cell-button" style="padding-left: 5px;" @click="openModalBlockParticipant(item.fk_participant)">
              <img v-if="item.fk_participant ? (item.fk_participant.status === ParticipantsStatus.ACTIVE) : true"
                   :title="$t('SuspiciousText.deactivate_from_promotion')"
                   width="20" height="20"
                   src="@/assets/img/icon_block.svg" alt="">
              <img v-else
                   :title="$t('SuspiciousText.activate_from_promotion')"
                   width="20" height="20"
                   src="@/assets/img/icon_blocked.svg" alt="">
            </div>

            <div v-if="editPermission && item.fk_user" class="table__cell-button" style="padding-left: 5px;" @click="openModalBlockEmp(item.fk_user)">
              <img v-if="item.fk_user ? item.fk_user.active : true"
                   :title="$t('SuspiciousText.deactivate_user')"
                   width="20" height="20"
                   src="@/assets/img/user_block.svg" alt="">
              <img v-else
                   :title="$t('SuspiciousText.activate_user')"
                   width="20" height="20"
                   src="@/assets/img/user_blocked.svg" alt="">
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="pagination-buttons">
      <div class="pagination-buttons__arrow left">
        <img src="@/assets/img/left-arrow.png" alt="arrow icon">
      </div>
      <div v-bind:class="(n===page)?'pagination-buttons__number active':'pagination-buttons__number'"
           @click="changePage(n)"
           v-for="n in pageCount" :key="n"
      >{{ n }}</div>
      <div class="pagination-buttons__arrow right">
        <img src="@/assets/img/left-arrow.png" alt="arrow icon">
      </div>
    </div>

  </section>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

import {UsersType} from "@/models/users";
import {hasContent, isEmpty} from "@/tools/dataTools";

import {parseFullTime} from "@/tools/dateTools";

import {
  ScamsStatusText,
} from "@/models/scams";
import {ParticipantsStatus} from "@/models/participants";
import InputsMixin from "@/mixins/inputsEvent";
import {SAVE_PAGES_FILTERS} from "@/common/config";

export default {
  name: 'SuspiciousParticipants',
  components: {
  },
  mixins: [InputsMixin],
  data() {
    return {
      ParticipantsStatus,

      isLoading: false,

      // pagination
      itemsPerPage: 7,
      page: 1,

      selectedItem: null,

      // data
      filterData: {
        search: '',
        promotion: null,
        userStatus: 'waiting',
      },
    };
  },
  watch: {
    pageCount() {
      if(this.pageCount < this.itemsPerPage)
        this.page = 1;
    },
    isLoading() {
      if(this.isLoading)
        this.$progress.start();
      else
        this.$progress.finish();
    },
  },
  computed: {
    user() {
      return this.GET_USER();
    },
    employees_filter() {
      return this.GET_EMPLOYEES().filter;
    },
    isAdmin() {
      return this.user ? (this.user.role === UsersType.ADMIN && this.user.isAdmin) : false;
    },
    pageCount() {
      //return this.suspiciousParticipants ? ~~(this.suspiciousParticipants.length / this.itemsPerPage) + 1 : 1;
      return this.suspiciousParticipants ? Math.ceil(this.suspiciousParticipants.length / this.itemsPerPage) : 1;
    },
    editPermission() {
      return this.isAdmin;
    },
    suspiciousParticipants() {
      return this.GET_SUSPICIOUS().filter(s => s.visible);
    },
    lists_filter() {
      return this.GET_LISTS().filter;
    },
    promotions() {
      if(isEmpty(this.user)) {
        return [];
      }
      else {
        let promotions = this.GET_PROMOTIONS().all;

        if (!this.isAdmin) {
          promotions = promotions ? promotions.filter(p => p.company === this.employees_filter.company) : []
        }

        //  check of promotions missing
        if (promotions == null || promotions.length === 0) {
          promotions = []
          this.setListsPromotion(null);
        }

        return promotions;
      }
    },
    userStatuses() {
      return [
        {
          status: 'waiting',
          label: this.$t('SuspiciousText.waiting'),
        },
        {
          status: 'suspended',
          label: this.$t('SuspiciousText.suspended'),
        },
        {
          status: 'blocked',
          label: this.$t('SuspiciousText.blocked'),
        },
      ]
    },
    showParticipants() {
      let items = this.suspiciousParticipants ? this.suspiciousParticipants : []

      // filter by search value
      items = items.filter(
          (v) =>
              `${v.fk_user.surname ? v.fk_user.surname : ''} ${v.fk_user.name ? v.fk_user.name : ''} ${v.fk_user.lastname ? v.fk_user.lastname : ''} ${v.fk_user.email ? v.fk_user.email : ''}`
                  .toLowerCase()
                  .indexOf(this.filterData.search.toLowerCase()) !== -1
      )

      // filter by status
      if (this.filterData.userStatus === 'blocked')
        items = items.filter((item) => (item.fk_user && !item.fk_user.active))
      else if (this.filterData.userStatus === 'suspended')
        items = items.filter((item) => (item.fk_participant && item.fk_participant.status === "blocked"))
      else if (this.filterData.userStatus === 'waiting')
        items = items.filter((item) => (
            item.fk_participant && item.fk_participant.status !== "blocked" &&
            item.fk_user && item.fk_user.active
        ))

      return items
    }
  },
  mounted() {
    if (SAVE_PAGES_FILTERS) {
      this.filterData = {
        search: '',
        promotion: this.lists_filter.promotion,
        userStatus: this.lists_filter.userStatus ? this.lists_filter.userStatus : 'waiting',
      }
    }

    this.getSuspiciousData()
  },
  methods: {
    ...mapGetters(["GET_USER","GET_LISTS","GET_EMPLOYEES","GET_SUSPICIOUS","GET_PROMOTIONS",]),
    ...mapActions(["SET_LISTS_FILTER","GET_SUSPICIOUS_FROM","UPDATE_SUSPICIOUS_USER","ACTIVATE_USER", "DEACTIVATE_USER", "GET_SUSPICIOUS_DOWNLOAD", "UPDATE_PARTICIPANT"]),

    ScamsStatusText,

    parseFullTime,

    changePage(page) {
      this.page = page;
    },

    // eslint-disable-next-line no-unused-vars
    setListsSearch(e) {
      this.filterData.search = e.target.value
    },

    setListsPromotion(data) {
      if (SAVE_PAGES_FILTERS) {
        this.SET_LISTS_FILTER({
          ...this.lists_filter,
          promotion: !data ? null : data.id,
        });
      }
      else {
        this.filterData.promotion = !data ? null : data.id
      }
    },

    setListsUserStatus(data) {
      if (SAVE_PAGES_FILTERS) {
        this.SET_LISTS_FILTER({
          ...this.lists_filter,
          userStatus: !data ? 'waiting' : data.status,
        });
      }
      else {
        this.filterData.userStatus = !data ? 'waiting' : data.status
      }
    },

    getSuspiciousData() {
      this.GET_SUSPICIOUS_FROM({
        company: this.employees_filter ? this.employees_filter.company : null,
        // promotion: this.filterData.promotion,
        // status: this.filterData.userStatus,
      });
    },

    openModalAcceptEmp(user) {
      if(hasContent(user)) {
        this.removeSuspiciousUser(user)
      }
    },

    openModalBlockParticipant(participant) {
      if(hasContent(participant)) {
        if(participant.status === ParticipantsStatus.ACTIVE) {
          this.deactivateParticipant(participant)
        }
        else {
          this.activateParticipant(participant)
        }
      }
    },

    openModalBlockEmp(user) {
      if(hasContent(user)) {
        if(user.active) {
          this.deactivateUser(user)
        }
        else {
          this.activateUser(user)
        }
      }
    },

    removeSuspiciousUser(user) {
      if(user != null) {
        if (
            confirm(this.$t('SuspiciousText.remove_suspect'))
        ) {
          this.isLoading = true

          this.UPDATE_SUSPICIOUS_USER({
            id: user.id,
            status: user.status,
            visible: false,
          }).then((res) => {
            this.isLoading = false

            if (res.success) {
              this.$notify({
                type: "success",
                title: this.$t('Errors.success_operation'),
                text: this.$t('SuspiciousText.remove_suspect_success'),
              });
            } else {
              this.$notify({
                type: "error",
                title: this.$t('Errors.not_success_operation'),
                text: this.$t('SuspiciousText.remove_suspect_error'),
              });
            }
          });
        }
      }
    },

    activateUser(employee) {
      if(employee != null) {
        if (
            confirm(this.$t('EmployeeText.activate_employee'))
        ) {
          this.isLoading = true

          this.ACTIVATE_USER({
            id: employee.id,
          }).then((res) => {
            this.isLoading = false

            if (res.success) {
              this.$notify({
                type: "success",
                title: this.$t('Errors.success_operation'),
                text: this.$t('EmployeeText.activate_employee_success'),
              });
            } else {
              this.$notify({
                type: "error",
                title: this.$t('Errors.not_success_operation'),
                text: this.$t('EmployeeText.activate_employee_error'),
              });
            }
          });
        }
      }
    },

    deactivateUser(employee) {
      if(employee != null) {
        if (
            confirm(this.$t('SuspiciousText.deactivate_user'))
        ) {
          this.isLoading = true

          this.DEACTIVATE_USER({
            id: employee.id,
          }).then((res) => {
            this.isLoading = false

            if (res.success) {
              this.$notify({
                type: "success",
                title: this.$t('Errors.success_operation'),
                text: this.$t('SuspiciousText.deactivate_user_success'),
              });
            } else {
              this.$notify({
                type: "error",
                title: this.$t('Errors.not_success_operation'),
                text: this.$t('SuspiciousText.deactivate_user_error'),
              });
            }
          });
        }
      }
    },

    activateParticipant(participant) {
      if(participant != null) {
        if (
            confirm(this.$t('EmployeeText.activate_employee'))
        ) {
          this.isLoading = true

          this.UPDATE_PARTICIPANT({
            id: participant.id,
            status: ParticipantsStatus.ACTIVE,
          }).then((res) => {
            this.isLoading = false

            if (res.success) {
              this.getSuspiciousData()

              this.$notify({
                type: "success",
                title: this.$t('Errors.success_operation'),
                text: this.$t('EmployeeText.activate_employee_success'),
              });
            } else {
              this.$notify({
                type: "error",
                title: this.$t('Errors.not_success_operation'),
                text: this.$t('EmployeeText.activate_employee_error'),
              });
            }
          });
        }
      }
    },

    deactivateParticipant(participant) {
      if(participant != null) {
        if (
            confirm(this.$t('SuspiciousText.deactivate_from_platform'))
        ) {
          this.isLoading = true

          this.UPDATE_PARTICIPANT({
            id: participant.id,
            status: ParticipantsStatus.BLOCKED,
          }).then((res) => {
            this.isLoading = false

            if (res.success) {
              this.getSuspiciousData()

              this.$notify({
                type: "success",
                title: this.$t('Errors.success_operation'),
                text: this.$t('SuspiciousText.deactivate_from_platform_success'),
              });
            } else {
              this.$notify({
                type: "error",
                title: this.$t('Errors.not_success_operation'),
                text: this.$t('SuspiciousText.deactivate_from_platform_error'),
              });
            }
          });
        }
      }
    },

    downloadSuspiciousList(format) {
      if (format) {
        this.isLoading = true

        this.GET_SUSPICIOUS_DOWNLOAD({
          format,
          company: this.employees_filter ? this.employees_filter.company : null,
        }).then((res) => {
          this.isLoading = false

          if (res.success) {
            this.$notify({
              type: "success",
              title: this.$t('Errors.success_operation'),
              text: this.$t('InterfaceText.file_downloaded'),
            });
          }
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t('Errors.not_success_operation'),
          text: this.$t('Errors.error_format'),
        });
      }
    },

  }
}
</script>

<style lang="scss" src="@/assets/scss/common.scss"></style>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixin.scss";

.suspicious-participants {
  flex: 1;
  width: 100%;
  min-width: 1150px;

  .table {
    @include grid-table-row(145px, 60px, 11.8% 136px 13.75% 136px 14% 153px 8.2% 125px 6%);
    //@include grid-table-row(60px, 30px, 6% 50px 9% 50px 9% 107px 9% 80px 12.6% 100px 12.6% 60px 3.2%);
    //@include grid-table-row(60px, 12px, 5.3% 60px 10% 27px 10% 27px 10% 27px 10% 200px 11.5% 210px 3.6%);

    margin-bottom: 35px;

    &__cell {
      &:first-child {
        grid-column-start: 1;
        grid-column-end: 2;
      }
      &:nth-child(2) {
        grid-column-start: 3;
        grid-column-end: 4;
      }
      &:nth-child(3) {
        grid-column-start: 5;
        grid-column-end: 6;
      }
      &:nth-child(4) {
        grid-column-start: 7;
        grid-column-end: 8;
      }
      &:nth-child(5) {
        grid-column-start: 9;
        grid-column-end: 10;
      }
      &:nth-child(6) {
        grid-column-start: 11;
        grid-column-end: 12;
      }
      &:nth-child(7) {
        grid-column-start: 13;
        grid-column-end: 14;
      }
    }
  }
}

.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  &__title {
    @include page-header;
  }

  .buttons-block {
    margin: 0;
  }
}

.page-header__outload {
  // display: flex;
  // flex-flow: row nowrap;
  // justify-content: center;
  // padding: 7px 0;
  // background-color: $white;
  // border-radius: 5px;
  // border: 1px solid $purple;
  // width: 180px;
  @include white-button;
  width: 180px;


  // border-image: linear-gradient(#8F21C9, #4640B9) 30 / 1 / 0 stretch;
  svg {
    margin-right: 5px;
  }

}

.pagination-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 60px;
  &__arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    margin-right: 10px;
    cursor: pointer;
    &.right {
      transform: rotate(180deg);
      margin-right: 0;
    }
  }

  &__number {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    width: 30px;
    height: 30px;
    border: 1px solid $purple;
    border-radius: 5px;
    color: $purple;
    cursor: pointer;
    &.active {
      background: linear-gradient(180deg, #8F21C9 0%, #8F21C9 0.01%, #4640B9 100%);
      color: $white;
    }
  }
}

</style>
