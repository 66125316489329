<template>
  <div class="lists">
    <ModalEditEmployee
        ref="ModalEditEmployee"
        :opened="modalOpened"
        :employee="currentEmployee"
        :tab="0"
        @close-modal="closeModalEmployee"
    />
    <ModalPromotionPrize
        v-if="modalPrizeOpened"
        :editPermission="false"
        :prize="currentPrize"
        @close-modal="closeModalPrizeInformation"
    />

    <div class="page-header">
      <h2 class="page-header__title">
        {{ $t('ListText.header') }}
      </h2>
      <div class="buttons-block">
        <div v-if="filterData.promotion && (screenOpened === 1)"
             class="page-header__outload"
             @click="downloadDocumentsList('xls')">
          <img
              width="20" height="16"
              src="@/assets/img/bi_cloud_download.svg"
              alt="notification avatar">
          <p class="page-header__outload-text">
            {{ $t('Buttons.download_xls') }}
          </p>
        </div>
      </div>
    </div>
    <div class="buttons-panel">

      <div class="buttons-panel__lists"
        @click="billsScreenOpen()"
        :class="{ active: (screenOpened === 0)}"
      >
        <p class="buttons-panel__lists-text">
          {{ $t('ListText.bills_to_prepare') }}
        </p>
        <div class="buttons-panel__lists-counter">
          <p>
            {{ billsCount }}
          </p>
        </div>
      </div>

      <div class="buttons-panel__lists"
        @click="prizesScreenOpen()"
        :class="{ active: (screenOpened === 1)}"
      >
        <p class="buttons-panel__lists-text">
          {{ $t('ListText.prizes_and_documents') }}
        </p>
        <div class="buttons-panel__lists-counter">
          <p>
            {{ prizesAndDocumentsCount }}
          </p>
        </div>
      </div>

      <div class="buttons-panel__lists"
        @click="questionScreenOpen()"
        :class="{ active: (screenOpened === 2)}"
      >
        <p class="buttons-panel__lists-text">
          {{ $t('ListText.questions') }}
        </p>
        <div class="buttons-panel__lists-counter">
          <p>
            {{ questionsCount }}
          </p>
        </div>
      </div>

      <div class="grid-container-2 width-full">

        <div class="step__item search">
          <p class="step__item-text">
            {{ $t('InterfaceText.search') }}
          </p>
          <input type="text" class="buttons-panel__input-input" :placeholder="$t('FieldsText.enter_request')"
                 :value="filterData.search"
                 @input="setListsSearch">
          <div class="buttons-panel__input-button"></div>
        </div>

        <!--label class="buttons-panel__checkbox">
          <input class="buttons-panel__checkbox-checkbox" type="checkbox">
          <span class="buttons-panel__checkbox-fake"></span>
          <div class="buttons-panel__checkbox-text">Активна</div>
        </label-->

        <div class="step__item select">
          <p class="step__item-text">
            {{ $t('FieldsText.promotion_label') }}
          </p>
          <div class="dd object dd-role-filter">
            <v-select
                :placeholder="$t('FieldsText.select_from_list')"
                :options="promotions"
                :reduce="(option) => option.id"
                label="name"
                :clearable="false"
                v-model="filterData.promotion"
                @option:selected="setListsPromotion"
            >
              <!-- eslint-disable-next-line vue/no-unused-vars  -->
              <template #no-options="{ search, searching, loading }">
                {{ $t('Errors.missing_options') }}
              </template>
            </v-select>
            <div
                class="dd-button"
                @click="openSelectOptions('.dd-role-filter .vs__dropdown-toggle')"
            ></div>
          </div>
        </div>

      </div>

    </div>

    <ListsCheckBillsManual
        v-if="(screenOpened === 0)"
        :editPermission="editPermission"
        :search="filterData.search"
        :promotionBills="promotionBills"
        @updateBillStatus="updateBillStatus"
    />
    <ListsPrizesAndDocumentsModal
      v-if="(screenOpened === 1)"
      :editPermission="editPermission"
      :search="filterData.search"
      :prizesAndDocuments="prizesAndDocuments"
      @refresh="getDocumentsAndPrizes"
      @edit="editPrizeUserInfo"
      @showPrizeInformation="showPrizeInformation"
      @sendPrizeConfirm="sendPrizeConfirm"
      @sendMoneyConfirm="sendMoneyConfirm"
    />
    <ListsQuestionModal
      v-if="(screenOpened === 2)"
      :editPermission="editPermission"
      :search="filterData.search"
      @refresh="getQuestions"
    />
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

import InputsMixin from "@/mixins/inputsEvent";

import ModalEditEmployee from "@/components/ModalEditEmployee";
import ModalPromotionPrize from "@/components/ModalPromotionPrize";

import ListsCheckBillsManual from '../components/ListsCheckBillsManual.vue'
import ListsPrizesAndDocumentsModal from '../components/ListsPrizesAndDocumentsModal.vue'
import ListsQuestionModal from '../components/ListsQuestionModal.vue'
import {hasContent, isEmpty} from "@/tools/dataTools";
import {UsersType} from "@/models/users";
import {SAVE_PAGES_FILTERS} from "@/common/config";

export default {
  name: 'Lists',
  components: {
    ModalEditEmployee,
    ModalPromotionPrize,
    ListsCheckBillsManual,
    ListsPrizesAndDocumentsModal,
    ListsQuestionModal
  },
  mixins: [InputsMixin],
  data() {
    return {
      isLoading: false,
      modalOpened: false,
      modalPrizeOpened: false,

      currentPrize: null,
      currentEmployee: null,

      screenOpened: 0,

      // data
      filterData: {
        search: '',
        promotion: null,
      },

      itemsPerPage: 25,
      page: 1,
      prizesAndDocuments: [],

      promotionBills: [],

    }
  },
  beforeMount() {
    this.CLEAR_LISTS()
  },
  mounted() {
    if (SAVE_PAGES_FILTERS) {
      this.filterData = {
        search: this.lists_filter.search,
        promotion: this.lists_filter.promotion,
      }
    }

    this.getPromotionBills()
    this.getDocumentsAndPrizes()
    this.getQuestions()

    this.getNextDocumentsAndPrizes()
  },
  computed: {
    user() {
      return this.GET_USER();
    },
    isAdmin() {
      return this.user ? (this.user.role === UsersType.ADMIN) : false;
    },
    editPermission() {
      return this.isAdmin;
    },
    billsCount() {
      return this.promotionBills.length;
    },
    prizesAndDocumentsCount() {
      return this.prizesAndDocuments.length;
    },
    questionsCount() {
      return this.GET_LISTS().questions ? this.GET_LISTS().questions.length : 0;
    },
    lists_filter() {
      return this.GET_LISTS().filter;
    },
    employees_filter() {
      return this.GET_EMPLOYEES().filter;
    },
    promotions() {
      if(isEmpty(this.user)) {
        return [];
      }
      else {
        let promotions = this.GET_PROMOTIONS().all;

        if (!this.isAdmin) {
          promotions = promotions ? promotions.filter(p => p.company === this.employees_filter.company) : []
        }

        //  check of promotions missing
        if (promotions == null || promotions.length === 0) {
          promotions = []
          this.setListsPromotion(null);
        }

        return promotions;
      }
    },
    selectedPromotionName() {
      if (this.filterData.promotion && this.promotions) {
        const promotion = this.promotions.find((item) => (item.id === this.filterData.promotion))

        return promotion ? promotion.name : null
      }

      return null
    },
    pageCount() {
      //return this.prizesAndDocuments ? ~~(this.prizesAndDocuments.length / this.itemsPerPage) + 1 : 1;
      return this.prizesAndDocuments ? Math.ceil(this.prizesAndDocuments.length / this.itemsPerPage) : 1;
    },
  },

  watch: {
    // eslint-disable-next-line no-unused-vars
    "$store.state.employees.filter": function (newValue, oldValue) {
      if(this.user) {
        if(newValue != null) {
          this.filterData.company = newValue.company
        }

        this.filterItems();
      }
    },
    // eslint-disable-next-line no-unused-vars
    "$store.state.lists.filter": function (newValue, oldValue) {
      if(newValue != null) {
        this.filterData.search = newValue.search
        this.filterData.promotion = newValue.promotion
      }

      if(newValue.search !== oldValue.search)
        this.filterItems();
      else if(newValue.promotion !== oldValue.promotion) {
        this.promotionBills = []
        this.prizesAndDocuments = []

        this.getPromotionBills()
        this.getDocumentsAndPrizes()
        this.getQuestions()
      }
    },
    // eslint-disable-next-line no-unused-vars
    "$store.state.lists.documents": function (newValue, oldValue) {
      this.filterItems();
    },
    // eslint-disable-next-line no-unused-vars
    "$store.state.lists.questions": function (newValue, oldValue) {
      this.filterItems();
    },
    pageCount() {
      if(this.pageCount < this.itemsPerPage)
        this.page = 1;
    },
    isLoading() {
      if(this.isLoading)
        this.$progress.start();
      else
        this.$progress.finish();
    },
  },
  methods: {
    ...mapGetters(["GET_USER","GET_LISTS","GET_EMPLOYEES","GET_PROMOTIONS",]),
    ...mapActions([
      "SET_LISTS_FILTER",
      "CLEAR_LISTS",
      "GET_PROMOTION_PRIZES",
      "GET_PROMOTION_PRIZE",
      "GET_PROMOTION_QUESTIONS",
      "UPDATE_PARTICIPANT_CONFIRM_PRIZE",
      "GET_DOCUMENTS_DOWNLOAD",
      "GET_PROMOTION_BILLS",
      "GET_PROMOTION_BILL",
      "UPDATE_PARTICIPANT_BILL",
    ]),

    billsScreenOpen() {
      this.filterData.search = '';
      this.screenOpened = 0;

      this.promotionBills = []
      this.getPromotionBills()
    },
    prizesScreenOpen() {
      this.filterData.search = '';
      this.screenOpened = 1;
    },
    questionScreenOpen() {
      this.filterData.search = '';
      this.screenOpened = 2;

      this.getQuestions()
    },

    setListsSearch(e) {
      if(hasContent(e.target.value)) {
        this.filterData.search = e.target.value
        this.filterItems()
      }

      if (SAVE_PAGES_FILTERS) {
        this.SET_LISTS_FILTER({
          ...this.lists_filter,
          search: e.target.value,
        });
      }
    },

    setListsPromotion(data) {
      if (SAVE_PAGES_FILTERS) {
        this.SET_LISTS_FILTER({
          ...this.lists_filter,
          promotion: !data ? null : data.id,
        });
      }
      else {
        this.filterData.promotion = !data ? null : data.id

        this.promotionBills = []
        this.prizesAndDocuments = []

        this.getPromotionBills()
        this.getDocumentsAndPrizes()
        this.getQuestions()
      }
    },

    filterItems() {

    },


    editPrizeUserInfo(prize) {
      this.currentPrize = prize;
      this.currentEmployee = prize.fk_user;
      this.modalOpened = true;

      //this.$refs.ModalEditEmployee.open(employee)
    },

    closeModalEmployee() {
      this.getDocumentAndPrize(this.currentPrize)

      this.currentPrize = null;
      this.currentEmployee = null;
      this.modalOpened = false;
    },

    getPromotionBills() {
      if(this.user) {
        this.isLoading = true

        let page = this.promotionBills ? Math. floor(this.promotionBills.length / this.itemsPerPage) : 0

        this.GET_PROMOTION_BILLS({
          promotion: this.filterData.promotion,
          search: this.filterData.search,
          page,
          count: this.itemsPerPage
        }).then((response) => {
          this.isLoading = false

          if (response.success) {
            // check pagination data
            let bills = this.promotionBills ? JSON.parse(JSON.stringify(this.promotionBills)) : [];

            for (let bill of response.bills) {
              if (bills.filter(item => item.id === bill.id).length === 0)
                bills.push(bill)
            }

            this.promotionBills = bills ? bills : []
          }
        });
      }
    },

    getPromotionBill(item) {
      if(this.user && item) {
        this.isLoading = true

        this.GET_PROMOTION_BILL({
          id: item.id,
        }).then((response) => {
          this.isLoading = false

          if (response.success) {
            // try to update existing prize object
            if (response.bill && this.promotionBills) {
              this.promotionBills = this.promotionBills.map((bill) => {
                if (bill.id === response.bill.id)
                  return response.bill
                else
                  return bill
              })
            }
          }
        });
      }
    },

    getDocumentsAndPrizes() {
      if(this.user) {
        this.isLoading = true

        let page = this.prizesAndDocuments ? Math. floor(this.prizesAndDocuments.length / this.itemsPerPage) : 0

        this.GET_PROMOTION_PRIZES({
          promotion: this.filterData.promotion,
          search: this.filterData.search,
          page,
          count: this.itemsPerPage
        }).then((response) => {
          this.isLoading = false

          if (response.success) {
            // check pagination data
            let prizes = this.prizesAndDocuments ? JSON.parse(JSON.stringify(this.prizesAndDocuments)) : [];

            for (var i = 0; i < response.prizes.length; i++) {
              let prize = response.prizes[i];

              if (prizes.filter(item => item.id === prize.id).length === 0)
                prizes.push(prize)
            }

            this.prizesAndDocuments = prizes ? prizes : []
          }
        });
      }
    },

    getDocumentAndPrize(item) {
      if(this.user && item) {
        this.isLoading = true

        this.GET_PROMOTION_PRIZE({
          id: item.id,
        }).then((response) => {
          this.isLoading = false

          if (response.success) {
            // try to update existing prize object
            if (response.prize && this.prizesAndDocuments) {
              this.prizesAndDocuments = this.prizesAndDocuments.map((prize) => {
                if (prize.id === response.prize.id)
                  return response.prize
                else
                  return prize
              })
            }
          }
        });
      }
    },

    getNextDocumentsAndPrizes() {
      window.onscroll = () => {
        let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
        if (bottomOfWindow) {
          this.getDocumentsAndPrizes()
        }
      }
    },

    getQuestions() {
      if(this.user) {
        this.isLoading = true

        this.GET_PROMOTION_QUESTIONS({
          promotion: this.filterData.promotion,
          search: this.filterData.search,
          empty: true,
        }).then(() => {
          this.isLoading = false
        });
      }
    },

    showPrizeInformation(prize) {
      if(prize) {
        this.currentPrize = prize;
        this.modalPrizeOpened = true;
      }
    },

    closeModalPrizeInformation() {
      this.currentPrize = null;
      this.modalPrizeOpened = false;
    },

    sendPrizeConfirm(participantPrize) {
      if(participantPrize && participantPrize.prize && participantPrize.prize.id) {
        if (
            confirm(this.$t('ListText.confirm_send_prize'))
        ) {
          this.isLoading = true

          this.UPDATE_PARTICIPANT_CONFIRM_PRIZE({
            prize: participantPrize.id,
            participant: participantPrize.participant,
          }).then((res) => {
            this.isLoading = false

            if (res.success) {
              this.getDocumentAndPrize(participantPrize)

              this.$notify({
                type: "success",
                title: this.$t('Errors.success_operation'),
                text: this.$t('ListText.send_prize_success'),
              });
            } else {
              this.$notify({
                type: "error",
                title: this.$t('Errors.not_success_operation'),
                text: this.$t('ListText.send_prize_error'),
              });
            }
          });
        }
      }
      else {
        // show error message
        this.$notify({
          type: "error",
          title: this.$t('Errors.not_success_operation'),
          text: this.$t('Errors.missing_prize'),
        });
      }
    },

    sendMoneyConfirm(participantPrize) {
      if(participantPrize && participantPrize.prize && participantPrize.prize.id) {
        // ask if prize was sent
        console.log(participantPrize)

        if (
            confirm(this.$t('ListText.confirm_send_money'))
        ) {
          this.isLoading = true

          this.UPDATE_PARTICIPANT_CONFIRM_PRIZE({
            prize: participantPrize.id,
            participant: participantPrize.participant,
          }).then((res) => {
            this.isLoading = false

            if (res.success) {
              this.getDocumentsAndPrizes()

              this.$notify({
                type: "success",
                title: this.$t('Errors.success_operation'),
                text: this.$t('ListText.send_money_success'),
              });
            } else {
              this.$notify({
                type: "error",
                title: this.$t('Errors.not_success_operation'),
                text: this.$t('ListText.send_money_error'),
              });
            }
          });
        }
      }
      else {
        // show error message
        this.$notify({
          type: "error",
          title: this.$t('Errors.not_success_operation'),
          text: this.$t('Errors.missing_prize'),
        });
      }
    },

    updateBillStatus(bill) {
      if(bill) {
        this.isLoading = true

        this.UPDATE_PARTICIPANT_BILL({
          id: bill.id,
          status: bill.qrStatusCode,
          score: bill.billScore,
        }).then((res) => {
          this.isLoading = false

          if (res.success) {
            this.getPromotionBill(bill)

            this.$notify({
              type: "success",
              title: this.$t('Errors.success_operation'),
              text: (bill.qrStatusCode === 1) ? this.$t('BillText.confirmSuccess') : this.$t('BillText.deactivateSuccess'),
            });
          } else {
            this.$notify({
              type: "error",
              title: this.$t('Errors.not_success_operation'),
              text: res.message ? res.message : (bill.qrStatusCode === 1) ? this.$t('BillText.confirmError') : this.$t('BillText.deactivateError'),
            });
          }
        });
      }
      else {
        // show error message
        this.$notify({
          type: "error",
          title: this.$t('Errors.error_get_data'),
          text: this.$t('Errors.bill_data_missing'),
        });
      }
    },

    // requests
    downloadDocumentsList(format) {
      if (format) {
        if(this.filterData.promotion) {
          this.isLoading = true

          // download specific format
          this.GET_DOCUMENTS_DOWNLOAD({
            format,
            promotion: this.filterData.promotion,
            name: this.selectedPromotionName
          }).then(() => {
            this.isLoading = false

            this.$notify({
              type: "success",
              title: this.$t('Errors.success_operation'),
              text: this.$t('Errors.file_is_generated'),
            });
          }).catch(() => {
            this.isLoading = false

            this.$notify({
              type: "error",
              title: this.$t('Errors.not_success_operation'),
              text: this.$t('Errors.file_is_not_generated'),
            });
          });
        }
        else {
          this.$notify({
            type: "error",
            title: this.$t('Errors.not_success_operation'),
            text: this.$t('Errors.promotion_is_not_selected'),
          });
        }
      } else {
        this.$notify({
          type: "error",
          title: this.$t('Errors.not_success_operation'),
          text: this.$t('Errors.error_format'),
        });
      }
    },

  }
}
</script>

<style lang="scss" src="@/assets/scss/common.scss"></style>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixin.scss";

.lists {
  flex: 1;
  width: 100%;
  min-width: 1150px;

  .buttons-panel {
    margin-bottom: 30px;
  }

  .buttons-panel__checkbox {
    margin-right: 30px;
  }

  .table {
    @include grid-table-row(60px, 20px, 10% 78px 11% 6px 11% 31px 7.3% 43px 13.5% 18px 10% 23px 15% 19px 4.5%);

    &__cell {
      &:first-child {
        grid-column-start: 1;
        grid-column-end: 2;
      }
      &:nth-child(2) {
        grid-column-start: 3;
        grid-column-end: 4;
      }
      &:nth-child(3) {
        grid-column-start: 5;
        grid-column-end: 6;
      }
      &:nth-child(4) {
        grid-column-start: 7;
        grid-column-end: 8;
      }
      &:nth-child(5) {
        grid-column-start: 9;
        grid-column-end: 10;
      }
      &:nth-child(6) {
        grid-column-start: 11;
        grid-column-end: 12;
      }
      &:nth-child(7) {
        grid-column-start: 13;
        grid-column-end: 14;
      }
      &:nth-child(8) {
        grid-column-start: 15;
        grid-column-end: 16;
      }
    }
  }
}

.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  &__title {
    @include page-header;
  }
}

.buttons-block {
  display: flex;
}

.page-header__outload {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  width: 180px;
  padding: 7px 0;
  background-color: $white;
  border-radius: 5px;
  border: 1px solid $purple;
  cursor: pointer;
  // border-image: linear-gradient(#8F21C9, #4640B9) 30 / 1 / 0 stretch;
  svg {
    margin-right: 5px;
  }
  img {
   margin-right: 5px;
  }
  &-text {
    font-size: 14px;
    font-weight: 16px;
    color: $purple;
  }
}

.buttons-panel {
  display: flex;
  align-items: center;
  padding: 25px 110px 25px 30px;
  background-color: $white;
  border-radius: 10px;
  box-shadow: 0px 0px 8px 2px #CED5F480;
}

.buttons-panel__lists {
  display: flex;
  align-items: center;
  margin-right: 25px;
  padding-right: 26px;
  position: relative;
  cursor: pointer;
  &::after {
    content: "";
    position: absolute;
    top: -4.5px;
    right: 0;
    width: 2px;
    height: 37px;
    background-color: $bodyBackground;
  }

  &-text {
    font-size: 16px;
    font-weight: 400;
    margin-right: 7px;
  }

  &-counter {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    min-width: 28px;
    height: 28px;
    min-height: 28px;
    background: $inputCaption;
    border-radius: 50px;
    p {
      color: $white;
    }
  }

  &.active {
    .buttons-panel__lists-text {
      font-weight: 600;
    }

    .buttons-panel__lists-counter {
      background: linear-gradient(180deg, #8F21C9 0%, #8F21C9 0.01%, #4640B9 100%);
    }
  }
}

.buttons-panel__input {
  display: flex;
  align-items: center;
  margin-right: 30px;
  &-text {
    font-size: 12px;
    font-weight: 500;
    margin-right: 30px;
  }

  &-input {
    @include search-input;
  }

  &-button {
    @include search-input__button;
  }

  &.select {
    .buttons-panel__input-text {
      position: relative;
      &::after {
        content: "*";
        position: absolute;
        top: -2px;
        right: -6px;
        color: $red;
      }
    }

    .buttons-panel__input-button {
      &::after {
        width: 9px;
        height: 7px;
        background: url('../assets/img/triangle.png');
      }
    }
  }
}

.buttons-panel__checkbox {
  display: flex;
  align-items: center;
  margin-right: 30px;
  cursor: pointer;
  &-checkbox {
    display: none;
  }
  &-fake {
    display: inline-block;
    width: 17px;
    height: 17px;
    margin-right: 12px;
    border-radius: 2px;
    box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.25);
    position: relative;
    &::before {
      content: '✔';
      position: absolute;
      color: $black;
      font-size: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
    }
  }
  &-text {
    font-size: 12px;
    font-weight: 500;
    color: $activeText;
  }
  .buttons-panel__checkbox-checkbox:checked + .buttons-panel__checkbox-fake::before {
    opacity: 1;
  }
  span {
    color: $red;
  }
}

</style>
