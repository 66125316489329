<template>
  <div class="reportmoney-modal"
    @close-modal="onModalClose"
  >

    <div class="reportmoney-modal__inner">

      <div class="step__inner-header">
          <p>{{ $t('ReportText.moneyReport') }}</p>
      </div>

      <!-- Content -->
      <div class="grid-container">

        <div class="step__item textarea required">
          <p class="step__item-text">
          {{ $t('ReportText.name') }}
          </p>
          <input class="step__item-input" type="text"
               v-model="reportData.info.name" :disabled="!(editPermission)">
        </div>

        <div class="step__item period required" style="margin-left:20px;">
          <p class="step__item-text">
            {{ $t('ReportText.period_req') }}
          </p>
          <DateTimePicker
              :start="reportData.info.from"
              :end="reportData.info.to"
              @updateDates="updateDatesStartEnd"
          />
        </div>

      </div>

      <div class="grid-container">

        <div class="step__item select required">
            <p class="step__item-text">
              {{ $t('FieldsText.company_req_label') }}
            </p>
            <div class="dd object dd-company-filter">
              <v-select
                  :placeholder="$t('FieldsText.select_from_list')"
                  :options="companies"
                  :reduce="(option) => option.id"
                  label="name"
                  :clearable="false"
                  v-model="reportData.info.company"
                  @option:selected="reportData.info.promotion = null"
              >
                <!-- eslint-disable-next-line vue/no-unused-vars  -->
                <template #no-options="{ search, searching, loading }">
                  {{ $t('Errors.missing_options') }}
                </template>
              </v-select>
              <div
                  class="dd-button"
                  @click="openSelectOptions('.dd-company-filter .vs__dropdown-toggle')"
              ></div>
            </div>
        </div>

        <div class="step__item select required" style="margin-left: 20px;">
            <p class="step__item-text">
              {{ $t('FieldsText.promotion_req_label') }}
            </p>
            <div class="dd object dd-promotion-filter">
              <v-select
                  :placeholder="$t('FieldsText.select_from_list')"
                  :options="promotions"
                  :reduce="(option) => option.id"
                  label="name"
                  :clearable="false"
                  v-model="reportData.info.promotion"
              >
                <!-- eslint-disable-next-line vue/no-unused-vars  -->
                <template #no-options="{ search, searching, loading }">
                  {{ $t('Errors.missing_options') }}
                </template>
              </v-select>
              <div
                  class="dd-button"
                  @click="openSelectOptions('.dd-promotion-filter .vs__dropdown-toggle')"
              ></div>
            </div>
        </div>

      </div>

      <div class="new-account__validation-rule" style="padding-top:10px;">
        <span>* </span>{{ $t('Rules.required_info') }}
      </div>

      <!-- Buttons -->
      <div class="reportmoney-modal__buttons-block">
          <div v-if="editPermission" class="reportmoney-modal__download-button" @click="addReport">
              <p>{{ $t('Buttons.save') }}</p>
          </div>
          <div
              class="reportmoney-modal__back-button"
              @click="close"
          >
              <p>{{ $t('Buttons.close') }}</p>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

import {isEmpty} from "@/tools/dataTools";

import DragDrop from "@/mixins/dragDropEvents";
import InputsMixin from "@/mixins/inputsEvent";

import DateTimePicker from '@/components/DateTimePicker.vue'

import {
  NewReportItem,
  ReportItemFromBody,
  ReportMoneyRequiredVerify,
  ReportType,
} from "@/models/reports";


export default {
  name: 'ModalEditReportMoney',
  components: {
    DateTimePicker,
  },
  props: {
    editPermission: Boolean,
    report: Object,
  },
  mixins: [InputsMixin, DragDrop],
  delimiters: ['${', '}'], // Avoid Twig conflicts
  data() {
    return {
      ReportType,

      isLoading: false,

      reportData: NewReportItem(),
    };
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    report: function (newValue, oldValue) {
      if (!newValue) {
        this.reportData = NewReportItem()
      } else {
        this.reportData = ReportItemFromBody(this.report)
      }
    },
    isLoading() {
      if(this.isLoading)
        this.$progress.start();
      else
        this.$progress.finish();
    },
  },
  mounted() {
    if (this.report) {
      this.reportData = ReportItemFromBody(this.report)
    }
  },
  computed: {
    companies() {
      return JSON.parse(JSON.stringify(this.GET_COMPANIES()))
    },
    promotions() {
      return this.reportData ?
          (
              this.reportData.info.company ?
                  (
                      this.GET_PROMOTIONS().all.filter(p => p.company === this.reportData.info.company)
                  ) : []
          ) : []
    },
  },
  methods: {
    ...mapGetters(["GET_COMPANIES","GET_PROMOTIONS",]),
    ...mapActions(["CREATE_REPORT","UPDATE_REPORT"]),

    textShow(event) {
      if (event.target.value.length > 33) {
        console.dir(event.target.parentElement.querySelector(".step__item-popup"))
        event.target.parentElement.querySelector(".step__item-popup").classList.add("not-empty");
        event.target.parentElement.querySelector(".step__item-popup p").innerText = event.target.value;
      }
    },

    updateDatesStartEnd(from, to) {
      this.reportData.info.from = from
      this.reportData.info.to = to
    },

    addReport() {

      if(this.reportData == null) {
        this.$notify({
          type: "error",
          title: this.$t('Errors.error_not_ok'),
          text: this.$t('Errors.error_wrong_data'),
        });
      }
      else {

        if(!ReportMoneyRequiredVerify(this.reportData)) {
          this.$notify({
            type: "error",
            title: this.$t('Errors.error_not_ok'),
            text: this.$t('Errors.missing_required_data'),
          });
        }
        else if(isEmpty(this.reportData.id)) {
          this.createReport()
        }
        else {
          this.updateReport()
        }
      }
    },

    createReport() {
      this.isLoading = true
      this.reportData.type = ReportType.MONEY

      this.CREATE_REPORT({
        ...this.reportData,
      }).then((res) => {
        this.isLoading = false

        if (res.success) {
          this.reportData.id = res.id

          this.$emit('downloadReport', this.reportData)

          this.$notify({
            type: "success",
            title: this.$t('Errors.success_operation'),
            text: this.$t('ReportText.report_created'),
          });
        } else {
          this.$notify({
            type: "error",
            title: this.$t('Errors.error_not_ok'),
            text: this.$t('Errors.error_try_again'),
          });
        }
      });
    },

    updateReport() {
      this.isLoading = true

      this.UPDATE_REPORT({
        ...this.reportData,
      }).then((res) => {
        this.isLoading = false

        if (res.success) {
          this.$emit('downloadReport', this.reportData)

          this.$notify({
            type: "success",
            title: this.$t('Errors.success_operation'),
            text: this.$t('ReportText.report_updated'),
          });
        } else {
          this.$notify({
            type: "error",
            title: this.$t('Errors.error_not_ok'),
            text: this.$t('Errors.error_try_again'),
          });
        }
      });
    },

    close() {
      this.reportData = NewReportItem();

      this.$emit('close-modal');
    },

  }
}
</script>

<style lang="scss" src="@/assets/scss/common.scss"></style>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixin.scss";

.reportmoney-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 45px 78px 25px 45px;
    background-color: $white;
    border-radius: 10px;
    box-shadow: 0px 0px 8px 2px #CED5F480;
    z-index: 1;

    &__dragAndDrop {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      //width: 600px;
      height: 270px;
      margin-bottom: 25px;
      padding: 67px 67px 67px;
      border: 1px dotted $purple;
      border-radius: 10px;
      background-color: $SideBlocksColor;
      cursor: pointer;
      svg {
          margin: 0 auto 35px;
      }
    }

    &__dragAndDrop-text {
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      cursor: pointer;
      color: $black;
    }

    &__buttons-block {
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }

    &__download-button {
        width: 190px;
        height: 35px;
        margin-right: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(180deg, #8F21C9 0%, #8F21C9 0.01%, #4640B9 100%);
        color: $white;
        font-size: 16px;
        border-radius: 5px;
        cursor: pointer;
    }

    &__back-button {
        width: 190px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $purple;
        font-size: 16px;
        border: 1px solid $purple;
        border-radius: 5px;
        cursor: pointer;
    }
}

.photo-preview {
  display: flex;
  flex-wrap: wrap;
  &__item {
    width: 70px;
    height: 70px;
    cursor: pointer;
    margin-bottom: 20px;
    &:not(:last-child) {
      margin-right: 30px;
    }
  }
  &__img {
    width: 70px;
    height: 70px;
  }
}

.upload-file {
  margin-bottom: 5px;
  cursor: no-drop;
}

.download-file {
  margin-bottom: 5px;
  cursor: context-menu;
}

.file_input {
  margin-bottom: -47px;
  width: inherit;
  height: inherit;
  opacity: 0;
  overflow: hidden;
  position: absolute;
}

/* Create two equal columns that floats next to each other */
.column {
  float: left;
  width: 50%;
  //padding: 10px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto;
  padding: 10px;
}

.dd {
  position: relative;
  width: 100%;
  &.object {
    width: 265px !important;
    margin-top: 0px !important;
    font-size: 12px;
    font-weight: 500;
    // .dd-button {
    //   height: 27px !important;
    // }
  }
  .dd-button {
    @include ui-dropdown-button();
    border-radius: 0px 5px 5px 0px;
  }
  & ::v-deep .vs__dropdown-toggle {
    border: 1px solid $inputCaption;
    box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
  &:not(:first-child) {
    margin-top: 10px;
  }
}


</style>
