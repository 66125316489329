<template>
  <section class="step fifth">

    <div class="page-header" style="margin-top: -20px;">

      <h2 class="page-header__title"/>

      <div class="buttons-block">
        <div
            class="page-header__outload"
            @click="addNewAction"
        >
          <p class="page-header__outload-text">
            {{ $t('PromoActionText.add') }}
          </p>
        </div>
      </div>

    </div>

    <NewPromotionStepFifthSubStepFirst
      v-if="subStepCounter === 1"
      :editPermission="editPermission"
      :promotion="item"
      @updatePromotion="updatePromotion"
    />
    <NewPromotionStepFifthSubStepSecond
      v-if="subStepCounter === 2"
    />
    <NewPromotionStepFifthSubStepThird
      v-if="subStepCounter === 3"
    />

  </section>
  <div class="buttons-block">
    <p class="buttons-block__validation-rule">
      {{ $t('Rules.required_fields') }}
    </p>
    <div
        class="buttons-block__back"
        @click="minusStep"
    >
      <p class="buttons-block__back-text">
        {{ $t('Buttons.back') }}
      </p>
    </div>
    <div
        class="buttons-block__forward"
        @click="finishStep"
    >
      <p class="buttons-block__forward-text">
        {{ $t('Buttons.save') }}
      </p>
    </div>
  </div>
</template>

<script>
import NewPromotionStepFifthSubStepFirst from './NewPromotionStepFifthSubStepFirst.vue'
import NewPromotionStepFifthSubStepSecond from './NewPromotionStepFifthSubStepSecond.vue'
import NewPromotionStepFifthSubStepThird from './NewPromotionStepFifthSubStepThird.vue'
import {
  hasContent,
  isEmpty,
} from "@/tools/dataTools";
import {PromoActionType} from "@/models/promoActions";

export default {
  name: 'NewPromotionStepFifth',
  emits: ["step","updatePromotion","savePromotion","addNewAction"],
  components: {
    NewPromotionStepFifthSubStepFirst,
    NewPromotionStepFifthSubStepSecond,
    NewPromotionStepFifthSubStepThird
  },
  props: {
    editPermission: Boolean,
    promotion: Object,
  },
  data() {
    return {
      subStepCounter: 2,

      item: {}
    }
  },
  watch: {
    promotion: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler () {
        this.item = this.promotion

        this.calculateCurrentView()
      }
    },
  },
  methods: {
    addNewAction() {
      this.$emit("addNewAction")
    },

    calculateCurrentView() {
      if (hasContent(this.item) && hasContent(this.item.actions) && this.item.actions.length > 0)
        this.subStepCounter = 1
      else
        this.subStepCounter = 2
    },

    updatePromotion(item) {
      if (hasContent(item)) {
        this.item = item
        this.$emit("updatePromotion", this.item)

        this.calculateCurrentView()
      }
    },

    minusStep() {
      this.$emit("updatePromotion", this.item)
      this.$emit("step", 5)
    },
    checkStepCompleted() {
      let success = true

      for(let i = 0; i < this.item.actions.length; i++) {
        let action = this.item.actions[i]

        if(isEmpty(action.name))
          success = false
        else if(isEmpty(action.type))
          success = false
        else if(isEmpty(action.type))
          success = false
        else if(isEmpty(action.quantityNewPersons))
          success = false
        else if(isEmpty(action.requiredNewPersonsRegisterBuy))
          success = false
        else if(isEmpty(action.buysQuantity))
          success = false
        else if(isEmpty(action.codesQuantity))
          success = false
        else if(isEmpty(action.codesQuantity))
          success = false

        if(action.type === PromoActionType.INTERACTIVE) {
          if(isEmpty(action.requiredComunity))
            success = false
          else if(isEmpty(action.requiredRepost))
            success = false
        }
      }

      if(success) {
        return true
      }
      else {
        this.$notify({
          type: "error",
          title: this.$t('Errors.error_not_ok'),
          text: this.$t('Errors.missing_required_data'),
        });
      }

      return false
    },
    finishStep() {
      // check if all fields are completed
      if(this.checkStepCompleted()) {
        this.$emit("savePromotion", this.item)
      }
    }
  }
}
</script>

<style lang="scss" src="@/assets/scss/common.scss"></style>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixin.scss";


</style>