<template>
  <div class="reports">
    <div class="page-header">
      <h2 class="page-header__title">
        Отчёты
      </h2>
      <div class="buttons-block">
        <router-link to="/reports/new-report">
          <div class="page-header__newrep">
            <p class="page-header__newrep-text">
              + Новый отчет
            </p>
          </div>
        </router-link>
      </div>
    </div>
    <div class="buttons-panel">
      <div class="buttons-panel__input">
        <p class="buttons-panel__input-text">
          Период
        </p>
        <input type="text" class="buttons-panel__input-input" placeholder="Введите запрос">
        <div class="buttons-panel__input-button"></div>
      </div>
    </div>
    <div class="report-list">
      <div
          class="report"
          v-for="n in 2" :key="n"
      >
          <div
              class="report__header"
              @click="toggleReport"
          >
              <p
                  class="report__title"
              >
                  Регистрация
              </p>
              <p
                  class="report__email"
              >
                <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.89415 0C0.852368 0 0 0.855865 0 1.90192V17.1173C0 18.1634 0.852368 19.0192 1.89415 19.0192H5.68245V17.2124L15.1532 7.70279V5.70577L9.47075 0H1.89415ZM8.52368 1.42644L13.7326 6.65673H8.52368V1.42644ZM15.2479 10.4606C15.1532 10.4606 14.9638 10.5557 14.8691 10.6508L13.922 11.6017L15.9109 13.5988L16.8579 12.6478C17.0474 12.4576 17.0474 12.0772 16.8579 11.887L15.6267 10.6508C15.532 10.5557 15.4373 10.4606 15.2479 10.4606ZM13.3538 12.1723L7.5766 17.9732V19.9702H9.56546L15.3426 14.1693L13.3538 12.1723Z" fill="#BDBDBD"/>
                </svg>
              </p>
          </div>
          <div class="report__body">
              <div class="report-input">
                  <p class="report-input__title">
                      ОТЧЁТ: БАЛЛЫ
                  </p>
                  <input class="report-input__input" type="text" placeholder="Введите сообщение">
              </div>
              <div class="report-button">
                  <p class="report-button__text">
                      Отправить
                  </p>
              </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Reports',
  components: {
  },
  methods: {
    toggleReport(event) {
        let activeReport = event.target;
        if (activeReport.parentElement.classList.contains("report")) {
            activeReport.parentElement.classList.toggle("active")
        } else {
            activeReport.parentElement.parentElement.classList.toggle("active")
        }
    }
  },
}
</script>

<style lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixin.scss";

  .reports {
    flex: 1;
    width: 100%;
  }

  .page-header__newrep {
    width: 130px;
    @include purple-button;
  }

  .report {
    padding: 21px 86px 22px 30px;
    max-height: 61px;
    transition: .25s all ease;
    font-size: 16px;
    border-radius: 10px;
    background: $white;
    box-shadow: 0px 0px 8px 2px rgba(206, 213, 244, 0.5);
    cursor: pointer;
    overflow: hidden;
    &:not(:last-child) {
        margin-bottom: 5px;
    }

    &.active {
        max-height: 140px;

        .report__header::after {
            transform: rotate(90deg);
        }

        .report__body {
            display: flex;
        }
    }

    &__header {
        display: flex;
        position: relative;
        &::after {
            content: "";
            width: 10px;
            height: 10px;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            background: url("../assets/img/paginate-triangle.png");
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
        }
    }
    &__title {
        padding-left: 40px;
        margin-right: auto;
    }
    &__name {
        margin-right: 90px;
    }
    .report__body {
        display: none;
        align-items: flex-end;
        padding-top: 26px;
    }

    .report-input {
        margin-right: 36px;
        flex-grow: 1;
        &__title {
            font-size: 12px;
            font-weight: 500;
            margin-bottom: 5px;
        }
        &__input {
            width: 100%;
            border-radius: 5px;
            padding: 11px 26px;
            font-size: 12px;
            font-weight: 500;
            outline: none;
            border: none;
            box-shadow: 0px 0px 2px 0px #00000040 inset;
            &::placeholder {
                color: $inputCaption;
            }
        }

    }

    .report-button {
        @include white-button;
        width: 190px;
        &__text {
            color: $purple;
        }
    }
  }

</style>
