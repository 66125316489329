<template>
  <div class="sms-modal"
    @close-modal="onModalClose"
  >
    <div class="sms-modal__inner">

      <div class="step__item select required">
        <p class="step__item-text">
          {{ $t('PhonesText.operator') }}
        </p>
        <div class="dd object dd-operator-filter">
          <v-select
              :placeholder="$t('FieldsText.select_from_list')"
              :options="operators"
              :reduce="(option) => option.id"
              label="name"
              :clearable="true"
              v-model="smsData.operator"
          >
            <!-- eslint-disable-next-line vue/no-unused-vars  -->
            <template #no-options="{ search, searching, loading }">
              {{ $t('Errors.missing_options') }}
            </template>
          </v-select>
          <div
              class="dd-button"
              @click="openSelectOptions('.dd-operator-filter .vs__dropdown-toggle')"
          ></div>
        </div>
      </div>

      <div class="step__item textarea large" style="margin-top: 10px">
        <p class="step__item-text">
          {{ $t('TableHeaders.sms_target_phone') }}
        </p>
        <input class="step__item-input" type="text"
               oninput="this.value = this.value.replace(/[^0-9+]/g, '')"
               v-model="smsData.target">
      </div>

      <div class="step__item textarea large" style="margin-top: 10px">
        <p class="step__item-text">
          {{ $t('ListText.message_text') }}
        </p>
        <input class="step__item-input" type="text"
               v-model="smsData.message">
      </div>


      <!-- Buttons -->
      <div class="sms-modal__buttons-block">
          <div
              class="sms-modal__download-button"
              @click="addSMS">
              <p>{{ $t('Buttons.send') }}</p>
          </div>

          <div
              class="sms-modal__back-button"
              @click="close"
          >
              <p>{{ $t('Buttons.close') }}</p>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

import DragDrop from "@/mixins/dragDropEvents";
import InputsMixin from "@/mixins/inputsEvent";

import {NewSMS, SMSFromBody, SMSRequiredVerify, } from "@/models/sms";


export default {
  name: 'ModalEditSMS',
  components: {
  },
  props: {
    editPermission: Boolean,
    sms: Object,
  },
  mixins: [InputsMixin, DragDrop],
  delimiters: ['${', '}'], // Avoid Twig conflicts
  data() {
    return {
      isLoading: false,

      smsData: NewSMS(),
    };
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    sms: function (newValue, oldValue) {
      if (!newValue) {
        this.smsData = NewSMS()
      } else {
        this.smsData = SMSFromBody(this.sms)
      }
    },
    isLoading() {
      if(this.isLoading)
        this.$progress.start();
      else
        this.$progress.finish();
    },
  },
  mounted() {
    if (this.sms) {
      this.smsData = SMSFromBody(this.sms)
    }
  },
  computed: {

    operators() {
      return this.GET_LIST_PHONES() ? this.GET_LIST_PHONES().operators : []
    }
  },
  methods: {
    ...mapGetters(["GET_LIST_PHONES"]),
    ...mapActions(["CREATE_SMS"]),

    textShow(event) {
      if (event.target.value.length > 33) {
        //console.dir(event.target.parentElement.querySelector(".step__item-popup"))
        event.target.parentElement.querySelector(".step__item-popup").classList.add("not-empty");
        event.target.parentElement.querySelector(".step__item-popup p").innerText = event.target.value;
      }
    },

    addSMS() {
      if(this.smsData == null) {
        this.$notify({
          type: "error",
          title: this.$t('Errors.error_not_ok'),
          text: this.$t('Errors.error_wrong_data'),
        });
      }
      else if(!SMSRequiredVerify(this.smsData)) {
        this.$notify({
          type: "error",
          title: this.$t('Errors.error_not_ok'),
          text: this.$t('Errors.missing_required_data'),
        });
      }
      else {
        this.createSMS()
      }
    },

    createSMS() {
      this.isLoading = true

      this.CREATE_SMS({
        ...this.smsData,
      }).then((res) => {
        this.isLoading = false

        if (res.success && res.status) {

          if(res.status.success) {
            const data = res.status.data.output

            // check errors
            if (data.errors) {
              this.$notify({
                type: "error",
                title: this.$t('Errors.error_not_ok'),
                text: data.errors.error ? data.errors.error.$t : this.$t('Errors.error_get_data'),
              });
            }
            else {

              this.$notify({
                type: "success",
                title: this.$t('Errors.success_operation'),
                text: this.$t('OperatorText.sms_sent'),
              });

              this.close()
            }

          }
          else {
            this.$notify({
              type: "error",
              title: this.$t('Errors.error_not_ok'),
              text: res.status.message ? res.status.message : this.$t('Errors.error_get_data'),
            });
          }
        } else {
          this.$notify({
            type: "error",
            title: this.$t('Errors.error_not_ok'),
            text: this.$t('Errors.error_try_again'),
          });
        }
      });
    },

    close() {
      this.smsData = NewSMS();

      this.$emit('close-modal');
    },

  }
}
</script>

<style lang="scss" src="@/assets/scss/common.scss"></style>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixin.scss";

.sms-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 45px 78px 25px 45px;
    background-color: $white;
    border-radius: 10px;
    box-shadow: 0px 0px 8px 2px #CED5F480;
    z-index: 1;

    &__dragAndDrop {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      //width: 600px;
      height: 270px;
      margin-bottom: 25px;
      padding: 67px 67px 67px;
      border: 1px dotted $purple;
      border-radius: 10px;
      background-color: $SideBlocksColor;
      cursor: pointer;
      svg {
          margin: 0 auto 35px;
      }
    }

    &__dragAndDrop-text {
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      cursor: pointer;
      color: $black;
    }

    &__buttons-block {
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }

    &__download-button {
        width: 190px;
        height: 35px;
        margin-right: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(180deg, #8F21C9 0%, #8F21C9 0.01%, #4640B9 100%);
        color: $white;
        font-size: 16px;
        border-radius: 5px;
        cursor: pointer;
    }

    &__back-button {
        width: 190px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $purple;
        font-size: 16px;
        border: 1px solid $purple;
        border-radius: 5px;
        cursor: pointer;
    }
}

.photo-preview {
  display: flex;
  flex-wrap: wrap;
  &__item {
    width: 70px;
    height: 70px;
    cursor: pointer;
    margin-bottom: 20px;
    &:not(:last-child) {
      margin-right: 30px;
    }
  }
  &__img {
    width: 70px;
    height: 70px;
  }
}

.upload-file {
  margin-bottom: 5px;
  cursor: no-drop;
}

.download-file {
  margin-bottom: 5px;
  cursor: context-menu;
}

.file_input {
  margin-bottom: -47px;
  width: inherit;
  height: inherit;
  opacity: 0;
  overflow: hidden;
  position: absolute;
}

/* Create two equal columns that floats next to each other */
.column {
  float: left;
  width: 50%;
  //padding: 10px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto;
  padding: 10px;
}

.dd {
  position: relative;
  width: 100%;
  &.object {
    width: 265px !important;
    margin-top: 0px !important;
    font-size: 12px;
    font-weight: 500;
    // .dd-button {
    //   height: 27px !important;
    // }
  }
  .dd-button {
    @include ui-dropdown-button();
    border-radius: 0px 5px 5px 0px;
  }
  & ::v-deep .vs__dropdown-toggle {
    border: 1px solid $inputCaption;
    box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
  &:not(:first-child) {
    margin-top: 10px;
  }
}


</style>
