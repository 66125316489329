<template>
  <div class="drawing-modal"
    @close-modal="onModalClose"
  >
    <div class="drawing-modal__inner">

      <div class="row">

        <div class="column">

          <div>{{ $t('DrawingText.prizesList') }}</div>

          <div class="row" style="margin-right:20px;">

            <div class="column grid-container-2-90">
              <p class="step__item-text">
                {{ $t('DrawingText.selectPrize') }}
              </p>
              <div class="dd select dd-automaticalDelivery-filter">
                <v-select
                  :disabled="!(editPermission)"
                  :placeholder="$t('FieldsText.select_from_list')"
                  :options="allPrizes"
                  :reduce="(option) => option.id"
                  label="name"
                  :clearable="false"
                  v-model="prize"
                  @option:selected="selectPrize"
                >
                  <!-- eslint-disable-next-line vue/no-unused-vars  -->
                  <template #no-options="{ search, searching, loading }">
                    {{ $t('Errors.missing_options') }}
                  </template>
                </v-select>
                <div
                    class="dd-button"
                    @click="openSelectOptions('.dd-automaticalDelivery-filter .vs__dropdown-toggle')"
                ></div>
              </div>
            </div>

            <div class="table__cell button column grid-container-2-10-icon">
              <div
                  v-if="editPermission"
                  class="table__cell-button"
                  style="margin-top:32px;"
                  @click="showPrizeInformation">
                <img
                    :title="$t('TabHeaders.information')"
                    src="@/assets/img/view-info.svg">
              </div>

            </div>

          </div>


          <div class="grid-container-2">

            <div class="step__item textarea">
              <p class="step__item-text">
                {{ $t('DrawingText.countPrizes') }}
              </p>
              <input class="step__item-input" type="text"
                      v-model="prizeDataCount" disabled>
            </div>

            <div class="step__item textarea">
              <p class="step__item-text">
                {{ $t('DrawingText.countWinner') }}
              </p>
              <input class="step__item-input" type="text"
                      v-model="prizeWinnerCount" disabled>
            </div>

          </div>


          <div class="grid-container-2">

            <div class="step__item period required">
                <p class="step__item-text">
                  {{ $t('DrawingText.drawingPromoDates') }}
                </p>
                <!-- <input class="step__item-period" type="text" placeholder="Дата начала">
                <input class="step__item-period" type="text" placeholder="Дата завершения"> -->
                <DateTimePicker
                  :editPermission="false"
                  :start="prizeData.drawingPromoStartedAt"
                  :end="prizeData.drawingPromoEndedAt"
                />
            </div>

            <div class="step__item textarea">
              <p class="step__item-text">
                {{ $t('DrawingText.participantsCount') }}
              </p>
              <input class="step__item-input" type="text"
                      v-model="prizeParticipantsCount" disabled>
            </div>

          </div>


          <div class="new-account__validation-rule" style="padding: 10px; margin-right:15px;">
            <span>* </span>{{ $t('DrawingText.drawingHelp') }}
          </div>


          <div class="grid-container-2" style="margin-top: -20px">

            <div class="step__item textarea">
              <p class="step__item-text">
                {{ $t('DrawingText.drawingMethod') }}
              </p>
              <input class="step__item-input" type="text"
                      v-model="prizeData.winnerDetermining" disabled>
            </div>

            <div class="step__item textarea">
              <p class="step__item-text">
                {{ $t('DrawingText.winnerNumber') }}
              </p>
              <input class="step__item-input" type="number"
                      v-model="winnerNumber" :disabled="!showWinnerEnabled">
            </div>

          </div>


        </div>

        <div class="column">

          <div>{{ $t('DrawingText.winnersList') }}</div>

          <div class="table" style="padding:10px;">
            <div class="table__row title">
              <p class="table__cell">{{ $t('FieldsText.name_label') }}</p>
              <p class="table__cell">{{ $t('FieldsText.surname_label') }}</p>
              <p class="table__cell">{{ $t('FieldsText.lastname_label') }}</p>
              <p class="table__cell">{{ $t('TableHeaders.phone') }}</p>
              <p class="table__cell">{{ $t('TableHeaders.action') }}</p>
            </div>
            <div class="table__body">
                  <div
                      v-for="n in prizeWinners" :key="n.id"
                      class="table__row"
                  >
                      <div class="table__cell">
                          <p>{{ n.fk_user.name }}</p>
                      </div>
                      <div class="table__cell">
                          <p>{{ n.fk_user.surname }}</p>
                      </div>
                      <div class="table__cell">
                          <p>{{ n.fk_user.lastname }}</p>
                      </div>
                      <div class="table__cell">
                        <p>{{ n.fk_user.phone }}</p>
                      </div>

                      <div class="table__cell button">
                          <div
                              v-if="editPermission"
                              class="table__cell-button"
                              @click="showUserInformation(n.fk_user)">
                            <img
                                :title="$t('TabHeaders.information')"
                                src="@/assets/img/view_info.svg">
                          </div>

                      </div>
                  </div>
              </div>
          </div>

        </div>

      </div>

      <div class="drawing-modal__buttons-block">
          <div
              v-if="showWinnerEnabled"
              class="drawing-modal__download-button"
              @click="showWinner">
              <p>{{ $t('DrawingText.defineWinner') }}</p>
          </div>

          <div
              class="drawing-modal__back-button"
              @click="close"
          >
              <p>{{ $t('Buttons.close') }}</p>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

import InputsMixin from "@/mixins/inputsEvent";

import DateTimePicker from './DateTimePicker.vue'
import {hasContent} from "@/tools/dataTools";
import {ParticipantsStatus} from "@/models/participants";

export default {
  name: 'RequisitesDownloadModal',
  components: {
    DateTimePicker,
  },
  props: {
    editPermission: Boolean,
    promotion: Object,
  },
  mixins: [InputsMixin],
  delimiters: ['${', '}'], // Avoid Twig conflicts
  data() {
    return {
      isLoading: false,

      prize: null,
      prizeData: {},

      winnerNumber: null,

      winners: [],
    };
  },
  watch: {
    isLoading() {
      if(this.isLoading)
        this.$progress.start();
      else
        this.$progress.finish();
    },
  },
  computed: {
    allPrizes() {
      return this.promotion ? (this.promotion.prizes ? this.promotion.prizes : []) : []
    },

    allParticipants() {
      return this.GET_PROMOTION_PARTICIPANTS()
    },

    allParticipantPrizes() {
      return this.GET_LISTS().prizes
    },

    prizeParticipants() {
      let participants = []

      if(this.prize) {
        // check if participant is active
        participants = this.allParticipants.filter( (p) =>
            p.status !== ParticipantsStatus.BLOCKED
        )

        // check if participant has current Prize, and check prizes Quantity
        if(this.prizeData.prizesQuantityPerPersonPerPromo) {
          participants = participants.filter( (p) =>
              p.prizes.filter(i => i.prize_id === this.prize).length < this.prizeData.prizesQuantityPerPersonPerPromo
          )
        }

        // check if participant has requiredScore
        if(this.prizeData.pointsQuantity) {
          participants = participants.filter( (p) =>
              p.count >= this.prizeData.pointsQuantity
          )
        }
      }

      return participants
    },

    prizeParticipantsCount() {
      return this.prizeParticipants.length
    },

    prizeWinners() {
      return this.prize ? this.allParticipantPrizes.filter(i => i.prize_id === this.prize) : []
    },

    prizeWinnerCount() {
      return this.prizeWinners ? this.prizeWinners.length : 0
    },

    prizeDataCount() {
      return this.prizeData ? (this.prizeData.count ? this.prizeData.count : 0) : 0
    },

    showWinnerEnabled() {
      return this.editPermission && this.prize && (this.prizeWinnerCount < this.prizeDataCount) && (this.prizeParticipantsCount > 0)
    }
  },
  methods: {
    ...mapGetters(["GET_LISTS","GET_PROMOTION_PARTICIPANTS"]),
    ...mapActions(["CREATE_PARTICIPANT_WINNER"]),

    close() {
      this.$emit('close-modal');
    },

    showWinner() {
      if(this.winnerNumber && this.winnerNumber > 0) {
        if(this.winnerNumber > this.prizeParticipantsCount) {
          this.$notify({
            type: "error",
            title: this.$t('Errors.not_success_operation'),
            text: this.$t('DrawingText.errorWinnNumberIsBig'),
          });
        }
        else {
          // success operation
          if (confirm(this.$t('DrawingText.confirm_create_winner')))
            this.registerNewWinner()
        }
      }
      else {
        this.$notify({
          type: "error",
          title: this.$t('Errors.not_success_operation'),
          text: this.$t('DrawingText.errorMissWinnNumber'),
        });
      }
    },

    selectPrize() {
      this.prizeData = this.prize ? (this.promotion ? this.promotion.prizes.find( (p) => p.id === this.prize ) : {}) : {}
    },

    textShow(event) {
      if (event.target.value.length > 33) {
        event.target.parentElement.querySelector(".step__item-popup").classList.add("not-empty");
        event.target.parentElement.querySelector(".step__item-popup p").innerText = event.target.value;
      }
    },

    showPrizeInformation() {
      if(hasContent(this.prize)) {
        this.$emit('showPrizeInformation', this.prizeData)
      }
    },

    showUserInformation(user) {
      if(hasContent(user)) {
        this.$emit('showUserInformation', user)
      }
    },

    registerNewWinner() {
      if(this.prize && this.prizeData) {
        this.isLoading = true

        this.CREATE_PARTICIPANT_WINNER({
          id: this.prizeParticipants[this.winnerNumber].id,
          prize: this.prizeData,
        }).then((res) => {
          this.isLoading = false

          if (res.success) {
            this.winnerNumber = null

            this.$emit('getPromotionWinnerPrizes', this.promotion.id)
            this.$emit('getPromotionParticipants', this.promotion.id)

            this.$notify({
              type: "success",
              title: this.$t('Errors.success_operation'),
              text: this.$t('DrawingText.create_winner_success'),
            });
          } else {
            this.$notify({
              type: "error",
              title: this.$t('Errors.not_success_operation'),
              text: this.$t('DrawingText.create_winner_error'),
            });
          }
        });
      }
    },

  }
}
</script>

<style lang="scss" src="@/assets/scss/common.scss"></style>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixin.scss";

.drawing-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1350px;
    transform: translate(-50%, -50%);
    padding: 45px 45px 25px 45px;
    background-color: $white;
    border-radius: 10px;
    box-shadow: 0px 0px 8px 2px #CED5F480;
    z-index: 200;

    &__dragAndDrop {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      //width: 600px;
      height: 270px;
      margin-bottom: 25px;
      padding: 67px 67px 67px;
      border: 1px dotted $purple;
      border-radius: 10px;
      background-color: $SideBlocksColor;
      cursor: pointer;
      svg {
          margin: 0 auto 35px;
      }
    }

    &__dragAndDrop-text {
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      cursor: pointer;
      color: $black;
    }

    &__buttons-block {
        display: flex;
        justify-content: center;
    }

    &__download-button {
        width: 190px;
        height: 35px;
        margin-right: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(180deg, #8F21C9 0%, #8F21C9 0.01%, #4640B9 100%);
        color: $white;
        font-size: 16px;
        border-radius: 5px;
        cursor: pointer;
    }

    &__back-button {
        width: 190px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $purple;
        font-size: 16px;
        border: 1px solid $purple;
        border-radius: 5px;
        cursor: pointer;
    }



  .table {
    max-height: 370px;
    overflow-y: scroll;
    overflow-x: hidden;

    //@include grid-table-row(60px, 30px, 11% 88px 11% 6px 11% 31px 7.3% 43px 13.5% 18px 10% 23px 10% 69px 3.4%);
    @include grid-table-row(70px, 30px, 11.8% 68px 13.75% 68px 14% 77px 14% 35px 5%);

    &__cell {
      &:nth-child(2) {
        grid-column-start: 3;
        grid-column-end: 4;
      }
      &:nth-child(3) {
        grid-column-start: 5;
        grid-column-end: 6;
      }
      &:nth-child(4) {
        grid-column-start: 7;
        grid-column-end: 8;
      }
      &:nth-child(5) {
        grid-column-start: 9;
        grid-column-end: 10;
      }
      &:nth-child(6) {
        grid-column-start: 11;
        grid-column-end: 12;
      }
      &:nth-child(7) {
        grid-column-start: 13;
        grid-column-end: 14;
      }
      &:nth-child(8) {
        grid-column-start: 15;
        grid-column-end: 16;
      }
    }
  }

  .grid-container-2-10-icon {
    width: 10%;
    padding: 10px;
    align-items: center;
  }

  .grid-container-2-90 {
    width: 90%;
    padding: 10px;
  }

}

.photo-preview {
  display: flex;
  flex-wrap: wrap;
  &__item {
    width: 70px;
    height: 70px;
    cursor: pointer;
    margin-bottom: 20px;
    &:not(:last-child) {
      margin-right: 30px;
    }
  }
  &__img {
    width: 70px;
    height: 70px;
  }
}

.upload-file {
  margin-bottom: 5px;
  cursor: no-drop;
}

.download-file {
  margin-bottom: 5px;
  cursor: context-menu;
}

.file_input {
  margin-bottom: -47px;
  width: inherit;
  height: inherit;
  opacity: 0;
  overflow: hidden;
  position: absolute;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}


.grid-container-2-10 {
  width: 10%;
  padding: 10px;
}

.grid-container-2-10-icon {
  width: 10%;
  padding: 10px;
  align-items: center;
}

.grid-container-2-25 {
  width: 25%;
  padding: 10px;
}

.grid-container-2-45 {
  width: 45%;
}

.grid-container-2-55 {
  width: 55%;
}

.grid-container-2-75 {
  width: 75%;
  padding: 10px;
}

.grid-container-2-90 {
  width: 90%;
  padding: 10px;
}

//.grid-container-2 {
//  display: grid;
//  grid-template-columns: auto auto;
//  padding: 10px;
//}



.select {
  width: 100%;
  .buttons-panel__input-text {
    position: relative;
    &::after {
      content: "*";
      position: absolute;
      top: -2px;
      right: -6px;
      color: $red;
    }
  }

  .buttons-panel__input-button {
    &::after {
      width: 9px;
      height: 7px;
      background: url('../assets/img/triangle.png');
    }
  }
}


</style>
