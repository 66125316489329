<template>
  <div class="bookkeeping">
    <div class="page-header">
      <h2 class="page-header__title">
        {{ $t('TaxText.header') }}
      </h2>
      <div class="buttons-block">
        <div v-if="firstBookkeepingScreenOpened && filterData.promotion"
             class="page-header__outload"
             @click="downloadTaxesList('xls')">
          <img
              width="20" height="16"
              src="@/assets/img/bi_cloud_download.svg"
              alt="notification avatar">
          <p class="page-header__outload-text">
            {{ $t('Buttons.download_xls') }}
          </p>
        </div>

        <div v-if="!firstBookkeepingScreenOpened"
             class="page-header__outload"
             @click="downloadUsersTaxesList('xls')">
          <img
              width="20" height="16"
              src="@/assets/img/bi_cloud_download.svg"
              alt="notification avatar">
          <p class="page-header__outload-text">
            {{ $t('Buttons.download_xls') }}
          </p>
        </div>

      </div>

    </div>

    <div class="buttons-panel">
      <!-- Бухгалтерские документы -->
      <div class="buttons-panel__firstScreen"
        @click="firstBookkeepingScreenOpen()"
        :class="{ active: firstBookkeepingScreenOpened}"
      >
        <p class="buttons-panel__firstScreen-text">
          {{ $t('TaxText.miss_documents') }}
        </p>
        <div class="buttons-panel__firstScreen-counter">
          <p>
            {{ prizesAndDocumentsCount }}
          </p>
        </div>
      </div>

      <!-- Контроль налогов -->
      <div class="buttons-panel__firstScreen"
        @click="secondBookkeepingScreenOpen()"
        :class="{ active: !firstBookkeepingScreenOpened}"
      >
        <p class="buttons-panel__firstScreen-text">
          {{ $t('TaxText.taxes_documents') }}
        </p>
        <div class="buttons-panel__firstScreen-counter">
          <p>
            {{ usersTaxesCount }}
          </p>
        </div>
      </div>

      <div class="grid-container-3 width-full">

        <div class="step__item search">
          <p class="step__item-text">
            {{ $t('InterfaceText.search') }}
          </p>
          <input type="text" class="buttons-panel__input-input" :placeholder="$t('FieldsText.enter_request')"
                 :value="filterData.search"
                 @input="setListsSearch">
          <div class="buttons-panel__input-button"></div>
        </div>

        <div
            v-if="firstBookkeepingScreenOpened"
            class="step__item select"
            style="width: 230px;"
        >
          <p class="step__item-text">
            {{ $t('FieldsText.promotion_label') }}
          </p>
          <div class="dd object dd-role-filter">
            <v-select
                :placeholder="$t('FieldsText.select_from_list')"
                :options="promotions"
                :reduce="(option) => option.id"
                label="name"
                :clearable="false"
                v-model="filterData.promotion"
                @option:selected="setListsPromotion"
            >
              <!-- eslint-disable-next-line vue/no-unused-vars  -->
              <template #no-options="{ search, searching, loading }">
                {{ $t('Errors.missing_options') }}
              </template>
            </v-select>
            <div
                class="dd-button"
                @click="openSelectOptions('.dd-role-filter .vs__dropdown-toggle')"
            ></div>
          </div>
        </div>

        <div
            v-if="firstBookkeepingScreenOpened"
            class="step__item select"
            style="width: 180px;"
        >
          <p class="step__item-text">
            {{ $t('TaxText.tax') }}
          </p>
          <div class="dd object dd-tax-filter">
            <v-select
                :placeholder="$t('FieldsText.select_from_list')"
                :options="TaxStatuses()"
                :reduce="(option) => option.status"
                label="label"
                :clearable="false"
                v-model="filterData.taxStatus"
                @option:selected="setListsTaxStatus"
            >
              <!-- eslint-disable-next-line vue/no-unused-vars  -->
              <template #no-options="{ search, searching, loading }">
                {{ $t('Errors.missing_options') }}
              </template>
            </v-select>
            <div
                class="dd-button"
                @click="openSelectOptions('.dd-tax-filter .vs__dropdown-toggle')"
            ></div>
          </div>
        </div>

        <div
            v-else
            class="step__item select"
            style="width: 180px;"
        >
          <p class="step__item-text">
            {{ $t('TaxText.year') }}
          </p>
          <div class="dd object dd-year-filter">
            <v-select
                :placeholder="$t('FieldsText.select_from_list')"
                :options="taxesYearsList"
                :clearable="false"
                v-model="filterData.year"
                @option:selected="setListsYear"
            >
              <!-- eslint-disable-next-line vue/no-unused-vars  -->
              <template #no-options="{ search, searching, loading }">
                {{ $t('Errors.missing_options') }}
              </template>
            </v-select>
            <div
                class="dd-button"
                @click="openSelectOptions('.dd-year-filter .vs__dropdown-toggle')"
            ></div>
          </div>
        </div>

      </div>

    </div>

    <ListBookkipingAll
        v-if="firstBookkeepingScreenOpened"
        :editPermission="editPermission"
        :search="filterData.search"
        :items="documents"
        @get="getDocumentAndPrize" />

    <ListUsersTaxesAll
        v-if="!firstBookkeepingScreenOpened"
        :editPermission="editPermission"
        :search="filterData.search"
        :items="usersTaxes"
        :year="this.filterData.year"
        @get="getUserTaxes"
        @getAll="getUsersTaxes" />

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex"

import InputsMixin from "@/mixins/inputsEvent"

import {UsersType} from "@/models/users"
import {hasContent, isEmpty} from "@/tools/dataTools"
import {TaxStatus, TaxStatuses} from "@/models/prizes";
import ListBookkipingAll from "@/components/ListBookkipingAll";
import ListUsersTaxesAll from "@/components/ListUsersTaxesAll";
import {SAVE_PAGES_FILTERS, taxesYearsList} from "@/common/config";

export default {
  name: 'Bookkeeping',
  components: {
    ListBookkipingAll,
    ListUsersTaxesAll,
  },
  mixins: [InputsMixin],
  data() {
    return {
      TaxStatus,
      taxesYearsList,

      isLoading: false,

      modalPrizeOpened: false,
      currentPrize: null,

      firstBookkeepingScreenOpened: true,

      // data
      filterData: {
        search: '',
        promotion: null,
        taxStatus: TaxStatus.NONPAID,
        year: 2023,
      },

      itemsPerPage: 25,
      page: 1,
      prizesAndDocuments: [],
      usersTaxes: [],

    }
  },
  mounted() {
    if (SAVE_PAGES_FILTERS) {
      this.filterData = {
        search: this.lists_filter.search,
        promotion: this.lists_filter.promotion,
        taxStatus: this.lists_filter.taxStatus ? this.lists_filter.taxStatus : TaxStatus.NONPAID,
        year: this.lists_filter.year ? this.lists_filter.year : 2023,
      }
    }

    this.getDocumentsAndPrizes()
    this.getNextDocumentsAndPrizes()

    this.getUsersTaxes()
  },
  computed: {
    user() {
      return this.GET_USER();
    },
    isAdmin() {
      return this.user ? (this.user.role === UsersType.ADMIN) : false;
    },
    editPermission() {
      return this.isAdmin;
    },
    lists_filter() {
      return this.GET_LISTS().filter;
    },
    employees_filter() {
      return this.GET_EMPLOYEES().filter;
    },
    prizesAndDocumentsCount() {
      return this.prizesAndDocuments.length;
    },
    usersTaxesCount() {
      return this.usersTaxes.length;
    },
    promotions() {
      if(isEmpty(this.user)) {
        return [];
      }
      else {
        let promotions = this.GET_PROMOTIONS().all;

        if (!this.isAdmin) {
          promotions = promotions ? promotions.filter(p => p.company === this.employees_filter.company) : []
        }

        //  check of promotions missing
        if (promotions == null || promotions.length === 0) {
          promotions = []
          this.setListsPromotion(null);
        }

        return promotions;
      }
    },
    selectedPromotionName() {
      if (this.filterData.promotion && this.promotions) {
        const promotion = this.promotions.find((item) => (item.id === this.filterData.promotion))

        return promotion ? promotion.name : null
      }

      return null
    },

    documents() {
      return this.prizesAndDocuments ?
          this.prizesAndDocuments.filter(
              (v) =>
                  `${v.fk_user.surname ? v.fk_user.surname : ''} ${v.fk_user.name ? v.fk_user.name : ''} ${v.fk_user.lastname ? v.fk_user.lastname : ''} ${v.fk_user.phone ? v.fk_user.phone : ''} ${v.fk_user.email ? v.fk_user.email : ''}`
                      .toLowerCase()
                      .indexOf(this.filterData.search.toLowerCase()) !== -1
          )
          : [];
    },
    pageCount() {
      //return this.prizesAndDocuments ? ~~(this.prizesAndDocuments.length / this.itemsPerPage) + 1 : 1;
      return this.prizesAndDocuments ? Math.ceil(this.prizesAndDocuments.length / this.itemsPerPage) : 1;
    },
  },

  watch: {
    // eslint-disable-next-line no-unused-vars
    "$store.state.employees.filter": function (newValue, oldValue) {
      if(this.user) {
        if(newValue != null) {
          this.filterData.company = newValue.company
        }

        this.filterItems();
      }
    },
    // eslint-disable-next-line no-unused-vars
    "$store.state.lists.filter": function (newValue, oldValue) {
      if(newValue != null) {
        this.filterData.search = newValue.search
        this.filterData.promotion = newValue.promotion
        this.filterData.taxStatus = newValue.taxStatus ? newValue.taxStatus : TaxStatus.NONPAID
        this.filterData.year = newValue.year ? newValue.year : 2023
      }

      if(newValue.search !== oldValue.search)
        this.filterItems();
      else if(newValue.promotion !== oldValue.promotion) {
        this.prizesAndDocuments = []
        this.getDocumentsAndPrizes()
      }
      else if(newValue.taxStatus !== oldValue.taxStatus) {
        this.prizesAndDocuments = []
        this.getDocumentsAndPrizes()
      }
      else if(newValue.year !== oldValue.year) {
        this.getUsersTaxes()
      }
    },
    // eslint-disable-next-line no-unused-vars
    "$store.state.lists.documents": function (newValue, oldValue) {
      this.filterItems();
    },
    // eslint-disable-next-line no-unused-vars
    "$store.state.lists.questions": function (newValue, oldValue) {
      this.filterItems();
    },
    pageCount() {
      if(this.pageCount < this.itemsPerPage)
        this.page = 1;
    },
    isLoading() {
      if(this.isLoading)
        this.$progress.start();
      else
        this.$progress.finish();
    },
  },
  methods: {
    ...mapGetters(["GET_USER","GET_LISTS","GET_EMPLOYEES","GET_PROMOTIONS",]),
    ...mapActions(["SET_LISTS_FILTER","GET_PROMOTION_PRIZES","GET_PROMOTION_PRIZE","GET_TAXES_DOWNLOAD","GET_USERS_TAXES","GET_USERS_TAXES_DOWNLOAD",]),

    TaxStatuses,

    firstBookkeepingScreenOpen() {
      this.firstBookkeepingScreenOpened = true;
    },
    secondBookkeepingScreenOpen() {
      this.firstBookkeepingScreenOpened = false;

      this.getUsersTaxes()
    },

    setListsSearch(e) {
      if(hasContent(e.target.value)) {
        this.filterData.search = e.target.value
        this.filterItems()
      }

      if (SAVE_PAGES_FILTERS) {
        this.SET_LISTS_FILTER({
          ...this.lists_filter,
          search: e.target.value,
        });
      }
    },

    setListsPromotion(data) {
      if (SAVE_PAGES_FILTERS) {
        this.SET_LISTS_FILTER({
          ...this.lists_filter,
          promotion: !data ? null : data.id,
        });
      }
      else {
        this.filterData.promotion = !data ? null : data.id
        this.prizesAndDocuments = []

        this.getDocumentsAndPrizes()
      }
    },

    setListsTaxStatus(data) {
      if (SAVE_PAGES_FILTERS) {
        this.SET_LISTS_FILTER({
          ...this.lists_filter,
          taxStatus: !data ? TaxStatus.NONPAID : data.status,
        });
      }
      else {
        this.filterData.taxStatus = !data ? TaxStatus.NONPAID : data.status
        this.prizesAndDocuments = []

        this.getDocumentsAndPrizes()
      }
    },

    setListsYear(data) {
      this.SET_LISTS_FILTER({
        ...this.lists_filter,
        year: !data ? 2023 : data,
      });
    },

    getDocumentsAndPrizes() {
      if(this.user) {
        this.isLoading = true

        let page = this.prizesAndDocuments ? Math. floor(this.prizesAndDocuments.length / this.itemsPerPage) : 0

        this.GET_PROMOTION_PRIZES({
          promotion: this.filterData.promotion,
          search: this.filterData.search,
          page,
          count: this.itemsPerPage,
          status: this.filterData.taxStatus
        }).then((response) => {
          this.isLoading = false

          if (response.success) {
            // check pagination data
            let prizes = this.prizesAndDocuments ? JSON.parse(JSON.stringify(this.prizesAndDocuments)) : [];

            for (var i = 0; i < response.prizes.length; i++) {
              let prize = response.prizes[i];

              if (prizes.filter(item => item.id === prize.id).length === 0)
                prizes.push(prize)
            }

            this.prizesAndDocuments = prizes ? prizes : []
          }
        });
      }
    },

    getDocumentAndPrize(item) {
      if(this.user && item) {
        this.isLoading = true

        this.GET_PROMOTION_PRIZE({
          id: item.id,
        }).then((response) => {
          this.isLoading = false

          if (response.success) {
            // try to update existing prize object
            if (response.prize && this.prizesAndDocuments) {
              this.prizesAndDocuments = this.prizesAndDocuments.map((prize) => {
                if (prize.id === response.prize.id)
                  return response.prize
                else
                  return prize
              })
            }
          }
        });
      }
    },



    getUsersTaxes() {
      if(this.user && !this.isLoading) {
        this.$notify({
          id: 'getUsersTaxes',
          duration: 9999999999,
          type: "notification",
          title: this.$t('Errors.wait_finish'),
          text: this.$t('TaxText.get_data_started'),
        });

        this.isLoading = true

        this.GET_USERS_TAXES({
          year: this.filterData.year,
        }).then((response) => {
          this.$notify.close('getUsersTaxes');
          this.isLoading = false

          if (response.success) {
            this.$notify({
              type: "success",
              title: this.$t('Errors.success_operation'),
              text: this.$t('TaxText.get_data_received'),
            });

            this.usersTaxes = response.users ? response.users : []
          }
          else {
            this.$notify({
              type: "error",
              title: this.$t('Errors.not_success_operation'),
              text: response.message ? response.message : this.$t('Errors.error_get_data'),
            });
          }
        });
      }
    },

    getUserTaxes(item) {
      if(this.user && item) {
        this.isLoading = true

        this.GET_USERS_TAXES({
          user: item.id,
        }).then((response) => {
          this.isLoading = false

          if (response.success) {
            // try to update existing prize object
            if (response.user && this.usersTaxes) {
              this.usersTaxes = this.usersTaxes.map((user) => {
                if (user.id === response.user.id)
                  return response.user
                else
                  return user
              })
            }
          }
          else {
            this.$notify({
              type: "error",
              title: this.$t('Errors.not_success_operation'),
              text: response.message ? response.message : this.$t('Errors.error_get_data'),
            });
          }
        });
      }
    },

    getNextDocumentsAndPrizes() {
      window.onscroll = () => {
        let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
        if (bottomOfWindow) {
          this.getDocumentsAndPrizes()
        }
      }
    },

    filterItems() {

    },

    // requests
    downloadTaxesList(format) {
      if (format) {
        if(this.filterData.promotion) {
          this.isLoading = true

          // download specific format
          this.GET_TAXES_DOWNLOAD({
            format,
            promotion: this.filterData.promotion,
            name: this.selectedPromotionName
          }).then(() => {
            this.isLoading = false

            this.$notify({
              type: "success",
              title: this.$t('Errors.success_operation'),
              text: this.$t('Errors.file_is_generated'),
            });
          }).catch(() => {
            this.isLoading = false

            this.$notify({
              type: "error",
              title: this.$t('Errors.not_success_operation'),
              text: this.$t('Errors.file_is_not_generated'),
            });
          });
        }
        else {
          this.$notify({
            type: "error",
            title: this.$t('Errors.not_success_operation'),
            text: this.$t('Errors.promotion_is_not_selected'),
          });
        }
      } else {
        this.$notify({
          type: "error",
          title: this.$t('Errors.not_success_operation'),
          text: this.$t('Errors.error_format'),
        });
      }
    },

    // requests
    downloadUsersTaxesList(format) {
      if (format) {
        if(this.filterData.promotion) {
          this.isLoading = true

          // download specific format
          this.GET_USERS_TAXES_DOWNLOAD({
            format,
            year: this.filterData.year,
          }).then(() => {
            this.isLoading = false

            this.$notify({
              type: "success",
              title: this.$t('Errors.success_operation'),
              text: this.$t('Errors.file_is_generated'),
            });
          }).catch(() => {
            this.isLoading = false

            this.$notify({
              type: "error",
              title: this.$t('Errors.not_success_operation'),
              text: this.$t('Errors.file_is_not_generated'),
            });
          });
        }
        else {
          this.$notify({
            type: "error",
            title: this.$t('Errors.not_success_operation'),
            text: this.$t('Errors.promotion_is_not_selected'),
          });
        }
      } else {
        this.$notify({
          type: "error",
          title: this.$t('Errors.not_success_operation'),
          text: this.$t('Errors.error_format'),
        });
      }
    }

  }
}
</script>

<style lang="scss" src="@/assets/scss/common.scss"></style>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixin.scss";

.bookkeeping {
  flex: 1;
  width: 100%;
  min-width: 1150px;

  .table {
    @include grid-table-row(163px, 20px, 11% 92px 10% 73px 7% 73px 15% 82px 9% 82px 9% 60px 3%);

    &__cell {
      &:first-child {
        grid-column-start: 1;
        grid-column-end: 2;
      }
      &:nth-child(2) {
        grid-column-start: 3;
        grid-column-end: 4;
      }
      &:nth-child(3) {
        grid-column-start: 5;
        grid-column-end: 6;
      }
      &:nth-child(4) {
        grid-column-start: 7;
        grid-column-end: 8;
      }
      &:nth-child(5) {
        grid-column-start: 9;
        grid-column-end: 10;
      }
      &:nth-child(6) {
        grid-column-start: 11;
        grid-column-end: 12;
      }
      &:nth-child(7) {
        grid-column-start: 13;
        grid-column-end: 14;
      }
    }
  }

  .page-header__requisitesDownload {
    @include purple-button;
    width: 200px;
  }
}

.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  &__title {
    @include page-header;
  }
}

.buttons-panel {
  display: flex;
  align-items: center;
  padding: 25px 110px 25px 30px;
  background-color: $white;
  border-radius: 10px;
  box-shadow: 0px 0px 8px 2px #CED5F480;
}

.buttons-panel__firstScreen {
  display: flex;
  align-items: center;
  margin-right: 25px;
  padding-right: 26px;
  position: relative;
  cursor: pointer;
  &::after {
    content: "";
    position: absolute;
    top: -4.5px;
    right: 0;
    width: 2px;
    height: 37px;
    background-color: $bodyBackground;
  }

  &-text {
    font-size: 16px;
    font-weight: 400;
    margin-right: 7px;
  }

  &-counter {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    min-width: 28px;
    height: 28px;
    min-height: 28px;
    background: $inputCaption;
    border-radius: 50px;
    p {
      color: $white;
    }
  }

  &.active {
    .buttons-panel__firstScreen-text {
      font-weight: 600;
    }

    .buttons-panel__firstScreen-counter {
      background: linear-gradient(180deg, #8F21C9 0%, #8F21C9 0.01%, #4640B9 100%);
    }
  }
}

.buttons-block {
  display: flex;
}

.page-header__outload {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  width: 180px;
  padding: 7px 0;
  background-color: $white;
  border-radius: 5px;
  border: 1px solid $purple;
  cursor: pointer;
  // border-image: linear-gradient(#8F21C9, #4640B9) 30 / 1 / 0 stretch;
  svg {
    margin-right: 5px;
  }
  img {
    margin-right: 5px;
  }
  &-text {
    font-size: 14px;
    font-weight: 16px;
    color: $purple;
  }
}

.buttons-panel {
  display: flex;
  align-items: center;
  padding: 25px 110px 25px 30px;
  background-color: $white;
  border-radius: 10px;
  box-shadow: 0px 0px 8px 2px #CED5F480;
}

.buttons-panel__period {
  // display: flex;
  // align-items: center;
  // position: relative;
  // &::after {
  //   content: "/";
  //   position: absolute;
  //   right: 130px;
  //   top: 50%;
  //   transform: translateY(-50%);
  //   color: $inputCaption;
  // }

  // &-text {
  //   margin-right: 30px;
  // }

  // &-input {
  //   width: 122px;
  //   padding: 10px 4px;
  //   font-size: 12px;
  //   font-weight: 500;
  //   border: 1px solid $inputCaption;
  //   border-radius: 5px;
  //   box-shadow: 0px 0px 2px 0px #00000040;
  //   outline: none;
  //   position: relative;
  //   &:last-child {
  //     margin-left: 20px;
  //   }

  //   &::placeholder {
  //     color: $inputCaption;
  //   }
  // }
  @include period-input;
  &-input {
    text-align: left;
  }
}

// DELETE THIS FAKE-BUTTON STYLES START
.fake-button {
  border: 1px solid #333;
  background-color: #fff;
  width: 170px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  cursor: pointer;
}
// DELETE THIS FAKE-BUTTON STYLES START


</style>
