<template>
    <!--v-date-picker v-model="dateFrom" :mode="mode" is24hr :popover="{ visibility: 'click' }">
        <template v-slot="{ inputValue, inputEvents }">
            <input
                class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300 v-date-picker__input"
                :disabled="!(editPermission)"
                :value="inputValue"
                v-on="inputEvents"
                is24hr="true"
                :placeholder="$t('TableHeaders.start_date')"
                :required="required" oninvalid="this.setCustomValidity('Выберите дату')" oninput="this.setCustomValidity('')"
            />
        </template>
    </v-date-picker>
    <v-date-picker v-model="dateTo" :mode="mode" is24hr :popover="{ visibility: 'click' }">
        <template v-slot="{ inputValue, inputEvents }">
            <input
                class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300 v-date-picker__input"
                :disabled="!(editPermission)"
                :value="inputValue"
                v-on="inputEvents"
                is24hr="true"
                :placeholder="$t('TableHeaders.end_date')"
                :required="required" oninvalid="this.setCustomValidity('Выберите дату')" oninput="this.setCustomValidity('')"
            />
        </template>
    </v-date-picker-->

  <div style="width: 265px; display: grid; grid-template-columns: 1fr 1fr; column-gap: 20px;">

    <div class="step__item textarea required" style="width: 122px;">
      <div class="date-picker">
        <div class="date-start">
          <input
              :disabled="!editPermission"
              :value="timeValue(dateFrom)"
              class="step__item-input"
              :placeholder="$t('TableHeaders.start_date')"
              required
          />
          <Datepicker
              :disabled="!editPermission"
              v-model="dateFrom"
              class="d-comp"
              valueType="format"
              locale="ru"
              autoApply
              hideInputIcon
              closable
              :clearable="false"
              :selectText="$t('Buttons.ok')"
              :cancelText="$t('Buttons.cancel')"
          ></Datepicker>
        </div>
      </div>
    </div>

    <div class="step__item textarea required" style="width: 122px;">
      <div class="date-picker">
        <div class="date-start">
          <input
              :disabled="!editPermission"
              :value="timeValue(dateTo)"
              class="step__item-input"
              :placeholder="$t('TableHeaders.end_date')"
              :required="required"
          />
          <Datepicker
              :disabled="!editPermission"
              v-model="dateTo"
              class="d-comp"
              valueType="format"
              locale="ru"
              autoApply
              hideInputIcon
              closable
              :clearable="false"
              :selectText="$t('Buttons.ok')"
              :cancelText="$t('Buttons.cancel')"
          ></Datepicker>
        </div>
      </div>
    </div>

  </div>

</template>

<script>

import {parseDate, parseFullTime} from "@/tools/dateTools";

export default {
  name: 'DateTimePicker',
  emits: ["updateDates"],
  components: {
  },
  props: {
    editPermission: {
      type: Boolean,
      default: true,
    },
    start: String,
    end: String,
    mode: {
      type: String,
      default: 'date',
    },
    required: {
      type: Boolean,
      default: false,
    },
    object: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      dateFrom: null,
      dateTo: null
    }
  },
  watch: {
    start: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler () {
        this.dateFrom = this.start
      }
    },
    end: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler () {
        this.dateTo = this.end
      }
    },

    dateFrom: function() {
      this.onChangeDate()
    },
    dateTo: function() {
      this.onChangeDate()
    },
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    parseFullTime,
    parseDate,

    onChangeDate() {
      this.$emit("updateDates", this.dateFrom, this.dateTo, this.object)
    },
    onDayClick(day) {
      console.log(day)
    },
    timeValue(value) {
      return (this.mode === 'date') ? parseDate(value) : parseFullTime(value)
    }
  },
}
</script>

<style lang="scss" src="@/assets/scss/common.scss"></style>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixin.scss";

.datetimepicker__wrapper {
    display: flex;
    align-items: center;
    position: relative;
    &::after {
        content: "/";
        position: absolute;
        display: flex;
        align-items: center;
        height: 35px;
        right: 130px;
        top: 0;
        color: $inputCaption;
    }
    .datetimepicker__from {
        margin-right: 20px;
    }
}

.v3dp__datepicker {
    .v3dp__input_wrapper {
        input {
            width: 122px;
            padding: 10px 4px;
            font-size: 12px;
            font-weight: 500;
            border: 1px solid $inputCaption;
            border-radius: 5px;
            box-shadow: 0px 0px 2px 0px #00000040;
            outline: none;
            position: relative;
            &:last-child {
                margin-left: 20px;
            }

            &::placeholder {
                color: $inputCaption;
            }
        }
    }
    .v3dp__popout {
        width: 220px;
        padding: 15px;
        &:last-child {
            padding-bottom: 93px;
        }
    }
    .v3dp__heading {
        height: fit-content;
    }
    .v3dp__heading__button {
        position: relative;
        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 8px;
            height: 8px;
            background: url('../assets/img/DatePickerIcon.jpg');
            background-size: contain;
            background-repeat: no-repeat;
        }
        .v3dp__heading__icon {
            g {
                display: none;
            }
        }
        &:last-child {
            &::before {
                transform: scale(-1, -1) translateY(50%);
                right: 0;
                left: unset;
            }
        }
    }

    .v3dp__heading__center {
        font-weight: 400;
        font-size: 16px;
        text-transform: capitalize;
    }

    .v3dp__body {
        .v3dp__subheading {
            margin-top: 10px;
            padding: 3px 5px;
            background: $bodyBackground;
            span {
                font-weight: 10px;
                line-height: 12px;
                color: $black;
                text-transform: capitalize;
            }
        }
        .v3dp__divider {
            display: none;
        }
        .v3dp__elements {
            button {
                padding: 0;
                &:not([disabled]) {
                    &:nth-child(6) {
                    color: $red;
                };
                &:nth-child(7n+6) {
                    color: $red;
                };
                &:nth-child(7n+7) {
                    color: $red;
                };
                }

            }
            button:hover {
                background: $bodyBackground;
                span {
                    background: inherit;
                    color: $activeText;
                }
            }
            .selected {
                background: $bodyBackground;
                span {
                    background: inherit;
                    color: $activeText;
                }
            }
        }
    }

}

.timepicker {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    position: absolute;
    top: 230px;
    left: 20px;
    // left: 50%;
    // transform: translateX(-50%);
    z-index: 10;
    &.disabled {
        display: none;
    }
    &__hours {
        display: flex;
        margin-right: 5px;
        position: relative;
        &::after {
            content: ':';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: -4px;
            font-size: 14px;
            color: $grayTriangle;
        }
        .timepicker__buttons {
            margin-right: 5px;
        }
    }
    &__minutes {
        display: flex;
        .timepicker__buttons {
            margin-left: 5px;
        }
    }
    &__title {
        color: #C4C4C4;
        font-size: 10px;
        line-height: normal;
        margin-right: 13px;
    }
    &__buttons {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        &-plus {
            position: relative;
            width: 0;
            height: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-bottom: 8px solid $grayTriangle;
            margin-bottom: 8px;
            cursor: pointer;
        }
        &-minus {
            position: relative;
            width: 0;
            height: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 8px solid $grayTriangle;
            cursor: pointer;
        }
        &-input {
            width: 30px;
            height: 30px;
            border: 1px solid #00000040;
            border-radius: 2px;
            box-shadow: 0px 0px 2px 0px #00000040 inset;
            font-size: 14px;
            color: $black;
            text-align: center;
        }
    }
}

.buttons-block.calendar {
    position: absolute;
    top: 265px;
    left: 20px;
    z-index: 10;
    .buttons-block__back,
    .buttons-block__forward {
        width: 90px;
        margin-left: 10px;
        p {
            font-size: 10px;
        }
    }
    .buttons-block__back {
        margin-left: 0px;
    }
}

.v-date-picker__input {
    width: 122px;
    padding: 10px 4px;
    font-size: 12px;
    font-weight: 500;
    border: 1px solid $inputCaption;
    border-radius: 5px;
    box-shadow: 0px 0px 2px 0px #00000040;
    outline: none;
    position: relative;
    &:last-child {
        margin-left: 20px;
    }

    &::placeholder {
        color: $inputCaption;
    }
}

.vc-day-content.vc-focusable {
    font-weight: 400 !important;
    &:hover {
        border-radius: 2px !important;
        background-color: #EDEFF8 !important;
    }
}

.vc-weekday {
    background-color: #EDEFF8 !important;
    font-size: 10px !important;
    color: #000 !important;
    font-weight: 400 !important;
    padding: 0 !important;
}

.vc-title {
    text-transform: capitalize;
    font-size: 16px !important;
    font-weight: 400 !important;
}

.vc-highlights.vc-day-layer {
    // background: #fff;
    // .vc-day-content.vc-focusable {
    //     background-color: #EDEFF8;
    // }
    .vc-highlight {
        background-color: #EDEFF8 !important;
        border-radius: 2px !important;
        color: $black !important;
    }
    & + .vc-day-content.vc-focusable {
        color: $black !important;
        font-weight: 400 !important;
    }
}

.vc-time-picker.vc-bordered {
    justify-content: center;
    position: relative;
    &::before {
        content: 'Время';
        position: absolute;
        color: #c4c4c4;
        top: 15px;
        left: 15px;
    }
}

.vc-time-date,
.vc-time-icon {
    display: none !important;
}

</style>
