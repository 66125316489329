import store from '@/store'
import {hasContent, isEmpty} from "@/tools/dataTools";

export const UsersType = {
    ADMIN: 'admin',
    SUPERVISOR: 'main_supervisor',
    MANAGER: 'main_manager',
    ACCOUNTANT: 'main_accountant',
    COMPANY_MANAGER: 'company_manager',
    PARTICIPANT: 'participant',
    CALL_CENTER_OPERATOR: 'call_center_operator',
};

export const UserSex = {
    MAN: 'man',
    WOMAN: 'woman',
};

export const Users = {
    // UUID
    id: '',
    // string
    role: UsersType.MANAGER,
    // string
    gender: UserSex.MAN,
    // string
    avatar: null,
    // string
    name: '',
    // string
    surname: '',
    // string
    lastname: '',
    // string
    phone: '',
    // string
    email: '',
    // date
    birthday: null,
    // string
    address: '',
    // json
    passport: {
        // string
        seria: '',
        // string
        number: '',
        // string
        who: '',
        // string
        department: '',
        // date
        when: '',
        // json
        scans: [],
        // json
        history: [],
    },
    // string - for inviting people to platform
    authcode: '',
    // string
    push_subscription: '',
    // boolean
    active: true,
    // json
    loyalty_cards: [],
    // json
    messages: [],
    // json
    history: [],
    // integer
    number_failed_logins: 0,
    // integer
    recovery_trys: 0,
    // json
    additional_fields: [],

    // data from other tables
    companies: [],
};

export const UsersRequiredVerify = (user) => {
    if(hasContent(user)) {
        if(hasContent(user.id)) {
            return !!(hasContent(user.name) &&
              hasContent(user.surname) &&
              hasContent(user.email) &&
              hasContent(user.role));
        }
        else {
            return !!(hasContent(user.name) &&
              hasContent(user.surname) &&
              hasContent(user.email) &&
              hasContent(user.role) &&
              hasContent(user.password));
        }
    }
    else {
        return false;
    }
};

export const NewUsers = () => {
    return JSON.parse(JSON.stringify(Users));
};

export const UsersFromBody = (data) => {
    let object = NewUsers()

    object.id = data.id;
    object.role = data.role;
    object.gender = data.gender ? data.gender : UserSex.MAN;

    object.avatar = data.avatar;
    object.name = data.name;
    object.surname = data.surname;
    object.lastname = data.lastname;
    object.phone = data.phone;
    object.email = data.email;
    object.birthday = data.birthday;
    object.address = data.address;

    object.passport = JSON.parse(JSON.stringify(data.passport));

    object.authcode = data.authcode;
    object.push_subscription = data.push_subscription;
    object.active = data.active;
    object.loyalty_cards = data.loyalty_cards;
    object.messages = data.messages;
    object.history = data.history;

    object.history = data.history;

    object.participants = data.participants;

    if(data.additional_fields != null)
        object.additional_fields = JSON.parse(JSON.stringify(data.additional_fields));

    // check if user is attached to any company
    if(data.companies != null)
        object.companies = JSON.parse(JSON.stringify(data.companies));

    return object;
};


export const UsersTypes = () => {
    return [
        {
            role: UsersType.ADMIN,
            label: store.state.$t('UsersTypes.admin'),
        },
        {
            role: UsersType.SUPERVISOR,
            label: store.state.$t('UsersTypes.supervisor'),
        },
        {
            role: UsersType.MANAGER,
            label: store.state.$t('UsersTypes.manager'),
        },
        {
            role: UsersType.ACCOUNTANT,
            label: store.state.$t('UsersTypes.accountant'),
        },
        {
            role: UsersType.COMPANY_MANAGER,
            label: store.state.$t('UsersTypes.company_manager'),
        },
        {
            role: UsersType.PARTICIPANT,
            label: store.state.$t('UsersTypes.participant'),
        },
        {
            role: UsersType.CALL_CENTER_OPERATOR,
            label: store.state.$t('UsersTypes.call_center_operator'),
        },
    ]
};

export const UserTypeText = (role) => {
    let userType = UsersTypes().find(item => item.role === role)
    if(role == null || userType == null)
        return store.state.$t('UsersTypes.unknown');
    else
        return userType.label;
};

export const PassportIsFull = (passport) => {
    if(isEmpty(passport.seria))
        return false
    else if(isEmpty(passport.number))
        return false
    else if(isEmpty(passport.who))
        return false
    else if(isEmpty(passport.department))
        return false
    else if(isEmpty(passport.when))
        return false
    else if(isEmpty(passport.scans) || passport.scans.length === 0)
        return false
    else
        return true
};


export const UserSexes = () => {
    return [
        {
            gender: UserSex.MAN,
            label: store.state.$t('UserSex.man'),
        },
        {
            gender: UserSex.WOMAN,
            label: store.state.$t('UserSex.woman'),
        },
    ]
};


export const userPrizesFiles = (user) => {
    let files = []

    // go through all participants
    if (hasContent(user) && hasContent(user.participants)) {
        user.participants.map((participant) => {

            // go through all prizes
            if(participant.prizes) {
                participant.prizes.map((prize) => {
                    // go through all prize files
                    if(prize.files) {
                        prize.files.map((file) => {
                            if(!files.includes(file))
                                files.push(file)
                        })
                    }
                })
            }
        })
    }

    return files
};

export const userPrizesIds = (user) => {
    let prizes = []

    // go through all participants
    if (hasContent(user) && hasContent(user.participants)) {
        user.participants.map((participant) => {

            // go through all prizes
            if(participant.prizes) {
                participant.prizes.map((prize) => {
                    prizes.push(prize.id)
                })
            }
        })
    }

    return prizes
};


// export module
export default {
    UsersType,
    UsersTypes,
    UserTypeText,

    Users,

    UsersRequiredVerify,
    NewUsers,
    UsersFromBody,

    PassportIsFull,

    UserSex,
    UserSexes,

    userPrizesFiles,
    userPrizesIds,
};
