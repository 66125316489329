import {hasContent} from "@/tools/dataTools";

export const CodeConfig = {
    // UUID
    company: '',
    // string
    companyName: '',
    // UUID
    promotion: '',
    // UUID
    promotionName: '',
    // integer
    count: 0,
    // integer
    length: 0,
    // string
    pattern: '',
    // string
    exclude: '',
    // boolean
    useDigits: false,
    // boolean
    useEnUpercase: false,
    // boolean
    useEnLowercase: false,
    // boolean
    useRuUpercase: false,
    // boolean
    useRuLowercase: false,
    // boolean
    generateQR: false,
    // json
    history: [],

    // boolean
    includePromotionToQR: false,

    // boolean - just local
    isNew: true,
};

export const NewCodeConfig = () => {
    return JSON.parse(JSON.stringify(CodeConfig));
};

export const CodeConfigFromBody = (data) => {
    let object = NewCodeConfig()

    if(data) {
        object.company = data.company;
        object.companyName = data.companyName;
        object.promotion = data.promotion;
        object.promotionName = data.promotionName;
        object.count = data.count ? data.count : 0;
        object.length = data.length ? data.length : 0;
        object.pattern = data.pattern;
        object.exclude = data.exclude;
        object.useDigits = data.useDigits;
        object.useEnUpercase = data.useEnUpercase;
        object.useEnLowercase = data.useEnLowercase;
        object.useRuUpercase = data.useRuUpercase;
        object.useRuLowercase = data.useRuLowercase;
        object.generateQR = data.generateQR;
        object.history = data.history;

        object.includePromotionToQR = data.includePromotionToQR;

        object.isNew = false;
    }

    return object;
};

export const CodeConfigRequiredVerify = (object) => {
    if(hasContent(object)) {

        return !!(hasContent(object.count) &&
          hasContent(object.length)
        );
    }
    else {
        return false;
    }
};

// export module
export default {
    CodeConfig,

    NewCodeConfig,
    CodeConfigFromBody,

    CodeConfigRequiredVerify,
};