<template>
    <div class="step__content">
        <div class="step__inner">
            <div class="step__inner-header">
                <p>
                    Условия для получения приза
                </p>
            </div>
            <div class="step__items">
                <div class="step__item textarea required">
                    <p class="step__item-text">
                        Категория приза<span>*</span>
                    </p>
                    <input class="step__item-input" type="text" placeholder="Введите название сайта">
                </div>
                <div class="step__item textarea required">
                    <p class="step__item-text">
                        Наименование приза<span>*</span>
                    </p>
                    <input class="step__item-input" type="text" placeholder="Введите название сайта">
                </div>
                <div class="step__item select required">
                    <p class="step__item-text">
                        Тип приза<span>*</span>
                    </p>
                    <input class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-button"></div>
                </div>
                <div class="step__item textarea required">
                    <p class="step__item-text">
                        Стоимость приза<span>*</span>
                    </p>
                    <input class="step__item-input" type="text" placeholder="Введите название сайта">
                </div>
                <div class="step__item textarea">
                    <p class="step__item-text">
                        Количество баллов для получения приза / участия в розыгрыше
                    </p>
                    <input class="step__item-input" type="text" placeholder="Введите название сайта">
                </div>
                <div class="step__item  textarea required">
                    <p class="step__item-text">
                        Количество призов<span>*</span>
                    </p>
                    <input class="step__item-input" type="text" placeholder="Введите название сайта">
                </div>
                <div class="step__item textarea">
                    <p class="step__item-text">
                        Сколько раз один участник может получить приз за период акции
                    </p>
                    <input @input="textShow" class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-popup">
                        <p></p>
                    </div>
                </div>
                <div class="step__item textarea">
                    <p class="step__item-text">
                        Сколько раз один участник может получить приз за день
                    </p>
                    <input @input="textShow" class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-popup">
                        <p></p>
                    </div>
                </div>
                <div class="step__item textarea">
                    <p class="step__item-text">
                        Сколько раз один участник может получить приз за неделю
                    </p>
                    <input @input="textShow" class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-popup">
                        <p></p>
                    </div>
                </div>
                <div class="step__item textarea">
                    <p class="step__item-text">
                        Сколько раз один участник может получить приз за месяц
                    </p>
                    <input @input="textShow" class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-popup">
                        <p></p>
                    </div>
                </div>
                <div class="step__item textarea required">
                    <p class="step__item-text">
                        Срок вручения приза в рабочих днях<span>*</span>
                    </p>
                    <input @input="textShow" class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-popup">
                        <p></p>
                    </div>
                </div>
                <div class="step__item textarea">
                    <p class="step__item-text">
                        Сколько один участник может подать заявок за период акции
                    </p>
                    <input @input="textShow" class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-popup">
                        <p></p>
                    </div>
                </div>
                <div class="step__item textarea">
                    <p class="step__item-text">
                        Сколько один участник может подать заявок за день
                    </p>
                    <input @input="textShow" class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-popup">
                        <p></p>
                    </div>
                </div>
                <div class="step__item textarea">
                    <p class="step__item-text">
                        Сколько один участник может подать заявок за неделю
                    </p>
                    <input @input="textShow" class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-popup">
                        <p></p>
                    </div>
                </div>
                <div class="step__item textarea">
                    <p class="step__item-text">
                        Сколько один участник может подать заявок за месяц
                    </p>
                    <input @input="textShow" class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-popup">
                        <p></p>
                    </div>
                </div>
                <div class="step__item select required">
                    <p class="step__item-text">
                        Автоматическое вручение<span>*</span>
                    </p>
                    <input class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-button"></div>
                </div>
                <div class="step__item select required">
                    <p class="step__item-text">
                        Автоматическая заявка на участие в розыгрыше<span>*</span>
                    </p>
                    <input class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-button"></div>
                </div>
                <div class="step__item period required">
                    <p class="step__item-text">
                        Даты сбора заявок для участия в розыгрыше<span>*</span>
                    </p>
                    <!-- <input class="step__item-period" type="text" placeholder="Дата начала">
                    <input class="step__item-period" type="text" placeholder="Дата завершения"> -->
                    <DateTimePicker/>
                </div>
                <div class="step__item period required">
                    <p class="step__item-text">
                        Время сбора заявок для участия в розыгрыше<span>*</span>
                    </p>
                    <!-- <input class="step__item-period" type="text" placeholder="Дата начала">
                    <input class="step__item-period" type="text" placeholder="Дата завершения"> -->
                    <DateTimePicker/>
                </div>
                <div class="step__item select required">
                    <p class="step__item-text">
                        Сгорание баллов при регистрации заявки/ получении приза<span>*</span>
                    </p>
                    <input class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-button"></div>
                </div>
                <div class="step__item period required">
                    <p class="step__item-text">
                        Даты розыгрыша<span>*</span>
                    </p>
                    <!-- <input class="step__item-period" type="text" placeholder="Дата начала">
                    <input class="step__item-period" type="text" placeholder="Дата завершения"> -->
                    <DateTimePicker/>
                </div>
                <div class="step__item select required">
                    <p class="step__item-text">
                        Действие для получения приза / участия в розыгрыше<span>*</span>
                    </p>
                    <input class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-button"></div>
                </div>
                <div class="step__item select required">
                    <p class="step__item-text">
                        Способ определения победителя<span>*</span>
                    </p>
                    <input class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-button"></div>
                </div>
                <div class="step__item select">
                    <p class="step__item-text">
                        Дополнительный приз
                    </p>
                    <input class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-button"></div>
                </div>
                <div class="step__item select required">
                    <p class="step__item-text">
                        Действие для получения дополнительного  приза<span>*</span>
                    </p>
                    <input class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-button"></div>
                </div>
                <div class="step__item select required">
                    <p class="step__item-text">
                        Направление выплат<span>*</span>
                    </p>
                    <input class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-button"></div>
                </div>
                <div class="step__item select">
                    <p class="step__item-text">
                        Комиссия
                    </p>
                    <input class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-button"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="step__content registry">
        <div class="step__inner">
            <div class="step__inner-header">
                <p>
                    Регистрация одного чека
                </p>
            </div>
            <div class="step__items">
                <div class="step__item textarea required">
                    <p class="step__item-text">
                        Группа товаров к которой относится код<span>*</span>
                    </p>
                    <input @input="textShow" class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-popup">
                        <p></p>
                    </div>
                </div>
                <div class="step__item textarea">
                    <p class="step__item-text">
                        Количество баллов за регистрацию товара из группы 
                    </p>
                    <input @input="textShow" class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-popup">
                        <p></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="step__content registry">
        <div class="step__inner">
            <div class="step__inner-header">
                <p>
                    Регистрация нескольких чеков
                </p>
            </div>
            <div class="step__items">
                <div class="step__item textarea required">
                    <p class="step__item-text">
                        Количество кодов с  товаров из группы 1<span>*</span>
                    </p>
                    <input @input="textShow" class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-popup">
                        <p></p>
                    </div>
                </div>
                <div class="step__item select required">
                    <p class="step__item-text">
                        Группа товаров 1<span>*</span>
                    </p>
                    <input class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-button"></div>
                </div>
                <div class="step__item textarea">
                    <p class="step__item-text">
                        Количество баллов за регистрацию товара из группы 1
                    </p>
                    <input @input="textShow" class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-popup">
                        <p></p>
                    </div>
                </div>
                <div class="step__item button">
                    <p class="step__item-text">
                        + Добавить
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>


export default {
    name: 'NewPromotionStepFourthSubStepThird',
    components: {
    },
    methods: {
        textShow(event) {
            if (event.target.value.length > 33) {
                console.dir(event.target.parentElement.querySelector(".step__item-popup"))
                event.target.parentElement.querySelector(".step__item-popup").classList.add("not-empty");
                event.target.parentElement.querySelector(".step__item-popup p").innerText = event.target.value;
            }
        }
    }
}
</script>

<style lang="scss" src="@/assets/scss/common.scss"></style>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixin.scss";

</style>