import store from '@/store'

export const CodesStatus = {
    ACTIVE: 'active',
    BLOCKED: 'blocked',
    USED: 'used',
    WIN: 'win',
};

export const Codes = {
    // UUID
    id: '',
    // UUID
    promotion: '',
    // string
    code: '',
    // string
    status: CodesStatus.ACTIVE,
    // json
    history: [],
};

export const NewCodes = () => {
    return JSON.parse(JSON.stringify(Codes));
};

export const CodesFromBody = (data) => {
    let object = NewCodes()

    if(data) {
        object.id = data.id;
        object.promotion = data.promotion;
        object.code = data.code;
        object.status = data.status;
        object.history = data.history;
    }

    return object;
};

export const CodesStatusText = (status) => {
    switch (status) {
        case CodesStatus.ACTIVE:
            return store.state.$t('CodesStatus.active');
        case CodesStatus.BLOCKED:
            return store.state.$t('CodesStatus.blocked');
        case CodesStatus.USED:
            return store.state.$t('CodesStatus.used');
        case CodesStatus.WIN:
            return store.state.$t('CodesStatus.win');
        default:
            return store.state.$t('CodesStatus.unknown');
    }
};


// export module
export default {
    CodesStatus,
    CodesStatusText,

    Codes,

    NewCodes,
    CodesFromBody,
};
