import store from '@/store'

export const ScamsStatus = {
    USER_BLOCKED: 'user_blocked',
    PARTICIPANT_BLOCKED: 'participant_blocked',
    CODE_SELECT: 'code_select',
    VIEW_PERSONAL_DATA: 'view_personal_data',
};

export const Scams = {
    // UUID
    id: '',
    // UUID
    user: '',
    // UUID
    company: '',
    // UUID
    promotion: '',
    // string
    description: '',
    // string
    status: ScamsStatus.USER_BLOCKED,
    // boolean
    visible: true,
    // json
    history: [],
};

export const NewScams = () => {
    return JSON.parse(JSON.stringify(Scams));
};

export const ScamsFromBody = (data) => {
    let object = NewScams()

    object.id = data.id;
    object.user = data.user;
    object.company = data.company;
    object.promotion = data.promotion;
    object.description = data.description;
    object.status = data.status;
    object.visible = data.visible;
    object.history = data.history;

    return object;
};

export const ScamsStatusText = (type) => {
    switch (type) {
        case ScamsStatus.USER_BLOCKED:
            return store.state.$t('ScamsStatus.user_blocked');
        case ScamsStatus.PARTICIPANT_BLOCKED:
            return store.state.$t('ScamsStatus.participant_blocked');
        case ScamsStatus.CODE_SELECT:
            return store.state.$t('ScamsStatus.code_select');
        case ScamsStatus.VIEW_PERSONAL_DATA:
            return store.state.$t('ScamsStatus.view_personal_data');
        default:
            return store.state.$t('ScamsStatus.unknown');
    }
};


// export module
export default {
    ScamsStatus,
    ScamsStatusText,

    Scams,

    NewScams,
    ScamsFromBody,
};
