<template>
    <div class="new-promotions">
        <div class="page-header">
            <h2 class="page-header__title">
                {{ (promotion && promotion.id) ? `${$t('PromotionText.promotion')}: ${promotion.name}` : $t('PromotionText.new_promotion') }}
            </h2>
        </div>
        <div class="steps-cart">
            <div :class="['steps-cart__item', {'active': stepCounter === 1}]" @click="changeStepManually(1)">{{ $t('PromotionText.step1') }}<br>{{ $t('PromotionText.step1_2') }}</div>
            <div :class="['steps-cart__item', {'active': stepCounter === 2}]" @click="changeStepManually(2)">{{ $t('PromotionText.step2') }}<br>{{ $t('PromotionText.step2_2') }}</div>
            <div :class="['steps-cart__item', {'active': stepCounter === 3}]" @click="changeStepManually(3)">{{ $t('PromotionText.step3') }}<br>{{ $t('PromotionText.step3_2') }}</div>
            <div :class="['steps-cart__item', {'active': stepCounter === 4}]" @click="changeStepManually(4)">{{ $t('PromotionText.step4') }}<br>{{ $t('PromotionText.step4_2') }}</div>
            <div :class="['steps-cart__item', {'active': stepCounter === 5}]" @click="changeStepManually(5)">{{ $t('PromotionText.step5') }}<br>{{ $t('PromotionText.step5_2') }}</div>
            <div :class="['steps-cart__item', {'active': stepCounter === 6}]" @click="changeStepManually(6)">{{ $t('PromotionText.step6') }}<br>{{ $t('PromotionText.step6_2') }}</div>
          <!--div
              v-if="stepCounter === 5"
              class="buttons-block">
            <div class="page-header__outload">
              <p class="page-header__outload-text" @click="addNewPrize">
                {{ $t('PrizeText.add') }}
              </p>
            </div>
          </div-->
          <!--div
              v-if="stepCounter === 6"
              class="buttons-block">
            <div class="page-header__outload">
              <p class="page-header__outload-text" @click="addNewAction">
                {{ $t('PromoActionText.add') }}
              </p>
            </div>
          </div-->

        </div>

        <NewPromotionsSteps
            ref="NewPromotionsSteps"
            :editPermission="editPermission"
            :promotion="promotion"
            :companies="companies"
            :step="stepCounter"
            @updatePromotion="updatePromotion"
            @savePromotion="savePromotion"
            @addNewStore="addNewStore"
            @addNewProduct="addNewProduct"
            @addNewPrize="addNewPrize"
            @addNewAction="addNewAction"
            @getDeterminings="getDeterminings"
            @step="changeStep"
        />
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

import NewPromotionsSteps from '../components/NewPromotionsStepsScreen.vue'

import {hasContent, isEmpty} from "@/tools/dataTools";

import {
  NewPrize
} from "@/models/prizes";

import {
  NewPromoAction
} from "@/models/promoActions";

import {
  NewPromotion,
  PromotionsFromBody,
} from "@/models/promotions";

import { NewStore, NewProduct } from "@/models/stores";

export default {
  name: 'NewPromotions',
  components: {
    NewPromotionsSteps
  },
  data() {
      return {
        stepCounter: 1,

        editPermission: true,

        promotion: NewPromotion()
      }
  },
  computed: {
    user() {
      return this.GET_USER();
    },
    companies() {
      return this.GET_COMPANIES();
    },
    promotions() {
      return this.GET_PROMOTIONS().all ? this.GET_PROMOTIONS().all : [];
    },
  },
  mounted() {
    let promotionId = this.$route.query.promotion ? this.$route.query.promotion : null
    let promotion = null

    if(promotionId)
      promotion = this.promotions.find(p => p.id === promotionId)

    promotion = promotion ? JSON.parse(JSON.stringify(promotion)) : NewPromotion()

    this.promotion = PromotionsFromBody(promotion)
  },
  methods: {
    ...mapActions(["CREATE_PROMOTION","UPDATE_PROMOTION","GET_DETERMINING",]),
    ...mapGetters(["GET_USER","GET_COMPANIES","GET_PROMOTIONS",]),

    NewPromotion,
    PromotionsFromBody,

    NewPrize,
    NewStore,
    NewProduct,

    changeStep(counter) {
        this.stepCounter = counter;
    },

    changeStepManually(newStep) {
      console.log({
        name: 'changeStepManually',
        newStep,
        currentStep: this.stepCounter,
        //currentStepCompleted: this.$refs.NewPromotionsSteps.checkStepCompleted(this.stepCounter)
      })

      // check if was clicked the current step - no any action
      if (newStep === this.stepCounter)
         return
      // check if clicked step is lower when current - go back
      // else if (newStep < this.stepCounter)
      //   this.$refs.NewPromotionsSteps.changeStep(newStep)
      // check if current step is completed
      if (this.$refs.NewPromotionsSteps.checkStepCompleted(this.stepCounter))
        this.$refs.NewPromotionsSteps.changeStep(newStep)
    },

    updatePromotion(item) {
      if (hasContent(item)) {
        this.promotion = item
      }
    },

    savePromotion(item) {
      if (hasContent(item)) {
        this.promotion = item

        if(isEmpty(this.promotion.id))
          this.createPromotion(this.promotion)
        else
          this.editPromotion(this.promotion)
      }
    },

    addNewStore() {
      if(isEmpty(this.promotion.stores))
        this.promotion.stores = []

      this.promotion.stores.push(NewStore())
      let item = JSON.parse(JSON.stringify(this.promotion));

      this.promotion = item
    },

    addNewProduct() {
      if(isEmpty(this.promotion.products))
        this.promotion.products = []

      this.promotion.products.push(NewProduct())
      let item = JSON.parse(JSON.stringify(this.promotion));

      this.promotion = item
    },

    addNewPrize() {
      if(isEmpty(this.promotion.prizes))
        this.promotion.prizes = []

      this.promotion.prizes.push(NewPrize())
      let item = JSON.parse(JSON.stringify(this.promotion));

      this.promotion = item
    },

    addNewAction() {
      if(isEmpty(this.promotion.actions))
        this.promotion.actions = []

      this.promotion.actions.push(NewPromoAction())
      let item = JSON.parse(JSON.stringify(this.promotion));

      this.promotion = item
    },

    getDeterminings() {
      // get determinings data
      this.GET_DETERMINING({})
    },

    createPromotion(promotion) {
      if(promotion) {

        this.CREATE_PROMOTION({
          ...promotion,
        }).then((res) => {
          if (res.success) {
            this.promotion.id = res.id
            this.$notify({
              type: "success",
              title: this.$t('Errors.success_operation'),
              text: this.$t('PromotionText.promo_created'),
            });

            this.$router.push({ name: "Promotions" }).catch(() => {});
          } else if (res.error === "badModelData" && res.message != null &&
              res.message.name === "SequelizeUniqueConstraintError") {
            this.$notify({
              type: "error",
              title: this.$t('Errors.error_not_ok'),
              text: this.$t('PromotionText.error_db'),
            });
          } else {
            this.$notify({
              type: "error",
              title: this.$t('Errors.error_not_ok'),
              text: this.$t('Errors.error_try_again'),
            });
          }
        });

      }
    },

    editPromotion(promotion) {
      if(promotion) {

        this.UPDATE_PROMOTION({
          ...promotion,
        }).then((res) => {
          if (res.success) {
            this.promotion.id = res.id
            this.$notify({
              type: "success",
              title: this.$t('Errors.success_operation'),
              text: this.$t('PromotionText.promo_updated'),
            });

            this.$router.push({ name: "Promotions" }).catch(() => {});
          } else if (res.error === "badModelData" && res.message != null &&
              res.message.name === "SequelizeUniqueConstraintError") {
            this.$notify({
              type: "error",
              title: this.$t('Errors.error_not_ok'),
              text: this.$t('PromotionText.error_db'),
            });
          } else {
            this.$notify({
              type: "error",
              title: this.$t('Errors.error_not_ok'),
              text: this.$t('Errors.error_try_again'),
            });
          }
        });
      }
    },

  },
}
</script>

<style lang="scss" src="@/assets/scss/common.scss"></style>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixin.scss";

.new-promotions {
  flex: 1;
  width: 100%;
  min-width: 1150px;

  .buttons-block {
      span {
          color: $red;
      }
      &-back {
        @include white-button;
        width: 190px;
      }
      &-forward {
        @include purple-button;
        width: 190px;
      }
  }
}

.steps-cart {
    display: flex;
    margin-bottom: 50px;
    &__item {
        font-size: 14px;
        font-weight: 500;
        color: $chatNotActiveText;
        cursor: pointer;
        &.active {
            color: $purple;
        }
        &:not(:last-child) {
            margin-right: 75px;
            position: relative;
            &:after {
                content: "";
                width: 45px;
                height: 10px;
                position: absolute;
                top: 50%;
                right: -60px;
                transform: translateY(-50%);
                background: url("../assets/img/steps-after.png");
                background-size: cover;
            }
        }
    }
}

.buttons-block {
    &__validation-rule {
        margin: 0 auto 0 0;
    }
    &__back {
        @include white-button;
        width: 190px;
    }
    &__forward {
        @include purple-button;
        width: 190px;
    }
}

</style>
