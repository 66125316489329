<template>
    <div class="step__table three-columns" style="margin-bottom: 20px;">
        <div class="table three-columns">
            <div class="table__row title">
              <p class="table__cell">{{ $t('PromoActionText.name') }}</p>
              <p class="table__cell">{{ $t('PromoActionText.type') }}</p>
              <p class="table__cell">{{ $t('TableHeaders.action') }}</p>
            </div>
            <div class="table__body">
                <div
                    v-for="action in item.actions" :key="action.id"
                    class="table__row"
                    @click="changeCurrentID(action)"
                >
                  <div class="table__cell">
                    <p>
                      {{ action.name }}
                    </p>
                  </div>
                    <div class="table__cell">
                        <p>
                            {{  PromoActionTypeText(action.type) }}
                        </p>
                    </div>
                    <div class="table__cell button">
                        <div class="table__cell-button" :title="$t('Buttons.delete')" @click="deleteAction(action)">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.0001 3.12868e-08C10.8855 -0.000110722 11.7389 0.293826 12.3928 0.824097C13.0467 1.35437 13.4539 2.08266 13.5344 2.86599H19.0325C19.2777 2.86605 19.5138 2.94881 19.6929 3.09753C19.8721 3.24625 19.981 3.44984 19.9977 3.66718C20.0145 3.88451 19.9377 4.09938 19.783 4.26837C19.6282 4.43736 19.4071 4.54786 19.1642 4.57755L19.0325 4.58558H18.2803L16.6454 17.173C16.5726 17.7317 16.2711 18.2472 15.7984 18.6214C15.3257 18.9955 14.7146 19.2022 14.0815 19.2021H5.9188C5.28569 19.2022 4.67464 18.9955 4.20189 18.6214C3.72915 18.2472 3.4277 17.7317 3.35489 17.173L1.71874 4.58558H0.967756C0.733898 4.58557 0.507953 4.51033 0.331707 4.37376C0.155461 4.2372 0.0408371 4.04855 0.00903235 3.84272L0 3.72578C9.86195e-06 3.51801 0.0847014 3.31727 0.238413 3.16069C0.392124 3.00411 0.604456 2.90227 0.836141 2.87401L0.967756 2.86599H6.4659C6.54639 2.08266 6.95356 1.35437 7.60747 0.824097C8.26139 0.293826 9.1148 -0.000110722 10.0001 3.12868e-08ZM8.06464 7.16497C7.7485 7.16497 7.48398 7.34266 7.42979 7.57652L7.41946 7.66709V14.6888L7.42979 14.7782C7.48398 15.012 7.7485 15.1897 8.06464 15.1897C8.38077 15.1897 8.64529 15.012 8.69948 14.7782L8.70981 14.6876V7.66823L8.69948 7.57652C8.64529 7.3438 8.38077 7.16497 8.06464 7.16497ZM11.9357 7.16497C11.6195 7.16497 11.355 7.34266 11.3008 7.57652L11.2905 7.66709V14.6888L11.3008 14.7782C11.355 15.012 11.6195 15.1897 11.9357 15.1897C12.2518 15.1897 12.5163 15.012 12.5705 14.7782L12.5808 14.6876V7.66823L12.5705 7.57652C12.5163 7.3438 12.2518 7.16611 11.9357 7.16611V7.16497ZM10.0001 1.71959C9.21949 1.71959 8.56787 2.21254 8.41948 2.86599H11.5808C11.4311 2.21254 10.7808 1.71959 10.0001 1.71959Z" fill="#BDBDBD"/>
                            </svg>
                        </div>
                        <!--div class="table__cell-button">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18 0H8C7.46957 0 6.96086 0.210714 6.58579 0.585786C6.21071 0.960859 6 1.46957 6 2V4H14C14.5304 4 15.0391 4.21071 15.4142 4.58579C15.7893 4.96086 16 5.46957 16 6V14H18C18.5304 14 19.0391 13.7893 19.4142 13.4142C19.7893 13.0391 20 12.5304 20 12V2C20 1.46957 19.7893 0.960859 19.4142 0.585786C19.0391 0.210714 18.5304 0 18 0Z" fill="#BDBDBD"/>
                                <path d="M2 20H12C13.103 20 14 19.103 14 18V8C14 6.897 13.103 6 12 6H2C0.897 6 0 6.897 0 8V18C0 19.103 0.897 20 2 20ZM4 10H10V12H4V10ZM4 14H10V16H4V14Z" fill="#BDBDBD"/>
                            </svg>
                        </div-->
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div 
        class="step__content"
        v-if="action && action.type === PromoActionType.INVITATION"
        style="margin-bottom: 20px;"
    >
        <div class="step__inner">
            <p class="step__header">
                {{ $t('PromoActionType.invitation') }}
            </p>

            <div>

              <div class="grid-container">

                <div class="step__item textarea required">
                  <p class="step__item-text">
                    {{ $t('PromoActionText.name') }}
                  </p>
                  <input class="step__item-input" type="text"
                         required oninvalid="this.setCustomValidity('Введите значение')" oninput="this.setCustomValidity('')"
                         v-model="action.name" :disabled="!(editPermission)">
                </div>

                <div class="step__item select required">
                    <p class="step__item-text">
                      {{ $t('PromoActionText.type') }}
                    </p>
                    <div class="dd object dd-type-filter">
                      <v-select
                          :placeholder="$t('FieldsText.select_from_list')"
                          :options="PromoActionTypes()"
                          :reduce="(option) => option.type"
                          label="label"
                          :clearable="false"
                          v-model="action.type"
                      >
                        <!-- eslint-disable-next-line vue/no-unused-vars  -->
                        <template #no-options="{ search, searching, loading }">
                          {{ $t('Errors.missing_options') }}
                        </template>
                      </v-select>
                      <div
                          class="dd-button"
                          @click="openSelectOptions('.dd-type-filter .vs__dropdown-toggle')"
                      ></div>
                    </div>
                </div>

                <div class="step__item select required">
                </div>

                <div class="step__item select required">
                </div>

              </div>


              <div class="grid-container">

                <div class="step__item textarea required">
                    <p class="step__item-text">
                      {{ $t('PromoActionText.quantityNewPersons') }}
                    </p>
                    <input class="step__item-input" type="text" name="quantityNewPersons" required
                           oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.setCustomValidity('')"
                           oninvalid="this.setCustomValidity('Введите значение')"
                           v-model="action.quantityNewPersons" :disabled="!(editPermission)">
                </div>

                <div class="step__item select required">
                    <p class="step__item-text">
                      {{ $t('PromoActionText.requiredNewPersonsRegisterBuy') }}
                    </p>
                    <div class="dd object dd-automaticalDelivery-filter">
                      <v-select
                          :placeholder="$t('FieldsText.select_from_list')"
                          :options="Actions()"
                          :reduce="(option) => option.action"
                          label="label"
                          :clearable="false"
                          v-model="action.requiredNewPersonsRegisterBuy"
                      >
                        <!-- eslint-disable-next-line vue/no-unused-vars  -->
                        <template #no-options="{ search, searching, loading }">
                          {{ $t('Errors.missing_options') }}
                        </template>
                      </v-select>
                      <div
                          class="dd-button"
                          @click="openSelectOptions('.dd-automaticalDelivery-filter .vs__dropdown-toggle')"
                      ></div>
                    </div>
                </div>

                <div class="step__item textarea required">
                    <p class="step__item-text">
                      {{ $t('PromoActionText.buysQuantity') }}
                    </p>
                    <input class="step__item-input" type="text" name="buysQuantity" required
                           oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.setCustomValidity('')"
                           oninvalid="this.setCustomValidity('Введите значение')"
                            v-model="action.buysQuantity" :disabled="!(editPermission)">
                </div>

                <div class="step__item textarea required">
                    <p class="step__item-text">
                      {{ $t('PromoActionText.codesQuantity') }}
                    </p>
                    <input class="step__item-input" type="text" name="codesQuantity" required
                           oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.setCustomValidity('')"
                           oninvalid="this.setCustomValidity('Введите значение')"
                            v-model="action.codesQuantity" :disabled="!(editPermission)">
                </div>

              </div>


              <div class="grid-container">

                <div class="step__item textarea">
                    <p class="step__item-text">
                      {{ $t('PromoActionText.codesQuantityPerPersonPerPromo') }}

                    </p>
                    <input class="step__item-input" type="text" name="codesQuantityPerPersonPerPromo" required
                           oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.setCustomValidity('')"
                           oninvalid="this.setCustomValidity('Введите значение')"
                           v-model="action.codesQuantityPerPersonPerPromo" :disabled="!(editPermission)">
                </div>

                <div class="step__item textarea">
                    <p class="step__item-text">
                      {{ $t('PromoActionText.codesQuantityPerPersonPerDay') }}
                    </p>
                    <input class="step__item-input" type="text" name="codesQuantityPerPersonPerDay" required
                           oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.setCustomValidity('')"
                           oninvalid="this.setCustomValidity('Введите значение')"
                           v-model="action.codesQuantityPerPersonPerDay" :disabled="!(editPermission)">
                </div>

                <div class="step__item textarea">
                    <p class="step__item-text">
                      {{ $t('PromoActionText.codesQuantityPerPersonPerWeek') }}
                    </p>
                    <input class="step__item-input" type="text" name="codesQuantityPerPersonPerWeek" required
                           oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.setCustomValidity('')"
                           oninvalid="this.setCustomValidity('Введите значение')"
                           v-model="action.codesQuantityPerPersonPerWeek" :disabled="!(editPermission)">
                </div>

                <div class="step__item textarea">
                    <p class="step__item-text">
                      {{ $t('PromoActionText.codesQuantityPerPersonPerMonth') }}
                    </p>
                    <input class="step__item-input" type="text" name="codesQuantityPerPersonPerMonth" required
                           oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.setCustomValidity('')"
                           oninvalid="this.setCustomValidity('Введите значение')"
                           v-model="action.codesQuantityPerPersonPerMonth" :disabled="!(editPermission)">
                </div>

              </div>


              <div class="grid-container">

                <div class="step__item select">
                    <p class="step__item-text">
                      {{ $t('PromoActionText.actionForCode') }}
                    </p>
                    <div class="dd object dd-actionForCode-filter">
                      <v-select
                          :placeholder="$t('FieldsText.select_from_list')"
                          :options="ActionsForGetCode()"
                          :reduce="(option) => option.action"
                          label="label"
                          :clearable="false"
                          v-model="action.actionForCode"
                      >
                        <!-- eslint-disable-next-line vue/no-unused-vars  -->
                        <template #no-options="{ search, searching, loading }">
                          {{ $t('Errors.missing_options') }}
                        </template>
                      </v-select>
                      <div
                          class="dd-button"
                          @click="openSelectOptions('.dd-actionForCode-filter .vs__dropdown-toggle')"
                      ></div>
                    </div>
                </div>

                <div class="step__item textarea">
                    <p class="step__item-text">
                      {{ $t('PromoActionText.scoreForAction') }}
                    </p>
                    <input class="step__item-input" type="text" name="scoreForAction" required
                           oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.setCustomValidity('')"
                           oninvalid="this.setCustomValidity('Введите значение')"
                           v-model="action.scoreForAction" :disabled="!(editPermission)">
                </div>

                <div class="step__item select">
                </div>

                <div class="step__item select">
                </div>

              </div>

            </div>

        </div>
    </div>

    <div
      class="step__content"
      v-if="action && action.type === PromoActionType.INTERACTIVE"
      style="margin-bottom: 20px;"
    >
        <div class="step__inner">
            <div class="step__inner-header">
                <p>
                  {{ $t('PromoActionType.interactive') }}
                </p>
            </div>

            <div>

              <div class="grid-container">

                <div class="step__item textarea required">
                  <p class="step__item-text">
                    {{ $t('PromoActionText.name') }}
                  </p>
                  <input class="step__item-input" type="text"
                         required oninvalid="this.setCustomValidity('Введите значение')" oninput="this.setCustomValidity('')"
                         v-model="action.name" :disabled="!(editPermission)">
                </div>

                <div class="step__item select required">
                    <p class="step__item-text">
                      {{ $t('PromoActionText.type') }}
                    </p>
                    <div class="dd object dd-type-filter">
                      <v-select
                          :placeholder="$t('FieldsText.select_from_list')"
                          :options="PromoActionTypes()"
                          :reduce="(option) => option.type"
                          label="label"
                          :clearable="false"
                          v-model="action.type"
                      >
                        <!-- eslint-disable-next-line vue/no-unused-vars  -->
                        <template #no-options="{ search, searching, loading }">
                          {{ $t('Errors.missing_options') }}
                        </template>
                      </v-select>
                      <div
                          class="dd-button"
                          @click="openSelectOptions('.dd-type-filter .vs__dropdown-toggle')"
                      ></div>
                    </div>
                </div>

                <div class="step__item select required">
                </div>

                <div class="step__item select required">
                </div>

              </div>


              <div class="grid-container">

                <div class="step__item textarea required">
                    <p class="step__item-text">
                      {{ $t('PromoActionText.quantityNewPersons') }}
                    </p>
                    <input class="step__item-input" type="text" name="quantityNewPersons" required
                           oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.setCustomValidity('')"
                           oninvalid="this.setCustomValidity('Введите значение')"
                           v-model="action.quantityNewPersons" :disabled="!(editPermission)">
                </div>

                <div class="step__item select required">
                    <p class="step__item-text">
                      {{ $t('PromoActionText.requiredNewPersonsRegisterBuy') }}
                    </p>
                    <div class="dd object dd-automaticalDelivery-filter">
                      <v-select
                          :placeholder="$t('FieldsText.select_from_list')"
                          :options="Actions()"
                          :reduce="(option) => option.action"
                          label="label"
                          :clearable="false"
                          v-model="action.requiredNewPersonsRegisterBuy"
                      >
                        <!-- eslint-disable-next-line vue/no-unused-vars  -->
                        <template #no-options="{ search, searching, loading }">
                          {{ $t('Errors.missing_options') }}
                        </template>
                      </v-select>
                      <div
                          class="dd-button"
                          @click="openSelectOptions('.dd-automaticalDelivery-filter .vs__dropdown-toggle')"
                      ></div>
                    </div>
                </div>

                <div class="step__item textarea required">
                    <p class="step__item-text">
                      {{ $t('PromoActionText.buysQuantity') }}
                    </p>
                    <input class="step__item-input" type="text" name="buysQuantity" required
                           oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.setCustomValidity('')"
                           oninvalid="this.setCustomValidity('Введите значение')"
                           v-model="action.buysQuantity" :disabled="!(editPermission)">
                </div>

                <div class="step__item textarea required">
                    <p class="step__item-text">
                      {{ $t('PromoActionText.codesQuantity') }}
                    </p>
                    <input class="step__item-input" type="text" name="codesQuantity" required
                           oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.setCustomValidity('')"
                           oninvalid="this.setCustomValidity('Введите значение')"
                           v-model="action.codesQuantity" :disabled="!(editPermission)">
                </div>

              </div>


              <div class="grid-container">

                <div class="step__item textarea">
                    <p class="step__item-text">
                      {{ $t('PromoActionText.codesQuantityPerPersonPerPromo') }}

                    </p>
                    <input class="step__item-input" type="text" name="codesQuantityPerPersonPerPromo" required
                           oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.setCustomValidity('')"
                           oninvalid="this.setCustomValidity('Введите значение')"
                           v-model="action.codesQuantityPerPersonPerPromo" :disabled="!(editPermission)">
                </div>

                <div class="step__item textarea">
                    <p class="step__item-text">
                      {{ $t('PromoActionText.codesQuantityPerPersonPerDay') }}
                    </p>
                    <input class="step__item-input" type="text" name="codesQuantityPerPersonPerDay" required
                           oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.setCustomValidity('')"
                           oninvalid="this.setCustomValidity('Введите значение')"
                           v-model="action.codesQuantityPerPersonPerDay" :disabled="!(editPermission)">
                </div>

                <div class="step__item textarea">
                    <p class="step__item-text">
                      {{ $t('PromoActionText.codesQuantityPerPersonPerWeek') }}
                    </p>
                    <input class="step__item-input" type="text" name="codesQuantityPerPersonPerWeek" required
                           oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.setCustomValidity('')"
                           oninvalid="this.setCustomValidity('Введите значение')"
                           v-model="action.codesQuantityPerPersonPerWeek" :disabled="!(editPermission)">
                </div>

                <div class="step__item textarea">
                    <p class="step__item-text">
                      {{ $t('PromoActionText.codesQuantityPerPersonPerMonth') }}
                    </p>
                    <input class="step__item-input" type="text" name="codesQuantityPerPersonPerMonth" required
                           oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.setCustomValidity('')"
                           oninvalid="this.setCustomValidity('Введите значение')"
                           v-model="action.codesQuantityPerPersonPerMonth" :disabled="!(editPermission)">
                </div>

              </div>


              <div class="grid-container">

                <div class="step__item select">
                    <p class="step__item-text">
                      {{ $t('PromoActionText.actionForCode') }}
                    </p>
                    <div class="dd object dd-actionForCode-filter">
                      <v-select
                          :placeholder="$t('FieldsText.select_from_list')"
                          :options="ActionsForGetCode()"
                          :reduce="(option) => option.action"
                          label="label"
                          :clearable="false"
                          v-model="action.actionForCode"
                      >
                        <!-- eslint-disable-next-line vue/no-unused-vars  -->
                        <template #no-options="{ search, searching, loading }">
                          {{ $t('Errors.missing_options') }}
                        </template>
                      </v-select>
                      <div
                          class="dd-button"
                          @click="openSelectOptions('.dd-actionForCode-filter .vs__dropdown-toggle')"
                      ></div>
                    </div>
                </div>

                <div class="step__item textarea">
                  <p class="step__item-text">
                    {{ $t('PromoActionText.scoreForAction') }}
                  </p>
                  <input class="step__item-input" type="text" name="scoreForAction" required
                         oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.setCustomValidity('')"
                         oninvalid="this.setCustomValidity('Введите значение')"
                         v-model="action.scoreForAction" :disabled="!(editPermission)">
                </div>

                <div class="step__item select required">
                    <p class="step__item-text">
                      {{ $t('PromoActionText.requiredComunity') }}
                    </p>
                    <div class="dd object dd-requiredComunity-filter">
                      <v-select
                          :placeholder="$t('FieldsText.select_from_list')"
                          :options="Actions()"
                          :reduce="(option) => option.action"
                          label="label"
                          :clearable="false"
                          v-model="action.requiredComunity"
                      >
                        <!-- eslint-disable-next-line vue/no-unused-vars  -->
                        <template #no-options="{ search, searching, loading }">
                          {{ $t('Errors.missing_options') }}
                        </template>
                      </v-select>
                      <div
                          class="dd-button"
                          @click="openSelectOptions('.dd-requiredComunity-filter .vs__dropdown-toggle')"
                      ></div>
                    </div>
                </div>

                <div class="step__item select required">
                    <p class="step__item-text">
                      {{ $t('PromoActionText.requiredRepost') }}
                    </p>
                    <div class="dd object dd-requiredRepost-filter">
                      <v-select
                          :placeholder="$t('FieldsText.select_from_list')"
                          :options="Actions()"
                          :reduce="(option) => option.action"
                          label="label"
                          :clearable="false"
                          v-model="action.requiredRepost"
                      >
                        <!-- eslint-disable-next-line vue/no-unused-vars  -->
                        <template #no-options="{ search, searching, loading }">
                          {{ $t('Errors.missing_options') }}
                        </template>
                      </v-select>
                      <div
                          class="dd-button"
                          @click="openSelectOptions('.dd-requiredRepost-filter .vs__dropdown-toggle')"
                      ></div>
                    </div>
                </div>

              </div>


              <div class="grid-container">

                <div class="step__item select">
                    <p class="step__item-text">
                      {{ $t('PromoActionText.socialMedia') }}
                    </p>
                    <div class="dd object dd-socialMedia-filter">
                      <v-select
                          :placeholder="$t('FieldsText.select_from_list')"
                          :options="Actions()"
                          :reduce="(option) => option.action"
                          label="label"
                          :clearable="false"
                          v-model="action.socialMedia"
                      >
                        <!-- eslint-disable-next-line vue/no-unused-vars  -->
                        <template #no-options="{ search, searching, loading }">
                          {{ $t('Errors.missing_options') }}
                        </template>
                      </v-select>
                      <div
                          class="dd-button"
                          @click="openSelectOptions('.dd-socialMedia-filter .vs__dropdown-toggle')"
                      ></div>
                    </div>
                </div>

                <div class="step__item textarea">
                    <p class="step__item-text">
                      {{ $t('PromoActionText.socialMediaAddress') }}
                    </p>
                    <input class="step__item-input" type="text"
                           v-model="action.socialMediaAddress" :disabled="!(editPermission)">
                </div>

                <div class="step__item textarea">
                    <p class="step__item-text">
                      {{ $t('PromoActionText.linkToPostForRepost') }}
                    </p>
                    <input class="step__item-input" type="text"
                           v-model="action.linkToPostForRepost" :disabled="!(editPermission)">
                </div>

                <div class="step__item textarea required">
                </div>

              </div>


              <div class="grid-container">

                <div class="step__item textarea">
                    <p class="step__item-text">
                      {{ $t('PromoActionText.requredAction') }}
                    </p>
                    <input class="step__item-input" type="text"
                           v-model="action.requredAction" :disabled="!(editPermission)">
                </div>

                <div class="step__item textarea required">
                    <p class="step__item-text">
                      {{ $t('PromoActionText.requredActionScore') }}
                    </p>
                    <input class="step__item-input" type="text" name="requredActionScore" required
                           oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.setCustomValidity('')"
                           oninvalid="this.setCustomValidity('Введите значение')"
                           v-model="action.requredActionScore" :disabled="!(editPermission)">
                </div>

                <div class="step__item textarea required">
                </div>

                <div class="step__item textarea required">
                </div>

              </div>

            </div>

        </div>
    </div>

</template>

<script>

import InputsMixin from "@/mixins/inputsEvent";



import {
  PromoActionType,
  PromoActionTypes,
  PromoActionTypeText,

  ActionsForGetCode,
} from "@/models/promoActions";


import {
  Actions,
} from "@/models/prizes";

export default {
  name: 'NewPromotionStepFifthSubStepFirst',
  emits: ["step","updatePromotion"],
  components: {
  },
  mixins: [InputsMixin],
  props: {
    editPermission: Boolean,
    promotion: Object,
  },
  data() {
      return {
        PromoActionType,
        PromoActionTypes,

        ActionsForGetCode,

        Actions,

        currentID: 0,
        item: {},
        action: null,
          actions: [
              {
                id: 1,
                name: "Конфигурация для Интерактивного конкурса",
                type: PromoActionType.INTERACTIVE,

                quantityNewPersons: 0,
                requiredNewPersonsRegisterBuy: 'no',
                buysQuantity: 0,
                codesQuantity: 0,
                codesQuantityPerPersonPerPromo: 0,
                codesQuantityPerPersonPerDay: 2,
                codesQuantityPerPersonPerWeek: 7,
                codesQuantityPerPersonPerMonth: 5,

                actionForCode: 'no',
                scoreForAction: 0,

                requiredComunity: 'no',
                requiredRepost: 'no',
                socialMedia: 'no',
                socialMediaAddress: null,
                linkToPostForRepost: null,
                requredAction: null,
                requredActionScore: 0,
              },
              {
                id: 2,
                name: "Конфигурация для пригласительных кодов",
                type: PromoActionType.INVITATION,

                quantityNewPersons: 0,
                requiredNewPersonsRegisterBuy: 'no',
                buysQuantity: 0,
                codesQuantity: 0,
                codesQuantityPerPersonPerPromo: 0,
                codesQuantityPerPersonPerDay: 2,
                codesQuantityPerPersonPerWeek: 7,
                codesQuantityPerPersonPerMonth: 5,

                actionForCode: 'no',
                scoreForAction: 0,

                requiredComunity: 'no',
                requiredRepost: 'no',
                socialMedia: 'no',
                socialMediaAddress: null,
                linkToPostForRepost: null,
                requredAction: null,
                requredActionScore: 0,

              },
          ]
      }
  },
  watch: {
    promotion: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler () {
        this.item = this.promotion

        // add stub actions to view UI
        // this.item.actions = this.item.actions.concat(this.actions)
        // console.log(this.item.actions)
      }
    },
  },
  methods: {
    PromoActionTypeText,

    textShow(event) {
        if (event.target.value.length > 25) {
            event.target.parentElement.querySelector(".step__item-popup").classList.add("not-empty");
            event.target.parentElement.querySelector(".step__item-popup p").innerText = event.target.value;
        }
    },
    changeCurrentID(action) {
      if(action)
        this.action = this.item.actions.find(p => p.id === action.id)
      else
        this.action = null
    },
    deleteAction(action) {
      if(action) {
        if (
            confirm(this.$t('PromoActionText.delete'))
        ) {
          this.item.actions = this.item.actions.filter(p => p.id !== action.id)

          if(this.action && this.action.id === action.id) {
            console.log(action)
            process.nextTick(() => {
              this.action = null
            });
          }

          this.$emit("updatePromotion", this.item)

          this.$notify({
            type: "success",
            title: this.$t('Errors.success_operation'),
            text: this.$t('PromoActionText.delete_success'),
          });
        }
      }
    },
  },
}
</script>

<style lang="scss" src="@/assets/scss/common.scss"></style>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixin.scss";

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto;
  padding: 10px;
}

.dd {
  position: relative;
  width: 100%;
  &.object {
    width: 265px !important;
    margin-top: 0px !important;
    font-size: 12px;
    font-weight: 500;
    // .dd-button {
    //   height: 27px !important;
    // }
  }
  .dd-button {
    @include ui-dropdown-button();
    border-radius: 0px 5px 5px 0px;
  }
  & ::v-deep .vs__dropdown-toggle {
    border: 1px solid $inputCaption;
    box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
  &:not(:first-child) {
    margin-top: 10px;
  }
}

</style>