import {CodeConfigFromBody, NewCodeConfig} from "@/models/codeConfig";
import store from '@/store'

export const PromotionsStatus = {
    NEW: 'new',
    ACTIVE: 'active',
    WAITING: 'waiting',
    FINISHED: 'finished',
    CANCELED: 'canceled',
};
export const AdditionalDataType = {
    TEXT: 'text',
    CHECK: 'check',
    DATE: 'date',
};

export const Promotions = {
    // UUID
    id: '',
    // UUID
    company: null,
    // UUID
    manager: null,

    // UUID
    commonPromotion: null,

    // string
    name: '',
    // date
    startedAt: null,
    // date
    endedAt: null,
    // string
    status: PromotionsStatus.NEW,

    // json
    register_participants: {
        // boolean
        phone: false,
        // boolean
        email: false,
    },

    // json
    register_codes: {
        // boolean
        qr_and_bill: true,
        // boolean
        promocode_and_bill: true,
        // boolean
        bill: false,
        // boolean
        qr: false,
        // boolean
        promocode: false,
    },

    // json
    prizes: [],

    // json
    actions: [],

    // json
    settings: {
        // boolean
        scoring: false,
        // boolean
        tax: true,
    },

    // json
    instant_prizes: {
        // integer
        required_score: null,
        // json
        required_actions: [],
        // json
        prizes: [],
    },
    // json
    draw_prizes: {
        // json
        conditions: {
            // integer
            required_score: null,
            // json
            required_actions: [],
        },
        // date
        drawDate: null,
        // json
        prizes: [],
        // json
        draw_algorithm: {},
        // boolean
        confirm_winner_by_manager: true,
        // boolean
        score_fire_after_draw: true,
        // boolean
        confirm_participant_to_draw: false,
    },
    // json
    codes: NewCodeConfig(),
    // json
    texts: {
        // string
        title: null,
        // string
        description: null,
        // json
        request_personal_data: [],
        // json
        responses_call_center: [],
        // json
        error_messages_promo: [],
        // json
        socials: [],
        // json
        emails: [],
        // json
        sms: [],
    },
    // json
    personal_data: {
        // boolean
        surname: false,
        // boolean
        name: false,
        // boolean
        lastname: false,
        // boolean
        birthday: false,
        // boolean
        phone: true,
        // boolean
        email: true,
        // boolean
        address: false,
        // boolean
        passport_data: false,
        // boolean
        gender: false,

        // string
        user_agreement: null,
        // string
        promotion_agreement: null,
        // string
        newsletters_agreement: null,
        // string
        textAboutPersonalData: null,

        // json
        additional_fields: [],
        /*
        has next structure:
        {
            name: 'Some name',
            type: 'text', // variants=['text','check','date',]
        }
         */
    },
    // json
    conditions: {
        // string
        get_score: '',
        // string
        temporary_block: '',
        // string
        permanent_block: '',
        // json
        registration_ways: [],
        // json
        registration_data: [],
        // json
        required_fields: [],
        // json
        required_promo_on_register: [],
    },
    // json
    communications: {
        // string
        hotPhoneNumber: null,
        // string
        site: null,
        // string
        promo: null,

        // boolean
        useMailing: false,
        // json
        mailing: {
            // string
            user: null,
            // string
            pass: null,
            // string
            host: 'smtp.mail.ru',
            // integer
            port: 465,
            // boolean
            verified: false,
        },

        // boolean
        sms: false,
        // string
        smsText: '',
        // boolean
        webChat: false,
        // string
        webChatText: '',
        // boolean
        appChat: false,
        // string
        appChatText: '',
        // boolean
        email: false,
        // string
        emailText: '',
        // boolean
        callCenter: false,
        // string
        callCenterText: '',
    },
    // json
    history: [],
    // json
    messages: [],
    // UUID
    operator: null,
    // json
    stores: [],
    // json
    products: [],
};

export const NewPromotion = () => {
    return JSON.parse(JSON.stringify(Promotions));
};

export const PromotionsFromBody = (data) => {
    let object = NewPromotion()

    object.id = data.id;
    object.company = data.company;
    object.manager = data.manager;

    object.commonPromotion = data.commonPromotion;

    object.name = data.name;
    object.startedAt = data.startedAt;
    object.endedAt = data.endedAt;
    object.status = data.status;

    object.register_participants = data.register_participants ? data.register_participants : {
        // boolean
        phone: false,
        // boolean
        email: false,
    }

    object.register_codes = data.register_codes ? data.register_codes : {
        // boolean
        qr_and_bill: true,
        // boolean
        promocode_and_bill: true,
        // boolean
        bill: false,
        // boolean
        qr: false,
        // boolean
        promocode: false,
    }

    object.prizes = data.prizes;
    object.actions = data.actions;

    object.settings = data.settings;

    object.instant_prizes = data.instant_prizes;
    object.draw_prizes = data.draw_prizes;
    object.codes = CodeConfigFromBody(data.codes);
    object.texts = data.texts;
    object.personal_data = data.personal_data;
    object.conditions = data.conditions;

    object.communications = data.communications;

    // check mailing data
    object.communications.useMailing = data.communications.useMailing ? data.communications.useMailing : false
    object.communications.mailing = data.communications.mailing ? data.communications.mailing : {
        // string
        user: null,
        // string
        sender: null,
        // string
        pass: null,
        // string
        host: 'smtp.mail.ru',
        port: 465,
        // boolean
        verified: false,
    }

    object.history = data.history;
    object.messages = data.messages ? data.messages : [];

    object.operator = data.operator;

    object.stores = data.stores ? data.stores : [];
    object.products = data.products ? data.products : [];

    return object;
};


export const PromotionsStatuses = () => {
    return [
        {
            status: PromotionsStatus.NEW,
            label: store.state.$t('PromotionsStatus.new'),
        },
        {
            status: PromotionsStatus.ACTIVE,
            label: store.state.$t('PromotionsStatus.active'),
        },
        // {
        //     status: PromotionsStatus.WAITING,
        //     label: store.state.$t('PromotionsStatus.waiting'),
        // },
        {
            status: PromotionsStatus.FINISHED,
            label: store.state.$t('PromotionsStatus.finished'),
        },
        {
            status: PromotionsStatus.CANCELED,
            label: store.state.$t('PromotionsStatus.canceled'),
        },
    ]
};


export const AdditionalDataTypes = () => {
    return [
        {
            type: AdditionalDataType.TEXT,
            label: store.state.$t('AdditionalDataType.text'),
        },
        {
            type: AdditionalDataType.CHECK,
            label: store.state.$t('AdditionalDataType.check'),
        },
        {
            type: AdditionalDataType.DATE,
            label: store.state.$t('AdditionalDataType.date'),
        },
    ]
};

export const PromotionsStatusText = (status) => {
    let promotionsStatus = PromotionsStatuses().find(item => item.status === status)
    if(status == null || promotionsStatus == null)
        return store.state.$t('PromotionsStatus.unknown');
    else
        return promotionsStatus.label;
};


// export module
export default {
    PromotionsStatus,
    PromotionsStatuses,
    PromotionsStatusText,

    Promotions,

    NewPromotion,
    PromotionsFromBody,

    AdditionalDataType,
    AdditionalDataTypes,
};
