<template>
    <div
        class="notification-modal"
        v-if="opened"
        @click.self="$emit('close-modal')"
    >
        <div class="notification-other">
            <ul class="notification-other__list">
                <li v-if="haveMessages"  class="notification-other__item" @click="openMessages">
                    <img class="notification-other__item-image" src="@/assets/img/notification/message-badge.svg" alt="notification avatar">
                    <p class="notification-other__item-name">{{ $t('NotificationsType.messages') }}</p>
                </li>
                <li v-if="haveCompanyMessages"  class="notification-other__item" @click="openCompanyMessages">
                    <img class="notification-other__item-image" src="@/assets/img/notification/message-badge.svg" alt="notification avatar">
                    <p class="notification-other__item-name">{{ $t('NotificationsType.companyMessages') }}</p>
                </li>
                <li v-if="haveScams"  class="notification-other__item" @click="openScam">
                  <img class="notification-other__item-image" src="@/assets/img/notification/scam-badge.svg" alt="notification avatar">
                    <p class="notification-other__item-name">{{ $t('NotificationsType.scams') }}</p>
                </li>
                <li v-if="haveLists"  class="notification-other__item" @click="openList">
                  <img class="notification-other__item-image" src="@/assets/img/notification/list-badge.svg" alt="notification avatar">
                    <p class="notification-other__item-name">{{ $t('NotificationsType.lists') }}</p>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: 'NotificationsListModal',
  props: {
    opened: Boolean,
  },
  methods: {
    ...mapGetters(["GET_USER","GET_COMPANIES","GET_SUSPICIOUS"]),

    openMessages() {
      this.$emit('open-user-modal')
    },
    openCompanyMessages() {
      this.$emit('open-company-modal')
    },
    openScam() {
      this.$router.push({ name: "SuspiciousParticipants" }).catch(() => {});
      this.$emit('close-modal')
    },
    openList() {
      this.$router.push({ name: "Lists" }).catch(() => {});
      this.$emit('close-modal')
    },
  },
  computed: {
    user() {
      return this.GET_USER();
    },
    haveMessages() {
      return this.user.newMessages
    },
    haveCompanyMessages() {
      let has = false

      const companies = this.GET_COMPANIES() ? this.GET_COMPANIES() : []

      companies.map(company => {
        if(company.newMessages) {
          has = true
        }
      })

      return has
    },
    haveScams() {
      return this.suspiciousParticipants.length > 0;
    },
    haveLists() {
      return false
    },
    suspiciousParticipants() {
      return this.GET_SUSPICIOUS() ? this.GET_SUSPICIOUS().filter(s => s.visible) : [];
    }
  },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixin.scss";

.notification-modal {
  position: absolute;
  top: 87px;
  right: 5%;
  width: 280px;
  border-radius: 10px;
  padding: 18px 10px 18px 15px;
  display: flex;
  z-index: 100;
  flex-flow: column nowrap;
  align-items: center;
  background-color: $white;
  box-shadow: 0px 0px 8px 2px $shadow;
}

.current-notification {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    align-items: center;
    position: relative;
    padding-bottom: 22px;
    margin-bottom: 18px;
    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 1px;
        background-color: $bodyBackground;
    }

    &__avatar {
        width: 90px;
        margin-bottom: 15px;
    }

    &__name {
        @include fz-14-500;
    }
}

.notification-other {
    width: 100%;
    &__list {

    }

    &__item {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        cursor: pointer;
    }

    &__item-image {
        margin-right: 11px;
    }

    &__item-name {
        font-size: 12px;
    }
}
</style>
