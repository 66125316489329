<template>
    <div class="delete-code">
        <div class="delete-code__inner">
            <div class="delete-code__header">
            {{ $t('CodesText.delete_code') }}
            </div>

            <div class="delete-code__text">
              {{ $t('CodesText.delete_code_text') }}
            </div>

            <div class="step__item select">
              <div class="new-promotion__checkbox">
                <input
                    type="checkbox" id="confirmDelete" value="confirmDelete"
                    v-model="confirmDelete" />
                <label for="email">{{ $t('CodesText.delete_confirmation') }}</label>
              </div>
            </div>

        </div>
        <div class="buttons-block">
            <div
                class="buttons-block__back"
                @click="$emit('close-modal')"
            >
                <p class="buttons-block__back-text">
                  {{ $t('Buttons.cancel') }}
                </p>
            </div>
            <div class="buttons-block__save" @click="deleteCode">
                <p class="buttons-block__save-text">
                  {{ $t('Buttons.delete') }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'DeleteCodeModal',
  components: {
  },
  data() {
    return {
      confirmDelete: false
    }
  },
  watch: {
  },
  methods: {
    deleteCode() {
      if(!this.confirmDelete) {
        this.$notify({
          type: "error",
          title: this.$t('Errors.error_not_ok'),
          text: this.$t('CodesText.delete_confirmation_miss'),
        });
      }
      else {
        this.$emit('confirm-modal')
      }
    },

    close() {
      this.$emit('close-modal');
    }
  },
  computed: {
  },
}
</script>

<style lang="scss" src="@/assets/scss/common.scss"></style>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixin.scss";

.delete-code {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 650px;
    transform: translate(-50%, -50%);
    background-color: $white;
    border-radius: 10px;
    padding: 50px 45px 40px 70px;
    box-shadow: 0px 0px 8px 2px rgba(206, 213, 244, 0.5);
    z-index: 1;
    &__inner {
        margin-bottom: 30px;
    }

    &__validation-rule {
        margin-bottom: 30px;
        span {
            color: $red;
        }
        &-back {
            @include white-button;
            width: 190px;
        }
        &-forward {

        }
    }

    &__header {
        width: 100%;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 30px;
        text-transform: uppercase;
    }

    .delete-code__item {
        margin-bottom: 0;
        &-text {
            font-size: 12px;
            font-weight: 500;
            text-transform: uppercase;
        }
    }

    .buttons-block__save {
        margin-left: 30px;
        margin-right: 0;
    }
}

.delete-code__columns {
    display: flex;
    flex-flow: row nowrap;
}

.delete-code__column {
    &:first-child {
        margin-right: 30px;
    }
}

.delete-code__item {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    width: 265px;
    margin-bottom: 20px;
    &-text {
        width: 100%;
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 5px;
        color: $black;
        span {
            color: $red;
        }
    }

    &-input {
        @include search-input;
    }

    &.textarea {
        grid-column: span 3;
        input {
            border-radius: 5px;
            width: 100%;
        }
    }

    &.select {
        .delete-code__item-text {
            span {
                color: $red;
            }
    }

        .delete-code__item-button {
            &::after {
                width: 9px;
                height: 7px;
                background: url('../assets/img/triangle.png');
            }
        }
    }

    &-button {
        @include search-input__button;
    }
}

.buttons-block {
    height: fit-content;
    margin: 0 auto;
    &__back {
        @include white-button;
        width: 190px;
    }
    &__save {
        @include purple-button;
        width: 190px;
        margin-left: 0;
        margin-right: 30px;
    }
}

.delete-code__checboxes {
    padding-top: 40px;
    &-text {
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: 20px;
    }
}

.delete-code__checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  &:not(:last-child) {
      margin-bottom: 10px;
  }
  &-checkbox {
    display: none;
  }
  &-fake {
    display: inline-block;
    width: 17px;
    height: 17px;
    margin-right: 12px;
    border-radius: 2px;
    box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.25);
    position: relative;
    &::before {
      content: '✔';
      position: absolute;
      color: $black;
      font-size: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
    }
  }
  &-text {
    font-size: 12px;
    font-weight: 500;
    color: $activeText;
  }
  .delete-code__checkbox-checkbox:checked + .delete-code__checkbox-fake::before {
    opacity: 1;
  }
  span {
    color: $red;
  }
}

</style>
