<template>
    <section class="step third">

      <!-- Promo sites information -->
        <div class="step__content">
            <div class="step__inner">
                <p class="step__header">
                  {{ $t('PromotionText.communication_information') }}
                </p>
                <div>

                  <div class="grid-container-3">

                    <div class="step__item textarea" style="width: 100%;">
                      <p class="step__item-text">
                        {{ $t('PromotionText.hotPhoneNumber') }}
                      </p>
                      <input class="step__item-input" type="tel"
                             oninput="this.value = this.value.replace(/[^0-9+]/g, '')"
                             pattern="[7-9]{1}[0-9]{10}"
                             maxlength="11"
                             :placeholder="$t('FieldsText.phone_rules')"
                             :disabled="!editPermission"
                             required
                             v-model="item.communications.hotPhoneNumber">
                    </div>

                    <div class="step__item textarea" style="width: 100%;">
                        <p class="step__item-text">
                            {{ $t('PromotionText.mainSite') }}
                        </p>
                        <input v-model="item.communications.site" class="step__item-input" type="text" :placeholder="$t('FieldsText.site_rules')">
                    </div>

                    <div class="step__item textarea" style="width: 100%;">
                        <p class="step__item-text">
                            {{ $t('PromotionText.promoSite') }}
                        </p>
                        <input v-model="item.communications.promo" class="step__item-input" type="text" :placeholder="$t('FieldsText.site_rules')">
                    </div>

                  </div>

                </div>
            </div>
        </div>

      <!-- Mailing information -->
      <div class="step__content" style="margin-top: 20px;">

        <div class="step__inner">
          <p class="step__header">
            {{ $t('PromotionText.mailings_information') }}
          </p>
          <div>

            <!-- Confirm use different Email account -->
            <div class="step__item select" style="width: 420px;">
              <div class="new-promotion__checkbox">
                <input
                    :disabled="!editPermission"
                    type="checkbox" id="useMailing"
                    v-model="item.communications.useMailing" />
                <label for="useMailing">{{ $t('PromotionText.useMailing') }}</label>
              </div>
            </div>

            <div v-if="item.communications.useMailing" class="grid-container-5">

              <!-- Email user account -->
              <div class="step__item textarea required">
                <p class="step__item-text">
                  {{ $t('FieldsText.email_req_label') }}
                </p>
                <input v-model="item.communications.mailing.user" class="step__item-input" type="email"
                       pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                       required :placeholder="$t('FieldsText.email_label_input')">
              </div>

              <!-- Email password account -->
              <div class="step__item textarea">
                <p class="step__item-text">
                  {{ $t('FieldsText.password_req_label') }}
                </p>
                <input v-model="item.communications.mailing.pass" class="step__item-input" type="password"
                       required :placeholder="$t('FieldsText.password_rules')">
              </div>

              <!-- SMTP Server address -->
              <div class="step__item textarea">
                <p class="step__item-text">
                  {{ $t('FieldsText.smtp_req_label') }}
                </p>
                <input v-model="item.communications.mailing.host" class="step__item-input" type="text"
                       required :placeholder="$t('FieldsText.smtp_rules')">
              </div>

              <!-- Server port account -->
              <div class="step__item textarea" style="width: 150px;">
                <p class="step__item-text">
                  {{ $t('FieldsText.port_req_label') }}
                </p>
                <input v-model="item.communications.mailing.port" class="step__item-input" type="number" min="1"
                       required :placeholder="$t('FieldsText.port_rules')">
              </div>

              <div class="buttons-block">
                <div
                    class="buttons-block__test"
                    @click="testEmailAccount"
                >
                  <p class="buttons-block__test-text">
                    {{ $t('Buttons.test') }}
                  </p>
                </div>
              </div>

            </div>

          </div>
        </div>

      </div>

      <!-- Answers templates information -->
      <div class="step__content" style="margin-top: 20px;">
        <div class="step__inner">
          <p class="step__header">
            {{ $t('PromotionText.answers_templates') }}
          </p>
          <div>

            <!--div class="grid-container">

              <div class="step__item select">
                  <p class="step__item-text">
                      Выберите действие
                  </p>
                  <input class="step__item-input" type="text" placeholder="Выберите дейсвие">
                  <div class="step__item-button"></div>
              </div>

              <div class="step__item select">
                  <p class="step__item-text">
                      Через какие сети возможна регистрация
                  </p>
                  <input class="step__item-input" type="text" placeholder="Выберите соц.сеть">
                  <div class="step__item-button"></div>
              </div>

              <div class="step__item select">
                  <p class="step__item-text">
                      Интерактивные действия
                  </p>
                  <input class="step__item-input" type="text" placeholder="Выберите из списка">
                  <div class="step__item-button"></div>
              </div>
              <div class="step__item select">
                  <p class="step__item-text">
                      В каких соц.сетях  используются интерактивные действия
                  </p>
                  <input class="step__item-input" type="text" placeholder="Выберите из списка">
                  <div class="step__item-button"></div>
              </div>
              <div class="step__item select">
                  <p class="step__item-text">
                      Какие действия при помощи СМС доступны
                  </p>
                  <input class="step__item-input" type="text" placeholder="Выберите дейсвие">
                  <div class="step__item-button"></div>
              </div>

            </div-->


            <!-- USE SMS channel -->
            <div class="grid-container">
              <div class="step__item select">
                <div class="new-promotion__checkbox">
                  <input
                      :disabled="!editPermission"
                      type="checkbox" id="useSMS"
                      v-model="item.communications.sms" />
                  <label for="useSMS">{{ $t('PromotionText.useSMS') }}</label>
                </div>
              </div>
              <div class="step__item textarea medium py-2">
                <p class="step__item-text">
                  {{ $t('TableHeaders.text_data') }}
                </p>
                <input class="step__item-input" type="text" :placeholder="$t('TableHeaders.text_example_response')"
                       :required="item.communications.sms"
                       v-model="item.communications.smsText" :disabled="!(editPermission && item.communications.sms)">
              </div>
            </div>

            <!-- USE Email channel -->
            <div class="grid-container">
              <div class="step__item select">
                <div class="new-promotion__checkbox">
                  <input
                      :disabled="!editPermission"
                      type="checkbox" id="useEmail"
                      v-model="item.communications.email" />
                  <label for="useEmail">{{ $t('PromotionText.useEmail') }}</label>
                </div>
              </div>
              <div class="step__item textarea medium py-2">
                <p class="step__item-text">
                  {{ $t('TableHeaders.text_data') }}
                </p>
                <input class="step__item-input" type="text" :placeholder="$t('TableHeaders.text_example_response')"
                       :required="item.communications.email"
                       v-model="item.communications.emailText" :disabled="!(editPermission && item.communications.email)">
              </div>
            </div>

            <!-- USE Call Center channel -->
            <div class="grid-container">
              <div class="step__item select">
                <div class="new-promotion__checkbox">
                  <input
                      :disabled="!editPermission"
                      type="checkbox" id="useCallCenter"
                      v-model="item.communications.callCenter" />
                  <label for="useCallCenter">{{ $t('PromotionText.useCallCenter') }}</label>
                </div>
              </div>
              <div class="step__item textarea medium py-2">
                <p class="step__item-text">
                  {{ $t('TableHeaders.text_data') }}
                </p>
                <input class="step__item-input" type="text" :placeholder="$t('TableHeaders.text_example_response')"
                       :required="item.communications.callCenter"
                       v-model="item.communications.callCenterText" :disabled="!(editPermission && item.communications.callCenter)">
              </div>
            </div>

            <!-- USE Web Chat channel -->
            <div class="grid-container">
              <div class="step__item select">
                <div class="new-promotion__checkbox">
                  <input
                      :disabled="!editPermission"
                      type="checkbox" id="useWebChat"
                      v-model="item.communications.webChat" />
                  <label for="useWebChat">{{ $t('PromotionText.useWebChat') }}</label>
                </div>
              </div>
              <div class="step__item textarea medium py-2">
                <p class="step__item-text">
                  {{ $t('TableHeaders.text_data') }}
                </p>
                <input class="step__item-input" type="text" :placeholder="$t('TableHeaders.text_example_response')"
                       :required="item.communications.webChat"
                       v-model="item.communications.webChatText" :disabled="!(editPermission && item.communications.webChat)">
              </div>
            </div>

            <!-- USE App Chat channel -->
            <div class="grid-container">
              <div class="step__item select">
                <div class="new-promotion__checkbox">
                  <input
                      :disabled="!editPermission"
                      type="checkbox" id="useAppChat"
                      v-model="item.communications.appChat" />
                  <label for="useAppChat">{{ $t('PromotionText.useAppChat') }}</label>
                </div>
              </div>
              <div class="step__item textarea medium py-2">
                <p class="step__item-text">
                  {{ $t('TableHeaders.text_data') }}
                </p>
                <input class="step__item-input" type="text" :placeholder="$t('TableHeaders.text_example_response')"
                       :required="item.communications.appChat"
                       v-model="item.communications.appChatText" :disabled="!(editPermission && item.communications.appChat)">
              </div>
            </div>

          </div>
        </div>
      </div>

    </section>
    <div class="buttons-block">
        <p class="buttons-block__validation-rule">
          {{ $t('Rules.required_fields') }}
        </p>
        <div 
            class="buttons-block__back"
            @click="minusStep"
        >
            <p class="buttons-block__back-text">
              {{ $t('Buttons.back') }}
            </p>
        </div>
        <div 
            class="buttons-block__forward"
            @click="plusStep"
        >
            <p class="buttons-block__forward-text">
              {{ $t('Buttons.forward') }}
            </p>
        </div>
    </div>
</template>

<script>


import InputsMixin from "@/mixins/inputsEvent";
import {mapActions} from "vuex";
import {checkEmailPattern, isEmpty} from "@/tools/dataTools";

export default {
  name: 'NewPromotionStepThird',
  emits: ['step','updatePromotion',],
  components: {
  },
  mixins: [InputsMixin],
  props: {
    editPermission: Boolean,
    promotion: Object,
  },
  data() {
    return {
      item: {}
    }
  },
  watch: {
    promotion: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler () {
        this.item = this.promotion
      }
    },
  },
  methods: {
    ...mapActions(["TEST_EMAIL_ACCOUNT",]),

    textShow(event) {
        if (event.target.value.length > 33) {
            console.dir(event.target.parentElement.querySelector(".step__item-popup"))
            event.target.parentElement.querySelector(".step__item-popup").classList.add("not-empty");
            event.target.parentElement.querySelector(".step__item-popup p").innerText = event.target.value;
        }
    },
    minusStep() {
      this.$emit("updatePromotion", this.item)
        this.$emit("step", 2)
    },
    checkStepCompleted() {
      let completed = true
      let emailVerified = true

      if(isEmpty(this.item.communications.hotPhoneNumber)) {
        completed = false
      }
      else if(this.item.communications.useMailing) {
        completed = this.verifyMailingFields()
        emailVerified = this.item.communications.mailing.verified
      }
      // check sms response
      else if(this.item.communications.sms && isEmpty(this.item.communications.smsText)) {
        completed = false
      }
      // check email response
      else if(this.item.communications.email && isEmpty(this.item.communications.emailText)) {
        completed = false
      }
      // check callCenter response
      else if(this.item.communications.callCenter && isEmpty(this.item.communications.callCenterText)) {
        completed = false
      }
      // check webChat response
      else if(this.item.communications.webChat && isEmpty(this.item.communications.webChatText)) {
        completed = false
      }
      // check webChat response
      else if(this.item.communications.appChat && isEmpty(this.item.communications.appChatText)) {
        completed = false
      }

      if(completed) {
        if(emailVerified) {
          return true
        }
        else {
          this.$notify({
            type: "error",
            title: this.$t('Errors.error_not_ok'),
            text: this.$t('PromotionText.email_account_not_tested'),
          });
        }
      }
      else {
        this.$notify({
          type: "error",
          title: this.$t('Errors.error_not_ok'),
          text: this.$t('Errors.missing_required_data'),
        });
      }

      return false
    },
    plusStep() {
      // check if all fields are completed
      if(this.checkStepCompleted()) {
        this.$emit("updatePromotion", this.item)
        this.$emit("step", 4)
      }
    },

    verifyMailingFields() {
      let success = true

      if(this.item.communications.useMailing) {
        if(isEmpty(this.item.communications.mailing))
          success = false
        else if(isEmpty(this.item.communications.mailing.user))
          success = false
        else if(!checkEmailPattern(this.item.communications.mailing.user))
          success = false
        else if(isEmpty(this.item.communications.mailing.pass))
          success = false
        else if(isEmpty(this.item.communications.mailing.host))
          success = false
        else if(isEmpty(this.item.communications.mailing.port))
          success = false
      }

      return success
    },


    testEmailAccount() {
      if(this.verifyMailingFields()) {

        this.TEST_EMAIL_ACCOUNT({
          ...this.item.communications.mailing,
        }).then((res) => {
          if (res.success) {
            this.item.communications.mailing.verified = true

            this.$notify({
              type: "success",
              title: this.$t('Errors.success_operation'),
              text: this.$t('PromotionText.email_account_tested'),
            });
          } else {
            this.$notify({
              type: "error",
              title: this.$t('Errors.error_not_ok'),
              text: res.message ? res.message : this.$t('Errors.error_not_ok'),
            });
          }
        });
      }
      else {
        this.$notify({
          type: "error",
          title: this.$t('Errors.error_not_ok'),
          text: this.$t('Errors.missing_required_data'),
        });
      }
    },
  }
}
</script>

<style lang="scss" src="@/assets/scss/common.scss"></style>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixin.scss";

.dd {
  position: relative;
  width: 100%;
  &.object {
    width: 265px !important;
    margin-top: 0px !important;
    font-size: 12px;
    font-weight: 500;
    // .dd-button {
    //   height: 27px !important;
    // }
  }
  .dd-button {
    @include ui-dropdown-button();
    border-radius: 0px 5px 5px 0px;
  }
  & ::v-deep .vs__dropdown-toggle {
    border: 1px solid $inputCaption;
    box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
  &:not(:first-child) {
    margin-top: 10px;
  }
}

.buttons-block {
  padding-bottom: 0px;
  &__back {
    @include white-button;
    width: 190px;
  }
  &__save {
    @include purple-button;
    width: 190px;
    margin-left: 0;
    margin-right: 30px;
  }
  &__test {
    @include white-button;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    /* set the bottom to 0*/
    margin-top: 20px;
  }
}

.new-promotion__checkbox {
  margin-top: 15px;

  label {
    margin-left: 10px;
  }
}

</style>