<template>
    <div class="question-list">
        <div
            class="question"
            v-for="n in questions" :key="n.id"
        >
            <div
                class="question__header"
                @click="toggleQuestion"
            >
                <p class="question__title">
                  {{ `${$t('ListText.question')}: ${n.question}` }}
                </p>

                <p class="question__name">
                  {{ `${n.fk_user.name} ${n.fk_user.surname} ${n.fk_user.lastname}` }}
                </p>

                <p class="question__email">
                  {{ n.fk_user.email }}
                </p>
            </div>
            <div class="question__body">
                <div class="question-input">
                    <p class="question-input__title">
                      {{ $t('ListText.message_text') }}
                    </p>
                    <input class="question-input__input" type="text" v-model="n.response" :placeholder="$t('ListText.enter_message')">
                </div>
                <div class="question-button" @click="sendMessage(n)">
                    <p class="question-button__text">
                      {{ $t('Buttons.send') }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";

export default {
  name: 'ListsQuestionModal',
  components: {
  },
  props: {
    editPermission: Boolean,
    search: String,
  },
  data() {
      return {
        isLoading: false,
      }
  },
  watch: {
    isLoading() {
      if(this.isLoading)
        this.$progress.start();
      else
        this.$progress.finish();
    },
  },
  computed: {
    questions() {
      let questions = this.GET_LISTS().questions ? JSON.parse(JSON.stringify(this.GET_LISTS().questions)) : []

      return questions.filter(
        (v) =>
          `${v.question ? v.question : ''} ${v.fk_user.surname ? v.fk_user.surname : ''} ${v.fk_user.name ? v.fk_user.name : ''} ${v.fk_user.email ? v.fk_user.email : ''}`
            .toLowerCase()
            .indexOf(this.search.toLowerCase()) !== -1
      )
    },
  },
  methods: {
    ...mapGetters(["GET_LISTS"]),
    ...mapActions(["UPDATE_PROMOTION_QUESTION",]),

    toggleQuestion(event) {
        let activeQuestion = event.target;
        if (activeQuestion.parentElement.classList.contains("question")) {
            activeQuestion.parentElement.classList.toggle("active")
        } else {
            activeQuestion.parentElement.parentElement.classList.toggle("active")
        }
    },

    sendMessage(item) {
      if(item.response) {
        // ask send response
        if (
            confirm(this.$t('ListText.confirm_send_message'))
        ) {
          this.isLoading = true

          this.UPDATE_PROMOTION_QUESTION({
            id: item.id,
            response: item.response,
          }).then((res) => {
            if (res.success) {
              this.isLoading = false
              this.$emit('refresh')

              this.$notify({
                type: "success",
                title: this.$t('Errors.success_operation'),
                text: this.$t('ListText.send_message_success'),
              });
            } else {
              this.$notify({
                type: "error",
                title: this.$t('Errors.not_success_operation'),
                text: this.$t('ListText.send_message_error'),
              });
            }
          });
        }
      }
      else {
        // show error message
        this.$notify({
          type: "error",
          title: this.$t('Errors.not_success_operation'),
          text: this.$t('Errors.missing_required_message'),
        });
      }
    }

  }
}
</script>

<style lang="scss" src="@/assets/scss/common.scss"></style>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixin.scss";

.question {
    padding: 21px 86px 22px 30px;
    max-height: 61px;
    transition: .25s all ease;
    font-size: 16px;
    border-radius: 10px;
    background: $white;
    box-shadow: 0px 0px 8px 2px rgba(206, 213, 244, 0.5);
    cursor: pointer;
    overflow: hidden;
    &:not(:last-child) {
        margin-bottom: 5px;
    }

    &.active {
        max-height: 140px;

        .question__header::after {
            transform: rotate(90deg);
        }

        .question__body {
            display: flex;
        }
    }

    &__header {
        display: flex;
        position: relative;
        &::after {
            content: "";
            width: 10px;
            height: 10px;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            background: url("../assets/img/paginate-triangle.png");
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
        }
    }
    &__title {
        padding-left: 40px;
        margin-right: auto;
    }
    &__name {
        margin-right: 90px;
    }
    .question__body {
        display: none;
        align-items: flex-end;
        padding-top: 26px;
    }

    .question-input {
        margin-right: 36px;
        flex-grow: 1;
        &__title {
            font-size: 12px;
            font-weight: 500;
            margin-bottom: 5px;
        }
        &__input {
            width: 100%;
            border-radius: 5px;
            padding: 11px 26px;
            font-size: 12px;
            font-weight: 500;
            outline: none;
            border: none;
            box-shadow: 0px 0px 2px 0px #00000040 inset;
            &::placeholder {
                color: $inputCaption;
            }
        }

    }

    .question-button {
        @include white-button;
        width: 190px;
        &__text {
            color: $purple;
        }
    }
}

</style>
