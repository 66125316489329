import {hasContent} from "@/tools/dataTools";

export const CompanyUsers = {
    // UUID
    id: '',
    // UUID
    company: null,
    // UUID
    user: null,
    // boolean
    read: false,
    // boolean
    write: false,
    // json
    history: [],
};

export const CompanyUsersIsNew = (data) => {
    return !(hasContent(data) && hasContent(data.id));
};

export const CompanyUsersIsNewAndEmpty = (data) => {
    return CompanyUsersIsNew(data) && !data.read && !data.write;
};

export const CompanyUsersIsEmpty = (data) => {
    return !CompanyUsersIsNew(data) && !data.read && !data.write;
};


export const NewCompanyUsers = () => {
    return JSON.parse(JSON.stringify(CompanyUsers));
};

export const CompanyUsersFromBody = (data) => {
    let object = NewCompanyUsers()

    object.id = data.id;
    object.company = data.company;
    object.user = data.user;
    object.read = data.read;
    object.write = data.write;
    object.history = data.history;

    return object;
};


// export module
export default {
    CompanyUsers,

    CompanyUsersIsNew,
    CompanyUsersIsNewAndEmpty,
    CompanyUsersIsEmpty,

    NewCompanyUsers,
    CompanyUsersFromBody,
};