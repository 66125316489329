import {baseURL} from "@/common/config";
// eslint-disable-next-line no-unused-vars
import {hasContent, isEmpty} from "@/tools/dataTools";

export const ImagePicker = {
  preview: null,
  url: "",
  reload: true,
};

export const getImageData = (imagePicker) => {
  if (imagePicker != null && imagePicker.preview != null) {
    const fr = new FileReader();
    fr.readAsDataURL(imagePicker.preview);
    fr.addEventListener("load", () => {
      imagePicker.url = fr.result;
      forceReloadImage(imagePicker);
    });
  } else {
    imagePicker = Object.assign({}, ImagePicker)
  }
};

export const forceReloadImage = (imagePicker) => {
  if(imagePicker == null)
    return

  imagePicker.reload = false;

  process.nextTick(() => {
    imagePicker.reload = true;
  });
};

export const getFileImage = async (file) => {
  return new Promise(resolve => {
    if (file != null) {
      const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
      if (!validImageTypes.includes(file.type)) {
        return;
      }
      const fr = new FileReader();
      fr.onload = () => {
        file.url = fr.result;
        file.upload = true;

        file.reloadPreview = false;
        process.nextTick(() => {
          file.reloadPreview = true;
        });

        resolve(file)
      };
      fr.readAsDataURL(file);
    }
    else {
      resolve(null)
    }
  });
};

export const getCsvFile = async (file) => {
  return new Promise(resolve => {
    if (file != null) {
      const validImageTypes = ["text/csv"];
      if (!validImageTypes.includes(file.type)) {
        return;
      }
      const fr = new FileReader();
      fr.onload = () => {
        file.url = fr.result;
        file.upload = true;

        file.reloadPreview = false;
        process.nextTick(() => {
          file.reloadPreview = true;
        });

        resolve(file)
      };
      fr.readAsDataURL(file);
    }
    else {
      resolve(null)
    }
  });
};

export const getImageUrl = (image) => {
  if (image) {
    return baseURL + image;
  } else {
    return null;
  }
};

export const getUserAvatarUrl = (image, you) => {
  if (image) {
    return baseURL + image;
  } else {
    if(you)
      return require("@/assets/img/avatar.jpg");
    else
      return require("@/assets/img/responder-avatar.jpg");
  }
};

export const getCompanyAvatarUrl = (company) => {
  if (hasContent(company) && hasContent(company.avatar)) {
    return baseURL + company.avatar;
  } else {
    return require("@/assets/img/campaign/compaign_logotip.svg");
  }
};


// export module
export default {
  ImagePicker,

  getImageData,
  forceReloadImage,

  getFileImage,

  getImageUrl,
  getUserAvatarUrl,

  getCompanyAvatarUrl,
};