<template>
  <ModalEditCompany
      :opened="modalOpened"
      :company="currentCompany"
      :tab="modalOpenedTab"
      @close-modal="closeModalCompany"
  />

  <AddNewCompanyModal
    v-if="AddNewCompanyModalOpened"
    @close-modal="AddNewCompanyModalClose()"
  />

  <div class="companies">
    <div class="page-header">
      <h2 class="page-header__title">
        {{ $t('NavigationDrawerText.nav_companies') }}
      </h2>
      <div class="buttons-block">
        <div
            v-if="currentCompanies && currentCompanies.length > 0"
          class="page-header__outload"
        >
          <img
              width="20" height="16"
              src="@/assets/img/bi_cloud_download.svg"
              alt="notification avatar">
          <p class="page-header__outload-text" @click="downloadCompaniesList('xls')">
            {{ $t('Buttons.download_xls') }}
          </p>
        </div>
        <div v-if="createPermission"
             class="page-header__newcompany"
          @click="createNewCompany"
        >
          <p class="page-header__newcompany-text">
            {{ $t('CompanyText.new_company_button') }}
          </p>
        </div>
      </div>
    </div>
    <div class="table">
      <div class="table__row title">
        <p class="table__cell">{{ $t('FieldsText.company_name_label') }}</p>
        <p class="table__cell">{{ $t('FieldsText.company_inn_label') }}</p>
        <p class="table__cell">{{ $t('TableHeaders.action') }}</p>
      </div>
      <div class="table__body">
        <div
          v-for="company in currentCompanies.slice((page - 1) * itemsPerPage, (page * itemsPerPage))" :key="company.id"
          class="table__row"
        >
          <div class="table__cell">
            <p>
              {{company.name}}
            </p>
          </div>
          <div class="table__cell">
            <p>
              {{company.inn}}
            </p>
          </div>
          <div class="table__cell button">
            <div class="table__cell-button"  @click="openModalEmp(company, 2)">
              <img
                  :title="$t('TabHeaders.chat')"
                  src="@/assets/img/notification/message-badge.svg"
                  alt="notification avatar">
            </div>
            <div v-if="editPermissionCompany(company)" class="table__cell-button" style="padding-left: 5px;" @click="openModalEmp(company, 0)">
              <img
                  :title="$t('TabHeaders.information')"
                  src="@/assets/img/view_info.svg"
                  alt="notification avatar">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="pagination-buttons">
      <div class="pagination-buttons__arrow left">
        <img src="@/assets/img/left-arrow.png" alt="arrow icon">
      </div>
      <div v-bind:class="(n===page)?'pagination-buttons__number active':'pagination-buttons__number'"
           @click="changePage(n)"
           v-for="n in pageCount" :key="n"
      >{{ n }}</div>
      <div class="pagination-buttons__arrow right">
        <img src="@/assets/img/left-arrow.png" alt="arrow icon">
      </div>
    </div>

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

import ModalEditCompany from '../components/ModalEditCompany.vue'
import AddNewCompanyModal from '../components/AddNewCompanyModal.vue'

import {
  CompaniesFromBody,
  NewCompanies
} from "@/models/companies";

import {
  UsersType
} from "@/models/users";

import {hasContent} from "@/tools/dataTools";

export default {
  name: 'Companies',
  components: {
    ModalEditCompany,
    AddNewCompanyModal,
  },
  data() {
    return {
      modalOpened: false,
      modalOpenedTab: 0,

      isLoading: false,

      AddNewCompanyModalOpened: false,

      itemsPerPage: 8,
      page: 1,

      currentCompany: null,
      currentCompanies: [],
    }
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    "$store.state.employees.filter": function (newValue, oldValue) {
      this.filter();
    },
    // eslint-disable-next-line no-unused-vars
    "$store.state.companies": function (newValue, oldValue) {
      // apply filters
      this.filter();
      // check changes in current company
      if (this.currentCompany) {
        let company = this.GET_COMPANIES().find(
            (v) => v.id.toString() === this.currentCompany.id.toString()
        );

        if(company)
          this.currentCompany = CompaniesFromBody(company)
      }
    },
    pageCount() {
      if(this.pageCount < this.itemsPerPage)
        this.page = 1;
    },
    isLoading() {
      if(this.isLoading)
        this.$progress.start();
      else
        this.$progress.finish();
    },
  },
  mounted() {
    this.GET_COMPANIES_FROM();

    this.filter();
  },
  computed: {
    user() {
      return this.GET_USER();
    },
    isAdmin() {
      return this.user ? (this.user.role === UsersType.ADMIN) : false;
    },
    pageCount() {
      //return ~~(this.GET_COMPANIES().length / this.itemsPerPage) + 1;
      //return this.currentCompanies ? ~~(this.currentCompanies.length / this.itemsPerPage) + 1 : 1;
      return this.currentCompanies ? Math.ceil(this.currentCompanies.length / this.itemsPerPage) : 1;
    },
    createPermission() {
      return this.isAdmin;
    },
    company_filter() {
      return this.GET_EMPLOYEES().filter.company;
    },
  },
  methods: {
    ...mapGetters(["GET_USER","GET_EMPLOYEES","GET_COMPANIES",]),
    ...mapActions(["GET_COMPANIES_FROM","VIEW_COMPANY_MESSAGE","GET_COMPANIES_DOWNLOAD"]),

    // eslint-disable-next-line no-unused-vars
    filter() {
      let currentCompanies = this.GET_COMPANIES();

      const oldWayPresentation = false

      if(oldWayPresentation) {
        // filter by current user company - if user is from specific company - show just current company users
        if (hasContent(this.company_filter)) {
          currentCompanies = currentCompanies.filter(
              (v) => v.id === this.company_filter
          );
        }
        else {
          if(!this.isAdmin)
            currentCompanies = []
        }
      }
      else {
        if(!this.isAdmin) {
          if(this.user) {
            currentCompanies = currentCompanies.filter((company) => {
              return company.users && company.users.find((companyUser) => companyUser.id === this.user.id)
            })
          }
          else {
            currentCompanies = []
          }
        }
      }

      // filter region
      this.currentCompanies = currentCompanies;
    },

    changePage(page) {
      this.page = page;
    },

    downloadCompaniesList(format) {
      if (format) {
        this.isLoading = true

        this.GET_COMPANIES_DOWNLOAD({
          format,
          company: this.company_filter,
        }).then((res) => {
          this.isLoading = false

          if (res.success) {
            this.$notify({
              type: "success",
              title: this.$t('Errors.success_operation'),
              text: this.$t('InterfaceText.file_downloaded'),
            });
          }
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t('Errors.not_success_operation'),
          text: this.$t('Errors.error_format'),
        });
      }
    },

    createNewCompany() {
      this.currentCompany = NewCompanies();
      this.modalOpenedTab = 0;
      this.modalOpened = true;
    },

    openModalEmp(company, tab) {
      if (company.newMessages) {
        this.VIEW_COMPANY_MESSAGE(company);
      }

      this.modalOpenedTab = tab ? tab : 0;

      this.currentCompany = company;
      this.modalOpened = true;
    },

    closeModalCompany() {
      this.currentCompany = null;
      this.modalOpened = false;
    },

    AddNewCompanyModalOpen() {
      this.AddNewCompanyModalOpened = true;
    },

    AddNewCompanyModalClose() {
      this.AddNewCompanyModalOpened = false;
    },

    editPermissionCompany(company) {
      if(this.isAdmin)
        return true
      else {
        // find type of access for current company
        let companies = this.user ? this.user.companies : [];

        if(company == null || companies == null || companies.length === 0)
          return false
        else {
          let userCompany = companies.find(c => c.id === company.id);

          if(userCompany == null || userCompany.CompanyUsers == null)
            return false;
          else
            return userCompany.CompanyUsers.write;
        }
      }
    },
  }
}
</script>

<style lang="scss" src="@/assets/scss/common.scss"></style>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixin.scss";

.companies {
  flex: 1;
  width: 100%;
  min-width: 1150px;

  .table {
    @include grid-table-row(60px, 40px, 27% 207px 25.2% 210px 1.5%);

    &__cell {
      &:first-child {
        grid-column-start: 1;
        grid-column-end: 2;
      }
      &:nth-child(2) {
        grid-column-start: 3;
        grid-column-end: 4;
      }
      &:nth-child(3) {
        grid-column-start: 5;
        grid-column-end: 6;
      }
    }
  }
}

.page-header {
  &__newcompany {
    @include purple-button;
    width: 210px;
  }
}

</style>
