<template>
    <div class="new-report">
        <div class="buttons-panel">
            <div class="buttons-panel__input">
                <p class="buttons-panel__input-text">
                    Наименование <span>*</span>
                </p>
                <div class="step__item textarea required">
                    <input @input="textShow" class="step__item-input" type="text" placeholder="Введите название сайта">
                    <div class="step__item-popup">
                        <p></p>
                    </div>
                </div>
                <label class="new-code__checkbox">
                    <input class="new-code__checkbox-checkbox" type="checkbox">
                    <span class="new-code__checkbox-fake"></span>
                    <div class="new-code__checkbox-text">Тип пользователя</div>
                </label>
                <label class="new-code__checkbox">
                    <input class="new-code__checkbox-checkbox" type="checkbox">
                    <span class="new-code__checkbox-fake"></span>
                    <div class="new-code__checkbox-text">Логин</div>
                </label>
                <label class="new-code__checkbox">
                    <input class="new-code__checkbox-checkbox" type="checkbox">
                    <span class="new-code__checkbox-fake"></span>
                    <div class="new-code__checkbox-text">Пароль</div>
                </label>
                <label class="new-code__checkbox">
                    <input class="new-code__checkbox-checkbox" type="checkbox">
                    <span class="new-code__checkbox-fake"></span>
                    <div class="new-code__checkbox-text">Уникальный идентификатор</div>
                </label>
            </div>
        </div>
        <div class="step__content">
            <div class="step__inner">
                <div class="step__inner-header">
                    <p>
                        КОНТАКТНАЯ ИНФОРМАЦИЯ
                    </p>
                </div>
                <div class="step__items">
                    <div class="step__item select">
                        <p class="step__item-text">
                            Действие для получения кода
                        </p>
                        <input class="step__item-input" type="text" placeholder="Введите название сайта">
                        <div class="step__item-button"></div>
                    </div>
                    
                    <div class="step__item select required">
                        <p class="step__item-text">
                            Необходимость состоять в сообществе<span>*</span>
                        </p>
                        <input class="step__item-input" type="text" placeholder="Введите название сайта">
                        <div class="step__item-button"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="step__content">
            <div class="step__inner">
                <div class="step__inner-header">
                    <p>
                        ИНФОРМАЦИЯ ОБ АКЦИЯХ
                    </p>
                </div>
                <div class="step__items">
                    <label class="new-code__checkbox">
                        <input class="new-code__checkbox-checkbox" type="checkbox">
                        <span class="new-code__checkbox-fake"></span>
                        <div class="new-code__checkbox-text">Наименования акций</div>
                    </label>
                    <div class="step__item select">
                        <p class="step__item-text">
                            История регистраций в акциях
                        </p>
                        <input class="step__item-input" type="text" placeholder="Введите название сайта">
                        <div class="step__item-button"></div>
                    </div>
                    <div class="step__item select">
                        <p class="step__item-text">
                            История авторизаций в личных кабинетах акций
                        </p>
                        <input class="step__item-input" type="text" placeholder="Введите название сайта">
                        <div class="step__item-button"></div>
                    </div>
                    <div class="step__item select">
                        <p class="step__item-text">
                            Зарегистрированные коды
                        </p>
                        <input class="step__item-input" type="text" placeholder="Введите название сайта">
                        <div class="step__item-button"></div>
                    </div>
                    <div class="step__item select">
                        <p class="step__item-text">
                            Привязанные карты лояльности
                        </p>
                        <input class="step__item-input" type="text" placeholder="Введите название сайта">
                        <div class="step__item-button"></div>
                    </div>
                    <div class="step__item select">
                        <p class="step__item-text">
                            Выполненные условия акций
                        </p>
                        <input class="step__item-input" type="text" placeholder="Введите название сайта">
                        <div class="step__item-button"></div>
                    </div>
                    <div class="step__item select">
                        <p class="step__item-text">
                            Выигранные призы
                        </p>
                        <input class="step__item-input" type="text" placeholder="Введите название сайта">
                        <div class="step__item-button"></div>
                    </div>
                    <div class="step__item select">
                        <p class="step__item-text">
                            Выигранные призы отметка о вручении
                        </p>
                        <input class="step__item-input" type="text" placeholder="Введите название сайта">
                        <div class="step__item-button"></div>
                    </div>
                    <div class="step__item select">
                        <p class="step__item-text">
                            Уведомление об отправке приза
                        </p>
                        <input class="step__item-input" type="text" placeholder="Введите название сайта">
                        <div class="step__item-button"></div>
                    </div>
                    <div class="step__item select">
                        <p class="step__item-text">
                            Данные об обращениях в контактный центр
                        </p>
                        <input class="step__item-input" type="text" placeholder="Введите название сайта">
                        <div class="step__item-button"></div>
                    </div>
                    <div class="step__item select">
                        <p class="step__item-text">
                            Сканы документов
                        </p>
                        <input class="step__item-input" type="text" placeholder="Введите название сайта">
                        <div class="step__item-button"></div>
                    </div>
                    <div class="step__item select">
                        <p class="step__item-text">
                            Данные о попытках совершения мошеннических действий
                        </p>
                        <input class="step__item-input" type="text" placeholder="Введите название сайта">
                        <div class="step__item-button"></div>
                    </div>
                    <div class="step__item select">
                        <p class="step__item-text">
                            Данные о блокировках
                        </p>
                        <input class="step__item-input" type="text" placeholder="Введите название сайта">
                        <div class="step__item-button"></div>
                    </div>
                    <div class="step__item select">
                        <p class="step__item-text">
                            История авторизаций в платформе
                        </p>
                        <input class="step__item-input" type="text" placeholder="Введите название сайта">
                        <div class="step__item-button"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="step__content">
            <div class="step__inner">
                <div class="step__inner-header">
                    <p>
                        ИНФОРМАЦИЯ О ПРОВЕДЕННЫХ АКЦИЯХ И ИХ АТРИБУТАХ
                    </p>
                </div>
                <div class="step__items">
                    <label class="new-code__checkbox">
                        <input class="new-code__checkbox-checkbox" type="checkbox">
                        <span class="new-code__checkbox-fake"></span>
                        <div class="new-code__checkbox-text">Наименование акции</div>
                    </label>
                    <label class="new-code__checkbox">
                        <input class="new-code__checkbox-checkbox" type="checkbox">
                        <span class="new-code__checkbox-fake"></span>
                        <div class="new-code__checkbox-text">Дата и время старта акции</div>
                    </label>
                    <label class="new-code__checkbox">
                        <input class="new-code__checkbox-checkbox" type="checkbox">
                        <span class="new-code__checkbox-fake"></span>
                        <div class="new-code__checkbox-text">Заказчик акции</div>
                    </label>
                    <label class="new-code__checkbox">
                        <input class="new-code__checkbox-checkbox" type="checkbox">
                        <span class="new-code__checkbox-fake"></span>
                        <div class="new-code__checkbox-text">Дата и время завершения акции</div>
                    </label>
                    <label class="new-code__checkbox">
                        <input class="new-code__checkbox-checkbox" type="checkbox">
                        <span class="new-code__checkbox-fake"></span>
                        <div class="new-code__checkbox-text">Тип механики акции</div>
                    </label>
                    <div class="step__item select">
                        <p class="step__item-text">
                            Контакт-центр
                        </p>
                        <input class="step__item-input" type="text" placeholder="Введите название сайта">
                        <div class="step__item-button"></div>
                    </div>
                    <div class="step__item select">
                        <p class="step__item-text">
                            Призы
                        </p>
                        <input class="step__item-input" type="text" placeholder="Введите название сайта">
                        <div class="step__item-button"></div>
                    </div>
                    <div class="step__item select">
                        <p class="step__item-text">
                            Дополнительные активности
                        </p>
                        <input class="step__item-input" type="text" placeholder="Введите название сайта">
                        <div class="step__item-button"></div>
                    </div>
                    <label class="new-code__checkbox">
                        <input class="new-code__checkbox-checkbox" type="checkbox">
                        <span class="new-code__checkbox-fake"></span>
                        <div class="new-code__checkbox-text">Необходимые разрешения (обработка персональных данных, согласие с условиями и т.п.);</div>
                    </label>
                    <label class="new-code__checkbox">
                        <input class="new-code__checkbox-checkbox" type="checkbox">
                        <span class="new-code__checkbox-fake"></span>
                        <div class="new-code__checkbox-text">Опциональные условия</div>
                    </label>
                    <label class="new-code__checkbox">
                        <input class="new-code__checkbox-checkbox" type="checkbox">
                        <span class="new-code__checkbox-fake"></span>
                        <div class="new-code__checkbox-text">Мошеннические действия</div>
                    </label>
                    <label class="new-code__checkbox">
                        <input class="new-code__checkbox-checkbox" type="checkbox">
                        <span class="new-code__checkbox-fake"></span>
                        <div class="new-code__checkbox-text">Условия акций</div>
                    </label>
                    <label class="new-code__checkbox">
                        <input class="new-code__checkbox-checkbox" type="checkbox">
                        <span class="new-code__checkbox-fake"></span>
                        <div class="new-code__checkbox-text">Призы</div>
                    </label>
                    <label class="new-code__checkbox">
                        <input class="new-code__checkbox-checkbox" type="checkbox">
                        <span class="new-code__checkbox-fake"></span>
                        <div class="new-code__checkbox-text">Дополнительные активности</div>
                    </label>
                    <label class="new-code__checkbox">
                        <input class="new-code__checkbox-checkbox" type="checkbox">
                        <span class="new-code__checkbox-fake"></span>
                        <div class="new-code__checkbox-text">Механики определения победителей</div>
                    </label>
                    <label class="new-code__checkbox">
                        <input class="new-code__checkbox-checkbox" type="checkbox">
                        <span class="new-code__checkbox-fake"></span>
                        <div class="new-code__checkbox-text">Условия блокировки</div>
                    </label>
                    <label class="new-code__checkbox">
                        <input class="new-code__checkbox-checkbox" type="checkbox">
                        <span class="new-code__checkbox-fake"></span>
                        <div class="new-code__checkbox-text">Заказчиках Акций</div>
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'NewReport',
  components: {
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixin.scss";

.new-report {
    flex: 1;
    width: 100%;

    .buttons-panel {
        span {
            color: $red;
        }
        .step__item.textarea {
            margin-right: 30px;
        }

        .new-code__checkbox {
            margin-right: 30px;
            margin-bottom: 0;
        }
    }
    .step__inner-header {
        margin-bottom: 15px;
    }
    .step__items {
        .new-code__checkbox {
            margin-bottom: 0;
        }
    }
    .step__content:not(:last-child) {
        margin-bottom: 5px;
    }
    .step__content:last-child {
        margin-bottom: 35px;
    }
}
</style>