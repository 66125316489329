<template>
  <DrawingModal
      v-if="drawingModalOpened"
      :editPermission="editPermission"
      :promotion="selectedItem ? selectedItem : {}"
      @showPrizeInformation="showPrizeInformation"
      @showUserInformation="openModalUserInformation"
      @getPromotionWinnerPrizes="getPromotionWinnerPrizes"
      @getPromotionParticipants="getPromotionParticipants"
      @close-modal="drawingModalClose"
  />
  <ModalPromotionPrize
      v-if="modalPrizeOpened"
      :editPermission="false"
      :prize="currentPrize"
      @close-modal="closeModalPrizeInformation"
  />
  <ModalEditEmployee
      :opened="modalOpened"
      :employee="currentEmployee"
      :tab="0"
      @close-modal="closeModalEmployee"
  />

  <div class="promotions">
    <div class="page-header">
      <h2 class="page-header__title">
        {{ $t('NavigationDrawerText.nav_promotions') }}
      </h2>
      <div class="buttons-block">
        <div v-if="currentPromotions && currentPromotions.length > 0 && false"
             class="page-header__outload"
        >
          <img
              width="20" height="16"
              src="@/assets/img/bi_cloud_download.svg"
              alt="notification avatar">
          <p class="page-header__outload-text" @click="downloadPromotionsList('xls')">
            {{ $t('Buttons.download_xls') }}
          </p>
        </div>
        <router-link
            v-if="createPermission"
            to="/promotions/new-promotions"
        >
          <div class="page-header__newpromo">
            <p class="page-header__newpromo-text">
              {{ $t('PromotionText.new_promotion_button') }}
            </p>
          </div>
        </router-link>
      </div>
    </div>
    <div class="buttons-panel">

      <div class="buttons-panel__input">
        <p class="buttons-panel__input-text">
          {{ $t('InterfaceText.search') }}
        </p>
        <input type="text" class="buttons-panel__input-input" :placeholder="$t('FieldsText.enter_request')"
               :value="filterData.name"
               @input="setPromoFilterName">
        <div class="buttons-panel__input-button"></div>
      </div>

      <div class="buttons-panel__input select">
        <p class="buttons-panel__input-text">
          {{ $t('FieldsText.status_label') }}
        </p>
        <div class="dd object dd-role-filter">
          <v-select
              :placeholder="$t('FieldsText.select_from_list')"
              :options="promotionStatuses"
              :reduce="(option) => option.status"
              :clearable="false"
              v-model="filterData.status"
              @option:selected="setPromoFilterStatus"
          >
            <!-- eslint-disable-next-line vue/no-unused-vars  -->
            <template #no-options="{ search, searching, loading }">
              {{ $t('Errors.missing_options') }}
            </template>
          </v-select>
          <div
              class="dd-button"
              @click="openSelectOptions('.dd-role-filter .vs__dropdown-toggle')"
          ></div>
        </div>
      </div>

      <div class="buttons-panel__input select">
        <p class="buttons-panel__input-text">
          {{ $t('FieldsText.company_label') }}
        </p>
        <div class="dd object dd-company-filter">
          <v-select
              :placeholder="$t('FieldsText.select_from_list')"
              :options="companies"
              :reduce="(option) => option.id"
              label="name"
              :clearable="false"
              v-model="filterData.company"
              @option:selected="setEmployeesCompany"
          >
            <!-- eslint-disable-next-line vue/no-unused-vars  -->
            <template #no-options="{ search, searching, loading }">
              {{ $t('Errors.missing_options') }}
            </template>
          </v-select>
          <div
              class="dd-button"
              @click="openSelectOptions('.dd-company-filter .vs__dropdown-toggle')"
          ></div>
        </div>
      </div>

    </div>

    <div class="table">
      <div class="table__row title">
        <p class="table__cell">{{ $t('FieldsText.title_label') }}</p>
        <p class="table__cell">{{ $t('FieldsText.client_label') }}</p>
        <p class="table__cell">{{ $t('PromotionText.period') }}</p>
        <p class="table__cell">{{ $t('FieldsText.status_label') }}</p>
        <p class="table__cell">{{ $t('TableHeaders.action') }}</p>
      </div>
      <div class="table__body">
        <div
          v-for="promotion in currentPromotions.slice((page - 1) * itemsPerPage, (page * itemsPerPage))" :key="promotion.id"
          class="table__row"
        >
          <div class="table__cell">
            <p>
              {{ promotion.name }}
            </p>
          </div>
          <div class="table__cell">
            <p>
              {{ promotion.fk_company.name }}
            </p>
          </div>
          <div class="table__cell">
            <p class="multiline">
              {{ `${parseDate(promotion.startedAt)}\n${parseDate(promotion.endedAt)}` }}
            </p>
          </div>
          <div class="table__cell">
            <p>
              {{ PromotionsStatusText(promotion.status) }}
            </p>
          </div>
          <div class="table__cell button">
            <!--div class="table__cell-button">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 19.9702C12.6522 19.9702 15.1957 18.9182 17.0711 17.0456C18.9464 15.1731 20 12.6333 20 9.9851C20 7.33688 18.9464 4.79714 17.0711 2.92457C15.1957 1.052 12.6522 0 10 0C7.34784 0 4.8043 1.052 2.92893 2.92457C1.05357 4.79714 0 7.33688 0 9.9851C0 12.6333 1.05357 15.1731 2.92893 17.0456C4.8043 18.9182 7.34784 19.9702 10 19.9702ZM11.1625 8.22273L9.9125 14.0952C9.825 14.5196 9.94875 14.7605 10.2925 14.7605C10.535 14.7605 10.9012 14.6731 11.15 14.4534L11.04 14.9727C10.6812 15.4045 9.89 15.719 9.20875 15.719C8.33 15.719 7.95625 15.1923 8.19875 14.0727L9.12125 9.74421C9.20125 9.3785 9.12875 9.2462 8.7625 9.15758L8.19875 9.05648L8.30125 8.58094L11.1637 8.22273H11.1625ZM10 6.86475C9.66848 6.86475 9.35054 6.73325 9.11612 6.49918C8.8817 6.26511 8.75 5.94764 8.75 5.61662C8.75 5.28559 8.8817 4.96812 9.11612 4.73405C9.35054 4.49998 9.66848 4.36848 10 4.36848C10.3315 4.36848 10.6495 4.49998 10.8839 4.73405C11.1183 4.96812 11.25 5.28559 11.25 5.61662C11.25 5.94764 11.1183 6.26511 10.8839 6.49918C10.6495 6.73325 10.3315 6.86475 10 6.86475Z" fill="url(#paint0_linear)"/>
                <defs>
                <linearGradient id="paint0_linear" x1="10" y1="0" x2="10" y2="19.9702" gradientUnits="userSpaceOnUse">
                <stop stop-color="#8F21C9"/>
                <stop offset="0.0001" stop-color="#8F21C9"/>
                <stop offset="1" stop-color="#4640B9"/>
                </linearGradient>
                </defs>
              </svg>
            </div-->

            <!-- Start Drawing -->
            <!--div
                v-if="promotion.status === PromotionsStatus.ACTIVE || promotion.status === PromotionsStatus.WAITING"
                class="table__cell-button"
                :title="$t('PromotionText.drawing_prizes')"
                @click="drawingModalOpen(promotion)"
            >
              <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.0127 5.68201H16.4809C17.3121 5.68201 18 6.35565 18 7.23431V12.5649H9.74522V6.35565C9.4586 6.26778 9.20064 6.15063 9.02866 5.9749C9 5.9749 9 5.94561 8.97134 5.94561C8.97134 5.94561 8.97134 5.9749 8.94268 5.9749C8.74204 6.15063 8.51274 6.26778 8.25478 6.35565V12.5649H0V7.23431C0 6.35565 0.659236 5.68201 1.49045 5.68201H4.98726C4.58599 5.47699 4.21338 5.1841 3.89809 4.9205C2.52229 3.57322 2.26433 1.69874 3.3535 0.644351C3.8121 0.23431 4.41401 0 5.07325 0C6.01911 0 6.99363 0.380753 7.82484 1.17155C8.28344 1.61088 8.71338 2.22594 8.97134 2.81172C9.25796 2.22594 9.6879 1.61088 10.1465 1.17155C10.949 0.380753 11.9809 0 12.8981 0C13.586 0 14.1879 0.23431 14.6178 0.644351C15.707 1.69874 15.449 3.57322 14.0446 4.9205C13.758 5.1841 13.3854 5.47699 13.0127 5.68201ZM7.91083 4.83264C8.02548 4.56904 7.73885 3.22176 6.79299 2.31381C6.30573 1.84519 5.6465 1.58159 5.07325 1.58159C4.90127 1.58159 4.61465 1.61088 4.41401 1.78661C4.35669 1.84519 4.18471 1.96234 4.18471 2.28452C4.18471 2.66527 4.41401 3.25105 4.9586 3.74895C5.6465 4.42259 6.73567 4.89121 7.53822 4.89121C7.79618 4.89121 7.91083 4.83264 7.91083 4.83264ZM10.0892 4.83264C10.0892 4.83264 10.2325 4.89121 10.4618 4.89121C11.2643 4.89121 12.3535 4.42259 13.0414 3.74895C13.586 3.25105 13.758 2.66527 13.758 2.28452C13.758 1.96234 13.6146 1.84519 13.586 1.78661C13.3854 1.61088 13.0701 1.58159 12.8981 1.58159C12.3535 1.58159 11.6656 1.84519 11.1783 2.31381C10.2611 3.22176 9.94586 4.53975 10.0892 4.86192V4.83264ZM8.25478 21H1.49045C0.659236 21 0 20.3264 0 19.477V14.1172H8.25478V21ZM16.4809 21H9.74522V14.1172H18V19.477C18 20.3264 17.3121 21 16.4809 21Z" fill="#BDBDBD"/>
              </svg>
            </div-->

            <!-- Edit Promotion -->
            <div class="table__cell-button" :title="$t('TabHeaders.information')" @click="openModalEmp(promotion)">
              <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.89415 0C0.852368 0 0 0.855865 0 1.90192V17.1173C0 18.1634 0.852368 19.0192 1.89415 19.0192H5.68245V17.2124L15.1532 7.70279V5.70577L9.47075 0H1.89415ZM8.52368 1.42644L13.7326 6.65673H8.52368V1.42644ZM15.2479 10.4606C15.1532 10.4606 14.9638 10.5557 14.8691 10.6508L13.922 11.6017L15.9109 13.5988L16.8579 12.6478C17.0474 12.4576 17.0474 12.0772 16.8579 11.887L15.6267 10.6508C15.532 10.5557 15.4373 10.4606 15.2479 10.4606ZM13.3538 12.1723L7.5766 17.9732V19.9702H9.56546L15.3426 14.1693L13.3538 12.1723Z" fill="#BDBDBD"/>
              </svg>
            </div>

            <!-- Delete Promotion -->
            <div
                v-if="isAdmin"
                class="table__cell-button" :title="$t('Buttons.delete')" @click="deletePromotion(promotion)">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.0001 3.25383e-08C10.8855 -0.000115151 11.7389 0.305579 12.3928 0.857061C13.0467 1.40854 13.4539 2.16597 13.5344 2.98063H19.0325C19.2777 2.9807 19.5138 3.06676 19.6929 3.22143C19.8721 3.3761 19.981 3.58784 19.9977 3.81387C20.0145 4.03989 19.9377 4.26336 19.783 4.4391C19.6282 4.61485 19.4071 4.72977 19.1642 4.76066L19.0325 4.769H18.2803L16.6454 17.8599C16.5726 18.441 16.2711 18.9771 15.7984 19.3662C15.3257 19.7553 14.7146 19.9703 14.0815 19.9702H5.9188C5.28569 19.9703 4.67464 19.7553 4.20189 19.3662C3.72915 18.9771 3.4277 18.441 3.35489 17.8599L1.71874 4.769H0.967756C0.733898 4.76899 0.507953 4.69074 0.331707 4.54871C0.155461 4.40669 0.0408371 4.2105 0.00903235 3.99642L0 3.87481C9.86195e-06 3.65873 0.0847014 3.44997 0.238413 3.28712C0.392124 3.12427 0.604456 3.01836 0.836141 2.98897L0.967756 2.98063H6.4659C6.54639 2.16597 6.95356 1.40854 7.60747 0.857061C8.26139 0.305579 9.1148 -0.000115151 10.0001 3.25383e-08ZM8.06464 7.45156C7.7485 7.45156 7.48398 7.63636 7.42979 7.87958L7.41946 7.97377V15.2763L7.42979 15.3693C7.48398 15.6125 7.7485 15.7973 8.06464 15.7973C8.38077 15.7973 8.64529 15.6125 8.69948 15.3693L8.70981 15.2751V7.97496L8.69948 7.87958C8.64529 7.63756 8.38077 7.45156 8.06464 7.45156ZM11.9357 7.45156C11.6195 7.45156 11.355 7.63636 11.3008 7.87958L11.2905 7.97377V15.2763L11.3008 15.3693C11.355 15.6125 11.6195 15.7973 11.9357 15.7973C12.2518 15.7973 12.5163 15.6125 12.5705 15.3693L12.5808 15.2751V7.97496L12.5705 7.87958C12.5163 7.63756 12.2518 7.45276 11.9357 7.45276V7.45156ZM10.0001 1.78838C9.21949 1.78838 8.56787 2.30104 8.41948 2.98063H11.5808C11.4311 2.30104 10.7808 1.78838 10.0001 1.78838Z" fill="#BDBDBD"/>
              </svg>
            </div>

          </div>

        </div>
      </div>
    </div>

    <div class="pagination-buttons">
      <div class="pagination-buttons__arrow left">
        <img src="@/assets/img/left-arrow.png" alt="arrow icon">
      </div>
      <div v-bind:class="(n===page)?'pagination-buttons__number active':'pagination-buttons__number'"
           @click="changePage(n)"
           v-for="n in pageCount" :key="n"
      >{{ n }}</div>
      <div class="pagination-buttons__arrow right">
        <img src="@/assets/img/left-arrow.png" alt="arrow icon">
      </div>
    </div>

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

import DrawingModal from "@/components/DrawingModal.vue"
import ModalPromotionPrize from "@/components/ModalPromotionPrize";
import ModalEditEmployee from "@/components/ModalEditEmployee";

import {UsersType} from "@/models/users";

import {hasContent} from "@/tools/dataTools";

import {
  PromotionsStatus,
  PromotionsStatuses,
  PromotionsStatusText,
} from "@/models/promotions";


import {
  parseDate,
} from "@/tools/dateTools";

import InputsMixin from "@/mixins/inputsEvent";
import { SAVE_PAGES_FILTERS } from "@/common/config";

export default {
  name: 'Promotions',
  components: {
    DrawingModal,
    ModalPromotionPrize,
    ModalEditEmployee,
  },
  mixins: [InputsMixin],
  data() {
    return {
      PromotionsStatus,

      isLoading: false,
      drawingModalOpened: false,
      modalPrizeOpened: false,
      modalOpened: false,

      currentPrize: null,
      currentEmployee: null,

      // pagination
      itemsPerPage: 6,
      page: 1,

      filterData: {
        name: '',
        status: null,
        company: null,
      },
      selectedItem: null,
      modalOpenedTab: 0,
      currentPromotions: [],
    };
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    "$store.state.promotions.filter": function (newValue, oldValue) {
      let filters = {
        name: this.promotions_filter.name,
        status: this.promotions_filter.status,
        company: this.employees_filter.company,
      }

      this.filter(filters)
    },
    // eslint-disable-next-line no-unused-vars
    "$store.state.promotions.all": function (newValue, oldValue) {
      // apply filters
      let filters = {
        name: this.promotions_filter.name,
        status: this.promotions_filter.status,
        company: this.employees_filter.company,
      }

      this.filter(filters)
    },
    pageCount() {
      if(this.pageCount < this.itemsPerPage)
        this.page = 1;
    },
    isLoading() {
      if(this.isLoading)
        this.$progress.start();
      else
        this.$progress.finish();
    },
  },
  mounted() {
    // get promotions data
    this.GET_PROMOTIONS_FROM({
      company: this.employees_filter ? this.employees_filter.company : null
    }).then((response) => {
      if (!response.success) {
        this.$notify({
          type: "error",
          title: this.$t('Errors.not_success_operation'),
          text: response.message ? response.message : this.$t('Errors.error_get_data'),
        })
      }
    })

    // get determining data
    this.GET_DETERMINING({})

    // get determining data
    this.GET_GAMES({})

    if (SAVE_PAGES_FILTERS) {
      let filters = {
        name: this.promotions_filter.name,
        status: this.promotions_filter.status,
        company: this.employees_filter.company,
      }

      this.filter(filters);
    }
  },
  computed: {
    user() {
      return this.GET_USER();
    },
    isAdmin() {
      return this.user ? (this.user.role === UsersType.ADMIN) : false;
    },
    pageCount() {
      //return ~~(this.GET_PROMOTIONS().all.length / this.itemsPerPage) + 1 ;
      //return this.currentPromotions ? ~~(this.currentPromotions.length / this.itemsPerPage) + 1 : 1;
      return this.currentPromotions ? Math.ceil(this.currentPromotions.length / this.itemsPerPage) : 1;
    },
    employees_filter() {
      return this.GET_EMPLOYEES().filter;
    },
    promotions_filter() {
      return this.GET_PROMOTIONS().filter;
    },
    createPermission() {
      return this.isAdmin;
    },
    editPermission() {
      return this.isAdmin;
    },
    promotionStatuses() {
      return [
        {role: null, label: this.$t('InterfaceText.all')},
        ...PromotionsStatuses()
      ]
    },
    companies() {
      if(this.isAdmin) {
        return [
          {id: null, name: this.$t('CompanyText.all_companies')},
          ...this.GET_COMPANIES()
        ]
      }
      else {
        return this.user ? this.user.companies : []
      }
    },
  },
  methods: {
    ...mapGetters(["GET_USER", "GET_PROMOTIONS", "GET_EMPLOYEES","GET_COMPANIES",]),
    ...mapActions([
      "SET_EMPLOYEES_FILTER",
      "SET_PROMOTIONS_FILTER",
      "GET_PROMOTION_PARTICIPANTS_FROM",
      "GET_PROMOTIONS_DOWNLOAD",
      "GET_PROMOTION_PRIZES",
      "DELETE_PROMOTION",
      "GET_PROMOTIONS_FROM",
      "GET_DETERMINING",
      "GET_GAMES",
    ]),

    PromotionsStatusText,

    parseDate,

    // eslint-disable-next-line no-unused-vars
    filter(filter) {
      this.filterData = {...filter}

      let currentPromotions = this.GET_PROMOTIONS().all ? this.GET_PROMOTIONS().all : [];

      // filter by name
      if (hasContent(filter.name))
        currentPromotions = currentPromotions.filter(
            (v) =>
                `${v.name ? v.name : ''}`
                    .toLowerCase()
                    .indexOf(filter.name.toLowerCase()) !== -1
        );

      // filter by status
      if (hasContent(filter.status))
        currentPromotions = currentPromotions.filter(
            (v) => v.status === filter.status
        );

      // filter by current user company - if user is from specific company - show just current company users
      if (hasContent(filter.company))
        currentPromotions = currentPromotions.filter(
            (v) => v.company === filter.company
        );

      this.currentPromotions = currentPromotions ? currentPromotions : [];
    },

    downloadPromotionsList(format) {
      if (format) {
        this.isLoading = true

        this.GET_PROMOTIONS_DOWNLOAD({
          format,
          company: this.employees_filter ? this.employees_filter.company : null,
        }).then((res) => {
          this.isLoading = false

          if (res.success) {
            this.$notify({
              type: "success",
              title: this.$t('Errors.success_operation'),
              text: this.$t('InterfaceText.file_downloaded'),
            });
          }
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t('Errors.not_success_operation'),
          text: this.$t('Errors.error_format'),
        });
      }
    },

    setPromoFilterName(e) {
      if (SAVE_PAGES_FILTERS) {
        this.SET_PROMOTIONS_FILTER({
          ...this.promotions_filter,
          name: e.target.value,
        });
      }
      else {
        this.filterData.name = e.target.value
        this.filter(this.filterData)
      }
    },

    setPromoFilterStatus(data) {
      if (SAVE_PAGES_FILTERS) {
        this.SET_PROMOTIONS_FILTER({
          ...this.promotions_filter,
          status: !data ? null : data.status,
        });
      }
      else {
        this.filter(this.filterData)
      }
    },

    setEmployeesCompany(data) {
      if (SAVE_PAGES_FILTERS) {
        this.SET_EMPLOYEES_FILTER({
          ...this.employees_filter,
          company: !data ? null : data.id,
        });
      }
      else {
        this.filterData.company = !data ? null : data.id
        this.filter(this.filterData)
      }
    },


    changePage(page) {
      this.page = page;
    },

    openModalEmp(promotion) {
      console.log(`/promotions/new-promotions?promotion=${promotion.id}`)
      if(promotion) {
        this.$router.push({ path: `/promotions/new-promotions`, query: { promotion: promotion.id }  }).catch(() => {})
      }
    },

    deletePromotion(promotion) {
      if(promotion && promotion.status === PromotionsStatus.NEW) {
        if (
            confirm(this.$t('PromotionText.delete'))
        ) {
          this.isLoading = true

          this.DELETE_PROMOTION({
            id: promotion.id,
          }).then((res) => {
            this.isLoading = false

            if (res.success) {
              this.$notify({
                type: "success",
                title: this.$t('Errors.success_operation'),
                text: this.$t('PromotionText.delete_success'),
              });
            } else {
              this.$notify({
                type: "error",
                title: this.$t('Errors.not_success_operation'),
                text: this.$t('PromotionText.delete_code_error'),
              });
            }
          });
        }
      }
    },

    drawingModalOpen(item) {
      console.log(item)

      if(item && item.prizes.length > 0) {
        this.selectedItem = item;
        this.drawingModalOpened = true;

        this.getPromotionWinnerPrizes(item.id)
        this.getPromotionParticipants(item.id)
      }
      else {
        this.$notify({
          type: "error",
          title: this.$t('Errors.not_success_operation'),
          text: this.$t('PromotionText.missing_prize_data'),
        });
      }
    },

    drawingModalClose() {
      this.drawingModalOpened = false;
    },

    showPrizeInformation(prize) {
      if(hasContent(prize)) {
        this.currentPrize = prize;
        this.modalPrizeOpened = true;
      }
    },

    closeModalPrizeInformation() {
      this.currentPrize = null;
      this.modalPrizeOpened = false;
    },

    getPromotionWinnerPrizes(promotion) {
      if(promotion) {
        this.isLoading = true

        this.GET_PROMOTION_PRIZES({
          promotion: promotion,
        }).then(() => {
          this.isLoading = false
        });
      }
    },

    getPromotionParticipants(promotion) {
      if(promotion) {
        this.isLoading = true

        this.GET_PROMOTION_PARTICIPANTS_FROM({
          promotion: promotion,
        }).then(() => {
          this.isLoading = false
        });
      }
    },


    openModalUserInformation(employee) {
      this.currentEmployee = employee;
      this.modalOpened = true;
    },

    closeModalEmployee() {
      this.currentEmployee = null;
      this.modalOpened = false;
    },

  },
}
</script>

<style lang="scss" src="@/assets/scss/common.scss"></style>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixin.scss";

.promotions {
  flex: 1;
  width: 100%;
  min-width: 1150px;

  .buttons-panel__checkbox {
    margin-right: 47px;
  }

  .buttons-panel__input-text {
    &:after {
      display: none;
    }
  }

  .table {
    @include grid-table-row(145px, 60px, 11.8% 136px 13.75% 136px 14% 153px 8.2% 125px 6%);

    &__cell {
      &:first-child {
        grid-column-start: 1;
        grid-column-end: 2;
      }
      &:nth-child(2) {
        grid-column-start: 3;
        grid-column-end: 4;
      }
      &:nth-child(3) {
        grid-column-start: 5;
        grid-column-end: 6;
      }
      &:nth-child(4) {
        grid-column-start: 7;
        grid-column-end: 8;
      }
      &:nth-child(5) {
        grid-column-start: 9;
        grid-column-end: 10;
      }
    }
  }

  .dd {
    position: relative;
    width: 250px;
    &.object {
      width: 250px !important;
      margin-top: 0px !important;
      font-size: 12px;
      font-weight: 500;
      // .dd-button {
      //   height: 27px !important;
      // }
    }
    .dd-button {
      @include ui-dropdown-button();
      border-radius: 0px 5px 5px 0px;
    }
    & ::v-deep .vs__dropdown-toggle {
      border: 1px solid $inputCaption;
      box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
    }
    &:not(:first-child) {
      margin-top: 10px;
    }
  }
}

.buttons-panel {
  margin-bottom: 30px;
}

.multiline {
  white-space: pre-wrap;
  text-align: center;
}

.page-header__newpromo {
  // margin-left: 30px;
  // padding: 7px 0;
  // border: 1px solid $purple;
  // border-radius: 5px;
  // background: linear-gradient(180deg, #8F21C9 0%, #8F21C9 0.01%, #4640B9 100%);
  // text-align: center;
  // cursor: pointer;
  // &-text {
  //   font-size: 14px;
  //   font-weight: 16px;
  //   color: $white;
  // }
  @include purple-button;
  width: 130px;
}

</style>
