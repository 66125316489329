import axios from 'axios';
import { baseURL } from '../config';
import { delay } from '@/tools/utilsTools';

axios.defaults.withCredentials = true;

export const subscribeForUpdates = async (token, actions) => {

  let response = await axios.get(`${baseURL}pool`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }).catch(async (error) =>  {
    console.info("subscribeForUpdates error:")
    console.info(error)

    if(error.response != null && error.response.status === 401) {
      // Unauthorized access
      console.info('cancel subscribeForUpdates')
    }
    else {
      // delay pool request
      await delay(1000);
      subscribeForUpdates(token, actions)
    }
  });

  //console.info('subscribeForUpdates')

  if (response == null) {
    // wrong response no pool request - need to refresh page
    //subscribeForUpdates(token, actions)
  } else if (response.status === 401) {
    // Unauthorized access
  } else if (response.status === 502) {
    subscribeForUpdates(token, actions)
  } else if (response.status !== 200) {
    subscribeForUpdates(token, actions)
  } else {

    if (response.data) {

      if (response.data.action === 'new-promotion-message') {
        actions.ADD_PROMOTION_MESSAGE(response.data)
      } else if (response.data.action === 'change-promotion') {
        actions.CHANGE_PROMOTION(response.data)
      } else if (response.data.action === 'change-tip') {
        actions.CHANGE_TIP(response.data)
      } else if (response.data.action === 'change-suspicious') {
        actions.CHANGE_SUSPICIOUS(response.data)
      } else if (response.data.action === 'change-user') {
        actions.CHANGE_USER(response.data)
      } else if (response.data.action === 'new-user-message') {
        actions.ADD_USER_MESSAGE(response.data)
      } else if (response.data.action === 'change-company') {
        actions.CHANGE_COMPANY(response.data)
      } else if (response.data.action === 'new-company-message') {
        actions.ADD_COMPANY_MESSAGE(response.data)
      } else if (response.data.action === 'change-company-user') {
        actions.CHANGE_COMPANY_USER(response.data)
      } else if (response.data.action === 'change-code') {
        actions.CHANGE_CODE(response.data)
      } else if (response.data.action === 'generate-code') {
        actions.GENERATE_CODE(response.data)
      } else if (response.data.action === 'generate-code-qr') {
        actions.GENERATE_CODE_QR(response.data)
      } else if (response.data.action === 'change-drawing') {
        actions.CHANGE_DRAWING(response.data)
      }

    }

    subscribeForUpdates(token, actions)

  }

}
