<template>
    <div class="table-3">
        <div class="table-3__row title">
          <p class="table-3__cell">{{ $t('TableHeaders.title') }}</p>
          <p class="table-3__cell">{{ $t('TableHeaders.id') }}</p>
          <p class="table-3__cell">{{ $t('TableHeaders.action') }}</p>
        </div>
        <div class="table-3__body">
            <div
                v-for="n in games" :key="n.id"
                class="table-3__row"
            >
                <div class="table-3__cell">
                    <p>{{ n.name }}</p>
                </div>
                <div class="table-3__cell">
                  <p>{{ n.id }}</p>
                </div>

                <div class="table-3__cell button">
                  <!-- Edit Item -->
                    <div
                        v-if="editPermission"
                        class="table__cell-button"
                        @click="editGameInfo(n)">
                      <img
                          :title="$t('TabHeaders.information')"
                          src="@/assets/img/view_info.svg">
                    </div>

                    <!-- Delete Item -->
                    <div
                        v-if="editPermission"
                        class="table__cell-button"
                        :title="$t('Buttons.delete')"
                        @click="deleteGame(n)">
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.0001 3.25383e-08C10.8855 -0.000115151 11.7389 0.305579 12.3928 0.857061C13.0467 1.40854 13.4539 2.16597 13.5344 2.98063H19.0325C19.2777 2.9807 19.5138 3.06676 19.6929 3.22143C19.8721 3.3761 19.981 3.58784 19.9977 3.81387C20.0145 4.03989 19.9377 4.26336 19.783 4.4391C19.6282 4.61485 19.4071 4.72977 19.1642 4.76066L19.0325 4.769H18.2803L16.6454 17.8599C16.5726 18.441 16.2711 18.9771 15.7984 19.3662C15.3257 19.7553 14.7146 19.9703 14.0815 19.9702H5.9188C5.28569 19.9703 4.67464 19.7553 4.20189 19.3662C3.72915 18.9771 3.4277 18.441 3.35489 17.8599L1.71874 4.769H0.967756C0.733898 4.76899 0.507953 4.69074 0.331707 4.54871C0.155461 4.40669 0.0408371 4.2105 0.00903235 3.99642L0 3.87481C9.86195e-06 3.65873 0.0847014 3.44997 0.238413 3.28712C0.392124 3.12427 0.604456 3.01836 0.836141 2.98897L0.967756 2.98063H6.4659C6.54639 2.16597 6.95356 1.40854 7.60747 0.857061C8.26139 0.305579 9.1148 -0.000115151 10.0001 3.25383e-08ZM8.06464 7.45156C7.7485 7.45156 7.48398 7.63636 7.42979 7.87958L7.41946 7.97377V15.2763L7.42979 15.3693C7.48398 15.6125 7.7485 15.7973 8.06464 15.7973C8.38077 15.7973 8.64529 15.6125 8.69948 15.3693L8.70981 15.2751V7.97496L8.69948 7.87958C8.64529 7.63756 8.38077 7.45156 8.06464 7.45156ZM11.9357 7.45156C11.6195 7.45156 11.355 7.63636 11.3008 7.87958L11.2905 7.97377V15.2763L11.3008 15.3693C11.355 15.6125 11.6195 15.7973 11.9357 15.7973C12.2518 15.7973 12.5163 15.6125 12.5705 15.3693L12.5808 15.2751V7.97496L12.5705 7.87958C12.5163 7.63756 12.2518 7.45276 11.9357 7.45276V7.45156ZM10.0001 1.78838C9.21949 1.78838 8.56787 2.30104 8.41948 2.98063H11.5808C11.4311 2.30104 10.7808 1.78838 10.0001 1.78838Z" fill="#BDBDBD"/>
                      </svg>
                  </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

import {hasContent} from "@/tools/dataTools";

export default {
  name: 'ListsGamesModal',
  components: {
  },
  props: {
    editPermission: Boolean,
    search: String,
  },
  data() {
    return {
    }
  },
  computed: {
    games() {
      return this.GET_LIST_ITEMS().games ?
          this.GET_LIST_ITEMS().games.filter(
              (v) =>
                  `${v.info.name ? v.info.name : ''} ${v.info.link ? v.info.link : ''} `
                      .toLowerCase()
                      .indexOf(this.search.toLowerCase()) !== -1
          )
          : [];
    },
  },
  watch: {
  },
  methods: {
    ...mapGetters(["GET_LIST_ITEMS"]),

    editGameInfo(item) {
      if(hasContent(item))
        this.$emit('edit', item)
    },

    deleteGame(item) {
      if(hasContent(item))
        this.$emit('delete', item)
    },

  },
}
</script>

<style lang="scss" src="@/assets/scss/common.scss"></style>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixin.scss";

</style>
