<template>
    <div class="wrapper">
        <div class="page-header">
            <h2 class="page-header__title">
            Создание нового пользователя
            </h2>
        </div>
        <div class="new-account">
            <div class="new-account__inner">
                <div class="new-account__columns">
                    <div class="new-account__column">
                        <div class="new-account__item textarea">
                            <p class="new-account__item-text">
                                Фамилия
                            </p>
                            <input class="new-account__item-input" type="text" placeholder="Введите фамилию">
                        </div>
                        <div class="new-account__item textarea">
                            <p class="new-account__item-text">
                                Отчество
                            </p>
                            <input class="new-account__item-input" type="text" placeholder="Введите отчество">
                        </div>
                        <div class="new-account__item textarea">
                            <p class="new-account__item-text">
                                Телефон
                            </p>
                            <input class="new-account__item-input" type="text" placeholder="Введите email">
                        </div>
                        <div class="new-account__input select">
                            <p class="new-account__input-text">
                                Компания
                            </p>
                            <input type="text" class="new-account__input-input" placeholder="Выберите из списка">
                            <div class="new-account__input-button"></div>
                        </div>
                    </div>
                    <div class="new-account__column">
                        <div class="new-account__item textarea">
                            <p class="new-account__item-text">
                                Имя
                            </p>
                            <input class="new-account__item-input" type="text" placeholder="Введите фамилию">
                        </div>
                        <div class="new-account__item textarea">
                            <p class="new-account__item-text">
                                Паспортные данные
                            </p>
                            <input class="new-account__item-input" type="text" placeholder="Введите отчество">
                        </div>
                        <div class="new-account__item textarea">
                            <p class="new-account__item-text">
                                Почта
                            </p>
                            <input class="new-account__item-input" type="text" placeholder="Введите email">
                        </div>
                        <div class="new-account__input select">
                            <p class="new-account__input-text">
                                Роль
                            </p>
                            <input type="text" class="new-account__input-input" placeholder="Выберите из списка">
                            <div class="new-account__input-button"></div>
                        </div>
                    </div>
                </div>
                <div class="new-account__validation-rule">
                    <span>* </span>Все поля обязательны к заполнению
                </div>
                <div class="buttons-block">
                    <div class="buttons-block__save">
                        <p class="buttons-block__save-text">
                            Сохранить
                        </p>
                    </div>
                    <div
                        class="buttons-block__back"
                        @click="$emit('close-modal')"    
                    >
                        <p class="buttons-block__back-text">
                          {{ $t('Buttons.back') }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="new-account__logo">
                <div class="new-account__logo-drop">
                    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.5 29.7002C1.89782 29.7002 2.27936 29.8582 2.56066 30.1395C2.84196 30.4208 3 30.8024 3 31.2002V38.7002C3 39.4958 3.31607 40.2589 3.87868 40.8215C4.44129 41.3841 5.20435 41.7002 6 41.7002H42C42.7957 41.7002 43.5587 41.3841 44.1213 40.8215C44.6839 40.2589 45 39.4958 45 38.7002V31.2002C45 30.8024 45.158 30.4208 45.4393 30.1395C45.7206 29.8582 46.1022 29.7002 46.5 29.7002C46.8978 29.7002 47.2794 29.8582 47.5607 30.1395C47.842 30.4208 48 30.8024 48 31.2002V38.7002C48 40.2915 47.3679 41.8176 46.2426 42.9428C45.1174 44.0681 43.5913 44.7002 42 44.7002H6C4.4087 44.7002 2.88258 44.0681 1.75736 42.9428C0.632141 41.8176 0 40.2915 0 38.7002V31.2002C0 30.8024 0.158035 30.4208 0.43934 30.1395C0.720644 29.8582 1.10218 29.7002 1.5 29.7002Z" fill="url(#paint0_linear)"/>
                        <path d="M22.9377 35.562C23.077 35.7017 23.2426 35.8125 23.4248 35.8881C23.607 35.9638 23.8024 36.0027 23.9997 36.0027C24.197 36.0027 24.3924 35.9638 24.5746 35.8881C24.7568 35.8125 24.9224 35.7017 25.0617 35.562L34.0617 26.562C34.3434 26.2803 34.5016 25.8983 34.5016 25.5C34.5016 25.1017 34.3434 24.7197 34.0617 24.438C33.78 24.1563 33.398 23.9981 32.9997 23.9981C32.6014 23.9981 32.2194 24.1563 31.9377 24.438L25.4997 30.879V4.5C25.4997 4.10218 25.3417 3.72064 25.0604 3.43934C24.7791 3.15804 24.3975 3 23.9997 3C23.6019 3 23.2203 3.15804 22.939 3.43934C22.6577 3.72064 22.4997 4.10218 22.4997 4.5V30.879L16.0617 24.438C15.78 24.1563 15.398 23.9981 14.9997 23.9981C14.6014 23.9981 14.2194 24.1563 13.9377 24.438C13.656 24.7197 13.4978 25.1017 13.4978 25.5C13.4978 25.8983 13.656 26.2803 13.9377 26.562L22.9377 35.562Z" fill="url(#paint1_linear)"/>
                        <defs>
                        <linearGradient id="paint0_linear" x1="24" y1="29.7002" x2="24" y2="44.7002" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#8F21C9"/>
                        <stop offset="0.0001" stop-color="#8F21C9"/>
                        <stop offset="1" stop-color="#4640B9"/>
                        </linearGradient>
                        <linearGradient id="paint1_linear" x1="23.9997" y1="3" x2="23.9997" y2="36.0027" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#8F21C9"/>
                        <stop offset="0.0001" stop-color="#8F21C9"/>
                        <stop offset="1" stop-color="#4640B9"/>
                        </linearGradient>
                        </defs>
                    </svg>
                    <p class="new-account__logo-text">
                        Перетащите сканы документо сюда или нажмите, что бы загрузить
                    </p>
                </div>
                <div class="new-account__logo-button">
                    Загрузить файлы
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>

export default {
  name: 'NewAccount',
  components: {
  },
}
</script>

<style lang="scss" src="@/assets/scss/common.scss"></style>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixin.scss";

.new-account {
    display: flex;
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    width: 1032px;
    background-color: $white;
    border-radius: 10px;
    padding: 50px 145px 40px 70px;
    // &__inner {
    //     display: flex;
    //     flex-flow: row nowrap;
    // }

    &__validation-rule {
        margin-bottom: 30px;
        span {
            color: $red;
        }
        &-back {
            @include white-button;
            width: 190px;
        }
        &-forward {

        }
    }
}

.new-account__columns {
    display: flex;
    flex-flow: row nowrap;
    margin-right: 30px;
}

.new-account__column {
    &:first-child {
        margin-right: 30px;
    }
}

.new-account__item {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    width: 265px;
    margin-bottom: 20px;
    &-text {
        width: 100%;
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 5px;
        color: $black;
        span {
            color: $red;
        }
    }

    &-input {
        @include search-input;
    }

    &.textarea {
        grid-column: span 3;
        input {
            border-radius: 5px;
            width: 100%;
        }
    }
}

.buttons-block {
    &__back {
        @include white-button;
        width: 180px;
    }
    &__save {
        @include purple-button;
        width: 190px;
        margin-left: 0;
        margin-right: 30px;
    }
}

.new-account__logo {
    width: 100%;
    // &-text {
    //     text-align: center;
    //     margin-bottom: 5px;
    //     text-transform: uppercase;
    // }
    &-drop {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: flex-end;
        width: 300px;
        height: 165px;
        padding: 0 30px 47px;
        margin-bottom: 45px;
        background: $white;
        border: 1px dashed $purple;
        border-radius: 10px;
    }
    &-text {
        font-size: 12px;
        font-weight: 500;
        text-align: center;
        margin-top: 18px;
    }
    &-button {
        @include white-button;
        width: 190px;
        color: $purple;
        text-align: center;
        margin: 0 auto;
    }
    // &-requirements {
    //     font-size: 9px;
    //     font-weight: 500;
    // }
}

.new-account__input {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  margin-bottom: 30px;
  width: 100%;
  &-text {
    font-size: 12px;
    font-weight: 500;
  }

  &-input {
    @include search-input;
  }

  &-button {
    @include search-input__button;
  }

  &.select {
    .new-account__input-text {
        // margin: 0 auto 5px 0;
        width: 100%;
        margin-bottom: 5px;
        span {
            color: $red;
        }
    }

    .new-account__input-button {
      &::after {
        width: 9px;
        height: 7px;
        background: url('../assets/img/triangle.png');
      }
    }
  }
}

</style>