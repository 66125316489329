<template>
  <div
    class="modal"
    v-if="showDialog"
  >
    <div class="wrapper">

      <!-- Header -->
      <div class="header">
        <div class="left-content">
          <div class="text">{{ cardName }}</div>
        </div>
        <div
          class="close"
          @click="close"
        >
          <img src="@/assets/img/icons/close.svg">
        </div>

      </div>

      <!-- Content -->
      <div class="content">

        <div class="chat-history">

          <!-- Tabs Titles -->
          <div class="tabs-wrapper">

            <!-- Main Info Tab -->
            <div v-if="mainTabVisibility"
                class="tab-chat"
                @click="activeTab = 0"
            >
              <p>{{ $t('TabHeaders.general') }}</p>
              <div
                  class="shadow"
                  v-if="!(activeTab === 0)"
              ></div>
            </div>

            <!-- Documents Info Tab -->
            <div v-if="documentsTabVisibility"
                 class="tab-chat"
                 @click="activeTab = 1"
            >
              <p>{{ $t('TabHeaders.documents') }}</p>
              <div
                  class="shadow"
                  v-if="!(activeTab === 1)"
              ></div>
            </div>

            <!-- Chat Tab -->
            <div v-if="chatTabVisibility"
                class="tab-chat"
                @click="activeTab = 2; scrollChatToLastPosition();"
            >
              <p>{{ $t('TabHeaders.chat') }}</p>
              <div
                  class="shadow"
                  v-if="!(activeTab === 2)"
              ></div>
            </div>

            <!-- History Tab -->
            <div v-if="historyTabVisibility"
                class="tab-history"
                @click="activeTab = 3"
            >
              <p>{{ $t('TabHeaders.history') }}</p>
              <div
                  class="shadow"
                  v-if="!(activeTab === 3)"
              ></div>
            </div>

            <!-- Promotions Tab -->
            <div v-if="promotionTabVisibility"
                class="tab-history"
                @click="activeTab = 4"
            >
              <p>{{ $t('TabHeaders.promotions') }}</p>
              <div
                  class="shadow"
                  v-if="!(activeTab === 4)"
              ></div>
            </div>

          </div>


          <!-- Main Info Tab Content -->
          <div
              class="features main-wrapper"
              v-if="activeTab === 0"
          >

            <div class="new-account">

              <!-- Input Data Part -->
              <div class="new-account__inner">
                <div class="new-account__columns">

                  <!-- General Part -->
                  <div class="new-account__column">

                    <div class="new-account__inner-header">
                      <p>{{ $t('FieldsText.general_info') }}</p>
                    </div>

                    <!-- Name -->
                    <div class="new-account__item textarea">
                      <p class="new-account__item-text">
                        {{ $t('FieldsText.name_req_label') }}
                      </p>
                      <input class="new-account__item-input" type="text"
                             oninput="this.value = this.value.replace(/[^a-zA-Zа-яА-Я\s]/g, '')"
                             :placeholder="$t('FieldsText.name_label_input')"
                             :disabled="!editPermission"
                             v-model="employeeData.name" required>
                    </div>

                    <!-- Surname -->
                    <div class="new-account__item textarea">
                      <p class="new-account__item-text">
                        {{ $t('FieldsText.surname_req_label') }}
                      </p>
                      <input class="new-account__item-input" type="text"
                             oninput="this.value = this.value.replace(/[^a-zA-Zа-яА-Я\s]/g, '')"
                             :placeholder="$t('FieldsText.surname_label_input')"
                             :disabled="!editPermission"
                             v-model="employeeData.surname" required>
                    </div>

                    <!-- Lastname -->
                    <div class="new-account__item textarea">
                      <p class="new-account__item-text">
                        {{ $t('FieldsText.lastname_label') }}
                      </p>
                      <input class="new-account__item-input" type="text"
                             oninput="this.value = this.value.replace(/[^a-zA-Zа-яА-Я\s]/g, '')"
                             :placeholder="$t('FieldsText.lastname_label_input')"
                             :disabled="!editPermission"
                             v-model="employeeData.lastname">
                    </div>

                    <!-- Phone -->
                    <div class="new-account__item textarea">
                      <p class="new-account__item-text">
                        {{ $t('FieldsText.phone_label') }}
                      </p>
                      <input class="new-account__item-input" type="tel"
                             oninput="this.value = this.value.replace(/[^0-9+]/g, '')"
                             pattern="[7-9]{1}[0-9]{10}"
                             :placeholder="$t('FieldsText.phone_rules')"
                             :disabled="!editPermission"
                             v-model="employeeData.phone">
                    </div>

                    <!-- Additional User Data-->
                    <div v-if="employeeData && employeeData.additional_fields && employeeData.additional_fields.length > 0">
                      <div class="new-account__inner-header" style="margin-top: 10px">
                        <p>{{ $t('InterfaceText.additional_info') }}</p>
                      </div>
                      <div class="grid-container" v-for="data in employeeData.additional_fields" :key="data">


                        <div
                            v-if="data.type === AdditionalDataType.TEXT"
                            class="step__item textarea required"
                            style="margin-top: -15px; margin-left: -10px"
                        >
                          <p class="step__item-text">
                            {{ data.name }}
                          </p>
                          <input v-model="data.value" class="step__item-input" type="text" :placeholder="data.name"  :disabled="!editPermission">
                        </div>

                        <div
                            v-else-if="data.type === AdditionalDataType.CHECK"
                            class="step__item select"
                            style="margin-top: -15px; margin-left: -10px"
                        >
                          <div class="new-promotion__checkbox">
                            <input
                                :disabled="!editPermission"
                                type="checkbox" id="surname"
                                v-model="data.value" />
                            <label for="surname">{{ data.name }}</label>
                          </div>
                        </div>

                        <div
                            v-else-if="data.type === AdditionalDataType.DATE"
                            class="new-account__item textarea"
                            style="margin-top: -5px; margin-left: -10px"
                        >
                          <p class="new-account__item-text">
                            {{ data.name }}
                          </p>
                          <div class="date-picker">
                            <div class="date-start">
                              <input
                                  :disabled="!editPermission"
                                  :value="parseDate(data.value)"
                                  class="new-account__item-input"
                                  :placeholder="data.name"
                              />
                              <Datepicker
                                  :disabled="!editPermission"
                                  v-model="data.value"
                                  class="d-comp"
                                  valueType="format"
                                  locale="ru"
                                  autoApply
                                  hideInputIcon
                                  closable
                                  :clearable="false"
                                  :selectText="$t('Buttons.ok')"
                                  :cancelText="$t('Buttons.cancel')"
                              ></Datepicker>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div v-else style="height: 100px;"></div>

                  </div>

                  <!-- Access Part -->
                  <div class="new-account__column">

                    <div class="new-account__inner-header">
                      <p v-html="$t('InterfaceText.empty_field')"></p>
                    </div>

                    <!-- Email -->
                    <div class="new-account__item textarea">
                      <p class="new-account__item-text">
                        {{ $t('FieldsText.email_req_label') }}
                      </p>
                      <input class="new-account__item-input" type="email"
                             :placeholder="$t('FieldsText.email_label_input')"
                             required pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                             :disabled="!editPermission"
                             v-model="employeeData.email">
                    </div>

                    <!-- Password -->
                    <div class="new-account__item textarea">
                      <p class="new-account__item-text">
                        {{ $t('FieldsText.password_req_label') }}
                      </p>
                      <input class="new-account__item-input" type="text" :placeholder="$t('FieldsText.password_rules')"
                             :disabled="!changePasswordPermission"
                             v-model="employeeData.password">
                    </div>

                    <!-- Role -->
                    <div class="new-account__item textarea">
                      <p class="new-account__item-text">
                        {{ $t('FieldsText.role_req_label') }}
                      </p>
                      <input v-if="!editRolePermission"
                        class="new-account__item-input" type="text" placeholder="-"
                            :disabled="true"
                             :value="UserTypeText(employeeData.role)">
                      <div v-else class="dd object dd-role">
                        <v-select
                            :placeholder="$t('FieldsText.select_from_list')"
                            :options="UsersTypes()"
                            :reduce="(option) => option.role"
                            v-model="employeeData.role"
                        >
                          <!-- eslint-disable-next-line vue/no-unused-vars  -->
                          <template #no-options="{ search, searching, loading }">
                            {{ $t('Errors.missing_options') }}
                          </template>
                        </v-select>
                        <div
                            class="dd-button"
                            @click="openSelectOptions('.dd-role .vs__dropdown-toggle')"
                        ></div>
                      </div>
                    </div>

                    <!-- Address -->
                    <div class="new-account__item textarea">
                      <p class="new-account__item-text">
                        {{ $t('FieldsText.address_label') }}
                      </p>
                      <input class="new-account__item-input" type="text"
                             oninput="this.value = this.value.replace(/[^a-zA-Zа-яА-Я0-9/\s]/g, '')"
                             :placeholder="$t('FieldsText.address_label_input')"
                             :disabled="!editPermission"
                             v-model="employeeData.address">
                    </div>

                  </div>
                </div>


                <!-- Buttons Save/Cancel Block -->
                <div>
                  <div class="new-account__validation-rule">
                    <span>* </span>{{ $t('Rules.required_info') }}
                  </div>
                  <div class="buttons-block">
                    <div v-if="editPermission" class="buttons-block__save" @click="addEmployee">
                      <p class="buttons-block__save-text">
                        {{ saveBtName }}
                      </p>
                    </div>
                    <div
                        class="buttons-block__back"
                        @click="close"
                    >
                      <p class="buttons-block__back-text">
                        {{ $t('Buttons.close') }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Input Avatar Part -->
              <div class="new-account__logo">

                <div class="new-account__inner-header">
                  <p>{{ $t('FieldsText.profile_avatar') }}</p>
                </div>

                <!-- Input Avatar Region -->
                <div v-if="editPermission" class="new-account__logo-drop pointer"
                     @dragover="dragover"
                     @dragleave="dragleave"
                     @drop="dropAvatar"
                >
                  <img
                      src="@/assets/img/bi_download.svg"
                      alt="notification avatar">

                  <label for="assetFieldHandle" class="block cursor-pointer pointer">
                    <div class="new-account__logo-text">
                      {{ $t('Photos.drop_avatar_here') }}
                    </div>
                  </label>
                  <input id="assetFieldHandle"
                         class="file_input pointer"
                         type="file" name="fields[assetFieldHandle][]"
                         @change="onChangeAvatar" ref="avatar_file" accept=".jpg,.jpeg,.png" />
                </div>

                <!-- View Profile Avatar -->
                <div v-if="avatar && reloadPreview"
                     style="margin-top: 0px; width: 100%;"
                     class="images avatar-preview">
                  <img
                      style="object-fit:inherit;"
                      class="avatar-preview__item"
                      :key="avatar"
                      :src="avatar.url"
                  >
                </div>

              </div>
            </div>

          </div>

          <!-- Documents Info Tab Content -->
          <div
              class="features documents-wrapper"
              v-if="activeTab === 1"
          >

            <div class="new-account">

              <div class="new-account__inner">
                <div class="new-account__columns">

                  <!-- Passport Part -->
                  <div v-if="viewDocumentsPermission" class="new-account__column">

                    <div class="new-account__inner-header">
                      <p>{{ $t('FieldsText.passport_info') }}</p>
                    </div>

                    <!-- Birthday -->
                    <div class="new-account__item textarea">
                      <p class="new-account__item-text">
                        {{ $t('FieldsText.birth_label') }}
                      </p>
                      <div class="date-picker">
                        <div class="date-start">
                          <input
                              :disabled="!editPermission"
                              :value="parseDate(employeeData.birthday)"
                              class="new-account__item-input"
                              :placeholder="$t('FieldsText.birth_rules')"
                          />
                          <Datepicker
                              :disabled="!editPermission"
                              v-model="employeeData.birthday"
                              class="d-comp"
                              valueType="format"
                              locale="ru"
                              autoApply
                              hideInputIcon
                              closable
                              :clearable="false"
                              :selectText="$t('Buttons.ok')"
                              :cancelText="$t('Buttons.cancel')"
                          ></Datepicker>
                        </div>
                      </div>
                    </div>

                    <!-- Gender -->
                    <div class="new-account__item select">
                      <p class="new-account__item-text">
                        {{ $t('FieldsText.gender_info') }}
                      </p>
                      <div class="dd object dd-gender-filter">
                        <v-select
                            :disabled="!(editPermission)"
                            :placeholder="$t('FieldsText.select_from_list')"
                            :options="UserSexes()"
                            :reduce="(option) => option.gender"
                            label="label"
                            :clearable="false"
                            v-model="employeeData.gender"
                        >
                          <!-- eslint-disable-next-line vue/no-unused-vars  -->
                          <template #no-options="{ search, searching, loading }">
                            {{ $t('Errors.missing_options') }}
                          </template>
                        </v-select>
                        <div
                            class="dd-button"
                            @click="openSelectOptions('.dd-gender-filter .vs__dropdown-toggle')"
                        ></div>
                      </div>
                    </div>

                    <!-- Seria -->
                    <div class="new-account__item textarea">
                      <p class="new-account__item-text">
                        {{ $t('PassportText.seria_label') }}
                      </p>
                      <input class="new-account__item-input" type="text" name="seria" maxlength="4"
                             oninput="this.value = this.value.replace(/[^0-9]/g, '')"
                             :placeholder="$t('PassportText.seria_label_input')"
                             :disabled="!editPermission"
                             v-model="employeeData.passport.seria">
                    </div>

                    <!-- Number -->
                    <div class="new-account__item textarea">
                      <p class="new-account__item-text">
                        {{ $t('PassportText.number_label') }}
                      </p>
                      <input class="new-account__item-input" type="text" name="number" maxlength="6"
                             oninput="this.value = this.value.replace(/[^0-9]/g, '')"
                             :placeholder="$t('PassportText.number_label_input')"
                             :disabled="!editPermission"
                             v-model="employeeData.passport.number">
                    </div>

                  </div>

                  <!-- Passport Part 2 -->
                  <div v-if="viewDocumentsPermission" class="new-account__column">

                    <div class="new-account__inner-header">
                      <p v-html="$t('InterfaceText.empty_field')"></p>
                    </div>

                    <!-- Who -->
                    <div class="new-account__item textarea">
                      <p class="new-account__item-text">
                        {{ $t('PassportText.who_label') }}
                      </p>
                      <input class="new-account__item-input" type="text" :placeholder="$t('PassportText.who_label_input')"
                             :disabled="!editPermission"
                             v-model="employeeData.passport.who">
                    </div>

                    <!-- Department -->
                    <div class="new-account__item textarea">
                      <p class="new-account__item-text">
                        {{ $t('PassportText.department_label') }}
                      </p>
                      <input class="new-account__item-input" type="text" name="department" pattern="\d{3} \d{3}" maxlength="6"
                             oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/\D/g, '').replace(/(\d{3})(\d{3})/, '$1 $2')"
                             :placeholder="$t('PassportText.department_label_input')"
                             :disabled="!editPermission"
                             v-model="employeeData.passport.department">
                    </div>

                    <!-- When -->
                    <div class="new-account__item textarea">
                      <p class="new-account__item-text">
                        {{ $t('PassportText.when_label') }}
                      </p>
                      <div class="date-picker">
                        <div class="date-start">
                          <input
                              :disabled="!editPermission"
                              :value="parseDate(employeeData.passport.when)"
                              class="new-account__item-input"
                              :placeholder="$t('PassportText.when_label_input')"
                          />
                          <Datepicker
                              :disabled="!editPermission"
                              v-model="employeeData.passport.when"
                              class="d-comp"
                              valueType="format"
                              locale="ru"
                              autoApply
                              hideInputIcon
                              closable
                              :clearable="false"
                              :selectText="$t('Buttons.ok')"
                              :cancelText="$t('Buttons.cancel')"
                          ></Datepicker>
                        </div>
                      </div>
                    </div>

                  </div>

                </div>

                <!-- Buttons Save/Cancel Block -->
                <div class="bottom-view">
                  <div class="new-account__validation-rule">
                    <span>* </span>{{ $t('Rules.required_info') }}
                  </div>
                  <div class="buttons-block">
                    <div v-if="editPermission" class="buttons-block__save" @click="addEmployee">
                      <p class="buttons-block__save-text">
                        {{ saveBtName }}
                      </p>
                    </div>
                    <div
                        class="buttons-block__back"
                        @click="close"
                    >
                      <p class="buttons-block__back-text">
                        {{ $t('Buttons.close') }}
                      </p>
                    </div>
                  </div>
                </div>

              </div>

              <div v-if="viewDocumentsPermission" class="new-account__logo">

                <div class="new-account__inner-header">
                  <p>{{ $t('FieldsText.documents_scans') }}</p>
                </div>

                <div v-if="editPermission" class="new-account__logo-drop pointer"
                     @dragover="dragover"
                     @dragleave="dragleave"
                     @drop="drop"
                >
                  <img
                      src="@/assets/img/bi_download.svg"
                      alt="notification avatar">

                  <label for="assetsFieldHandle" class="block cursor-pointer pointer">
                    <div class="new-account__logo-text">
                      {{ $t('Photos.drop_scans_here') }}
                    </div>
                  </label>
                  <input id="assetsFieldHandle"
                         class="file_input pointer" multiple
                         type="file" name="fields[assetsFieldHandle][]"
                         @change="onChange" ref="file" accept=".jpg,.jpeg,.png" />
                </div>

                <!-- Upload Photos List  -->
                <div v-if="fileList.length > 0">
                  <p class="new-account__item-text" style="margin-top: -15px;">
                    {{ $t('Photos.scans_wait_upload') }}
                  </p>
                  <div v-if="reloadPreview" class="images photo-preview">
                    <img
                        style="object-fit:inherit;"
                        class="photo-preview__item"
                        @click="show(fileList)"
                        v-for="file in fileList"
                        :key="file"
                        :src="file.url">
                  </div>

                  <div @click="uploadScans" class="new-account__logo-button">
                    {{ $t('Buttons.upload_file') }}
                  </div>

                </div>

                <!-- Documents Photos List  -->
                <div v-if="images.length > 0">
                  <p class="new-account__item-text" style="margin-top: 10px;">
                    {{ $t('Photos.scans_uploaded') }}
                  </p>
                  <div
                      class="images photo-preview"
                  >
                    <img
                        style="object-fit:inherit;"
                        class="photo-preview__item"
                        @click="show(images)"
                        v-for="file in images"
                        :key="file"
                        :src="file"
                    >
                  </div>

                </div>

              </div>
            </div>

          </div>

          <!-- Chat Tab Content -->
          <div
              class="chat-wrapper"
              v-if="activeTab === 2"
          >
            <div
                id='msgsContainer'
                ref='msgsContainer'
                class="msgs-container"
            >
              <div
                  v-for="msg in employeeData.messages"
                  :key="msg.date"
                  :class="msg.user_id === user.id ? 'msg-container self-message': 'msg-container'"
              >
                <img
                    class="avatar"
                    :src="getUserAvatarUrl(msg.avatar, msg.user_id === user.id)"
                    alt="avatar icon"
                >
                <div class="msg-wrapper">
                  <div class="msg">
                    <div class="msg-info">
                      <p class="name">{{msg.user}}</p>
                      <p class="date-time">{{parseFullTime(msg.date)}}</p>
                    </div>
                    <p class="text">{{msg.message}}</p>
                  </div>
                  <!--span class="answer-button">Ответить</span-->
                </div>
              </div>
            </div>
            <div class="chat-input">
              <img
                  class="avatar"
                  :src="getUserAvatarUrl(user.avatar, true)"
                  alt="avatar icon"
              >
              <div class="chat-form">
                <input
                    class="chat-message"
                    :placeholder="$t('InterfaceText.write_message')"
                    v-model="message"
                >
                <button
                    :class="(message == null || message === '') ? 'send-message-inactive' : 'send-message'"
                    :disabled="message == null || message === ''"
                    @click="sendMessage"
                >{{ $t('Buttons.send' )}}</button>
              </div>
            </div>
          </div>

          <!-- History Tab Content -->
          <div
              class="history-wrapper"
              v-if="activeTab === 3"
          >
            <div class="inner">
              <div class="table-wrapper">
                <div class="column-names">
                  <div class="date">{{ $t('TableHeaders.date') }}</div>
                  <div class="time">{{ $t('TableHeaders.time') }}</div>
                  <div class="author">{{ $t('TableHeaders.name') }}</div>
                  <div class="changes">{{ $t('TableHeaders.changes') }}</div>
                </div>
                <div class="table">
                  <div
                      class="table-string"
                      v-for="action in employeeData.history"
                      :key="action.date"
                  >
                    <div class="table-cell">{{parseDate(action.date)}}</div>
                    <div class="table-cell">{{parseTime(action.date)}}</div>
                    <div class="table-cell">{{action.user}}</div>
                    <div class="table-cell">{{action.status}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Promotions Tab Content -->
          <div
              class="history-wrapper"
              v-if="activeTab === 4"
          >
            <div class="inner">
              <div class="table-wrapper">
                <div class="column-names">
                  <div class="date">{{ $t('FieldsText.participation_date_label') }}</div>
                  <div class="time">{{ $t('FieldsText.status_label') }}</div>
                  <div class="author">{{ $t('FieldsText.title_label') }}</div>
                  <div class="changes">{{ $t('FieldsText.prize_label') }}</div>
                </div>
                <div class="table">
                  <div
                      class="table-string"
                      v-for="participant in employeeData.participants"
                      :key="participant.id"
                  >
                    <div class="table-cell">{{parseFullTime(participant.createdAt)}}</div>
                    <div class="table-cell">{{ParticipantsStatusText(participant.status)}}</div>
                    <div class="table-cell">{{participant.fk_promotion.name}}</div>
                    <div class="table-cell multiline">{{ (participant.prizes.length === 0) ? $t('PrizeText.no_prize') : getPrizesNames(participant.prizes) }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { baseURL } from "@/common/config.js";
import { mapActions, mapGetters } from "vuex";

import InputsMixin from "@/mixins/inputsEvent";
import DragDrop from "@/mixins/dragDropEvents";

import 'viewerjs/dist/viewer.css'

import { delay } from '@/tools/utilsTools';
import {
  getFileImage,

  getImageUrl,
  getUserAvatarUrl,
} from '@/tools/imageTools';

import {
  UsersType,
  UsersTypes,
  NewUsers,
  UsersRequiredVerify,
  UsersFromBody,
  UserTypeText,
  UserSexes,
} from "@/models/users";

import {
  PrizeStatusText,
  ParticipantsStatusText,
} from "@/models/participants";

import {
  checkEmailPattern,
  hasContent,
  isEmpty,
} from "@/tools/dataTools";

import {
  parseDate,
  parseTime,
  parseFullTime,
} from "@/tools/dateTools";

import {
  AdditionalDataType,
  AdditionalDataTypes,
} from "@/models/promotions";

export default {
  name: "ModalEditEmployee",
  components: {
  },
  props: {
    opened: Boolean,
    tab: Number,
    employee: Object,
  },
  mixins: [InputsMixin, DragDrop],
  delimiters: ['${', '}'], // Avoid Twig conflicts
  data() {
    return {
      // types
      UsersTypes,
      AdditionalDataType,

      dialog: false,

      // data
      isLoading: false,

      formDisabled: true,
      activeTab: 0,

      message: '',

      employeeData: NewUsers(),

      // drag and drop files
      reloadPreview: true,
      fileList: [],
      avatar: null,
    };
  },
  mounted() {
    if (this.employee) {
      this.employeeData = UsersFromBody(this.employee)
    }
  },
  created() {
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    employee: function (newValue, oldValue) {
      if (!newValue) {
        this.employeeData = NewUsers()
      } else {
        this.employeeData = UsersFromBody(this.employee)

        // get avatar
        if(this.employeeData.avatar == null || this.employeeData.avatar === '')
          this.avatar = null
        else {
          this.avatar = {};
          this.reloadPreview = false;
          this.avatar.url = getImageUrl(this.employeeData.avatar);
          this.reloadPreview = true;
        }
      }

      if(this.tab != null && this.activeTab !== this.tab)
        this.activeTab = this.tab
      else if(this.activeTab == null)
        this.activeTab = 0

      this.scrollChatToLastPosition()
    },
    tab() {
      if(this.tab != null && this.activeTab !== this.tab)
        this.activeTab = this.tab
      else if(this.activeTab == null)
        this.activeTab = 0
    },
    isLoading() {
      if(this.isLoading)
        this.$progress.start();
      else
        this.$progress.finish();
    },
  },
  methods: {
    ...mapGetters(["GET_USER","GET_COMPANIES"]),
    ...mapActions(["GET_USER_FROM","CREATE_USER","UPDATE_USER", "UPLOAD_USER_SCAN", "SEND_USER_MESSAGE"]),

    parseDate,
    parseTime,
    parseFullTime,

    getUserAvatarUrl,
    UserTypeText,

    PrizeStatusText,
    ParticipantsStatusText,

    AdditionalDataTypes,
    UserSexes,

    inited (viewer) {
      this.$viewer = viewer
    },
    show(images) {
      this.$viewerApi({
        images: images ? images : [],
      });
    },

    open(user) {
      console.log( {
        name: 'open',
        user
      })

      if (user) {
        this.employeeData = UsersFromBody(user)
        this.dialog = true
      }
    },

    addEmployee() {
      if(this.employeeData == null) {
        this.$notify({
          type: "error",
          title: this.$t('Errors.error_not_ok'),
          text: this.$t('Errors.error_wrong_data'),
        });
      }
      else if(!UsersRequiredVerify(this.employeeData)) {
        this.$notify({
          type: "error",
          title: this.$t('Errors.error_not_ok'),
          text: this.$t('Errors.missing_required_data'),
        });
      }
      else if(!checkEmailPattern(this.employeeData.email)) {
        this.$notify({
          type: "error",
          title: this.$t('Errors.error_not_ok'),
          text: this.$t('Errors.badEmail'),
        });
      }
      else if(isEmpty(this.employeeData.id)) {
        this.createProfile()
      }
      else {
        this.updateProfile()
      }
    },

    createProfile() {
      let formData = new FormData();
      formData.append("avatar", this.avatar);

      this.employeeData.avatar = this.avatar ? formData : null;

      this.isLoading = true

      this.CREATE_USER({
        ...this.employeeData,
      }).then((res) => {
        this.isLoading = false

        if (res.success) {
          this.employeeData.id = res.id
          this.$notify({
            type: "success",
            title: this.$t('Errors.success_operation'),
            text: this.$t('EmployeeText.user_created'),
          });
        }
        else if (res.error === "badPassword") {
          this.$notify({
            type: "error",
            title: this.$t('Errors.error_not_ok'),
            text: this.$t('Errors.not_strong_password'),
          });
        }
        else if (res.error === "badModelData" && res.message != null &&
            res.message.name === "SequelizeUniqueConstraintError") {
          this.$notify({
            type: "error",
            title: this.$t('Errors.error_not_ok'),
            text: this.$t('EmployeeText.error_exists'),
          });
        }
        else if (res.message) {
          this.$notify({
            type: "error",
            title: this.$t('Errors.error_not_ok'),
            text: res.message,
          });
        }
        else {
          this.$notify({
            type: "error",
            title: this.$t('Errors.error_not_ok'),
            text: this.$t('Errors.error_try_again'),
          });
        }
      });
    },

    getProfile(updateJustScans) {
      if (this.employeeData) {
        this.isLoading = true

        this.GET_USER_FROM({
          id: this.employeeData.id,
        }).then((res) => {
          this.isLoading = false

          if (res.success) {
            if(res.user) {
              if(updateJustScans && res.user.passport)
                this.employeeData.passport.scans = res.user.passport.scans ? res.user.passport.scans : []
              else
                this.employeeData = UsersFromBody(res.user)
            }
          } else if (res.error === "badPassword") {
            this.$notify({
              type: "error",
              title: this.$t('Errors.error_not_ok'),
              text: this.$t('Errors.not_strong_password'),
            });
          } else if (res.error === "badModelData" && res.message != null &&
              res.message.name === "SequelizeUniqueConstraintError") {
            this.$notify({
              type: "error",
              title: this.$t('Errors.error_not_ok'),
              text: this.$t('EmployeeText.error_exists'),
            });
          } else {
            this.$notify({
              type: "error",
              title: this.$t('Errors.error_not_ok'),
              text: this.$t('Errors.error_try_again'),
            });
          }
        });
      }
    },

    updateProfile() {
      let formData = new FormData();

      formData.append("avatar", this.avatar);
      formData.append("id", this.employeeData.id);

      this.employeeData.avatar = (this.avatar && this.avatar.upload) ? formData : null;

      this.isLoading = true

      this.UPDATE_USER({
        ...this.employeeData,
      }).then((res) => {
        this.isLoading = false

        if (res.success) {
          this.$notify({
            type: "success",
            title: this.$t('Errors.success_operation'),
            text: this.$t('EmployeeText.user_updated'),
          });
        }
        else if (res.error === "badPassword") {
          this.$notify({
            type: "error",
            title: this.$t('Errors.error_not_ok'),
            text: this.$t('Errors.not_strong_password'),
          });
        }
        else {
          this.$notify({
            type: "error",
            title: this.$t('Errors.error_not_ok'),
            text: this.$t('Errors.error_try_again'),
          });
        }
      });
    },

    async uploadScans() {
      if(this.fileList != null) {
        // try to send files
        for (let i = 0; i < this.fileList.length; i++) {
          await delay(1000)
          let result = await this.uploadScanFile(this.fileList[i])
          this.fileList[i].uploaded = result
        }

        // remove uploaded files
        this.fileList = this.fileList.filter(file => file.uploaded === false)

        // get last user information
        this.getProfile(true)
      }
    },

    async uploadScanFile(file) {
      return new Promise(resolve => {
        if(file == null)
          resolve(false);

        let formData = new FormData();

        formData.append("scan", file);
        formData.append("id", this.employeeData.id);

        this.isLoading = true

        this.UPLOAD_USER_SCAN( {
          id: this.employeeData.id,
          scan: formData,
        }).then((res) => {
          this.isLoading = false

          if (res.success) {
            this.$notify({
              type: "success",
              title: this.$t('Errors.success_operation'),
              text: this.$t('EmployeeText.scan_uploaded_success'),
            });
            resolve(true)
          } else {
            this.$notify({
              type: "error",
              title: this.$t('Errors.error_not_ok'),
              text: this.$t('Errors.error_try_again'),
            });

            resolve(false)
          }
        });

      });
    },

    sendMessage() {
      if (hasContent(this.message))
        this.isLoading = true

        this.SEND_USER_MESSAGE({
          id: this.employeeData.id,
          message: this.message,
        }).then((res) => {
          this.isLoading = false

          if (res.success) {
            this.message = '';
            this.scrollChatToLastPosition()

            this.$notify({
              type: "success",
              title: this.$t('Errors.success_operation'),
              text: this.$t('ChatText.sent_success'),
            });
          } else {
            this.$notify({
              type: "error",
              title: this.$t('Errors.not_success_operation'),
              text: this.$t('ChatText.sent_error'),
            });
          }

        });
    },

    // drag and drop files
    async onChangeAvatar() {
      this.avatar = this.$refs.avatar_file.files[0];
      this.reloadPreview = false
      await getFileImage(this.avatar)
      this.reloadPreview = true
    },
    // eslint-disable-next-line no-unused-vars
    dropAvatar(event) {
      event.preventDefault();
      this.$refs.avatar_file.files = event.dataTransfer.files;
      this.onChangeAvatar();
    },
    async onChange() {
      this.fileList = [...this.$refs.file.files];

      for (let i = 0; i < this.fileList.length; i++) {
        if(this.fileList[i].url == null) {
          this.reloadPreview = false
          await getFileImage(this.fileList[i])
          this.reloadPreview = true
        }
      }
    },

    // eslint-disable-next-line no-unused-vars
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
    },

    scrollChatToLastPosition() {
      setTimeout(() => {
        let msgsContainer = document.getElementById("msgsContainer");

        if(msgsContainer) {
          msgsContainer.scrollTo({ top: msgsContainer.scrollHeight, behavior: 'smooth' })
        }
      }, 500);
    },

    close() {
      this.activeTab = 0;
      this.avatar = null;
      this.fileList = [];

      this.dialog = false
      this.$emit('close-modal');
    },

    getPrizesNames(prizes) {
      if(isEmpty(prizes))
        return this.$t('PrizeText.no_prize')
      else {
        let names = ''
        prizes.map(v => names += `${v.prize.name}: ${PrizeStatusText(v.status)}\n`);

        return names
      }
    }

  },
  computed: {
    showDialog() {
      return this.dialog || this.opened
    },
    user() {
      return this.GET_USER();
    },
    isAdmin() {
      return this.user ? (this.user.role === UsersType.ADMIN) : false;
    },
    companies() {
      return this.GET_COMPANIES();
    },
    cardName() {
      if(this.employeeData == null || this.employeeData.id == null || this.employeeData.id === '')
        return this.$t('EmployeeText.new_employee')
      else
        return this.$t('EmployeeText.info_employee')
    },
    saveBtName() {
      if(this.employeeData == null || this.employeeData.id == null || this.employeeData.id === '')
        return this.$t('Buttons.add')
      else
        return this.$t('Buttons.save')
    },

    mainTabVisibility() {
      return true;
    },
    documentsTabVisibility() {
      //return hasContent(true);
      return hasContent(this.employeeData) && hasContent(this.employeeData.id) && this.viewDocumentsPermission;
    },
    chatTabVisibility() {
      //return hasContent(true);
      return hasContent(this.employeeData) && hasContent(this.employeeData.id);
    },
    historyTabVisibility() {
      //return true;
      return hasContent(this.employeeData) && hasContent(this.employeeData.id) && this.editPermission;
    },
    promotionTabVisibility() {
      //return true;
      return hasContent(this.employeeData) && hasContent(this.employeeData.id) && this.editPermission;
    },
    images() {
      let allImages = [];

      if (this.employeeData && this.employeeData.passport && this.employeeData.passport.scans) {
        allImages = this.employeeData.passport.scans
      }

      return allImages.map((v) => baseURL + v);
    },
    editPermission() {
      if(this.user.id === this.employeeData.id)
        return true;
      else
        return this.isAdmin;
    },
    editRolePermission() {
      // TODO need to test if this approach is correct
      //return this.isAdmin && this.employeeData.role !== UsersType.ADMIN;
      // can change any user Role if user is Admin
      return this.isAdmin;
    },
    changePasswordPermission() {
      if(this.employeeData.id == null || this.employeeData.id === '' || this.user.id === this.employeeData.id)
        return true;
      else
        return false;
    },
    viewDocumentsPermission() {
      if(this.user.id === this.employeeData.id)
        return true;
      else
        return this.isAdmin || this.user.role === UsersType.ACCOUNTANT;
    },
  },
};
</script>

<style scoped lang="scss">
  @import "@/assets/scss/_variables.scss";
  @import "@/assets/scss/_mixin.scss";

  .v-cloak {
  display: none;
  }

  .file_input {
    margin-bottom: -47px;
    width: inherit;
    height: inherit;
    opacity: 0;
    overflow: hidden;
    position: absolute;
  }

  .avatar {
    height: 36px;
    width: 36px;
    border-radius: 50%;
  }

  .modal {
    @include modal-base;
    .wrapper {
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 100;
      overflow: hidden;
      border-radius: 10px;
      background: #fff;
      width: 1032px;
      padding-bottom: 1px;
      @include modal-base-header;
      @include modal-base-content;
      .header {
        background: $mainBgColor;
      }
      .vs__dropdown-toggle {
        height: 24px !important;
      }
      .content {
        .user-info {
          @include d-flex-row-n;
          .edit-buttons {
            @include d-flex-col-n;
            .avatar {
              border-radius: 5px;
              overflow: hidden;
              display: flex;
              flex-flow: row nowrap;
              position: relative;
              width: 158px;
              height: 132px;
              align-items: center;
              justify-content: center;
              img {
                width: 100%;
                height: 100%;
              }
              .edit-overlay {
                background: rgba(0, 0, 0, 0.3);
                @include d-flex-row-n;
                justify-content: center;
                align-items: center;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                cursor: pointer;
                .image-input {
                  left: 0;
                  top: 0;
                  opacity: 0;
                  display: block;
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  cursor: pointer;
                }
              }
            }
            .edit-button {
              margin-top: 9px;
              @include ui-default-button(#fff, $blue);
            }
            .save-button {
              margin-top: 11px;
              @include ui-default-button($blue, #fff);
            }
          }
          .edit-inputs {
            @include d-flex-col-n;
            width: 241px;
            margin-left: 33px;
            & > *:first-child ~ * {
              margin-top: 5px;
            }
          }
        }
        .chat-history {
          .tabs-wrapper {
            display: flex;
            .tab-chat,
            .tab-history {
              background-color: $white;
              border-radius: 10px;
              width: 123px;
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              box-shadow: 0px -3px 4px 0px #0000000d;
              cursor: pointer;
              position: relative;
              p {
                color: $blue;
              }
              .shadow {
                position: absolute;
                bottom: -10px;
                left: -6px;
                width: 144px;
                height: 10px;
                box-shadow: 0px -3px 4px 0px #0000000d;
                z-index: 1;
              }
            }
            .tab-chat {
              margin-right: 10px;
              position: relative;
              &::before {
                content: "";
                position: absolute;
                width: 10px;
                height: 20px;
                bottom: -10px;
                left: 0;
                background-color: $white;
              }
              &::after {
                content: "";
                width: 20px;
                height: 10px;
                position: absolute;
                bottom: -1px;
                right: -10px;
                background-color: $white;
                clip-path: polygon(
                        52% 23%,
                        51% 10%,
                        50% 0%,
                        0% 0%,
                        0% 25%,
                        0% 100%,
                        100% 100%,
                        92% 96%,
                        84% 90%,
                        73% 80%,
                        67% 70%,
                        62% 59%,
                        58% 47%,
                        55% 36%
                );
              }
              .shadow {
                left: 0;
                width: 128px;
              }
            }
            .tab-history {
              position: relative;
              &::before {
                content: "";
                position: absolute;
                width: 20px;
                height: 10px;
                bottom: -1px;
                left: -10px;
                background-color: $white;
                transform: scale(-1, 1);
                clip-path: polygon(
                        52% 23%,
                        51% 10%,
                        50% 0%,
                        0% 0%,
                        0% 25%,
                        0% 100%,
                        100% 100%,
                        92% 96%,
                        84% 90%,
                        73% 80%,
                        67% 70%,
                        62% 59%,
                        58% 47%,
                        55% 36%
                );
              }

              &::after {
                content: "";
                width: 20px;
                height: 10px;
                position: absolute;
                bottom: -1px;
                right: -10px;
                background-color: $white;
                clip-path: polygon(
                        52% 23%,
                        51% 10%,
                        50% 0%,
                        0% 0%,
                        0% 25%,
                        0% 100%,
                        100% 100%,
                        92% 96%,
                        84% 90%,
                        73% 80%,
                        67% 70%,
                        62% 59%,
                        58% 47%,
                        55% 36%
                );
              }
            }
          }
          .main-wrapper {
            border-radius: 10px;
            background-color: $white;
            //padding: 30px 30px 40px;
            box-shadow: 0px -3px 4px 0px #0000000d;
          }
          .documents-wrapper {
            border-radius: 10px;
            background-color: $white;
            //padding: 30px 30px 40px;
            box-shadow: 0px -3px 4px 0px #0000000d;
          }
          .chat-wrapper {
            border-radius: 10px;
            background-color: $white;
            padding: 12px 25px 25px 25px;
            box-shadow: 0px -3px 4px 0px #0000000d;
            .msgs-container {
              margin-bottom: 27px;
              height: 454px;
              overflow-y: scroll;
              .msg-container {
                max-width: 77%;
                display: flex;
                margin-bottom: 13px;
                img {
                  width: 40px;
                  height: 40px;
                  margin-right: 20px;
                }
                .msg-wrapper {
                  display: flex;
                  flex-direction: column;
                  .msg {
                    margin-bottom: 5px;
                    padding: 7px 33px 6px 13px;
                    background-color: #f2f2f2;
                    border-radius: 100px;
                    p {
                      color: $activeText;
                      margin: 0;
                    }
                    .msg-info {
                      display: flex;
                      margin-bottom: 5px;
                      .name {
                        font-size: 12px;
                        font-weight: 500;
                        margin-right: 15px;
                      }
                      .date-time {
                        color: $chatNotActiveText;
                        font-size: 10px;
                      }
                    }
                    .text {
                      font-size: 12px;
                      font-weight: 300;
                      flex-grow: 1;
                    }
                  }
                  .answer-button {
                    color: $textGray;
                    font-size: 10px;
                  }
                }
                &.self-message {
                  margin: 0 0 13px auto;
                  justify-content: flex-end;
                  img {
                    order: 2;
                    margin-right: 10px;
                    margin-left: 20px;
                  }
                  .msg-wrapper {
                    .answer-button {
                      display: inline-block;
                      cursor: pointer;
                      text-align: right;
                    }
                  }
                }
                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
            .chat-input {
              display: flex;
              .avatar {
                width: 36px;
                height: 36px;
                border-radius: 50%;
                margin-right: 18px;
              }
              .chat-form {
                display: flex;
                width: 100%;
                .chat-message {
                  flex-grow: 1;
                  margin-right: 10px;
                  padding: 12px;
                  border: 1px solid $inputCaption;
                  border-radius: 5px;
                  font-size: 12px;
                  font-weight: 500;
                  &::placeholder {
                    color: $textGray;
                    font-size: 12px;
                  }
                }
                .send-message {
                  width: 158px;
                  border: 1px solid $purple;
                  border-radius: 5px;
                  background-color: $white;
                  box-shadow: 0px 0px 4px 0px #00000040;
                  color: $purple;
                  &:hover {
                    cursor: pointer;
                  }
                }
                .send-message-inactive {
                  width: 158px;
                  border: 1px solid #00000040;
                  border-radius: 5px;
                  background-color: $white;
                  box-shadow: 0px 0px 4px 0px #00000040;
                  color: #00000040;
                  &:hover {
                    cursor: pointer;
                  }
                }
              }
            }
          }
          .history-wrapper {
            background-color: $white;
            padding: 12px 25px 25px 25px;
            box-shadow: 0px -3px 4px 0px #0000000d;
            border-radius: 10px;
            .inner {
              .table-wrapper {
                height: 520px;
                overflow-y: scroll;
                .column-names {
                  padding: 7px;
                  margin-bottom: 5px;
                  display: flex;
                  justify-content: space-between;
                  background-color: $photoBg;
                  border-radius: 5px;
                  div {
                    color: $textGray;
                    text-align: center;
                  }
                  .date {
                    width: 114px;
                  }
                  .time {
                    width: 93px;
                  }
                  .author {
                    width: 133px;
                  }
                  .changes {
                    width: 362px;
                  }
                }
                .table {
                  .table-string {
                    display: flex;
                    justify-content: space-between;
                    .table-cell {
                      padding: 7px 0 13px;
                      color: $textGray;
                      text-align: center;
                      position: relative;
                      &:nth-child(1) {
                        width: 114px;
                      }
                      &:nth-child(2) {
                        width: 93px;
                      }
                      &:nth-child(3) {
                        width: 133px;
                      }
                      &:nth-child(4) {
                        width: 362px;
                      }
                      &::after {
                        position: absolute;
                        content: "";
                        bottom: 0;
                        left: 0;
                        right: 0;
                        border-radius: 5px;
                        height: 1px;
                        background-color: $photoBg;
                      }
                    }
                  }
                }
              }
            }
          }
          .active {
            display: block;
          }
        }

        .dd {
          position: relative;
          width: 250px;
          &.object {
            width: 100% !important;
            margin-top: 0px !important;
            font-size: 12px;
            font-weight: 500;
            // .dd-button {
            //   height: 27px !important;
            // }
          }
          .dd-button {
            @include ui-dropdown-button();
            border-radius: 0px 5px 5px 0px;
          }
          & ::v-deep .vs__dropdown-toggle {
            border: 1px solid $inputCaption;
            box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.25);
            border-radius: 5px;
          }
          &:not(:first-child) {
            margin-top: 10px;
          }
        }

        .avatar-preview {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          &__item {
            width: 180px;
            height: 180px;
            //border-radius: 50%;
            border-radius: 5%;
            margin: auto;
            cursor: pointer;
            &:not(:last-child) {
              margin-right: 30px;
            }
          }
          &__img {
            width: 70px;
            height: 70px;
          }
        }

        .photo-preview {
          display: flex;
          flex-wrap: wrap;
          &__item {
            width: 70px;
            height: 70px;
            cursor: pointer;
            margin-bottom: 20px;
            &:not(:last-child) {
              margin-right: 30px;
            }
          }
          &__img {
            width: 70px;
            height: 70px;
          }
        }

        .input-wrapper {
          margin-bottom: 10px;
          .caption {
            font-size: 12px;
            color: $inputCaption;
          }
          input {
            border: none;
            border-bottom: 1px solid #d9d9d9;
            color: rgba(#1e4fdf, 0.17);
            outline: none;
            font-size: 14px;
            color: #000000;
            width: 100%;
          }
          textarea {
            border: none;
            border-bottom: 1px solid #d9d9d9;
            color: rgba(30, 79, 223, 0.17);
            outline: none;
            font-size: 14px;
            color: #4f4f4f;
            width: 100%;
            resize: none;
          }
        }

      }
    }
    .date-picker {
      align-items: center;
      .d-comp {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        opacity: 0;
        background: black;
        // z-index: 99999;
      }
      .date-start {
        position: relative;
        input {
          @include ui-input($pl: 11px, $w: 265px);
        }
      }
      .date-pick {
        position: relative;
      }
    }
  }

  .multiline {
    white-space: pre-wrap;
    text-align: center;
  }

  .new-account {
    display: flex;
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    width: 1032px;
    height: 558px;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: $white;
    border-radius: 10px;
    padding: 50px 145px 40px 30px;
    // &__inner {
    //     display: flex;
    //     flex-flow: row nowrap;
    // }

    &__validation-rule {
      margin-bottom: 30px;
      span {
        color: $red;
      }
      &-back {
        @include white-button;
        width: 190px;
      }
      &-forward {

      }
    }
  }

  .new-account__columns {
    display: flex;
    flex-flow: row nowrap;
    margin-right: 30px;
  }

  .new-account__column {
    &:first-child {
      margin-right: 30px;
    }
  }

  .new-account__inner-header {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 500;
    margin-top: -20px;
    margin-bottom: 20px;
  }

  .new-account__item {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    width: 265px;
    margin-bottom: 20px;
    &-text {
      width: 100%;
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 5px;
      color: $black;
      span {
        color: $red;
      }
    }

    &-input {
      @include search-input;
    }

    &.textarea {
      grid-column: span 3;
      input {
        border-radius: 5px;
        width: 100%;
      }
    }
  }

  .bottom-view {
    position: absolute;
    bottom: 54px;
  }

</style>
