<template>
  <div class="reportdelivery-modal"
    @close-modal="onModalClose"
  >

    <div class="reportdelivery-modal__inner">

      <div class="step__inner-header">
          <p>{{ $t('ReportText.deliveryReport') }}</p>
      </div>

      <!-- Content -->

      <div class="row">

        <div class="column grid-container-2-70">

          <div class="grid-container">

            <div class="step__item textarea required">
              <p class="step__item-text">
              {{ $t('ReportText.name') }}
              </p>
              <input class="step__item-input" type="text"
                   v-model="reportData.info.name" :disabled="!(editPermission)">
            </div>

            <div class="step__item period required" style="margin-left:20px;">
              <p class="step__item-text">
                {{ $t('ReportText.period_req_req') }}
              </p>
              <DateTimePicker
                  :start="reportData.info.from"
                  :end="reportData.info.to"
                  @updateDates="updateDatesStartEnd"
              />
            </div>

          </div>

          <div class="grid-container">

            <div class="step__item large required">
              <p class="step__item-text">
                {{ $t('ReportText.companies') }}
              </p>
              <div
                v-for="n in allCompanies" :key="n.id"
                class="row"
              >
                <div class="new-report__checkbox">
                  <input
                      :disabled="!editPermission"
                      type="checkbox" :id="n.id"
                      @input="selectCompany(n)"
                      v-model="n.selected" />
                  <label :for="n.id">{{ n.name }}</label>
                </div>
              </div>
            </div>

            <div class="step__item select" style="width: 75px;">
            </div>

          </div>

        </div>


        <div class="column grid-container-2-30">

          <div class="step__item select required">
            <p class="step__item-text">
              {{ $t('ReportText.promotions') }}
            </p>
            <div
              v-for="n in selectedPromotions" :key="n.id"
              class="row"
            >
              <div class="new-report__checkbox">
                <input
                    :disabled="!editPermission"
                    type="checkbox" :id="n.id"
                    v-model="n.selected" />
                <label :for="n.id">{{ n.name }}</label>
              </div>
            </div>

          </div>

        </div>

      </div>

      <div class="new-account__validation-rule" style="margit-top:10px;">
        <span>* </span>{{ $t('Rules.required_info') }}
        <br>
        <span>** </span>{{ $t('ReportText.required_period') }}
      </div>

      <!-- Buttons -->
      <div class="reportdelivery-modal__buttons-block">
          <div v-if="editPermission" class="reportdelivery-modal__download-button" @click="addReport">
              <p>{{ $t('Buttons.generate') }}</p>
          </div>
          <div
              class="reportdelivery-modal__back-button"
              @click="close"
          >
              <p>{{ $t('Buttons.close') }}</p>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

import {getFileName, isEmpty} from "@/tools/dataTools";

import DragDrop from "@/mixins/dragDropEvents";
import InputsMixin from "@/mixins/inputsEvent";
import DateTimePicker from '@/components/DateTimePicker.vue'

import {NewReportItem, ReportItemFromBody, ReportDeliveryRequiredVerify, ReportType, } from "@/models/reports";


export default {
  name: 'ModalEditReportDelivery',
  components: {
    DateTimePicker,
  },
  props: {
    editPermission: Boolean,
    report: Object,
  },
  mixins: [InputsMixin, DragDrop],
  delimiters: ['${', '}'], // Avoid Twig conflicts
  data() {
    return {
      isLoading: false,

      reportData: NewReportItem(),
      selectedCompanies: false,

      allCompanies: [],
      allPromotions: [],

      selectedPromotions: [],
    };
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    report: function (newValue, oldValue) {
      if (!newValue) {
        this.reportData = NewReportItem()
      } else {
        this.reportData = ReportItemFromBody(this.report)
      }
    },
    isLoading() {
      if(this.isLoading)
        this.$progress.start();
      else
        this.$progress.finish();
    },
  },
  mounted() {
    this.allCompanies = this.companies;
    this.allPromotions = this.promotions;

    if (this.report) {
      this.reportData = ReportItemFromBody(this.report)

      // check selected companies
      for(let i = 0; i < this.reportData.info.companies.length; i++) {
        let company = this.reportData.info.companies[i]
        let selectedCompany = this.allCompanies.find(c => c.id === company)
        selectedCompany.selected = true
        this.selectCompany(selectedCompany)
      }

      // check selected promotions
      for(let i = 0; i < this.reportData.info.promotions.length; i++) {
        let promotion = this.reportData.info.promotions[i]
        this.allPromotions.find(p => p.id === promotion).selected = true
      }

    }
  },
  computed: {
    companies() {
      return JSON.parse(JSON.stringify(this.GET_COMPANIES()))
    },
    promotions() {
      return JSON.parse(JSON.stringify(this.GET_PROMOTIONS().all))
    },
  },
  methods: {
    ...mapGetters(["GET_COMPANIES","GET_PROMOTIONS",]),
    ...mapActions(["CREATE_REPORT","UPDATE_REPORT"]),

    getFileName,

    textShow(event) {
      if (event.target.value.length > 33) {
        console.dir(event.target.parentElement.querySelector(".step__item-popup"))
        event.target.parentElement.querySelector(".step__item-popup").classList.add("not-empty");
        event.target.parentElement.querySelector(".step__item-popup p").innerText = event.target.value;
      }
    },

    updateDatesStartEnd(from, to) {
      this.reportData.info.from = from
      this.reportData.info.to = to
    },

    selectCompany(company) {
      let th = this

      process.nextTick(() => {

        if(company) {
          // try to remove all promotions if conpany is not selected
          if(company.selected) {
            th.selectedPromotions = th.selectedPromotions.concat(th.allPromotions.filter( (p) => p.company === company.id ))
          }
          else
            th.selectedPromotions = th.selectedPromotions.filter( (p) => p.company !== company.id );
        }
      });
    },

    addReport() {

      if(this.reportData == null) {
        this.$notify({
          type: "error",
          title: this.$t('Errors.error_not_ok'),
          text: this.$t('Errors.error_wrong_data'),
        });
      }
      else {
        // get all selected companies
        this.reportData.info.companies = []
        this.allCompanies.map(p => {
          if(p.selected)
            this.reportData.info.companies.push(p.id)
        })

        // get all selected promotions
        this.reportData.info.promotions = []
        this.selectedPromotions.map(p => {
          if(p.selected)
            this.reportData.info.promotions.push(p.id)
        })

        if(!ReportDeliveryRequiredVerify(this.reportData)) {
          this.$notify({
            type: "error",
            title: this.$t('Errors.error_not_ok'),
            text: this.$t('Errors.missing_required_data'),
          });
        }
        else if(isEmpty(this.reportData.id)) {
          this.createReport()
        }
        else {
          this.updateReport()
        }
      }
    },

    createReport() {
      this.isLoading = true
      this.reportData.type = ReportType.DELIVERY

      this.CREATE_REPORT({
        ...this.reportData,
      }).then((res) => {
        this.isLoading = false

        if (res.success) {
          this.reportData.id = res.id

          this.$emit('downloadReport', this.reportData)

          this.$notify({
            type: "success",
            title: this.$t('Errors.success_operation'),
            text: this.$t('ReportText.report_created'),
          });
        } else {
          this.$notify({
            type: "error",
            title: this.$t('Errors.error_not_ok'),
            text: this.$t('Errors.error_try_again'),
          });
        }
      });
    },

    updateReport() {
      this.isLoading = true

      this.UPDATE_REPORT({
        ...this.reportData,
      }).then((res) => {
        this.isLoading = false

        if (res.success) {
          this.$emit('downloadReport', this.reportData)

          this.$notify({
            type: "success",
            title: this.$t('Errors.success_operation'),
            text: this.$t('ReportText.report_updated'),
          });
        } else {
          this.$notify({
            type: "error",
            title: this.$t('Errors.error_not_ok'),
            text: this.$t('Errors.error_try_again'),
          });
        }
      });
    },

    close() {
      this.reportData = NewReportItem();

      this.$emit('close-modal');
    },

  }
}
</script>

<style lang="scss" src="@/assets/scss/common.scss"></style>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixin.scss";

.reportdelivery-modal {
    position: absolute;
    width: 1100px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 45px 78px 25px 45px;
    background-color: $white;
    border-radius: 10px;
    box-shadow: 0px 0px 8px 2px #CED5F480;
    z-index: 1;

    &__dragAndDrop {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      //width: 600px;
      height: 270px;
      margin-bottom: 25px;
      padding: 67px 67px 67px;
      border: 1px dotted $purple;
      border-radius: 10px;
      background-color: $SideBlocksColor;
      cursor: pointer;
      svg {
          margin: 0 auto 35px;
      }
    }

    &__dragAndDrop-text {
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      cursor: pointer;
      color: $black;
    }

    &__buttons-block {
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }

    &__download-button {
        width: 190px;
        height: 35px;
        margin-right: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(180deg, #8F21C9 0%, #8F21C9 0.01%, #4640B9 100%);
        color: $white;
        font-size: 16px;
        border-radius: 5px;
        cursor: pointer;
    }

    &__back-button {
        width: 190px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $purple;
        font-size: 16px;
        border: 1px solid $purple;
        border-radius: 5px;
        cursor: pointer;
    }

    .grid-container-2-30 {
      width: 30%;
    }

    .grid-container-2-50 {
      width: 50%;
      padding: 10px;
      column-gap: 10px;
    }

    .grid-container-2-70 {
      width: 70%;
    }
}

.photo-preview {
  display: flex;
  flex-wrap: wrap;
  &__item {
    width: 70px;
    height: 70px;
    cursor: pointer;
    margin-bottom: 20px;
    &:not(:last-child) {
      margin-right: 30px;
    }
  }
  &__img {
    width: 70px;
    height: 70px;
  }
}

.upload-file {
  margin-bottom: 5px;
  cursor: no-drop;
}

.download-file {
  margin-bottom: 5px;
  cursor: context-menu;
}

.file_input {
  margin-bottom: -47px;
  width: inherit;
  height: inherit;
  opacity: 0;
  overflow: hidden;
  position: absolute;
}

/* Create two equal columns that floats next to each other */
.column {
  float: left;
  width: 50%;
  //padding: 10px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto;
  padding: 10px;
}

.dd {
  position: relative;
  width: 100%;
  &.object {
    width: 265px !important;
    margin-top: 0px !important;
    font-size: 12px;
    font-weight: 500;
    // .dd-button {
    //   height: 27px !important;
    // }
  }
  .dd-button {
    @include ui-dropdown-button();
    border-radius: 0px 5px 5px 0px;
  }
  & ::v-deep .vs__dropdown-toggle {
    border: 1px solid $inputCaption;
    box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
  &:not(:first-child) {
    margin-top: 10px;
  }
}

.new-report__checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 10px;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
  &-checkbox {
    display: none;
  }
  &-fake {
    display: inline-block;
    width: 17px;
    height: 17px;
    margin-right: 12px;
    border-radius: 2px;
    box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.25);
    position: relative;
    &::before {
      content: '✔';
      position: absolute;
      color: $black;
      font-size: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
    }
  }
  &-text {
    font-size: 12px;
    font-weight: 500;
    color: $activeText;
  }
  .new-code__checkbox-checkbox:checked + .new-code__checkbox-fake::before {
    opacity: 1;
  }
  span {
    color: $red;
  }
  label {
    margin-left: 5px;
  }
}


</style>
