<template>
    <div
        class="campaign-modal"
        v-if="opened"
        @click.self="$emit('close-modal')"
    >
        <div class="current-campaign">
            <img class="current-campaign__avatar" :src="getCompanyAvatarUrl(company)" alt="campaign logo">
            <p class="current-campaign__name">Компания: {{ getCompanyName(company) }}</p>
        </div>
        <div class="campaign-other" v-if="companies.length > 0" >
            <ul class="campaign-other__list">
                <li class="campaign-other__item"
                    v-for="company in companies"
                    :key="company.id"
                    @click="setEmployeesCompany(company)"
                >
                    <img class="campaign-other__item-image"
                         :src="getCompanyAvatarUrl(company)"
                         alt="campaign avatar"
                    >
                    <p class="campaign-other__item-name">{{ company.name }}</p>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

import {isEmpty} from "@/tools/dataTools";
import {getCompanyAvatarUrl} from "@/tools/imageTools";
import {UsersType} from "@/models/users";

export default {
  name: 'CampaignListModal',
  props: {
    opened: Boolean,
  },
  methods: {
    ...mapGetters(["GET_USER","GET_EMPLOYEES","GET_COMPANIES",]),
    ...mapActions(["SET_EMPLOYEES_FILTER",]),

    getCompanyAvatarUrl,

    getCompanyName(company) {
      if(isEmpty(company))
        return '-'
      else {
        return company.name;
      }
    },

    setEmployeesCompany(data) {
      this.SET_EMPLOYEES_FILTER({
        ...this.employees_filter,
        company: !data ? null : data.id,
      });

      if(this.opened)
        this.$emit('close-modal')
    },

  },
  computed: {
    user() {
      return this.GET_USER();
    },
    isAdmin() {
      return this.user ? (this.user.role === UsersType.ADMIN) : false;
    },
    company() {
      if (this.companies.length > 0) {
        let company = this.companies.find(c => c.id === this.employees_filter.company);

        if(company == null) {
          this.setEmployeesCompany(this.companies[0])
          return this.companies[0];
        }
        else {
          return company;
        }
      }
      else {
        this.setEmployeesCompany(null)

        if(this.isAdmin) {
          return  {id: null, name: this.$t('CompanyText.all_companies')}
        }
        else {
          return  {id: null, name: this.$t('CompanyText.miss_company')}
        }
      }
    },
    companies() {
      if(this.isAdmin) {
        return [
          {id: null, name: this.$t('CompanyText.all_companies')},
          ...this.GET_COMPANIES()
        ]
      }
      else {
        return this.user ? this.user.companies : []
      }
    },
    employees_filter() {
      return this.GET_EMPLOYEES().filter;
    },
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixin.scss";

.campaign-modal {
  position: absolute;
  top: 87px;
  right: 20%;
  width: 280px;
  border-radius: 10px;
  padding: 18px 10px 18px 15px;
  display: flex;
  z-index: 100;
  flex-flow: column nowrap;
  align-items: center;
  background-color: $white;
  box-shadow: 0px 0px 8px 2px $shadow;
}

.current-campaign {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    align-items: center;
    position: relative;
    padding-bottom: 22px;
    margin-bottom: 18px;
    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 1px;
        background-color: $bodyBackground;
    }

    &__avatar {
        width: 60px;
        height: 60px;
        border-radius: 10%;
        margin-bottom: 15px;
    }
   
    &__name {
        @include fz-14-500;
    }
}

.campaign-other {
    width: 100%;
    &__list {
        
    }
              
    &__item {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      cursor: pointer;
    }
        
    &__item-image {
      margin-right: 11px;
      height: 25px;
      width: 25px;
      border-radius: 50%;
    }

    &__item-name {
        font-size: 12px;
    }
}
</style>