import {
    hasContent,
    isEmpty
} from "@/tools/dataTools";

import {
    CompanyUsers,
    CompanyUsersIsNew,
    CompanyUsersIsNewAndEmpty,
    CompanyUsersIsEmpty,
} from "@/models/company_users";

export const CompanyUsersCompany = {
    // UUID
    id: '',
    // string
    name: null,
    // string
    avatar: null,
    // Object
    CompanyUsers: JSON.parse(JSON.stringify(CompanyUsers)),
};

export const CompanyUsersCompanyIsNew = (data) => {
    return isEmpty(data) || CompanyUsersIsNew(data.CompanyUsers);
};

export const CompanyUsersCompanyIsNewAndEmpty = (data) => {
    return CompanyUsersCompanyIsNew(data) && CompanyUsersIsNewAndEmpty(data.CompanyUsers);
};

export const CompanyUsersCompanyIsEmpty = (data) => {
    return !CompanyUsersCompanyIsNew(data) && CompanyUsersIsEmpty(data.CompanyUsers);
};

export const NewCompanyUsersCompany = (company = null, user = null) => {
    let newObject = JSON.parse(JSON.stringify(CompanyUsersCompany));

    if(hasContent(company)) {
        newObject.id = company.id;
        newObject.name = company.name;
        newObject.avatar = company.avatar;

        newObject.CompanyUsers.company = company.id;
    }

    if(hasContent(user)) {
        newObject.CompanyUsers.user = user.id;
    }

    return newObject;
};

export const CompanyUsersCompanyFromBody = (data) => {
    let object = NewCompanyUsersCompany()

    object.id = data.id;
    object.name = data.name;
    object.avatar = data.avatar;

    object.CompanyUsers = JSON.parse(JSON.stringify(data.CompanyUsers));

    return object;
};


// export module
export default {
    CompanyUsersCompany,

    CompanyUsersCompanyIsNew,
    CompanyUsersCompanyIsNewAndEmpty,
    CompanyUsersCompanyIsEmpty,

    NewCompanyUsersCompany,
    CompanyUsersCompanyFromBody,
};