<template>
    <header class="header">
      <ModalEditEmployee
          :opened="modalEmployeeOpened"
          :tab="modalOpenedTab"
          :employee="currentEmployee"
          @close-modal="closeModalEmployee"
      />
      <ModalEditCompany
          :opened="modalCompanyOpened"
          :company="currentCompany"
          :tab="modalOpenedTab"
          @close-modal="closeModalCompany"
      />
      <CampaignListModal
          :opened="campaignListModalOpened"
          @close-modal="campaignListModalClose()"
      />
      <NotificationsListModal
          :opened="notificationsListModalOpened"
          @open-user-modal="openModalEmp(2)"
          @open-company-modal="openModalCompany(2)"
          @close-modal="notificationsListModalClose()"
      />

        <a href="/" class="header__logo">
            <img class="header__logo-img" src="@/assets/img/logo.svg" alt="logo">
        </a>
        <div class="header__message">
            <!--p class="header__message-text">Добрый день,
                <span>Сергей!</span>
            </p-->
        </div>

        <div v-if="userCompany"
            class="header__campaign"
            @click="campaignListModalToggle()"
        >
            <img class="header__campaign-avatar" :src="getCompanyAvatarUrl(company)">
            <p class="header__campaign-name">
              {{ getCompanyName(this.company) }}
            </p>
        </div>

        <div class="header__employee" @click="openModalEmp()">
            <img class="header__employee-avatar" :src="getUserAvatarUrl(user ? user.avatar : null, true)">
            <div class="header__employee-description">
                <p class="header__employee-name">
                  {{ userFio }}
                </p>
                <p class="header__employee-position">
                  {{ userRole }}
                </p>
            </div>
        </div>
        <div class="header__buttons">
            <div class="header__buttons-notifications" @click="notificationsListModalToggle()">
              <img v-if="haveNotifications" width="20" height="20" src="@/assets/img/notifications.svg" alt="">
              <img v-else width="20" height="20" src="@/assets/img/no_notifications.svg" alt="">
            </div>
            <div class="header__buttons-exit" @click="logoutDialog">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.53836 0C1.13036 0 0.739072 0.162087 0.450574 0.450605C0.162076 0.739122 0 1.13044 0 1.53846V18.4615C0 18.8696 0.162076 19.2609 0.450574 19.5494C0.739072 19.8379 1.13036 20 1.53836 20H13.076C13.28 20 13.4757 19.919 13.6199 19.7747C13.7642 19.6304 13.8452 19.4348 13.8452 19.2308C13.8452 19.0268 13.7642 18.8311 13.6199 18.6868C13.4757 18.5426 13.28 18.4615 13.076 18.4615H1.53836V1.53846H13.076C13.28 1.53846 13.4757 1.45742 13.6199 1.31316C13.7642 1.1689 13.8452 0.973243 13.8452 0.769231C13.8452 0.565218 13.7642 0.369561 13.6199 0.225302C13.4757 0.0810437 13.28 0 13.076 0H1.53836ZM16.3127 5.99385C16.1683 5.84941 15.9724 5.76826 15.7681 5.76826C15.5639 5.76826 15.368 5.84941 15.2236 5.99385C15.0791 6.13829 14.998 6.33419 14.998 6.53846C14.998 6.74273 15.0791 6.93864 15.2236 7.08308L17.3727 9.23077H6.9226C6.7186 9.23077 6.52296 9.31181 6.37871 9.45607C6.23446 9.60033 6.15342 9.79599 6.15342 10C6.15342 10.204 6.23446 10.3997 6.37871 10.5439C6.52296 10.6882 6.7186 10.7692 6.9226 10.7692H17.3727L15.2236 12.9169C15.0791 13.0614 14.998 13.2573 14.998 13.4615C14.998 13.6658 15.0791 13.8617 15.2236 14.0062C15.368 14.1506 15.5639 14.2317 15.7681 14.2317C15.9724 14.2317 16.1683 14.1506 16.3127 14.0062L19.774 10.5446C19.8457 10.4732 19.9025 10.3883 19.9413 10.2948C19.98 10.2014 20 10.1012 20 10C20 9.89882 19.98 9.79863 19.9413 9.70518C19.9025 9.61172 19.8457 9.52684 19.774 9.45538L16.3127 5.99385Z" fill="#4F4F4F"/>
                </svg>
            </div>
        </div>
    </header>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

import CampaignListModal from './CampaignListModal.vue'
import NotificationsListModal from './NotificationsListModal.vue'
import ModalEditEmployee from "@/components/ModalEditEmployee";
import ModalEditCompany from "@/components/ModalEditCompany";

import {
  UsersType,
  UserTypeText,
} from "@/models/users";

import {
  hasContent, isEmpty,
} from "@/tools/dataTools";

import {
  getUserAvatarUrl,
  getCompanyAvatarUrl,
} from '@/tools/imageTools';

export default {
  name: 'Header',
  components: {
    ModalEditCompany,
    CampaignListModal,
    NotificationsListModal,
    ModalEditEmployee,
  },
  data() {
    return {
      campaignListModalOpened: false,
      notificationsListModalOpened: false,
      simpleDialog: true,

      userCompany: false,
      userFio: '',
      userRole: '-',

      userNotifications: false,
      modalEmployeeOpened: false,
      modalOpenedTab: 0,
      currentEmployee: null,

      companyNotifications: false,
      modalCompanyOpened: false,
      currentCompany: null,
    };
  },
  mounted() {
    window.addEventListener("click", this.campaignListModalDisable);
    window.addEventListener("click", this.notificationsListModalDisable);
  },
  unmounted() {
    window.removeEventListener("click", this.campaignListModalDisable);
    window.removeEventListener("click", this.notificationsListModalDisable);
  },
  created() {
    this.getUserInfo()
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    "$store.state.user": function (newValue, oldValue) {
      // check changes in current employee
      if (this.currentEmployee) {
        this.currentEmployee = this.user;
      }

      // check new messages
      if(this.user != null && this.user.newMessages) {
        this.userNotifications = true;
      }
      else {
        this.userNotifications = false;
      }

      // update user data
      this.getUserInfo();
    },
    // eslint-disable-next-line no-unused-vars
    "$store.state.companies": function (newValue, oldValue) {
      this.companyNotifications = false

      const companies = this.GET_COMPANIES() ? this.GET_COMPANIES() : []

      companies.map(company => {
        if(company.newMessages) {
          this.currentCompany = company
          this.companyNotifications = true
        }
      })
    }
  },
  methods: {
    ...mapGetters(["GET_USER", "GET_EMPLOYEES", "GET_COMPANIES", "GET_SUSPICIOUS"]),
    ...mapActions(["SET_EMPLOYEES_FILTER","VIEW_USER_MESSAGE","VIEW_COMPANY_MESSAGE","EXIT_FROM_ACCOUNT"]),

    getUserAvatarUrl,
    getCompanyAvatarUrl,

    logoutDialog() {
      if (confirm(this.$t('LogoutText.card_header'))) {
        //callback to execute when user confirms the action
        this.EXIT_FROM_ACCOUNT()
      }
    },
    notificationsListModalToggle() {
      if(this.haveNotifications) {
        this.campaignListModalOpened = false
        this.notificationsListModalOpened = !this.notificationsListModalOpened;
      }
    },
    notificationsListModalClose() {
      this.notificationsListModalOpened = false;
    },
    notificationsListModalDisable(e) {
      if (!this.$el.contains(e.target)) {
        this.notificationsListModalOpened = false;
      }
    },
    campaignListModalToggle() {
      this.notificationsListModalOpened = false
      this.campaignListModalOpened = !this.campaignListModalOpened;
    },
    campaignListModalClose() {
        this.campaignListModalOpened = false;
    },
    campaignListModalDisable(e) {
        if (!this.$el.contains(e.target)) {
            this.campaignListModalOpened = false;
        }
    },
    getUserInfo() {
      if (isEmpty(this.user))
        return

      this.delay(500).then(() => {
        let user = {...this.user}
        if (hasContent(user)) {
          // get user FIO
          this.userFio = `${user.surname ? user.surname : ''} ${user.name ? user.name : ''} ${user.lastname ? user.lastname : ''}`;

          // get user role
          try {
            this.userRole = UserTypeText(user.role);
          } catch (error) {
            this.userRole = '-';
          }

          // get user company
          if(this.isAdmin) {
            // show all company list
            this.userCompany = true;
          }
          else {
            // find user lists
            //this.userCompany = this.user ? this.user.companies.length > 0 : false;
            this.userCompany = true;
          }
        } else {
          // clear user name and role
          this.userFio = '';
          this.userRole = '-';

          // clear user company
          this.userCompany = false;
        }
      });
    },
    openModalEmp(tab) {
      this.campaignListModalOpened = false
      this.notificationsListModalOpened = false

      this.modalOpenedTab = tab ? tab : 0;

      this.currentEmployee = this.user;

      if (this.currentEmployee.newMessages) {
        this.VIEW_USER_MESSAGE(this.currentEmployee);
      }

      this.modalEmployeeOpened = true;
    },
    closeModalEmployee() {
      this.currentEmployee = null;
      this.modalEmployeeOpened = false;
    },


    openModalCompany(tab) {
      if(this.currentCompany) {
        this.modalOpenedTab = tab ? tab : 0;

        if (this.currentCompany.newMessages) {
          this.VIEW_COMPANY_MESSAGE(this.currentCompany);
        }

        this.modalCompanyOpened = true;
      }
    },
    closeModalCompany() {
      this.currentCompany = null;
      this.modalCompanyOpened = false;
    },

    delay(time) {
      return new Promise(resolve => setTimeout(resolve, time));
    },
    getCompanyName(company) {
      if(isEmpty(company))
        return '-'
      else {
        return company.name;
      }
    },

    setEmployeesCompany(data) {
      let company = !data ? null : data.id

      // add condition for exclude multiple assign with the same value
      if(this.employees_filter && this.employees_filter.company !== company) {
        this.SET_EMPLOYEES_FILTER({
          ...this.employees_filter,
          company: company,
        });
      }

    },
  },
  computed: {
    user() {
      return this.GET_USER();
    },
    isAdmin() {
      return this.user ? (this.user.role === UsersType.ADMIN) : false;
    },
    haveNotifications() {
      return this.userNotifications ||
          this.companyNotifications ||
          (this.suspiciousParticipants.length > 0);
    },
    suspiciousParticipants() {
      return this.GET_SUSPICIOUS() ? this.GET_SUSPICIOUS().filter(s => s.visible) : [];
    },
    company() {
      if (this.companies.length > 0) {
        let company = this.companies.find(c => c.id === this.employees_filter.company);

        if(company == null) {
          this.setEmployeesCompany(this.companies[0])
          return this.companies[0];
        }
        else {
          return company;
        }
      }
      else {
        this.setEmployeesCompany(null)

        if(this.isAdmin) {
          return  {id: null, name: this.$t('CompanyText.all_companies')}
        }
        else {
          return  {id: null, name: this.$t('CompanyText.miss_company')}
        }
      }
    },
    companies() {
      if(isEmpty(this.user)) {
        return [];
      }
      else {
        if (this.isAdmin) {
          return [
            {id: null, name: this.$t('CompanyText.all_companies')},
            ...this.GET_COMPANIES()
          ]
        } else {
          return this.user ? this.user.companies : []
        }
      }
    },
    employees_filter() {
      return this.GET_EMPLOYEES().filter;
    },
  },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixin.scss";

    .header {
        padding: 11px 80px 15px 44px;
        background-color: $SideBlocksColor;
        display: flex;
        align-items: center;
        box-shadow: 0px 0px 8px 2px rgba($shadow, 0.5);
        margin-bottom: 30px;
        &__logo {
            margin-right: 23px;
        }

        &__message {
            margin: 0 auto 0 0;
        }

        &__message-text {
            font-weight: 500;
            font-size: 18px;
            color: $black;
            span {
                font-weight: 700;
                font-size: 18px;
            }
        }

        &__campaign {
            display: flex;
            align-items: center;
            margin-right: 75px;
            cursor: pointer;
        }

        &__campaign-avatar {
            margin-right: 10px;
            height: 32px;
            width: 32px;
            border-radius: 50%;
        }

        &__campaign-name {
            @include fz-14-500;
        }

        &__employee {
            display: flex;
            align-items: center;
            margin-right: 64px;
            cursor: pointer;
        }

        &__employee-avatar {
            margin-right: 10px;
            height: 32px;
            width: 32px;
            border-radius: 50%;
        }

        &__employee-description {
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
        }
        &__employee-name {
            @include fz-14-500;
        }

        &__employee-position {
            @include fz-12-500;
        }

        &__buttons {
            display: flex;
            align-items: center;
        }

        &__buttons-notifications {
            margin-right: 25px;
            cursor: pointer;
        }
        &__buttons-exit {
            cursor: pointer;
        }
    }
</style>
