<template>
    <section class="step second">

        <!-- Client registration information -->
        <div class="step__content">
          <div class="step__inner">
            <p class="step__header">
              {{ $t('PromotionText.participant_registration') }}
            </p>

            <div>

              <div class="title-part">
                <p class="step__item-text" style="margin-top: 10px;">
                  {{ $t('PromotionText.use_register_participant') }}
                </p>
              </div>

              <div class="grid-part-container">

                <div class="step__item select">
                  <div class="new-promotion__checkbox">
                    <input
                        type="checkbox" id="email" value="email"
                        v-model="item.register_participants.email" />
                    <label for="email">{{ $t('PromotionText.useEmail') }}</label>
                  </div>
                </div>

                <div class="step__item select">
                  <div class="new-promotion__checkbox">
                    <input
                        type="checkbox" id="phone" value="phone"
                        v-model="item.register_participants.phone" />
                    <label for="phone">{{ $t('PromotionText.usePhone') }}</label>
                  </div>
                </div>

                <div class="step__item select">
                </div>

              </div>


              <div class="title-part">
                <p class="step__item-text" style="margin-top: 10px;">
                  {{ $t('PromotionText.use_register_codes') }}
                </p>
              </div>

              <div class="grid-part-container">

                <div class="step__item select">
                  <div class="new-promotion__checkbox">
                    <input
                        :disabled="!editPermission"
                        type="radio" name="register_codes" id="qr_and_bill" value="qr_and_bill"
                        v-model="item.register_codes_value" />
                    <label for="qr_and_bill">{{ $t('PromotionText.qr_and_bill') }}</label>
                  </div>
                </div>

                <div class="step__item select">
                  <div class="new-promotion__checkbox">
                    <input
                        :disabled="!editPermission"
                        type="radio" name="register_codes" id="promocode_and_bill" value="promocode_and_bill"
                        v-model="item.register_codes_value" />
                    <label for="promocode_and_bill">{{ $t('PromotionText.promocode_and_bill') }}</label>
                  </div>
                </div>

                <div class="step__item select">
                  <div class="new-promotion__checkbox">
                    <input
                        :disabled="!editPermission"
                        type="radio" name="register_codes" id="bill" value="bill"
                        v-model="item.register_codes_value" />
                    <label for="bill">{{ $t('PromotionText.bill') }}</label>
                  </div>
                </div>

                <div class="step__item select">
                  <div class="new-promotion__checkbox">
                    <input
                        :disabled="!editPermission"
                        type="radio" name="register_codes" id="qr" value="qr"
                        v-model="item.register_codes_value" />
                    <label for="qr">{{ $t('PromotionText.qr') }}</label>
                  </div>
                </div>

                <div class="step__item select">
                  <div class="new-promotion__checkbox">
                    <input
                        :disabled="!editPermission"
                        type="radio" name="register_codes" id="promocode" value="promocode"
                        v-model="item.register_codes_value" />
                    <label for="promocode">{{ $t('PromotionText.promocode') }}</label>
                  </div>
                </div>

              </div>

            </div>

          </div>
        </div>

        <!-- Personal data information -->
        <div class="step__content" style="margin-top: 20px">
            <div class="step__inner">
                <p class="step__header">
                  {{ $t('PromotionText.personal_data_information') }}
                </p>

                <div>

                  <!-- Required User Data-->
                  <div class="grid-container">

                    <div class="step__item select">
                      <div class="new-promotion__checkbox">
                        <input
                            :disabled="!editPermission"
                            type="checkbox" id="surname"
                            v-model="item.personal_data.surname" />
                        <label for="surname">{{ $t('FieldsText.surname_label') }}</label>
                      </div>
                    </div>

                    <div class="step__item select">
                      <div class="new-promotion__checkbox">
                        <input
                            :disabled="!editPermission"
                            type="checkbox" id="name"
                            v-model="item.personal_data.name" />
                        <label for="name">{{ $t('FieldsText.name_label') }}</label>
                      </div>
                    </div>

                    <div class="step__item select">
                      <div class="new-promotion__checkbox">
                        <input
                            :disabled="!editPermission"
                            type="checkbox" id="lastname"
                            v-model="item.personal_data.lastname" />
                        <label for="lastname">{{ $t('FieldsText.lastname_label') }}</label>
                      </div>
                    </div>

                  </div>


                  <div class="grid-container">

                    <div class="step__item select">

                      <div class="new-promotion__checkbox">
                        <input
                            :disabled="!editPermission"
                            type="checkbox" id="birthday"
                            v-model="item.personal_data.birthday" />
                        <label for="birthday">{{ $t('FieldsText.birth_label') }}</label>
                      </div>
                    </div>

                    <div class="step__item select">
                      <div class="new-promotion__checkbox">
                        <input
                            :disabled="!editPermission"
                            type="checkbox" id="phone"
                            v-model="item.personal_data.phone" />
                        <label for="phone">{{ $t('FieldsText.phone_label') }}</label>
                      </div>
                    </div>

                    <div class="step__item select">
                      <div class="new-promotion__checkbox">
                        <input
                            :disabled="!editPermission"
                            type="checkbox" id="email"
                            v-model="item.personal_data.email" />
                        <label for="email">{{ $t('FieldsText.email_label') }}</label>
                      </div>
                    </div>

                  </div>


                  <div class="grid-container">

                    <div class="step__item select">

                      <div class="new-promotion__checkbox">
                        <input
                            :disabled="!editPermission"
                            type="checkbox" id="address"
                            v-model="item.personal_data.address" />
                        <label for="address">{{ $t('FieldsText.your_address_label') }}</label>
                      </div>
                    </div>

                    <div class="step__item select">

                      <div class="new-promotion__checkbox">
                        <input
                            :disabled="!editPermission"
                            type="checkbox" id="passport_data"
                            v-model="item.personal_data.passport_data" />
                        <label for="passport_data">{{ $t('FieldsText.passport_info') }}</label>
                      </div>
                    </div>

                    <div class="step__item select">
                      <div class="new-promotion__checkbox">
                        <input
                            :disabled="!editPermission"
                            type="checkbox" id="gender"
                            v-model="item.personal_data.gender" />
                        <label for="gender">{{ $t('FieldsText.gender_info') }}</label>
                      </div>
                    </div>

                  </div>


                  <!-- Accept User Agreement Link -->
                  <div class="grid-container">
                    <div class="step__item textarea large">
                        <p class="step__item-text">
                            {{ $t('TermsAndConditions.user_agreement_req') }}
                        </p>
                        <input class="step__item-input" type="text" :placeholder="$t('TableHeaders.text_example_link')"
                               required
                               v-model="item.personal_data.user_agreement" :disabled="!editPermission">
                    </div>
                  </div>

                  <!-- Accept Promotion Agreement Link -->
                  <div class="grid-container">
                    <div class="step__item textarea large">
                        <p class="step__item-text">
                            {{ $t('TermsAndConditions.promotion_agreement_req') }}
                        </p>
                        <input class="step__item-input" type="text" :placeholder="$t('TableHeaders.text_example_link')"
                               required
                               v-model="item.personal_data.promotion_agreement" :disabled="!editPermission">
                    </div>
                  </div>

                  <!-- Accept Newsletters Agreement Link -->
                  <div class="grid-container">
                    <div class="step__item textarea large">
                        <p class="step__item-text">
                            {{ $t('TermsAndConditions.newsletters_agreement') }}
                        </p>
                        <input class="step__item-input" type="text" :placeholder="$t('TableHeaders.text_example_link')"
                               v-model="item.personal_data.newsletters_agreement" :disabled="!editPermission">
                    </div>
                  </div>

                  <!-- Text About Upload Personal Data -->
                  <div class="grid-container">
                    <div class="step__item textarea large">
                        <p class="step__item-text">
                            {{ $t('TableHeaders.text_personal_data') }}
                        </p>
                        <input class="step__item-input" type="text" :placeholder="$t('TableHeaders.text_example_personal_response')"
                               v-model="item.personal_data.textAboutPersonalData" :disabled="!editPermission" style="margin-right: 64px">
                    </div>
                  </div>

                </div>

            </div>
        </div>

      <!-- Additional data information -->
      <div class="step__content" style="margin-top: 20px">

        <div class="page-header">
          <p class="step__header">
            {{ $t('PromotionText.additional_personal_data_information') }}
          </p>
          <div class="buttons-block">
            <div class="page-header__outload">
              <p class="page-header__outload-text" @click="addNewPersonalData">
                {{ $t('PromotionText.new_user_personal_date') }}
              </p>
            </div>
          </div>
        </div>

        <div>

          <!-- Additional User Data-->
          <div class="grid-container" v-for="data in item.personal_data.additional_fields" :key="data">

            <div class="step__item textarea required">
              <p class="step__item-text">
                {{ $t('FieldsText.title_req_label') }}
              </p>
              <input v-model="data.name" class="step__item-input" type="text" :placeholder="$t('FieldsText.title_label_input')"
                     required :disabled="!editPermission">
            </div>

            <div class="step__item select required">
              <p class="step__item-text">
                {{ $t('FieldsText.type_label') }}
              </p>
              <div class="dd object" :class="`dd-type-filter-${data.name}`">
                <v-select
                    :disabled="!(editPermission)"
                    :placeholder="$t('FieldsText.select_from_list')"
                    :options="AdditionalDataTypes()"
                    :reduce="(option) => option.type"
                    label="label"
                    :clearable="false"
                    v-model="data.type"
                >
                  <!-- eslint-disable-next-line vue/no-unused-vars  -->
                  <template #no-options="{ search, searching, loading }">
                    {{ $t('Errors.missing_options') }}
                  </template>
                </v-select>
                <div
                    class="dd-button"
                    @click="openSelectOptions(`.dd-type-filter-${data.name} .vs__dropdown-toggle`)"
                ></div>
              </div>
            </div>

            <div class="step__item select">
              <div class="table__cell button">
                <div v-if="editPermission" class="clickable-button" style="padding-bottom: 5px;" @click="deletePersonalData(data)">
                  <img
                      :title="$t('Buttons.delete')"
                      src="@/assets/img/icon_delete.svg"
                      alt="notification avatar">
                </div>
              </div>
            </div>

          </div>

        </div>

      </div>

    </section>

    <div class="buttons-block">
        <p class="buttons-block__validation-rule">
          {{ $t('Rules.required_fields') }}
        </p>
        <div 
            class="buttons-block__back"
            @click="minusStep"
        >
            <p class="buttons-block__back-text">
                {{ $t('Buttons.back') }}
            </p>
        </div>
        <div 
            class="buttons-block__forward"
            @click="plusStep"
        >
            <p class="buttons-block__forward-text">
              {{ $t('Buttons.forward') }}
            </p>
        </div>
    </div>
</template>

<script>

import {isEmpty} from "@/tools/dataTools";
import InputsMixin from "@/mixins/inputsEvent";
import {
  AdditionalDataType,
  AdditionalDataTypes,
} from "@/models/promotions";


export default {
  name: 'NewPromotionStepSecond',
  emits: ["step","updatePromotion"],
  components: {
  },
  mixins: [InputsMixin],
  props: {
    editPermission: Boolean,
    promotion: Object,
  },
  data() {
    return {
      AdditionalDataType,

      item: {}
    }
  },
  watch: {
    promotion: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler () {
        this.item = this.promotion

        this.item.register_codes_value =
            this.item.register_codes.qr_and_bill ? 'qr_and_bill' :
            this.item.register_codes.promocode_and_bill ? 'promocode_and_bill' :
            this.item.register_codes.bill ? 'bill' :
            this.item.register_codes.qr ? 'qr' :
            this.item.register_codes.promocode ? 'promocode' :
            'qr_and_bill'
      }
    },
  },
  methods: {
    AdditionalDataTypes,

    textShow(event) {
      if (event.target.value.length > 33) {
        //console.dir(event.target.parentElement.querySelector(".step__item-popup"))
        //event.target.parentElement.querySelector(".step__item-popup").classList.add("not-empty");
        event.target.parentElement.classList.add("not-empty");
        //event.target.parentElement.querySelector(".step__item-popup p").innerText = event.target.value;
        event.target.parentElement.innerText = event.target.value;
      }
    },
    minusStep() {
      this.$emit("updatePromotion", this.item)
        this.$emit("step", 1)
    },

    checkStepCompleted() {
      let completed = true

      if(isEmpty(this.item.personal_data.user_agreement))
        completed = false
      if(isEmpty(this.item.personal_data.promotion_agreement))
        completed = false
      if(!this.checkAdditionalPersonalData())
        completed = false

      if (completed) {
        return true
      }
      else {
        this.$notify({
          type: "error",
          title: this.$t('Errors.error_not_ok'),
          text: this.$t('Errors.missing_required_data'),
        });
      }

      return false
    },

    plusStep() {
      // check if all fields are completed
      if(this.checkStepCompleted()) {

        // prepare register participant value
        if (this.item.register_participants.phone) {
          // mark personal data phone
          this.item.personal_data.phone = true
        }
        if (this.item.register_participants.email) {
          // mark personal data phone
          this.item.personal_data.email = true
        }

        // prepare register buy value
        if (this.item.register_codes_value === 'promocode_and_bill') {
          this.item.register_codes.promocode_and_bill = true
          this.item.register_codes.qr_and_bill = false
          this.item.register_codes.bill = false
          this.item.register_codes.qr = false
          this.item.register_codes.promocode = false
        }
        else if (this.item.register_codes_value === 'bill') {
          this.item.register_codes.bill = true
          this.item.register_codes.qr_and_bill = false
          this.item.register_codes.promocode_and_bill = false
          this.item.register_codes.qr = false
          this.item.register_codes.promocode = false
        }
        else if (this.item.register_codes_value === 'qr') {
          this.item.register_codes.qr = true
          this.item.register_codes.qr_and_bill = false
          this.item.register_codes.promocode_and_bill = false
          this.item.register_codes.bill = false
          this.item.register_codes.promocode = false
        }
        else if (this.item.register_codes_value === 'promocode') {
          this.item.register_codes.promocode = true
          this.item.register_codes.qr = false
          this.item.register_codes.bill = false
          this.item.register_codes.qr_and_bill = false
          this.item.register_codes.promocode_and_bill = false
        }
        else {
          this.item.register_codes.qr_and_bill = true
          this.item.register_codes.promocode_and_bill = false
          this.item.register_codes.bill = false
          this.item.register_codes.qr = false
          this.item.register_codes.promocode = false
        }

        this.$emit("updatePromotion", this.item)
        this.$emit("step", 3)
      }
    },
    addNewPersonalData() {
      if (this.item) {
        if(!this.checkAdditionalPersonalData()) {
          this.$notify({
            type: "error",
            title: this.$t('Errors.error_not_ok'),
            text: this.$t('Errors.missing_required_data'),
          });

          return
        }

        if(!this.item.personal_data.additional_fields)
          this.item.personal_data.additional_fields = []

        this.item.personal_data.additional_fields.push({
          name: '',
          type: AdditionalDataType.TEXT, // variants=['text','check','date',]
        })
      }
    },
    deletePersonalData(date) {
      if (this.item && date) {
        if(!this.item.personal_data.additional_fields)
          this.item.personal_data.additional_fields = []

        this.item.personal_data.additional_fields = this.item.personal_data.additional_fields.filter((item) => item.name !== date.name)
      }
    },
    checkAdditionalPersonalData() {
      let success = true

      if(!this.item.personal_data.additional_fields)
        this.item.personal_data.additional_fields = []

      for (let i = 0; i < this.item.personal_data.additional_fields.length; i++) {
        let item = this.item.personal_data.additional_fields[i]

        if (isEmpty(item.name) || isEmpty(item.type))
          success = false
      }


      return success
    }
  }
}
</script>

<style lang="scss" src="@/assets/scss/common.scss"></style>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixin.scss";

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 10px;
}

.dd {
  position: relative;
  width: 100%;
  &.object {
    width: 265px !important;
    margin-top: 0px !important;
    font-size: 12px;
    font-weight: 500;
    // .dd-button {
    //   height: 27px !important;
    // }
  }
  .dd-button {
    @include ui-dropdown-button();
    border-radius: 0px 5px 5px 0px;
  }
  & ::v-deep .vs__dropdown-toggle {
    border: 1px solid $inputCaption;
    box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
  &:not(:first-child) {
    margin-top: 10px;
  }
}

.clickable-button {
  cursor: pointer;
}

</style>