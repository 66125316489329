import {isEmpty} from "@/tools/dataTools";

export const parseDate = (time) => {
  if (isEmpty(time))
    return ''

  time = new Date(time);

  let date = time.getDate();
  let month = time.getMonth() + 1;
  let year = time.getFullYear();

  if (date < 10) date = "0" + date;

  if (month < 10) month = "0" + month;

  return `${date}/${month}/${year}`;
};

export const parseDateWithDates = (time) => {
  if (isEmpty(time))
    return ''

  time = new Date(time);

  let date = time.getDate();
  let month = time.getMonth() + 1;
  let year = time.getFullYear();

  if (date < 10) date = "0" + date;

  if (month < 10) month = "0" + month;

  // 01.05.2023
  return `${date}/${month}/${year}`;
};

export const parseTime = (time) => {
  if (isEmpty(time))
    return ''

  time = new Date(time);

  let hours = time.getHours();
  let minutes = time.getMinutes();

  if (hours < 10) hours = "0" + hours;

  if (minutes < 10) minutes = "0" + minutes;

  return `${hours}:${minutes}`;
};


export const parseFullTime = (time) => {
  if(isEmpty(time))
    return null

  time = new Date(time);

  let date = time.getDate();
  let month = time.getMonth() + 1;
  let year = time.getFullYear();

  let hours = time.getHours();
  let minutes = time.getMinutes();

  if (date < 10) date = "0" + date;

  if (month < 10) month = "0" + month;

  if (hours < 10) hours = "0" + hours;

  if (minutes < 10) minutes = "0" + minutes;

  return `${date}/${month}/${year} ${hours}:${minutes}`;
};


export const parseFullWithSecsTime = (time) => {
  if (isEmpty(time))
    return ''

  time = new Date(time);

  let date = time.getDate();
  let month = time.getMonth() + 1;
  let year = time.getFullYear();

  let hours = time.getHours();
  let minutes = time.getMinutes();
  let secconds = time.getSeconds();

  if (date < 10) date = "0" + date;

  if (month < 10) month = "0" + month;

  if (hours < 10) hours = "0" + hours;

  if (minutes < 10) minutes = "0" + minutes;

  if (secconds < 10) secconds = "0" + secconds;

  return `${date}.${month}.${year} ${hours}:${minutes}:${secconds}`;
};


export const getMilliseconds = (dateTime) => {
  if (isEmpty(dateTime))
    return 0

  return new Date(dateTime).getTime();
};


// export module
export default {
  parseDate,
  parseTime,
  parseFullTime,
  parseFullWithSecsTime,

  getMilliseconds,
};